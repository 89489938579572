import React, { useContext, useMemo } from 'react'

import {
  digObject,
  formatDate,
  launchModal,
  matchFilterBetweenDates,
  matchFilterNumber,
  sortArrayBy,
  toggleArray,
} from '@campaignhub/javascript-utils'

import {
  UseFormOptions,
  useForm,
  useLoadMore,
  useSetState,
  useThunkDispatch,
  useWatchEntityUpdates,
} from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import { getSortKey } from '@functions/getSortKey'
import { getStatusIdByKey } from '@functions/status'
import { matchInvoiceStatus, validateInvoicesForMerge } from '@functions/invoice'

import * as invoiceActions from '@redux/modules/invoice'

import { invoiceFormState } from '@models/invoice'
import type {
  InvoiceCsvFormParams,
  InvoiceModel,
  InvoiceRequestOptions
} from '@models/invoice'

import PageContext from '@contexts/pageContext'
import { AppDispatch } from '@redux/store'

export interface InvoicesPayload {
  callbacks: {
    approveInvoices: () => void,
    mergeInvoices: () => void,
    loadMore: () => void,
    clearSelectedInvoiceIds: () => void,
    generateInvoicesCSV: () => void,
    sendReminders: () => void,
    toggleSelectAllIds: () => void,
    toggleSelectedId: () => void,
    voidInvoices: () => void,
  },
  canLoadMore: boolean,
  canMergeInvoices: boolean,
  filteredInvoices: InvoiceModel[],
  filteredInvoicesCount: number,
  hasFilteredInvoices: boolean,
  hasSelectedInvoices: boolean,
  isAllSelected: boolean,
  isBulkSelecting: boolean,
  loading: boolean,
  selectedInvoiceIds: number[],
  selectedInvoiceIdsCount: number,
}

const watchEntityKeys = ['invoices']

type DefaultState = {
  selectedInvoiceIds: number[],
  isAllSelected: boolean,
}

const defaultState = {
  selectedInvoiceIds: [],
  isAllSelected: false,
}

const bulkInvoicesAction = (params) => {
  const { action, dispatch, selectedInvoiceIds } = params
  const {
    approveInvoices: approveInvoicesFn,
    sendReminders: sendRemindersFn,
    voidInvoices: voidInvoicesFn,
  } = invoiceActions

  const invoiceActionFns = {
    approve: approveInvoicesFn,
    sendReminders: sendRemindersFn,
    void: voidInvoicesFn,
  }

  const actionFn = invoiceActionFns[action]

  return dispatch(actionFn(selectedInvoiceIds))
}

type ToggleSelectedIdParams = {
  allInvoiceIdsCount: number,
  invoiceId: number,
  selectedInvoiceIds: number[],
  setState: React.Dispatch<React.SetStateAction<Partial<DefaultState>>>,
}

const toggleSelectedId = (params: ToggleSelectedIdParams) => {
  const {
    allInvoiceIdsCount,
    invoiceId,
    selectedInvoiceIds,
    setState,
  } = params

  const updatedSelectedIds = toggleArray(selectedInvoiceIds, invoiceId)

  setState({ selectedInvoiceIds: updatedSelectedIds, isAllSelected: updatedSelectedIds.length === allInvoiceIdsCount })
}

type ToggleSelectAllIdsParams = {
  isAllSelected: boolean,
  allInvoiceIds?: number[],
  setState: React.Dispatch<React.SetStateAction<Partial<DefaultState>>>,
}

const toggleSelectAllIds = (params: ToggleSelectAllIdsParams) => {
  const {
    allInvoiceIds,
    isAllSelected,
    setState,
  } = params

  setState({
    selectedInvoiceIds: !isAllSelected ? allInvoiceIds : [],
    isAllSelected: !isAllSelected,
  })
}

type MergeInvoices = {
  sortedSelectedInvoices: InvoiceModel[],
  showMergeInvoicesModal: Function,
}

const mergeInvoices = (params: MergeInvoices) => new Promise((resolve, reject) => {
  const { sortedSelectedInvoices, showMergeInvoicesModal } = params

  if (showMergeInvoicesModal) {
    const payload = {
      sortedSelectedInvoices,
    }

    showMergeInvoicesModal(payload)

    resolve({ success: true, result: payload })
  }

  reject(new Error('showMergeInvoicesModal not defined in PageContext callbacks'))
})

type UseInvoicesOptions = {
  filters?: {
    businessUnitId?: number,
    contactId?: number,
    creditApplied?: boolean,
    endDate?: string,
    limit?: number,
    sort?: string,
    startDate?: string,
    status?: string,
    statusIds?: number[],
  },
  performHttpRequests?: boolean,
  requestOptions?: InvoiceRequestOptions,
}

export type InvoicesFilters = UseInvoicesOptions['filters']

type DownloadInvoicesCsvParams = {
  dispatch: AppDispatch,
  entityParams: InvoiceCsvFormParams,
}

export const downloadInvoicesCSV = (params: DownloadInvoicesCsvParams) => {
  const { dispatch, entityParams } = params
  const { downloadInvoicesCSV: downloadFn } = invoiceActions

  return dispatch(downloadFn(entityParams))
}

export function useInvoiceForm(
  invoice: Partial<InvoiceCsvFormParams>,
  options: UseFormOptions = {}
) {
  const { customRequiredFields = [{ key: 'startDate' }], validateOn } = options

  const invoiceCsvForm = useForm(
    invoiceFormState,
    { entity: invoice, requiredFields: [...customRequiredFields], validateOn },
    [invoice.status],
  )

  return {
    ...invoiceCsvForm,
  }
}

function useInvoices(options?: UseInvoicesOptions) {
  const { filters, requestOptions } = options || {}

  const {
    businessUnitId: filterBusinessUnitId,
    contactId: filterContactId,
    creditApplied: filterCreditApplied,
    endDate: filterEndDate,
    limit: filterLimit,
    sort,
    startDate: filterStartDate,
    status: filterStatus,
    statusIds: filterStatusIds,
  } = filters || {}

  const [state, setState] = useSetState(defaultState)
  const { isAllSelected, selectedInvoiceIds } = state

  const dispatch = useThunkDispatch()

  const { callbacks } = useContext(PageContext)
  const { showMergeInvoicesModal, } = callbacks || {}

  const {
    updatedEntities: { invoices: invoicesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { loading: loadingInvoices } = useSelector(reduxState => reduxState.invoices)

  const entities = useSelector(reduxState => reduxState.entities)
  const { contacts, invoices, statuses } = entities

  const order = (sort != null) ? sort.split('_')[0] : 'desc'
  const defaultSortKey = filterStatus === 'overdue' ? 'daysOverdue' : 'dateCreated'
  const sortKey = getSortKey(sort, defaultSortKey)

  const filteredInvoices = useMemo(() => {
    const filtered = Object.values(invoices).filter((invoice) => {
      const {
        businessUnitId, contactId, dateCreated, dateDue, statusId,
      } = invoice || {}

      const status = digObject(statuses, String(statusId), {})
      const formattedCreatedDate = formatDate(dateCreated, 'ISO8601', 'yyyy-MM-dd')

      const contact = digObject(contacts, String(contactId), {})
      invoice.contact = contact

      const matchBusinessUnit = matchFilterNumber(Number(businessUnitId), Number(filterBusinessUnitId))
      const matchContact = matchFilterNumber(Number(contactId), Number(filterContactId))
      const matchDate = matchFilterBetweenDates(formattedCreatedDate, filterStartDate, filterEndDate)
      const matchStatusString = matchInvoiceStatus(status?.key, filterStatus, dateDue)
      const matchStatusIds = (filterStatusIds && filterStatusIds.length) ? filterStatusIds.includes(statusId) : true

      return (
        matchBusinessUnit
        && matchContact
        && matchDate
        && matchStatusString
        && matchStatusIds
      )
    })

    setState({ isAllSelected: false }) // reset Select All checkbox if list is refreshed or changed to another status

    return sortArrayBy(filtered, order, sortKey)
  }, [invoicesUpdatedAt, JSON.stringify(filters)])

  const filteredInvoicesCount = filteredInvoices?.length

  const loadMorePayload = useLoadMore({
    ...options,
    loadedCount: filteredInvoicesCount,
  })
  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  useReduxAction(
    'invoices',
    'loadInvoices',
    {
      ...filtersWithOffset,
      filterLimit,
      ...requestOptions,
    },
    [filtersWithOffset, performHttpRequests],
    { shouldPerformFn: ({ loading }) => performHttpRequests && !loading },
  )

  const selectedInvoiceIdsCount = selectedInvoiceIds.length
  const selectedInvoices = filteredInvoices.filter((invoice: InvoiceModel) => selectedInvoiceIds.includes(invoice.id))
  const sortedSelectedInvoices = sortArrayBy(selectedInvoices, 'asc', 'dateDue')

  const draftStatusId = getStatusIdByKey('draft', statuses)
  const overdueStatusId = getStatusIdByKey('overdue', statuses)

  const canEditInvoices = !selectedInvoices.some(invoice => invoice.statusId !== draftStatusId)
  const canMergeInvoices = validateInvoicesForMerge(selectedInvoices)
  const canSendInvoiceReminders = !selectedInvoices.some(invoice => invoice.statusId !== overdueStatusId)

  const hasFilteredInvoices = !!filteredInvoicesCount
  const hasSelectedInvoices = !!selectedInvoiceIdsCount
  const isBulkSelecting = selectedInvoiceIdsCount > 1

  const allInvoiceIds = filteredInvoices.map((invoice: InvoiceModel) => invoice.id)
  const allInvoiceIdsCount = allInvoiceIds.length

  return {
    callbacks: {
      approveInvoices: () => bulkInvoicesAction({ action: 'approve', dispatch, selectedInvoiceIds }),
      mergeInvoices: () => mergeInvoices({ sortedSelectedInvoices, showMergeInvoicesModal }),
      loadMore,
      clearSelectedInvoiceIds: () => setState({ selectedInvoiceIds: [] }),
      downloadInvoicesCSV: (entityParams: InvoiceCsvFormParams) => downloadInvoicesCSV({ entityParams, dispatch }),
      generateInvoicesCSV: () => launchModal({
        callbacks,
        modalKey: 'GenerateInvoicesCsvModal',
        payload: {
          filterContactId,
          filterStatus,
        },
      }),
      sendReminders: () => bulkInvoicesAction({ action: 'sendReminders', dispatch, selectedInvoiceIds }),
      toggleSelectAllIds: () => toggleSelectAllIds({ isAllSelected, allInvoiceIds, setState }),
      toggleSelectedId: (invoiceId: number) => toggleSelectedId({
        invoiceId, selectedInvoiceIds, allInvoiceIdsCount, setState,
      }),
      voidInvoices: () => bulkInvoicesAction({ action: 'void', dispatch, selectedInvoiceIds }),
    },
    canLoadMore,
    canEditInvoices,
    canMergeInvoices,
    canSendInvoiceReminders,
    filteredInvoices,
    filteredInvoicesCount,
    hasFilteredInvoices,
    hasSelectedInvoices,
    isAllSelected,
    isBulkSelecting,
    loading: loadingInvoices,
    selectedInvoiceIds,
    selectedInvoiceIdsCount,
  }
}

export default useInvoices

import { isValidEmail } from '@campaignhub/javascript-utils'

export type ContactRequestOptions = {
  includeContactContactPaymentMethods?: boolean,
  includeContactContactType?: boolean,
  includeContactContactUsers?: boolean,
  includeContactParentContact?: boolean,
  includeContactParentContactName?: boolean,
  includeContactSubscriptions?: boolean,
}

export type ContactModel = {
  active?: boolean,
  addressLine1?: string,
  addressLine2?: string,
  bankAccountNumber?: string,
  bsb?: string,
  businessName?: string,
  businessNumber?: string,
  cacheKey?: string,
  city?: string,
  contactType?: number,
  contactTypeId?: number,
  countryId?: number,
  dateCreated?: string,
  defaultCurrency?: string,
  email?: string,
  firstName?: string,
  id: number | null,
  invoicesOverduePercentage?: number,
  lastName?: string,
  name?: string,
  parentContact?: ContactModel,
  parentContactId?: number,
  parentContactName?: string,
  phone?: string,
  postCode?: string,
  realbaseId?: string,
  regionName?: string,
  stripeReferenceKey?: string,
  stateName?: string,
  tradingName?: string,
  userIds?: number[],
  website?: string,
}

const state = {
  active: true,
  addressLine1: '',
  addressLine2: '',
  bankAccountNumber: '',
  bsb: '',
  businessName: '',
  businessNumber: '',
  city: '',
  contactTypeId: null,
  countryId: null,
  dateCreated: '',
  defaultCurrency: '',
  email: '',
  firstName: '',
  id: null,
  lastName: '',
  name: '',
  parentContact: null,
  parentContactId: null,
  phone: '',
  postCode: '',
  realbaseId: '',
  regionName: '',
  stripeReferenceKey: '',
  stateName: '',
  tradingName: '',
  website: '',
}

export type StatementFormParams = {
  endDate?: string,
  id: number | null,
  startDate?: string,
}

export const statementFormState = {
  endDate: '',
  id: null,
  startDate: '',
}

export const requiredFields = [
  { key: 'addressLine1' },
  { key: 'businessNumber' },
  { key: 'contactTypeId' },
  { key: 'countryId' },
  { key: 'email', validation: isValidEmail, invalidMessage: 'Invalid Email format' },
  { key: 'name' },
  { key: 'phone' },
  { key: 'postCode' },
  { key: 'regionName' },
  { key: 'website' },
]

export default state

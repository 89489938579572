import { useContext } from 'react'

import {
  Button, Columns, LoadingModule, SaveFormModule,
} from '@campaignhub/suit-theme'
import { generateApplicationUrl } from '@campaignhub/javascript-utils'

import useContact, { useContactForm } from '@hooks/useContact'
import useContactType from '@hooks/useContactType'
import useCurrentUser from '@hooks/useCurrentUser'

import PageContext from '@contexts/pageContext'

import EntityActivity from '@components/EntityActivity'

import PageLayout from '../../../../components/PageLayout'

import Address from '../Address'
import BillingNotes from '../BillingNotes'
import BankDetails from '../BankDetails'
import ContactUsers from '../ContactUsers'
import Details from '../Details'
import ExternalCodes from '../ExternalCodes'
import ParentContact from '../ParentContact'
import PaymentMethods from '../PaymentMethods'
import Tools from '../Tools'

const viewOnly = JSON.parse(process.env.REACT_APP_VIEW_ONLY || "false")

const PageContent = (props: { contactId: number }) => {
  const { contactId } = props

  const pageContext = useContext(PageContext)
  const { callbacks: { updateContact } } = pageContext

  const { isAdmin } = useCurrentUser()

  const contactPayload = useContact({ id: contactId }, {
    performHttpRequests: !!contactId,
    requestOptions: {
      includeContactBillingNotes: !!contactId,
      includeContactContactType: !!contactId,
      includeContactContactUsers: !!contactId,
      includeContactParentContact: !!contactId,
      includeContactContactPaymentMethods: !!contactId,
    },
  })

  const {
    callbacks,
    callbacks: {
      updateContact: updateFn,
    },
    contact,
    loading,
    updating,
  } = contactPayload

  const contactFormPayload = useContactForm(contact)
  const { entityState, saveEnabled } = contactFormPayload

  const {
    contactTypeId, name, parentContactId, realbaseId,
  } = entityState

  const { contactType } = useContactType({ id: contactTypeId })
  const { name: contactTypeName } = contactType || {}

  const applicationUrlPayload = generateApplicationUrl({
    applicationKey: 'realbase',
    environment: process.env.REACT_APP_APPLICATION_ENV,
    hash: `#/account/${contactTypeName}s/${realbaseId}/edit`,
  })
  const { applicationUrlWithHash } = applicationUrlPayload

  const updateContactPayload = {
    callbacks: {
      action: updateFn,
    },
    entityParams: entityState,
    toastText: 'Contact Updated',
  }

  return (
    <PageLayout entityPayload={contactPayload} activeTabBarItemKey="details">
      <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
        <Columns.Main>
          <LoadingModule loading={loading} times={3} />

          {!loading && (
            <>
              <Details
                contactFormPayload={contactFormPayload}
                contactTypeName={contactTypeName}
                editInRealbaseUrl={applicationUrlWithHash}
              />

              <Address
                contactFormPayload={contactFormPayload}
                editInRealbaseUrl={applicationUrlWithHash}
              />

              <BankDetails contactFormPayload={contactFormPayload} />

              {isAdmin && (
                <ExternalCodes contactFormPayload={contactFormPayload} />
              )}

              {!viewOnly && (
                <SaveFormModule>
                  <Button
                    buttonStyle="primaryCreate"
                    disabled={!saveEnabled}
                    loading={updating}
                    marginLeft="auto"
                    onClick={() => updateContact(updateContactPayload)}
                    size="medium"
                  >
                    Save Details
                  </Button>
                </SaveFormModule>
              )}

              <EntityActivity
                componentSettings={{
                  conversation: {
                    title: name,
                  },
                }}
                subjectType="Contact"
                subjectId={contactId}
              />
            </>
          )}
        </Columns.Main>

        <Columns.Sidebar>
          {parentContactId && (
            <ParentContact parentContactId={parentContactId} />
          )}

          <Tools callbacks={callbacks} contact={contact} isAdmin={isAdmin} />

          <ContactUsers contactId={contactId} isAdmin={isAdmin} />

          <PaymentMethods contactId={contactId} isAdmin={isAdmin} />

          {isAdmin && (
            <BillingNotes contactId={contactId} />
          )}
        </Columns.Sidebar>
      </Columns>
    </PageLayout>
  )
}

export default PageContent

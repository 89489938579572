import React, { useMemo } from 'react'
import { formatMoney } from 'accounting'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-light-svg-icons'

import {
  Box, DashboardModule, Link, StatusBadge, Text, Table,
} from '@campaignhub/suit-theme'

import { digObject, formatDate, goToEntity } from '@campaignhub/javascript-utils'

import { displaySubscriptionInterval, getSubscriptionDateTitle } from '@functions/subscription'
import { statusColor } from '@functions/status'

import { generateUrls as generateContactUrls } from '@hooks/useContact'
import { generateUrls as generateSubscriptionUrls } from '@hooks/useSubscription'
import useSelector from '@hooks/useSelector'
import useSubscriptions from '@hooks/useSubscriptions'

import type { EntitiesState } from '@redux/entities'

import { defaultRequestOptions } from '@sections/Client/packs/Accounting'

import ListBlankState from '@components/ListBlankState'
import BusinessUnitLogo from '@components/BusinessUnitLogo'

import type { PageFilterModuleProps } from '@campaignhub/suit-theme'

type BuildColumnParams = {
  entities: EntitiesState,
  subscriptionStatus: string,
}

const buildColumns = (params: BuildColumnParams) => {
  const { entities, subscriptionStatus } = params
  const { businessUnits, contacts, statuses } = entities

  const showSubscriptionEndDate = (subscriptionStatus === 'expiring') || (subscriptionStatus === 'inactive')

  return [
    {
      cellProps: {
        key: 'statusId',
        verticalAlign: 'middle',
        width: '75px',
      },
      title: 'Status',
      dataKey: 'statusId',
      render: (statusId: number) => {
        const status = statuses[statusId]
        const statusKey = status?.key

        return (
          <StatusBadge boxProps={{ minWidth: '75px' }} color={statusColor(statusKey)} ghost text={statusKey} />
        )
      },
    },
    {
      cellProps: {
        key: 'title',
        verticalAlign: 'middle',
        width: '100px',
      },
      title: 'Title',
      dataKey: 'id',
      render: (id: number) => {
        const { editSubscriptionUrl } = generateSubscriptionUrls({ id })

        return (
          <Link href={editSubscriptionUrl}>
            {`SUB-${id}`}
          </Link>
        )
      },
    },
    {
      cellProps: {
        key: 'contact',
        verticalAlign: 'middle',
      },
      title: 'Contact',
      dataKey: 'contactId',
      render: (contactId: number) => {
        const contact = digObject(contacts, String(contactId), {})
        const { name } = contact

        return (
          <Link
            onClick={() => goToEntity({
              generateUrls: () => generateContactUrls({ id: contactId }),
              entityUrlKey: 'editContactUrl',
            })}
          >
            {name}
          </Link>
        )
      },
    },
    {
      cellProps: {
        key: 'interval',
        verticalAlign: 'middle',
      },
      title: 'Billing',
      dataKey: 'interval',
      render: (interval: string) => (
        <Text>{displaySubscriptionInterval(interval)}</Text>
      ),
    },
    {
      cellProps: {
        key: 'date',
        verticalAlign: 'middle',
      },
      title: getSubscriptionDateTitle(subscriptionStatus),
      dataKey: showSubscriptionEndDate ? 'subscriptionEnd' : 'subscriptionStart',
      render: (dataKey: string) => (
        <Text variant="ellipsis">{formatDate(dataKey, 'ISO8601', 'DD')}</Text>
      ),
    },
    {
      cellProps: {
        key: 'products',
        verticalAlign: 'middle',
      },
      title: 'Products',
      dataKey: 'businessUnitIds',
      render: (businessUnitIds: number[]) => (
        <Box flowDirection="column">
          {
            businessUnitIds.map((businessUnitId: number, index: number) => {
              const businessUnit = digObject(businessUnits, String(businessUnitId), {})
              const { key } = businessUnit
              const isLastId = businessUnitIds.length === index + 1

              return (
                <BusinessUnitLogo
                  boxProps={{
                    height: 30,
                    marginRight: isLastId ? 0 : 'medium',
                    width: 30,
                  }}
                  businessUnitKey={key}
                />
              )
            })
          }
        </Box>
      ),
    },
    {
      cellProps: {
        key: 'amount',
        verticalAlign: 'middle',
      },
      title: 'Amount ($)',
      dataKey: 'incTaxAmount',
      render: (incTaxAmount: number) => (
        <Text>{incTaxAmount ? formatMoney(incTaxAmount) : ''}</Text>
      ),
    },
    {
      cellProps: {
        key: 'edit',
        verticalAlign: 'middle',
      },
      dataKey: 'id',
      render: (id: number) => {
        const { editSubscriptionUrl } = generateSubscriptionUrls({ id })

        return (
          <Link greyLink href={editSubscriptionUrl}>
            <FontAwesomeIcon icon={faPencil} />
          </Link>

        )
      },
    },
  ]
}

interface SubscriptionsProps {
  currentStatus: string,
  pageFilters: PageFilterModuleProps,
}

const Subscriptions = (props: SubscriptionsProps) => {
  const { currentStatus, pageFilters } = props

  const subscriptionsPayload = useSubscriptions({
    filters: {
      status: currentStatus,
      ...pageFilters,
    },
    performHttpRequests: !!currentStatus,
    requestOptions: {
      ...defaultRequestOptions.subscription,
    },
  })
  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredSubscriptions,
    filteredSubscriptionsCount,
    hasFilteredSubscriptions,
    loading,
  } = subscriptionsPayload

  const { entities } = useSelector(reduxState => reduxState)

  const memoColumns = useMemo(
    () => buildColumns(
      { entities, subscriptionStatus: currentStatus },
    ),
    [currentStatus, filteredSubscriptions.length],
  )

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column' }}
      loading={loading}
      title="Subscriptions"
    >
      {!loading && !hasFilteredSubscriptions && <ListBlankState />}

      {!loading && hasFilteredSubscriptions && (
        <Table
          boxProps={{ border: 'none', maxHeight: 500, overflowY: 'auto' }}
          columns={memoColumns}
          data={filteredSubscriptions}
          stickyHeader
        />
      )}

      <DashboardModule.LoadMoreFooter
        callbacks={{ loadMore }}
        canLoadMore={canLoadMore}
        entityCount={filteredSubscriptionsCount}
        loading={loading}
      />
    </DashboardModule>
  )
}

export default React.memo(Subscriptions)

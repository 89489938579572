let accessToken: null | string = null

export const setAccessToken = async (getAccessTokenSilently: Function) => {
  try {
    accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
    })
  } catch (error){
    console.log(error)
  }
}

export const getAccessToken = () => accessToken

import { useMemo } from 'react'

import { sortArrayBy } from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { TaxJurisdictionRequestOptions } from "@models/taxJurisdiction"
import type { ModuleState } from "@redux/modules"

const watchEntityKeys = ['taxJurisdiction']

type TaxJurisdictionFilters = {
  string?: string,
}

type UseTaxJurisdictionOptions = {
  filters?: TaxJurisdictionFilters,
  performHttpRequests?: boolean,
  requestOptions?: TaxJurisdictionRequestOptions,
}

function useTaxJurisdictions(options: UseTaxJurisdictionOptions = {}) {
  const { performHttpRequests } = options

  const {
    updatedEntities: { taxJurisdictions: taxJurisdictionsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { taxJurisdictions } = useSelector(reduxState => reduxState.entities)

  const filteredTaxJurisdictions = useMemo(() => {
    const filtered = Object.values(taxJurisdictions)
    return sortArrayBy(filtered, 'asc', 'name')
  }, [taxJurisdictionsUpdatedAt])

  useReduxAction(
    'taxJurisdictions',
    'loadTaxJurisdictions',
    {},
    [performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    filteredTaxJurisdictions,
  }
}

export default useTaxJurisdictions
const entitySettings = {
  businessUnits: {
    actionName: 'loadBusinessUnit',
    endpoint: 'business-units',
    groupTitle: 'BUSINESS UNITS',
    key: 'businessUnits',
    label: 'Business Unit',
    labelPlural: 'Business Units',
  },
  businessUnitProducts: {
    actionName: 'loadBusinessUnitProduct',
    endpoint: 'business-unit-products',
    groupTitle: 'BUSINESS UNIT PRODUCTS',
    key: 'businessUnitProducts',
    label: 'Business Unit Product',
    labelPlural: 'Business Unit Products',
  },
  contacts: {
    actionName: 'loadContact',
    endpoint: 'contacts',
    groupTitle: 'CONTACTS',
    key: 'contacts',
    label: 'Contact',
    labelPlural: 'Contacts',
  },
  prices: {
    actionName: 'loadPrice',
    endpoint: 'prices',
    groupTitle: 'PRICES',
    key: 'prices',
    label: 'Price',
    labelPlural: 'Prices',
  },
  users: {
    actionName: 'loadUser',
    endpoint: 'users',
    groupTitle: 'Users',
    key: 'users',
    label: 'User',
    labelPlural: 'Users',
  },
}

export default entitySettings

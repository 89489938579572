import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { getAccessToken } from '@functions/accessToken'

import AdminRoutes from '@sections/Admin/routes'
import Login from '@sections/Auth/Login'

import useAccountingSystems from '@hooks/useAccountingSystems'
import useBankTypes from '@hooks/useBankTypes'
import useBillingEntities from '@hooks/useBillingEntities'
import useBusinessUnits from '@hooks/useBusinessUnits'
import useCountries from '@hooks/useCountries'
import useCurrentUser from '@hooks/useCurrentUser'
import useStatuses from '@hooks/useStatuses'
import useTaxJurisdictions from '@hooks/useTaxJurisdictions'
import useUserTypes from '@hooks/useUserTypes'

// When logging in the token is not instantly available
// We wait and check 0.1 second later for it to be available

type SetAccessToken = (token: string | null) => React.Dispatch<React.SetStateAction<string | null>>

const waitForAccessToken = (setAccessToken: SetAccessToken) => {
  const intervalId = setInterval(() => {
    const token = getAccessToken()
    if (token){
      clearInterval(intervalId)
      setAccessToken(token)
    }
  }, 100)
}

const AuthenticationController = () => {
  const [accessToken, setAccessToken] = useState(null)

  const auth0Payload = useAuth0()
  const { isAuthenticated } = auth0Payload

  useEffect(() => {
    waitForAccessToken(setAccessToken)
  }, [])

  // Access token needs to be present to make requests
  const performHttpRequests = !!accessToken && !!isAuthenticated

  const { currentUser } = useCurrentUser({ performHttpRequests })

  // load Entities
  useAccountingSystems({ performHttpRequests })
  useBankTypes({ performHttpRequests })
  useBillingEntities({ performHttpRequests })
  useBusinessUnits({ performHttpRequests })
  useCountries({ performHttpRequests })
  useStatuses({ performHttpRequests })
  useTaxJurisdictions({performHttpRequests})
  useUserTypes({ performHttpRequests })

  if (!accessToken || !isAuthenticated || !currentUser?.id){
    return <Login />
  }

  return <AdminRoutes />
}

export default AuthenticationController

import mainNavigationStyle from './styles/mainNavigation.module.scss'

const brand = {
  key: 'default',
  logoMark: {
    fillColor: 'black',
    logoKey: 'core_billing',
  },
  styles: {
    mainNavigation: mainNavigationStyle,
  },
  title: 'Default',
}

export default brand

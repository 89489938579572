import Swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import { Button, Form, SidebarModal } from '@campaignhub/suit-theme'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import { useContactPaymentMethodForm } from '@hooks/useContactPaymentMethod'

import { swalColors } from '@constants/swal'

type ConfirmActionParams = {
  action: (params: HandleCallbackActionParams) => void,
  actionPayload: HandleCallbackActionParams,
  confirmButtonText: string,
  icon: string,
  text: string,
  title: string,
}

const confirmAction = (params: ConfirmActionParams) => {
  const {
    action,
    actionPayload,
    confirmButtonText,
    icon,
    text,
    title,
  } = params

  const { deletion } = swalColors

  Swal
    .fire({
      title,
      text,
      icon,
      showCancelButton: true,
      confirmButtonText,
      confirmButtonColor: deletion,
    })
    .then(({ value }) => {
      if (value){
        action(actionPayload)
      }
    })
}

interface EditContactPaymentMethodProps {
  callbacks: {
    closeModal: () => void,
    deleteContactPaymentMethod: () => void,
    setDefaultContactPaymentMethod: () => void,
  },
  contactPaymentMethodPayload: any,
}

const EditContactPaymentMethod = (props: EditContactPaymentMethodProps) => {
  const { callbacks, contactPaymentMethodPayload } = props || {}
  const {
    closeModal,
    deleteContactPaymentMethod,
    updateContactPaymentMethod,
  } = callbacks || {}

  const {
    callbacks: {
      deleteContactPaymentMethod: deleteFn,
      setDefaultContactPaymentMethod,
      updateContactPaymentMethod: updateFn,
    },
    contactPaymentMethod,
    loading,
  } = contactPaymentMethodPayload

  const formPayload = useContactPaymentMethodForm(contactPaymentMethod)
  const {
    entityState,
    entityState: {
      contactId,
      data,
      externalKey,
      externalValue,
      id,
      isDefault,
      paymentMethodId,
    },
    errors,
    handlers,
    saveEnabled,
  } = formPayload

  const name = digObject(data, 'billing_details.name')
  const last4 = digObject(data, 'card.last4')
  const exp_month = digObject(data, 'card.exp_month')
  const exp_year = digObject(data, 'card.exp_year')

  const deleteContactPaymentMethodPayload = {
    callbacks: {
      action: deleteFn,
      afterAction: closeModal,
    },
    toastText: 'Payment Method Deleted',
  }

  const updateContactPaymentMethodPayload = {
    callbacks: {
      action: updateFn,
      afterAction: closeModal,
    },
    toastText: 'Payment Method Updated',
    entityParams: entityState,
  }

  return (
    <>
      <SidebarModal.Content>
        <Form>
          {/*
            Initially Disable all fields
            Can only set as default or delete contact payment method
          */}
          <Form.Field label="Card Number">
            <input disabled name="last4" type="text" value={last4 ? `•••• •••• •••• ${last4}` : ''} />
          </Form.Field>

          <Form.Field label="Name on the Card" marginTop="large">
            <input disabled name="name" type="text" value={name} />
          </Form.Field>

          <Form.Row marginTop="large">
            <Form.Field label="Expiry Month">
              <input disabled name="exp_month" type="number" value={exp_month} />
            </Form.Field>

            <Form.Field label="Expiry Year">
              <input disabled name="exp_year" type="number" value={exp_year} />
            </Form.Field>
          </Form.Row>

          <Form.Field direction="column" label="More Options" marginTop="large">
            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              onClick={() => confirmAction({
                action: deleteContactPaymentMethod,
                actionPayload: deleteContactPaymentMethodPayload,
                confirmButtonText: 'Yes, delete it',
                icon: 'error',
                text: 'Are you sure? This cannot be undone',
                title: 'Delete Payment Method?',
              })}
              size={['large', 'medium']}
              width="100%"
            >
              Delete Payment Method
            </Button>

            {!isDefault && (
              <Button
                buttonStyle="secondaryUtility"
                disbled={isDefault}
                icon={<FontAwesomeIcon icon={faThumbsUp} />}
                marginTop="medium"
                onClick={() => confirmAction({
                  action: updateContactPaymentMethod,
                  actionPayload: {
                    callbacks: {
                      action: updateFn,
                      afterAction: closeModal,
                    },
                    toastText: 'Default Payment Method Updated',
                    entityParams: {
                      ...entityState,
                      isDefault: true,
                    },
                  },
                  confirmButtonText: 'Yes, set default',
                  icon: 'warning',
                  text: 'Are you sure?',
                  title: 'Set as Default Payment Method?',
                })}
                size={['large', 'medium']}
                width="100%"
              >
                Set as Default
              </Button>
            )}
          </Form.Field>
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={loading}
          onClick={() => updateContactPaymentMethod(updateContactPaymentMethodPayload)}
          size="large"
        >
          {saveEnabled ? 'Update Payment Method' : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </>
  )
}

export default EditContactPaymentMethod

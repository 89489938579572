import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { BillingNoteModel } from '@models/billingNote'
import type { AppDispatch } from '@redux/store'
import type {
  Action, ActionResult, EntityOptions, Errors, FetchRequestPayload,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/billingNote/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/billingNote/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/billingNote/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/billingNote/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/billingNote/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/billingNote/CREATE_FAILURE'

export type BillingNoteModuleState = {
  creating: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loading: boolean,
}

const initialState: BillingNoteModuleState = {
  creating: false,
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loadedIds: [],
  loading: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}): Action {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(): Action {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []): Action {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(): Action {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(): Action {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []): Action {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadBillingNotes(options: EntityOptions = {}) {
  return (dispatch: AppDispatch): Promise<ActionResult<BillingNoteModel[]>> => {
    dispatch(fetchRequest())

    const promise = api('/billing-notes', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.BILLING_NOTE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function createBillingNote(billingNote: BillingNoteModel, options: EntityOptions = {}) {
  const config = {
    method: 'POST',
    data: JSON.stringify(billingNote),
  }

  return (dispatch: AppDispatch): Promise<ActionResult<BillingNoteModel>> => {
    dispatch(createRequest())

    const promise = api('/billing-notes', options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.BILLING_NOTE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: BillingNoteModuleState = initialState,
  action: Action = { type: '' },
): BillingNoteModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}

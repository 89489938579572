import { useMemo } from 'react'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import PageContent from './components/PageContent'

const defaultState = {}

const Subscriptions = (props: { businessUnitId: number }) => {
  const { businessUnitId } = props || {}

  const [state, setState] = useSetState(defaultState)

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {},
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent businessUnitId={businessUnitId} />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Subscriptions

import { useContext } from 'react'
import Swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, StatusBadge, Text,
} from '@campaignhub/suit-theme'
import { digObject, formatDate } from '@campaignhub/javascript-utils'

import { swalColors } from '@constants/swal'

import PageContext from '@contexts/pageContext'

import useSelector from '@hooks/useSelector'

import { getStatusIdByKey, statusColor } from '@functions/status'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'
import type { InvoicePayload } from '@hooks/useInvoice'

const viewOnly = JSON.parse(process.env.REACT_APP_VIEW_ONLY || "false")

type SwalConfirmParams = {
  action: (payload: HandleCallbackActionParams) => void,
  actionPayload: HandleCallbackActionParams,
  buttonColor: string,
  buttonText: string,
  icon: string,
  text: string,
  title: string,
}

const confirmAction = (params: SwalConfirmParams) => {
  const {
    action,
    actionPayload,
    buttonColor,
    buttonText,
    icon,
    text,
    title,
  } = params

  // Do we want to redirect to Invoices List Screen after successfully voiding an invoice?

  Swal
    .fire({
      confirmButtonColor: buttonColor,
      confirmButtonText: buttonText,
      icon,
      showCancelButton: true,
      text,
      title,
    })
    .then(({ value }) => {
      if (value){
        action(actionPayload)
      }
    })
}

const InvoiceActions = (props: { invoicePayload: InvoicePayload }) => {
  const { invoicePayload } = props || {}
  const { callbacks, editing, invoice } = invoicePayload || {}
  const { updateInvoice: updateFn } = callbacks || {}
  const { dateCreated, number, statusId } = invoice || {}

  const pageContext = useContext(PageContext)
  const { callbacks: { updateInvoice } } = pageContext

  const { statuses } = useSelector(reduxState => reduxState.entities)
  const status = digObject(statuses, String(statusId), {})
  const { name: statusName, key: statusKey } = status

  const { approval, deletion } = swalColors

  const approvedStatusId = getStatusIdByKey('approved', statuses)
  const voidStatusId = getStatusIdByKey('void', statuses)

  return (
    <Box
      alignItems="center"
      flexDirection={['column', 'row']}
      justifyContent="space-between"
      lineHeight="1.3"
      marginBottom="large"
      padding="large"
      variant="white"
    >
      <Box flexDirection={['row', 'column']} justifyContent="space-between" marginBottom={['large', 0]}>
        <StatusBadge
          boxProps={{ justifySelf: 'end' }}
          color={statusColor(statusKey)}
          ghost
          text={statusName}
        />

        <Text color="bodyFontLightColor" fontSize="small" marginTop="medium">
          Created {formatDate(dateCreated, 'ISO8601', 'dd LLLL yyyy')}
        </Text>
      </Box>

      {/* TODO: Different Button Actions based on Invoice */}
      {/* TODO: Add Download Invoice PDF Button? */}

      {!viewOnly && (
        <Box flexDirection={['column', 'row']} justifyContent="flex-end">
          <Button
            buttonStyle="secondaryUtility"
            disabled={editing}
            icon={<FontAwesomeIcon icon={faTimes} />}
            onClick={() => confirmAction(
              {
                action: updateInvoice,
                actionPayload: {
                  callbacks: {
                    action: updateFn,
                  },
                  entityParams: {
                    ...invoice,
                    statusId: voidStatusId,
                  },
                  toastText: 'Invoice Voided',
                },
                buttonColor: deletion,
                buttonText: 'Void Invoice',
                icon: 'error',
                text: `Confirming you are about to void ${!number ? 'this' : ''} Invoice
                ${number && `#${number}`}. This cannot be undone.`,
                title: 'Void Invoice?',
              },
            )}
            size="medium"
            width={['100%', 'auto']}
          >
            Void Invoice
          </Button>

          <Button
            buttonStyle="primaryCreate"
            disabled={editing}
            height="37.5px"
            icon={<FontAwesomeIcon icon={faCheck} />}
            marginLeft={[0, 'medium']}
            marginTop={['medium', 0]}
            onClick={() => confirmAction(
              {
                action: updateInvoice,
                actionPayload: {
                  callbacks: {
                    action: updateFn,
                  },
                  entityParams: {
                    ...invoice,
                    statusId: approvedStatusId,
                  },
                  toastText: 'Invoice Approved',
                },
                buttonColor: approval,
                buttonText: 'Approve Invoice',
                icon: 'success',
                text: `Confirming you are about to approve ${!number ? 'this' : ''} Invoice
                ${number && `#${number}`}. This cannot be undone.`,
                title: 'Approve Invoice?',
              },
            )}
            size="medium"
            width={['100%', 'auto']}
          >
            Approve
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default InvoiceActions

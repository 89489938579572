import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as navigationActions from '@redux/modules/navigation'

import type { AppDispatch } from '@redux/store'

const toggleNavigation = (showNavigation: boolean, dispatch: AppDispatch) => {
  const { changeParam } = navigationActions
  return dispatch(changeParam('showNavigation', !showNavigation))
}

type UseMainNavigationPayload = {
  callbacks: {
    toggleNavigation: Function,
  },
  showNavigation: boolean,
}

function useMainNavigation(): UseMainNavigationPayload {
  const dispatch = useDispatch()

  const showNavigation = useSelector(reduxState => reduxState.navigation.showNavigation)

  return {
    callbacks: {
      toggleNavigation: () => toggleNavigation(showNavigation, dispatch),
    },
    showNavigation,
  }
}

export default useMainNavigation

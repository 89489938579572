import { Box, NumberWithIconGrid, Text } from '@campaignhub/suit-theme'

import { ContactSummaryModel } from '@model/contactSummary'

interface AccountIconGridProps {
  contactSummary: ContactSummaryModel,
}

const AccountIconGrid = (props: AccountIconGridProps) => {
  const { contactSummary } = props

  const {
    newAccounts,
    overdueAccounts,
    totalAccounts,
    totalBilled,
  } = contactSummary

  return (
    <Box backgroundColor="white" borderColor="lineColor" borderRadius={5} flexDirection="column">
      <NumberWithIconGrid>
        <NumberWithIconGrid.Item
          disableHover
          label="Billed"
          value={`$${totalBilled}`}
        />

        <NumberWithIconGrid.Item
          disableHover
          label="New Accounts"
          value={`${newAccounts}`}
        />

        <NumberWithIconGrid.Item
          disableHover
          label="Total Accounts"
          value={`${totalAccounts}`}
        />

        <NumberWithIconGrid.Item
          disableHover
          label="Overdue Accounts"
          value={<Text color="red">{overdueAccounts}</Text>}
        />
      </NumberWithIconGrid>
    </Box>
  )
}

export default AccountIconGrid

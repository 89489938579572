import { DashboardModule } from '@campaignhub/suit-theme'

import useSubscriptionItems from '@hooks/useSubscriptionItems'

import type { SubscriptionItemModel } from '@models/subscriptionItem'

import ListBlankState from '@components/ListBlankState'

import { defaultRequestOptions } from '@sections/Client/packs/Accounting'

import SubscriptionItem from './components/SubscriptionItem'

const SubscriptionItems = (props: { subscriptionId: number }) => {
  const { subscriptionId } = props

  const subscriptionItemsPayload = useSubscriptionItems({
    filters: {
      subscriptionId,
    },
    performHttpRequests: !!subscriptionId,
    requestOptions: {
      ...defaultRequestOptions.subscriptionItem,
    },
  })
  const { filteredSubscriptionItems, hasFilteredSubscriptionItems, loading } = subscriptionItemsPayload

  // TODO: ASK DARRELL whether this should be Subscription Items or Products

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column' }}
      loading={loading}
      title="Products"
    >
      {!loading && !hasFilteredSubscriptionItems && <ListBlankState />}

      {!loading && filteredSubscriptionItems.map((subscriptionItem: SubscriptionItemModel) => (
        <SubscriptionItem key={subscriptionItem.id} subscriptionItem={subscriptionItem} />
      ))}
    </DashboardModule>
  )
}

export default SubscriptionItems

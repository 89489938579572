import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStripe } from '@fortawesome/free-brands-svg-icons'

import {
  Box, Link, SidebarModal, Text,
} from '@campaignhub/suit-theme'

const StripeDisclaimer = () => (
  <SidebarModal.NoticeBox boxProps={{ marginTop: 'large' }}>
    <Box>
      <Text marginRight="medium">
        <FontAwesomeIcon icon={faStripe} />
      </Text>

      <SidebarModal.NoticeBox.Title>
        Paying Credit Card via Stripe
      </SidebarModal.NoticeBox.Title>
    </Box>

    <SidebarModal.NoticeBox.Paragraph>
      You are adding your card to pay your subscription or invoice.
      Realbase uses Stripe as a payment gateway for all payments.
      Please reaad the
      <Link greyLink href="https://stripe.com/en-au/legal/end-users" textProps={{ marginX: 'small' }}>
        Terms and Condtions
      </Link>
      and
      <Link greyLink href="https://stripe.com/en-au/privacy" textProps={{ marginX: 'small' }}>
        Privacy Policy
      </Link>
      for more information.
    </SidebarModal.NoticeBox.Paragraph>
  </SidebarModal.NoticeBox>
)

export default StripeDisclaimer

import Swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCopy, faCheckCircle, faFileExport, faShare, faTimesCircle,
} from '@fortawesome/pro-light-svg-icons'

import { Button, Tools } from '@campaignhub/suit-theme'
import { digObject } from '@campaignhub/javascript-utils'

import { swalColors } from '@constants/swal'
import type { SwalConfirmParams } from '@constants/swal'

import type { InvoicesPayload } from '@hooks/useInvoices'

const confirmAction = (params: SwalConfirmParams) => {
  const {
    actionFn, confirmButtonColor, confirmButtonText, icon, text, title,
  } = params

  Swal
    .fire({
      confirmButtonColor: digObject(swalColors, confirmButtonColor),
      confirmButtonText: confirmButtonText || title,
      icon,
      showCancelButton: true,
      text,
      title: `${title}?`,
    })
    .then(({ value }) => {
      if (value) {
        actionFn()
      }
    })
}

interface InvoiceToolsProps {
  invoicesPayload: InvoicesPayload,
  status: string,
}

const InvoiceTools = (props: InvoiceToolsProps) => {
  const { invoicesPayload, status } = props
  const {
    callbacks: {
      approveInvoices,
      generateInvoicesCSV,
      mergeInvoices,
      sendReminders,
      voidInvoices,
    },
    canMergeInvoices,
    hasSelectedInvoices,
    isBulkSelecting,
    selectedInvoiceIdsCount,
  } = invoicesPayload || {}

  return (
    <Tools>
      {!!selectedInvoiceIdsCount && (status === 'draft') && (
        <>
          <Button
            buttonStyle="secondaryUtility"
            icon={<FontAwesomeIcon icon={faCheckCircle} />}
            iconPosition="left"
            onClick={() => confirmAction({
              actionFn: voidInvoices,
              confirmButtonColor: 'approval',
              icon: 'success',
              text: `Yes, Approve Selected Invoice${isBulkSelecting ? 's' : ''}.`,
              title: isBulkSelecting ? 'Approve Invoices' : 'Approve Invoice',
            })}
            size="medium"
          >
            {isBulkSelecting ? `Approve (${selectedInvoiceIdsCount}) Invoices` : 'Approve Invoice'}
          </Button>

          <Button
            buttonStyle="secondaryUtility"
            icon={<FontAwesomeIcon icon={faTimesCircle} />}
            iconPosition="left"
            marginTop="medium"
            onClick={() => confirmAction({
              actionFn: approveInvoices,
              confirmButtonColor: 'deletion',
              icon: 'error',
              text: `Yes, Void Selected Invoice${isBulkSelecting ? 's' : ''}.`,
              title: isBulkSelecting ? 'Void Invoices' : 'Void Invoice',
            })}
            size="medium"
          >
            {isBulkSelecting ? `Void (${selectedInvoiceIdsCount}) Invoices` : 'Void Invoice'}
          </Button>

          {isBulkSelecting && (
            <Button
              buttonStyle="secondaryUtility"
              disabled={!canMergeInvoices}
              icon={<FontAwesomeIcon icon={faCopy} />}
              iconPosition="left"
              marginTop="medium"
              onClick={() => mergeInvoices()}
              size="medium"
            >
              {`Merge (${selectedInvoiceIdsCount}) Invoices`}
            </Button>
          )}
        </>
      )}

      {!!selectedInvoiceIdsCount && (status === 'overdue') && (
        <Button
          buttonStyle="secondaryUtility"
          marginTop="medium"
          icon={<FontAwesomeIcon icon={faShare} />}
          iconPosition="left"
          onClick={() => confirmAction({
            actionFn: sendReminders,
            confirmButtonColor: 'approval',
            confirmButtonText: isBulkSelecting ? 'Send Reminders' : 'Send Reminder',
            icon: 'success',
            text: `Yes, Send Selected Invoice Reminder${isBulkSelecting ? 's' : ''}.`,
            title: isBulkSelecting ? 'Send Invoice Reminders' : 'Send Invoice Reminder',
          })}
          size="medium"
        >
          {isBulkSelecting ? `Send (${selectedInvoiceIdsCount}) Reminders` : 'Send Reminder'}
        </Button>
      )}

      <Button
        buttonStyle="secondaryUtility"
        icon={<FontAwesomeIcon icon={faFileExport} />}
        iconPosition="left"
        marginTop={hasSelectedInvoices ? 'medium' : '0'}
        onClick={() => generateInvoicesCSV()}
        size="medium"
      >
        Generate .CSV
      </Button>
    </Tools>
  )
}

export default InvoiceTools

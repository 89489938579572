import { Helmet } from 'react-helmet-async'

import { faPencil } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, LoadingModule,
} from '@campaignhub/suit-theme'
import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import useCredit, { generateUrls } from '@hooks/useCredit'

import AdminPageHeader from '@components/AdminPageHeader'
import ContactDetails from '@components/ContactDetails'
import EntityActivity from '@components/EntityActivity'

import { defaultRequestOptions } from '@sections/Client/packs/Accounting'

import CreditDetails from '../Details'
import CreditAllocationHistory from '../CreditAllocationHistory'
import CreditRequest from '../CreditRequest'
import Tools from '../Tools'

const PageContent = (props: { creditId: number }) => {
  const { creditId } = props || {}

  const creditPayload = useCredit(
    {
      id: creditId,
    },
    {
      performHttpRequests: !!creditId,
      requestOptions: {
        ...defaultRequestOptions.credit,
      },
    },
  )
  const {
    callbacks,
    credit,
    credit: {
      number,
    },
    loading,
  } = creditPayload

  const { creditsIndexUrl } = generateUrls()

  const { redirect } = parsePermittedQueryParams(getQueryParamsFromHash(), ['redirect'])

  return (
    <>
      <Helmet>
        <title>Credit | Billing</title>
      </Helmet>

      <AdminPageHeader
        activeTabBarItemKey="edit"
        actionContent={(
          <Button
            as="a"
            buttonStyle="secondaryUtility"
            href={redirect || creditsIndexUrl}
            size="medium"
          >
            Back
          </Button>
        )}
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: '#',
            icon: faPencil,
            key: 'edit',
            title: 'Edit Credit',
          },
        ]}
        title={number ? `Credit #${number}` : 'Credit'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && (
              <>
                <ContactDetails entity={credit} />

                <CreditRequest creditPayload={creditPayload} />

                <CreditAllocationHistory creditPayload={creditPayload} />

                <EntityActivity
                  boxProps={{ marginTop: 'large' }}
                  componentSettings={{
                    conversation: {
                      title: number ? `Credit #${number}` : 'Credit',
                    },
                  }}
                  subjectType="Credit"
                  subjectId={creditId}
                />
              </>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <Tools callbacks={callbacks} />

            <CreditDetails creditPayload={creditPayload} />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent

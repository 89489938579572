import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities, deleteEntity } from '@redux/entities'

import api from '@functions/api'

import type { ContactPaymentMethodModel } from '@models/contactPaymentMethod'
import type { AppDispatch } from '@redux/store'
import type {
  Action, ActionResult, EntityOptions, Errors, FetchRequestPayload, RootReducerState,
} from '@redux/modules/types'
import type { RequestContactPaymentMethodPayload } from '@hooks/useContactPaymentMethod'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/contactPaymentMethod/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/contactPaymentMethod/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/contactPaymentMethod/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/contactPaymentMethod/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/contactPaymentMethod/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/contactPaymentMethod/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/contactPaymentMethod/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/contactPaymentMethod/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/contactPaymentMethod/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/contactPaymentMethod/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/contactPaymentMethod/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/contactPaymentMethod/DELETE_FAILURE'

export type ContactPaymentMethodModuleState = {
  creating: boolean,
  deleting: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loading: boolean,
  updating: boolean,
}

const initialState: ContactPaymentMethodModuleState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loadedIds: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}): Action {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(): Action {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []): Action {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(): Action {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(): Action {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []): Action {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(): Action {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(): Action {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []): Action {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(): Action {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(): Action {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors: Errors = []): Action {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadContactPaymentMethods(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState): Promise<ActionResult<ContactPaymentMethodModel[]>> => {
    const loadedForKeys = [...getState().contactPaymentMethods.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('contact-payment-methods', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.CONTACT_PAYMENT_METHOD_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function createContactPaymentMethod(contactPaymentMethod: ContactPaymentMethodModel, options: EntityOptions = {}) {
  const config = {
    method: 'POST',
    data: JSON.stringify(contactPaymentMethod),
  }

  return (dispatch: AppDispatch): Promise<ActionResult<ContactPaymentMethodModel>> => {
    dispatch(createRequest())

    const promise = api('contact-payment-methods', options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.CONTACT_PAYMENT_METHOD)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function updateContactPaymentMethod(contactPaymentMethod: ContactPaymentMethodModel, options: EntityOptions = {}) {
  const config = {
    method: 'PUT',
    data: JSON.stringify(contactPaymentMethod),
  }

  return (dispatch: AppDispatch): Promise<ActionResult<ContactPaymentMethodModel>> => {
    dispatch(updateRequest())

    const promise = api(`contact-payment-methods/${contactPaymentMethod.id}`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.CONTACT_PAYMENT_METHOD)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function deleteContactPaymentMethod(contactPaymentMethod: ContactPaymentMethodModel) {
  const config = {
    method: 'DELETE',
  }

  return (dispatch: AppDispatch): Promise<ActionResult<ContactPaymentMethodModel>> => {
    dispatch(deleteRequest())

    const promise = api(`contact-payment-methods/${contactPaymentMethod.id}`, {}, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.CONTACT_PAYMENT_METHOD)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false as const, errors }
      })
    return promise
  }
}

// Send Email to Users Asking them to add a payment method for this contact
export function requestContactPaymentMethod(payload: RequestContactPaymentMethodPayload, options: EntityOptions = {}) {
  const config = {
    method: 'POST',
    data: JSON.stringify({
      ...payload,
    }),
  }

  return (): Promise<ActionResult<{ errors?: string[], queued?: boolean }>> => {
    const promise = api('contact-payment-methods/request', options, config)
      .then(({ data }) => ({ success: true, data }))
      .catch((data) => {
        const errors = handleError(data)
        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: ContactPaymentMethodModuleState = initialState,
  action: Action = { type: '' },
): ContactPaymentMethodModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}

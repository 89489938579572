import api from '@functions/api'

import type { EntityOptions } from '@redux/modules/types'

const searchEndpoint = (string: string, requestOptions: EntityOptions, requestUrl: string) => {
  if (!requestUrl) return { success: false }

  const mergedOptions = {
    string,
    ...requestOptions,
  }

  return api(requestUrl, mergedOptions)
    .then(({ data }) => ({ success: true, data }))
    .catch(errors => ({ success: false, errors }))
}

export default searchEndpoint

import { DateTime } from 'luxon'

import { digObject } from '@campaignhub/javascript-utils'

import type { InvoiceModel } from '@models/types'
import type { EntitiesState } from '@redux/entities'

export function validateInvoicesForMerge(selectedInvoices: InvoiceModel[] = []) {
  if (!Array.isArray(selectedInvoices) || !selectedInvoices.length) return false

  // Get first invoice contactId & businessUnitId
  const firstInvoice = selectedInvoices[0]
  const { businessUnitId, contactId } = firstInvoice || {}

  // Confirm there are no selectedInvoices with different contacts or businessUnits
  const canMergeInvoices = !selectedInvoices.some(invoice => (invoice.contactId !== contactId) || (invoice.businessUnitId !== businessUnitId))

  return canMergeInvoices
}

export function matchInvoiceStatus(statusKey: string, filterStatusKey: string, dateDue?: string) {
  if (!filterStatusKey || typeof filterStatusKey !== 'string') return true

  if (filterStatusKey === 'processed'){
    return statusKey !== 'draft'
  }

  if (statusKey === 'approved'){
    const today = DateTime.now()
    const dateDueDateTime = DateTime.fromISO(dateDue)

    if (filterStatusKey === 'overdue' && today > dateDueDateTime) return true

    if (filterStatusKey === 'approved' && dateDueDateTime > today) return true

    return false
  }

  return statusKey === filterStatusKey
}

export function getInvoiceStatusDisplayKey(invoice: InvoiceModel, statuses: EntitiesState['statuses']) {
  const { dateDue, statusId } = invoice || {}

  const status = digObject(statuses, String(statusId), {})
  const { key } = status

  if (key === 'approved'){
    const today = DateTime.now()
    const dateDueDateTime = DateTime.fromISO(dateDue)

    if (today > dateDueDateTime) return 'overdue'

    return 'invoiced'
  }

  return key
}

import { DashboardModule } from '@campaignhub/suit-theme'

import { snakeToTitleCase } from '@campaignhub/javascript-utils'

import { CreditsPayload } from '@hooks/useCredits'

import ListBlankState from '@components/ListBlankState'

import CreditsTable from './components/Table'

interface CreditsProps {
  creditsPayload: CreditsPayload,
  status: string,
}

const Credits = (props: CreditsProps) => {
  const { creditsPayload, status } = props
  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredCreditsCount,
    hasFilteredCredits,
    loading,
  } = creditsPayload

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column' }}
      loading={loading}
      title={`${snakeToTitleCase(status)} Credits`}
    >
      {!loading && !hasFilteredCredits && <ListBlankState />}

      {!loading && hasFilteredCredits && (
        <CreditsTable creditsPayload={creditsPayload} statusKey={status} />
      )}

      <DashboardModule.LoadMoreFooter
        callbacks={{ loadMore }}
        canLoadMore={canLoadMore}
        entityCount={filteredCreditsCount}
        loading={loading}
      />
    </DashboardModule>
  )
}

export default Credits

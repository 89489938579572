import React, { useEffect } from 'react'

import {
  useDebounce, useDeepEffect, useLoadMore, useSetState, useThunkDispatch,
} from '@campaignhub/react-hooks'

import {
  digObject, generateEntityStrings, sortArrayBy, toggleArray,
} from '@campaignhub/javascript-utils'

import * as contactActions from '@redux/modules/contact'

import useContactType from '@hooks/useContactType'
import useContacts from '@hooks/useContacts'

import { getAlreadyImportedContactIds } from '@functions/contact'

import type { AppDispatch } from '@redux/store'
import type { ContactModel, ContactTypeModel } from '@models/types'

type ImportContactsPayload = {
  contactTypeId: number,
  selectedContactIds: number[],
}

type State = typeof defaultState

type ToggleAllContactsParams = {
  realbaseContacts: ContactModel[],
  state: State,
  setState: React.Dispatch<React.SetStateAction<Partial<State>>>,
}

const toggleAllContacts = (params: ToggleAllContactsParams) => {
  const { realbaseContacts, state, setState } = params || {}
  const { selectedContactIds } = state || {}

  if (selectedContactIds.length){
    setState({ selectedContactIds: [] })
    return
  }

  setState({ selectedContactIds: realbaseContacts.map((contact: ContactModel) => contact.realbaseId) })
}

type ToggleContactParams = {
  id: number,
  state: State,
  setState: React.Dispatch<React.SetStateAction<Partial<State>>>,
}

const toggleContact = (params: ToggleContactParams) => {
  const { id, state, setState } = params || {}
  const { selectedContactIds } = state || {}

  const updatedContactsIds = toggleArray(selectedContactIds, id)

  setState({ selectedContactIds: updatedContactsIds })
}

export type LoadFromRealbaseParams = {
  dispatch: AppDispatch,
  fetchRequestParams: {
    contactTypeId: number,
    limit: number,
    parentContactId?: number,
    parentContactTypeId?: number,
    parentContactRealbaseId?: number,
    string?: string,
  },
  setState: React.Dispatch<React.SetStateAction<Partial<State>>>,
}

const loadFromRealbase = (params: LoadFromRealbaseParams) => {
  const { dispatch, fetchRequestParams, setState } = params || {}
  const { loadFromRealbase: loadFromRealbaseFn } = contactActions

  dispatch(loadFromRealbaseFn(fetchRequestParams)).then(({ success, data }) => {
    if (success){
      setState({ realbaseContacts: data, loading: false })
    }
    setState({ loading: false })
  })
}

type ImportContactsParams = {
  dispatch: AppDispatch,
  payload: ImportContactsPayload,
}

const importContacts = (params: ImportContactsParams) => {
  const { dispatch, payload } = params
  const { importContacts: importFn } = contactActions

  return dispatch(importFn(payload))
}

const defaultState = {
  realbaseContacts: [],
  limit: 50,
  loading: false,
  parentContactId: null,
  selectedContactIds: [],
  string: '',
}

type UseImportContactsParams = {
  contactType: ContactTypeModel,
}

const useImportContacts = (params: UseImportContactsParams) => {
  const { contactType } = params

  const [state, setState] = useSetState(defaultState)
  const {
    realbaseContacts,
    limit,
    loading,
    parentContactId,
    selectedContactIds,
    string,
  } = state

  const dispatch = useThunkDispatch()

  const debouncedString = useDebounce(string, 300)

  const { filteredContacts } = useContacts()

  const contactTypePayload = useContactType(contactType)
  const {
    contactType: {
      id: contactTypeId,
    },
    hasParentContactType,
    parentContactType: {
      id: parentContactTypeId,
      key: parentContactTypeKey,
    },
  } = contactTypePayload

  const parentContactStrings = generateEntityStrings(parentContactTypeKey)

  // Create an array of IDs for contacts that have already been imported
  // Push the existing contacts realbaseId if the contact has already been imported
  const alreadyImportedRealbaseIds = getAlreadyImportedContactIds({ filteredContacts, realbaseContacts })

  const loadMorePayload = useLoadMore({
    loadedCount: realbaseContacts.length,
    filters: {
      contactTypeId,
      limit,
      parentContactId,
      parentContactTypeId,
      string: debouncedString,
    },
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
  } = loadMorePayload

  // Re-fetch contacts whenever filters change
  useEffect(() => {
    if (contactTypeId){
      const fetchRequestParams = {
        ...filtersWithOffset,
      }

      if (parentContactId){
        const parentContact = digObject(realbaseContacts, String(parentContactId), {})
        const parentContactRealbaseId = digObject(parentContact, 'realbaseId', null)

        fetchRequestParams.parentContactRealbaseId = parentContactRealbaseId
      }

      loadFromRealbase({ fetchRequestParams, dispatch, setState })
      setState({ realbaseContacts: [], loading: true })
    } else {
      setState({ realbaseContacts: [], loading: false })
    }
  }, [filtersWithOffset])

  // Select all contacts that have not already been imported
  useDeepEffect(() => {
    const notImportedRealbaseIds = realbaseContacts
      .filter(realbaseContact => !alreadyImportedRealbaseIds.includes(realbaseContact.realbaseId))
      .map(realbaseContact => realbaseContact.realbaseId)

    setState({ selectedContactIds: notImportedRealbaseIds })
  }, [realbaseContacts])

  return {
    alreadyImportedRealbaseIds,
    callbacks: {
      importContacts: (payload: ImportContactsPayload) => importContacts({ dispatch, payload }),
      loadMore,
      setState,
      toggleAllContacts: () => toggleAllContacts({ realbaseContacts, state, setState }),
      toggleContact: (id: number) => toggleContact({ id, state, setState }),
    },
    canLoadMore,
    hasRealbaseContacts: !!realbaseContacts.length,
    hasParentContactType,
    hasSelectedContacts: !!selectedContactIds.length,
    limit,
    loading,
    parentContactId,
    parentContactStrings,
    parentContactTypeId,
    realbaseContacts: sortArrayBy(realbaseContacts, 'asc', 'fullName'),
    selectedContactIds,
    string,
  }
}

export default useImportContacts

import { useMemo } from 'react'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'
import {
  digObject, matchFilterArrayIncludes, matchFilterNumber, matchFilterString, sortArrayBy,
} from '@campaignhub/javascript-utils'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import { ContactModel, ContactRequestOptions } from '@models/contact'

const watchEntityKeys = ['contacts']

interface UseContactsOptions {
  filters?: {
    countryId?: number,
    selectedContactTypeKey?: string,
    parentContactId?: number,
    string?: string,
    userId?: number,
  },
  performHttpRequests?: boolean,
  requestOptions?: ContactRequestOptions,
}

function useContacts(options?: UseContactsOptions) {
  const { filters, requestOptions } = options || {}

  const {
    countryId: filterCountryId,
    parentContactId: filterParentContactId,
    selectedContactTypeKey: filterSelectedContactTypeKey,
    string,
    userId,
  } = filters || {}

  const {
    updatedEntities: { contacts: contactsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const entities = useSelector(reduxState => reduxState.entities)
  const { contacts, contactTypes } = entities || {}

  const filteredContacts = useMemo(() => {
    const filtered = Object.values(contacts).filter((contact: ContactModel) => {
      const {
        contactTypeId, countryId, id, name, parentContactId,
      } = contact

      const contactUserIds = digObject(contact, 'user_ids', [])

      const contactType = digObject(contactTypes, `${contactTypeId}`, {})

      const matchContactType = matchFilterString(contactType.key, filterSelectedContactTypeKey)
      const matchCountry = matchFilterNumber(Number(countryId), filterCountryId)
      const matchId = id?.toString() === string
      const matchParentContact = matchFilterNumber(parentContactId, filterParentContactId)
      const matchName = matchFilterString(name, string)
      const matchString = matchId || matchName
      const matchUser = matchFilterArrayIncludes(contactUserIds, userId)

      return matchContactType && matchCountry && matchParentContact && matchString && matchUser
    })

    return sortArrayBy(filtered, 'asc', 'name')
  }, [contactsUpdatedAt, JSON.stringify(filters)])

  const filteredContactsCount = filteredContacts?.length

  const loadMorePayload = useLoadMore({ ...options, loadedCount: filteredContactsCount })
  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading } = useReduxAction(
    'contacts',
    'loadContacts',
    {
      ...filtersWithOffset,
      limit,
      ...requestOptions,
    },
    [JSON.stringify(filtersWithOffset), performHttpRequests],
    { shouldPerformFn: ({ loading }) => performHttpRequests && !loading },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredContacts,
    filteredContactsCount,
    hasContacts: filteredContactsCount > 0,
    loading,
  }
}
export default useContacts

import { useContext, useEffect } from 'react'
import { formatMoney } from 'accounting'

import {
  Button, Form, Link, ModalContext, SidebarModal, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import { useSetState } from '@campaignhub/react-hooks'

import useBusinessUnitProducts from '@hooks/useBusinessUnitProducts'
import useContact from '@hooks/useContact'
import useSubscriptionItem, { useSubscriptionItemForm } from '@hooks/useSubscriptionItem'
import usePrice from '@hooks/usePrice'
import usePrices from '@hooks/usePrices'
import usePriceTotal from '@hooks/usePriceTotal'

import type { SubscriptionItemModel } from '@models/subscriptionItem'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import EntitySelector from '@components/EntitySelector'

const MODAL_KEY = 'CreateSubscriptionItemModal'

type AddItemToSubscriptionParams = {
  addSubscriptionItemFn: (subscriptionItem: SubscriptionItemModel) => void,
  closeModal: () => void,
  subscriptionItem: SubscriptionItemModel,
}

// When creating a subscription, Add SubscriptionItem to Subscription EntityState (items)
const addItemToSubscription = (params: AddItemToSubscriptionParams) => {
  const { addSubscriptionItemFn, closeModal, subscriptionItem } = params

  addSubscriptionItemFn(subscriptionItem)
  closeModal()
}

interface CreateSubscriptionItemModalProps {
  callbacks: {
    closeModal: () => void,
    createSubscriptionItem: (createSubscriptionPayload: HandleCallbackActionParams) => void,
  },
  showModal: boolean,
}

const defaultState = {
  addSubscriptionItemFn: null,
  businessUnitId: null,
}

const CreateSubscriptionItemModal = (props: CreateSubscriptionItemModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, createSubscriptionItem } = callbacks || {}

  const [state, setState] = useSetState(defaultState)
  const { addSubscriptionItemFn, businessUnitId } = state

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const {
    callbacks: modalCallbacks,
    contactId,
    interval,
    subscriptionItem: initSubscriptionItem,
  } = modalPayload

  const { addSubscriptionItem: addSubscriptionItemCallback } = modalCallbacks || {}

  const { contact, loading: loadingContact } = useContact({ id: contactId }, { performHttpRequests: !!contactId })
  const { countryId } = contact

  const subscriptionItemPayload = useSubscriptionItem(initSubscriptionItem || {})
  const {
    callbacks: {
      createSubscriptionItem: createSubscriptionItemFn,
    },
    subscriptionBillingType,
    subscriptionItem,
    loading: loadingSubscriptionItems,
  } = subscriptionItemPayload

  const subscriptionItemFormPayload = useSubscriptionItemForm(subscriptionItem)
  const {
    entityState,
    entityState: {
      businessUnitProductId,
      priceId,
      qty,
      subscriptionId,
    },
    errors,
    handlers,
    resetDefaultState,
    saveEnabled,
  } = subscriptionItemFormPayload

  const subscriptionBillingTypeId = digObject(subscriptionBillingType, 'id')

  const businessUnitProductsPayload = useBusinessUnitProducts({
    filters: {
      businessUnitId,
      isSubscribable: true,
    },
    performHttpRequests: !!businessUnitId,
  })
  const {
    filteredBusinessUnitProducts,
    loading: loadingBusinessUnitProducts,
  } = businessUnitProductsPayload

  const pricesPayload = usePrices({
    filters: {
      businessUnitProductId,
      contactId,
      countryId,
      interval,
    },
    performHttpRequests: !!businessUnitProductId,
  })
  const { filteredPrices, loading: loadingPrices } = pricesPayload

  const { callbacks: { createOrEditPrice } } = usePrice({
    billingTypeId: subscriptionBillingTypeId,
    businessUnitProductId,
    interval,
  })

  const { total } = usePriceTotal(priceId, qty)

  const loading = loadingContact && loadingSubscriptionItems && loadingBusinessUnitProducts && loadingPrices

  const createSubscriptionItemPayload = {
    callbacks: {
      action: createSubscriptionItemFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    toastText: 'Subscription Item Created',
  }

  // Reset Default SubscriptionItem State if User unselects Business Unit
  useEffect(() => {
    if (!businessUnitId && businessUnitProductId){
      resetDefaultState()
    }
  }, [businessUnitId])

  // Store Create Subscription Modal Callback in State to prevent loss when opening further modals
  useEffect(() => {
    if (addSubscriptionItemCallback){
      setState({ addSubscriptionItemFn: addSubscriptionItemCallback })
    }
  }, [])

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Add" titleSecondLine="Product" />

      <SidebarModal.Content>
        <Form>
          <EntitySelector
            callbacks={{
              selectItem: businessUnit => setState({
                businessUnitId: businessUnit ? businessUnit.id : null,
              }),
            }}
            entityKey="businessUnits"
            label="* Business Unit"
            selectedItemId={businessUnitId}
          />

          {businessUnitId && (
            <Form.Field
              errorMessage={errors.businessUnitProductId}
              label="* Product"
              marginTop="large"
            >
              <select
                data-value-type="number"
                disabled={!businessUnitId}
                name="businessUnitProductId"
                value={businessUnitProductId}
                {...handlers}
              >
                <option value="">Please Select...</option>
                {filteredBusinessUnitProducts.map(businessUnitProduct => (
                  <option key={businessUnitProduct.id} value={businessUnitProduct.id}>
                    {businessUnitProduct.name}
                  </option>
                ))}
              </select>
            </Form.Field>
          )}

          {businessUnitProductId && (
            <Form.Field
              errorMessage={errors.priceId}
              label="* Price"
              labelRight={businessUnitProductId
                && (
                  <Link onClick={() => createOrEditPrice({ contactId })} textProps={{ fontSize: 'xsmall' }}>
                    + Add Price
                  </Link>
                )}
              marginTop="large"
            >
              <select
                data-value-type="number"
                disabled={!businessUnitProductId}
                name="priceId"
                value={priceId}
                {...handlers}
              >
                <option value="">Please Select...</option>
                {filteredPrices.map((price) => {
                  const { id: filteredPriceId, incTaxAmount, name } = price || {}

                  return (
                    <option key={filteredPriceId} value={filteredPriceId}>
                      {name ? `$${incTaxAmount} - ${name}` : `$${incTaxAmount}`}
                    </option>
                  )
                })}
              </select>
            </Form.Field>
          )}

          {priceId && (
            <Form.Field errorMessage={errors.qty} label="* Quantity" marginTop="large">
              <input
                name="qty"
                value={qty}
                type="number"
                {...handlers}
              />
            </Form.Field>
          )}

          {qty && total && (
            <Form.Field boxProps={{ lineHeight: 1.3, textAlign: 'end' }} marginTop="large">
              <Text fontSize="large">{formatMoney(total)}</Text>
              <Text color="bodyFontLightColor" fontSize="small">TOTAL</Text>
            </Form.Field>
          )}
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={loading}
          onClick={subscriptionId
            ? () => createSubscriptionItem(createSubscriptionItemPayload)
            : () => addItemToSubscription({
              addSubscriptionItemFn,
              closeModal,
              subscriptionItem: entityState,
            })}
          size="large"
        >
          Add Item
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: CreateSubscriptionItemModalProps) => (
  <SidebarModal.RenderController {...props}>
    <CreateSubscriptionItemModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal

import { schema } from 'normalizr'

// Circular Dependencies - Use the define method
// const item = new schema.Entity('item')
// const child = new schema.Entity('child')
// item.define({ children: [child] })

// Define Entities
// START ENTITY DEFINITIONS
const activity = new schema.Entity('activities')
const accountingSystem = new schema.Entity('accountingSystems')
const bankType = new schema.Entity('bankTypes')
const billingEntity = new schema.Entity('billingEntities')
const billingNote = new schema.Entity('billingNotes')
const billingType = new schema.Entity('billingTypes')
const businessUnit = new schema.Entity('businessUnits')
const businessUnitProduct = new schema.Entity('businessUnitProducts')
const comment = new schema.Entity('comments')
const contact = new schema.Entity('contacts')
const contactPaymentMethod = new schema.Entity('contactPaymentMethods')
const contactType = new schema.Entity('contactTypes')
const contactUser = new schema.Entity('contactUsers')
const conversation = new schema.Entity('conversations')
const country = new schema.Entity('countries')
const credit = new schema.Entity('credits')
const creditAllocation = new schema.Entity('creditAllocations')
const image = new schema.Entity('images')
const invoice = new schema.Entity('invoices')
const paymentMethod = new schema.Entity('paymentMethods')
const price = new schema.Entity('prices')
const status = new schema.Entity('statuses')
const subscriber = new schema.Entity('subscribers')
const subscription = new schema.Entity('subscriptions')
const subscriptionItem = new schema.Entity('subscriptionItems')
const subscriptionTransaction = new schema.Entity('subscriptionTransactions')
const taxJurisdiction = new schema.Entity('taxJurisdictions')
const transaction = new schema.Entity('transactions')
const transactionItem = new schema.Entity('transactionItems')
const transactionType = new schema.Entity('transactionTypes')
const user = new schema.Entity('users')
const userType = new schema.Entity('userTypes')
// END ENTITY DEFINITIONS

businessUnit.define({
  businessUnitProducts: [businessUnitProduct],
})

comment.define({
  user,
})

conversation.define({
  comments: [comment],
  subscribers: [subscriber],
})

contact.define({
  billingNotes: [billingNote],
  contactPaymentMethods: [contactPaymentMethod],
  contactType,
  contactUsers: [contactUser],
  parentContact: contact,
})

contactPaymentMethod.define({
  paymentMethod,
})

credit.define({
  contact,
  creditAllocations: [creditAllocation],
  transaction,
})

invoice.define({
  contact,
  transaction,
})

price.define({
  billingType,
})

subscription.define({
  contact,
  status,
  subscriptionItems: [subscriptionItem],
  subscriptionTransactions: [subscriptionTransaction],
})

subscriptionItem.define({
  businessUnitProduct,
  price,
})

transaction.define({
  contact,
  status,
  transactionItems: [transactionItem],
  transactionType,
})

transactionItem.define({
  businessUnitProduct,
})

// Export Schemas
export const Schemas = {
// START SCHEMA DEFINITION
  ACTIVITY: activity,
  ACTIVITY_ARRAY: [activity],
  ACCOUNTINGSYSTEM: accountingSystem,
  ACCOUNTINGSYSTEM_ARRAY: [accountingSystem],
  BANKTYPE: bankType,
  BANKTYPE_ARRAY: [bankType],
  BILLING_ENTITY: billingEntity,
  BILLING_ENTITY_ARRAY: [billingEntity],
  BILLING_NOTE: billingNote,
  BILLING_NOTE_ARRAY: [billingNote],
  BILLING_TYPE: billingType,
  BILLING_TYPE_ARRAY: [billingType],
  BUSINESS_UNIT: businessUnit,
  BUSINESS_UNIT_ARRAY: [businessUnit],
  BUSINESS_UNIT_PRODUCT: businessUnitProduct,
  BUSINESS_UNIT_PRODUCT_ARRAY: [businessUnitProduct],
  COMMENT: comment,
  COMMENT_ARRAY: [comment],
  CONTACT: contact,
  CONTACT_ARRAY: [contact],
  CONTACT_PAYMENT_METHOD: contactPaymentMethod,
  CONTACT_PAYMENT_METHOD_ARRAY: [contactPaymentMethod],
  CONTACT_TYPE: contactType,
  CONTACT_TYPE_ARRAY: [contactType],
  CONTACT_USER: contactUser,
  CONTACT_USER_ARRAY: [contactUser],
  CONVERSATION: conversation,
  CONVERSATION_ARRAY: [conversation],
  COUNTRY: country,
  COUNTRY_ARRAY: [country],
  CREDIT: credit,
  CREDIT_ARRAY: [credit],
  CREDIT_ALLOCATION: creditAllocation,
  CREDIT_ALLOCATION_ARRAY: [creditAllocation],
  IMAGE: image,
  IMAGE_ARRAY: [image],
  INVOICE: invoice,
  INVOICE_ARRAY: [invoice],
  PAYMENT_METHOD: paymentMethod,
  PAYMENT_METHOD_ARRAY: [paymentMethod],
  PRICE: price,
  PRICE_ARRAY: [price],
  STATUS: status,
  STATUS_ARRAY: [status],
  SUBSCRIPTION: subscription,
  SUBSCRIPTION_ARRAY: [subscription],
  SUBSCRIPTION_ITEM: subscriptionItem,
  SUBSCRIPTION_ITEM_ARRAY: [subscriptionItem],
  SUBSCRIPTION_TRANSACTION: subscriptionTransaction,
  SUBSCRIPTION_TRANSACTION_ARRAY: [subscriptionTransaction],
  TAXJURISDICTION: taxJurisdiction,
  TAXJURISDICTION_ARRAY: [taxJurisdiction],
  TRANSACTION: transaction,
  TRANSACTION_ARRAY: [transaction],
  TRANSACTION_ITEM: transactionItem,
  TRANSACTION_ITEM_ARRAY: [transactionItem],
  TRANSACTION_TYPE: transactionType,
  TRANSACTION_TYPE_ARRAY: [transactionType],
  USER: user,
  USER_ARRAY: [user],
  USER_TYPE: userType,
  USER_TYPE_ARRAY: [userType],
// END SCHEMA DEFINITION
}

export default Schemas

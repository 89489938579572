import type { AppDispatch } from '@redux/store'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as commentActions from '@redux/modules/comment'

import defaultFormState, { requiredFields } from '@models/comment'

import type { DeleteParams, EntityOptions } from '@redux/modules/types'
import type { CommentModel } from '@models/types'

type CreateCommentParams = {
  commentParams: Partial<CommentModel>,
  dispatch: AppDispatch,
  requestOptions?: EntityOptions,
}

const createComment = (params: CreateCommentParams) => {
  const { commentParams, dispatch, requestOptions } = params
  const { createComment: createFn } = commentActions

  return dispatch(createFn(commentParams, requestOptions))
}

type UpdateCommentParams = {
  comment: CommentModel,
  commentParams: Partial<CommentModel>,
  dispatch: AppDispatch,
  requestOptions?: EntityOptions,
}

const updateComment = (params: UpdateCommentParams) => {
  const {
    dispatch, comment, commentParams, requestOptions,
  } = params
  const { updateComment: updateFn } = commentActions

  const updatedParams = {
    id: comment.id,
    ...commentParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteCommentParams = {
  comment: DeleteParams<CommentModel>,
  dispatch: AppDispatch,
}

const deleteComment = (params: DeleteCommentParams) => {
  const { comment, dispatch } = params
  const { deleteComment: deleteFn } = commentActions

  return dispatch(deleteFn(comment))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useCommentForm(
  comment: Partial<CommentModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const commentForm = useForm(
    defaultFormState,
    { entity: comment, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [comment.id, comment.cacheKey],
  )

  return {
    ...commentForm,
  }
}

// Return related entities
export const useRelations = (entity: Partial<CommentModel> = {}) => {
  const { commentableId, commentableType, userId } = entity

  const { conversations, users } = useSelector(reduxState => reduxState.entities)

  const conversation = commentableType === 'Conversation' && commentableId && conversations[commentableId]
    ? conversations[commentableId]
    : {}
  const user = userId && users[userId] ? users[userId] : {}

  return {
    conversation,
    user,
  }
}

function useComment(initEntity: Partial<CommentModel> = {}) {
  const { entity: comment }: { entity: CommentModel} = useLatestEntity(initEntity, 'comments')

  const dispatch = useDispatch()

  const { creating } = useSelector(reduxState => reduxState.comments)

  const { conversation, user } = useRelations(comment)

  return {
    callbacks: {
      createComment: (commentParams: Partial<CommentModel>, entityRequestOptions?: EntityOptions) => (
        createComment({ commentParams, dispatch, requestOptions: entityRequestOptions })
      ),
      deleteComment: (entityRequestOptions?: EntityOptions) => (
        deleteComment({ dispatch, comment, requestOptions: entityRequestOptions })
      ),
      updateComment: (commentParams: Partial<CommentModel>, entityRequestOptions?: EntityOptions) => (
        updateComment({
          comment, commentParams, dispatch, requestOptions: entityRequestOptions,
        })
      ),
    },
    comment,
    conversation,
    creating,
    user,
  }
}

export default useComment

import { DashboardModule, Form } from '@campaignhub/suit-theme'

import type { BusinessUnitFormPayload } from '@hooks/useBusinessUnit'
import useCountries from '@hooks/useCountries'

interface DetailsProps {
  businessUnitFormPayload: BusinessUnitFormPayload,
}

const Details = (props: DetailsProps)  => {
  const { businessUnitFormPayload } = props || {}
  const {
    entityState: {
      name,
      tradingName,
      countryId,
    },
    errors,
    handlers,
   } = businessUnitFormPayload

  const { filteredCountries } = useCountries()

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column', padding: 'large' }} title=" Details">
      <Form>
        <Form.Row>
         <Form.Field errorMessage={errors.name} label="* Display Name">
            <input
              name="name"
              type="text"
              value={name}
              {...handlers}
            />
          </Form.Field>
          <Form.Field errorMessage={errors.tradingName} label="* Legal Entity Name">
            <input
              name="tradingName"
              type="text"
              value={tradingName}
              {...handlers}
            />
          </Form.Field>
        </Form.Row>
        <Form.Row>
          <Form.Field errorMessage={errors.countryId} label="* Country" marginTop={"large"}>
            <select
                data-value-type="number"
                disabled={false}
                name="countryId"
                value={countryId}
                {...handlers}
              >
                <option value="">Please Select...</option>
                {filteredCountries.map(country => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </select>
          </Form.Field>
        </Form.Row>
      </Form>
    </DashboardModule>
  )
}

export default Details

import { useEffect } from 'react'

import { digObject } from '@campaignhub/javascript-utils'
import { useSetState } from '@campaignhub/react-hooks'

import useSelector from '@hooks/useSelector'

const defaultState = {
  total: null,
}

const usePriceTotal = (priceId: number | string, quantity: number | string) => {
  const [state, setState] = useSetState(defaultState)
  const { total } = state

  const { prices } = useSelector(reduxState => reduxState.entities)

  useEffect(() => {
    if (!!priceId && !!quantity){
      const selectedPrice = digObject(prices, String(priceId), {})
      const { incTaxAmount } = selectedPrice

      const priceTotal = incTaxAmount ? Number(incTaxAmount) * Number(quantity) : null

      setState({ total: priceTotal })
    }
  }, [priceId, quantity])

  return {
    total,
  }
}

export default usePriceTotal

export type SubscriptionItemRequestOptions = {
  includeSubscriptionItemBusinessUnitProduct?: boolean,
  includeSubscriptionItemPrice?: boolean,
  includeSubscriptionItemSubscription?: boolean,
}

export type SubscriptionItemModel = {
  businessUnitName?: string,
  businessUnitProductId?: number,
  cacheKey?: string,
  id: number | null,
  incTaxAmount?: number,
  key?: string,
  name?: string,
  priceId?: number,
  qty?: number,
  stripeReferenceKey?: string,
  subscriptionId?: number,
  taxAmount?: number,
  totalAmount?: number,
}

const state = {
  businessUnitProductId: null,
  id: null,
  incTaxAmount: null,
  key: '',
  name: '',
  priceId: null,
  qty: null,
  stripeReferenceKey: '',
  subscriptionId: null,
  taxAmount: null,
  totalAmount: null,
}

export const requiredFields = [
  { key: 'businessUnitProductId' },
  { key: 'priceId' },
  { key: 'qty' },
]

export default state

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOut } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Image, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import type { InvoiceModel } from '@models/invoice'

import campaignPlaceholderImage from '../../../assets/campaign_image_400x400.png'

const CampaignMetaData = (props: { invoice: InvoiceModel }) => {
  const { invoice } = props || {}
  const { invoiceMetaData } = invoice || {}
  const {
    campaignId,
    campaignAddress,
    campaignAgentName,
    orderId,
  } = invoiceMetaData || {}

  // TODO: GET EXTERNAL CAMPAIGN URL?

  return (
    <>
      <ListItem
        boxProps={{
          alignItems: 'center',
          borderBottom: '1px solid',
          padding: 'large',
        }}
        disableHover
      >
        <Image
          boxProps={{ borderRadius: 5, marginRight: 'large' }}
          height="40px"
          placeholderUrl={campaignPlaceholderImage}
        // url={imageUrl} TODO: Get from Campaign
          width="60px"
        />

        <Box flexDirection="column">
          <Text color="bodyFontLightColor" fontSize="small">{campaignAddress}</Text>

          <Link greyLink textProps={{ fontSize: 'small', marginTop: 'medium' }}>View in Realhub</Link>
        </Box>

        <Link greyLink href="#">
          <FontAwesomeIcon icon={faSignOut} />
        </Link>
      </ListItem>

      <Box borderBottom="1px solid" borderColor="lineColor" flexDirection="column" padding="large">
        <Box alignItems="center" justifyContent="space-between">
          <Text color="bodyFontLightColor" fontSize="small">Campaign</Text>

          <Text fontSize="small" maxWidth="60%" textAlign="right" variant="ellipsis">
            {campaignId ? `Campaign #${campaignId}` : ''}
          </Text>
        </Box>

        <Box alignItems="center" justifyContent="space-between" marginTop="large">
          <Text color="bodyFontLightColor" fontSize="small">Agent</Text>

          <Text fontSize="small" textAlign="right">
            {campaignAgentName}
          </Text>
        </Box>

        {orderId && (
          <Box alignItems="center" justifyContent="space-between" marginTop="large">
            <Text color="bodyFontLightColor" fontSize="small">Order</Text>

            <Text fontSize="small" textAlign="right">
              {`Order #${orderId}`}
            </Text>
          </Box>
        )}
      </Box>
    </>
  )
}

export default CampaignMetaData

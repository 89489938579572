import { formatMoney } from 'accounting'

import {
  Box, ListItem, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import { CreditSummaryByStatusModel } from '@models/creditSummary'

import { statusColor } from '@functions/status'

interface CreditSummaryGroupProps {
  creditSummaryItem: CreditSummaryByStatusModel,
}

const CreditSummaryGroup = (props: CreditSummaryGroupProps) => {
  const { creditSummaryItem } = props || {}
  const { creditCount, creditTotalAmount, key: statusKey } = creditSummaryItem

  return (
    <ListItem
      boxProps={{
        alignItems: 'center', borderBottom: '1px dashed', lineHeight: 1.3, padding: 'large',
      }}
      disableHover
      forceBottomBorder
    >
      <Box flexDirection="column">
        <Text color="bodyFontLightColor" fontSize="small">
          {`${creditCount || '0'} Credits`}
        </Text>

        <Text color={statusColor(statusKey) || 'bodyFontColor'} fontSize="large" marginTop="small">
          {formatMoney(creditTotalAmount)}
        </Text>
      </Box>

      <StatusBadge
        boxProps={{ justifySelf: 'end', height: 25, width: 80 }}
        color={statusColor(statusKey)}
        ghost
        text={statusKey}
      />
    </ListItem>
  )
}

export default CreditSummaryGroup

type BillingPeriodKeys = ['day', 'week', 'month', 'year']

export type BillingPeriod = {
  key: string,
  name: string,
}
 
export type BillingPeriods = {
  day: BillingPeriod,
  week: BillingPeriod,
  month: BillingPeriod,
  year: BillingPeriod,
}

export const billingPeriods: BillingPeriods = {
  day: {
    key: "day",
    name: 'Daily',
  },
  week: {
    key: "week",
    name: 'Weekly',
  },
  month: {
    key: "month",
    name: 'Monthly',
  },
  year: {
    key: "year",
    name: 'Yearly',
  },
}

import { Columns, LoadingModule } from '@campaignhub/suit-theme'

import useContact from '@hooks/useContact'

import CreditsSummary from '@components/CreditsSummary'
import InvoicesSummary from '@components/InvoicesSummary'

import Statements from '@sections/Admin/packs/BusinessUnits/screens/Edit/components/Statements'

// Contact Edit Screens Shared Components
import PageLayout from '../../../../components/PageLayout'

// Contact Edit Overview Screen Components
import Details from '../Details'
import Subscriptions from '../Subscriptions'
import Tools from '../Tools'

const PageContent = (props: { contactId: number }) => {
  const { contactId } = props

  const contactPayload = useContact({ id: contactId })

  const {
    contact, loading, urls,
  } = contactPayload
  const { name } = contact || {}
  const { editContactCreditsUrl, editContactInvoicesUrl } = urls || {}

  return (
    <PageLayout entityPayload={contactPayload} activeTabBarItemKey="overview">
      <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
        <Columns.Main>
          <LoadingModule loading={loading} />

          {!loading && (
            <>
              <InvoicesSummary
                filters={{ contactId }}
                href={editContactInvoicesUrl}
              />

              <CreditsSummary
                filters={{ contactId }}
                href={editContactCreditsUrl}
              />
            </>
          )}
        </Columns.Main>

        <Columns.Sidebar>
          <Tools contact={contact} />

          <Details contactId={contactId} />

          <Subscriptions contactId={contactId} />

          <Statements contactId={contactId} />
        </Columns.Sidebar>
      </Columns>
    </PageLayout>
  )
}

export default PageContent

import Axios from 'axios'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'

import { getAccessToken } from '@functions/accessToken'

const urls = {
  development: 'https://localhost:5001/v1',
  production: 'https://api.billing.realbase.io/v1',
  staging: 'https://api.billing.staging.realbase.io/v1',
  test: 'http://localhost:5001/v1',
}

const axiosConfig: AxiosRequestConfig = {
  baseURL: urls[process.env.REACT_APP_APPLICATION_ENV],
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
}

const axios: AxiosInstance = Axios.create(axiosConfig)

axios.interceptors.request.use(
  async (config) => {
    const accessToken = getAccessToken()

    const updatedConfig = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    }

    return updatedConfig
  },
  (error) => {
    // Do something with request error
    Promise.reject(error)
  },
)

axios.interceptors.response.use(
  response => response,
  responseError => Promise.reject(responseError.response.data),
)

const baseConfig: AxiosRequestConfig = {
  method: 'get',
}

const api = (
  url: string,
  options: any = {},
  config: AxiosRequestConfig = {},
) => {
  const mergedConfig: AxiosRequestConfig = { ...baseConfig, ...config }

  // Remove entityKey from requestOptions
  const { entityKey, ...requestOptions } = options

  return axios({
    ...mergedConfig,
    params: requestOptions,
    url,
  })
}

export default api

import {
  Navigate, Route, Routes, HashRouter as Router,
} from 'react-router-dom'

import useCurrentUser from '@hooks/useCurrentUser'

import Admin from '.'
import Dashboard from './packs/Dashboard'
import Accounting from '@sections/Client/packs/Accounting'
import AdminPack from './packs/Admin'
import BusinessUnits from './packs/BusinessUnits'

const AdminRoutes = () => {
  const { currentUser } = useCurrentUser()
  const { isSystemAdmin } = currentUser || {}

  return (
    <Router>
      <Admin>
        <Routes>
          <Route
            index
            element={
              isSystemAdmin ? (
                <Dashboard />
              ) : (
                <Navigate replace to="/admin/contacts" />
              )
            }
          />
          <Route path="/accounting/*" element={<Accounting />} />
          <Route path="/admin/*" element={<AdminPack />} />
          <Route path="/business-units/*" element={<BusinessUnits />} />
        </Routes>
      </Admin>
    </Router>
  )
}

export default AdminRoutes

import api from '@functions/api'

import type { UserModel } from '@models/user'
import type { AppDispatch } from '@redux/store'
import type {
  Action, ActionResult, EntityOptions, Errors,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/currentUser/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/currentUser/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/currentUser/FETCH_FAILURE'

export type CurrentUserModuleState = {
  errors: string[],
  loaded: boolean,
  loading: boolean,
  result: UserModel | {},
}

const initialState: CurrentUserModuleState = {
  errors: [],
  loaded: false,
  loading: false,
  result: {},
}

// Actions
export function fetchRequest(): Action {
  return {
    type: FETCH_REQUEST,
  }
}

type FetchRequestPayload = {
  result: UserModel,
}

export function fetchSuccess(payload: FetchRequestPayload): Action {
  return {
    type: FETCH_SUCCESS,
    result: payload.result,
  }
}

export function fetchFailure(errors: Errors = []): Action {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

// Action Creators
export function loadCurrentUser(options: EntityOptions = {}) {
  return (dispatch: AppDispatch): Promise<ActionResult<UserModel>> => {
    dispatch(fetchRequest())

    const promise = api('/users/me', options)
      .then(({ data }) => {
        dispatch(fetchSuccess({ result: data }))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: CurrentUserModuleState = initialState,
  action: Action = { type: '' },
): CurrentUserModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        errors: [],
        loaded: true,
        loading: false,
        result: action.result || state.result,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        errors: action.errors || [],
        loaded: false,
        loading: false,
      }
    default:
      return state
  }
}

import { Button, DashboardModule, Form } from '@campaignhub/suit-theme'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BusinessUnitFormPayload } from '@hooks/useBusinessUnit'
import { useBusinessNumbers } from '@hooks/useBusinessNumbers'
import { default as defaultBusinessNumberState } from "@models/businessNumber"
import BusinessNumberItem from './BusinessNumberItem'

interface BusinessNumberProps {
  businessUnitFormPayload: BusinessUnitFormPayload,
  setValidationStatus: React.Dispatch<React.SetStateAction<boolean>>,
}

const BusinessNumber = (props: BusinessNumberProps) => {
  const { businessUnitFormPayload, setValidationStatus } = props || {}
  const {
    entityState: {
      businessNumbers,
    },
    setEntityState
   } = businessUnitFormPayload

   const { formErrors } = useBusinessNumbers(businessNumbers, setValidationStatus)

   const handleAddMoreClick = () => {
    const newItems = [...businessNumbers]
    newItems.push(defaultBusinessNumberState)
    setEntityState({ businessNumbers: newItems })
  }

  const handleDelete = (id: number) => {
    const newItems = businessNumbers.filter((_, i) => i !== id)
    setEntityState({businessNumbers: newItems})
  }

  const handleChange = (index: number, key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    const newItems = [...businessNumbers]
    newItems[index] = { ...newItems[index], id: index, [key]: newValue }
    setEntityState({ businessNumbers: newItems })
  }

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column', padding: 'large' }} title="Business Information">
      <Form>
        {businessNumbers && businessNumbers.map((item, index) => {
          return (
            <BusinessNumberItem key={index} index={index} businessNumber={item}  errors={formErrors} handleChange={handleChange} handleDelete={() => handleDelete(index)} />
          )
        })}
      </Form>
      <DashboardModule.FooterRight boxProps={{ flexDirection: ['column', 'row'], marginTop:"large" }}>
        <Button
            buttonStyle="primaryCreate"
            disabled={false}
            marginLeft={[0, 'medium']}
            marginTop={['medium', 0]}
            size="small"
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={handleAddMoreClick}
          >
            Add More
          </Button>
      </DashboardModule.FooterRight>
    </DashboardModule>
  )
}

export default BusinessNumber

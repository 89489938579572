import { formatMoney } from 'accounting'

import {
  Box,
  Image,
  ListItem,
  StatusBadge,
  Text,
} from '@campaignhub/suit-theme'

import { statusColor } from '@functions/status'

import { generateUrls } from '@hooks/useContact'

import { ContactsInvoiceSummaryModel } from '@models/contactsInvoiceSummary'

import agencyPlaceHolderImage from '../../assets/agency_blank.svg'

interface ContactInvoicesListItemProps {
  contact: ContactsInvoiceSummaryModel,
}

const ContactListItem = (props: ContactInvoicesListItemProps) => {
  const { contact } = props

  const {
    active,
    contactId,
    contactName,
    imageUrl,
    totalOverdue,
    totalOverdueAmount,
  } = contact

  const { editContactUrl } = generateUrls({ id: contactId })

  return (
    <ListItem
      boxProps={{ as: 'a', borderBottom: '1px dashed', padding: 'large' }}
      href={editContactUrl}
      showAngleIcon
    >
      <Image
        boxProps={{ borderRadius: 5, marginRight: 'large' }}
        height="40px"
        placeholderUrl={agencyPlaceHolderImage}
        url={imageUrl}
        width="60px"
      />

      <Box flexDirection="column" maxWidth={['50%', '70%']} marginRight="medium">
        <Text fontSize="small" marginBottom="small" variant="ellipsis">
          {contactName}
        </Text>

        <StatusBadge
          color={statusColor(active ? 'active' : 'inactive')}
          ghost
          text={active ? 'OVERDUE' : 'DISABLED'}
        />
      </Box>

      <Box
        alignItems="flex-end"
        flexDirection="column"
        marginLeft="auto"
      >
        <Text color="bodyFontColor" marginBottom="small" variant="ellipsis">
          {formatMoney(totalOverdueAmount)}
        </Text>
        <Text color="bodyFontLightColor" fontSize="xsmall" variant="ellipsis">
          {`${totalOverdue} Overdue Invoices`}
        </Text>
      </Box>
    </ListItem>
  )
}

export default ContactListItem

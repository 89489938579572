import { Conversation as ConversationBase } from '@campaignhub/suit-theme'

import useEntityConversation from '@hooks/useEntityConversation'

import BlankState from '@components/BlankState'

import type { EntityOptions } from '@redux/modules/types'

import Comment from './Comment'

import ConversationImageUrl from './assets/conversation.svg'

const viewOnly = JSON.parse(process.env.REACT_APP_VIEW_ONLY || "false")

interface ConversationProps {
  boxProps?: object,
  requestOptions?: EntityOptions,
  showAddCommentBox?: boolean,
  subjectId: number,
  subjectType: string,
  title: string,
}

const Conversation = (props: ConversationProps) => {
  const {
    boxProps, requestOptions, showAddCommentBox = true, subjectId, subjectType, title,
  } = props

  const entityConversationPayload = useEntityConversation({
    conversation: {
      subjectId,
      subjectType,
      title,
    },
    performHttpRequests: !!subjectId,
    requestOptions,
  })

  const {
    callbacks: { createCommentWithConversation },
    commentCount,
    comments,
    creating,
    loading,
    hasConversation
  } = entityConversationPayload

  const hasComments = !loading && commentCount > 0 && hasConversation

  return (
    <ConversationBase
      boxProps={{
        justifyContent: 'flex-start',
        ...boxProps,
      }}
      callbacks={{ createCommentWithConversation }}
      commentsBoxProps={{
        alignItems: !hasComments ? 'center' : undefined,
        height: !hasComments ? 270 : undefined,
        justifyContent: !hasComments ? 'center' : undefined,
      }}
      commentCount={commentCount}
      creating={creating}
      loading={loading}
      requestOptions={requestOptions}
      showAddCommentBox={!viewOnly ? showAddCommentBox : false}
    >
      {!hasComments && (
        <BlankState
          imageUrl={ConversationImageUrl}
          title="No Comments"
          subtitle="Keep a detailed history of work, decisions and requests in the conversation."
        />
      )}

      {hasComments && comments.map(comment => (
        <Comment comment={comment} hideLastChildBottomBorder={!showAddCommentBox} key={comment.id} />
      ))}
    </ConversationBase>
  )
}

export default Conversation

import { DashboardModule } from '@campaignhub/suit-theme'

interface ListBlankStateProps {
  title?: string,
  text?: string,
  secondaryText?: string,
}

const ListBlankState = (props: ListBlankStateProps) => {
  const { secondaryText, title, text } = props

  return (
    <DashboardModule.BlankState>
      <DashboardModule.BlankState.Title>
        {title || 'No Results.'}
      </DashboardModule.BlankState.Title>

      <DashboardModule.BlankState.Paragraph>
        {text || 'Please adjust your filters.'}
      </DashboardModule.BlankState.Paragraph>

      {secondaryText && (
        <DashboardModule.BlankState.Paragraph>
          {secondaryText}
        </DashboardModule.BlankState.Paragraph>
      )}
    </DashboardModule.BlankState>
  )
}

export default ListBlankState

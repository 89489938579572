import {
  useForm, useLatestEntity, useSetState, useThunkDispatch,
} from '@campaignhub/react-hooks'
import type { UseFormOptions, UseFormPayload } from '@campaignhub/react-hooks'

import * as transactionItemActions from '@redux/modules/transactionItem'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'
import useTransaction from '@hooks/useTransaction'

import { default as defaultTransactionItemState, requiredFields } from '@models/transactionItem'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams } from '@redux/modules/types'
import type { TransactionItemModel, TransactionItemRequestOptions } from '@models/transactionItem'

interface Options {
  performHttpRequests?: boolean,
  requestOptions?: TransactionItemRequestOptions,
}

type CreateTransactionItemParams = {
  dispatch: AppDispatch,
  entityParams: Partial<TransactionItemModel>,
  requestOptions?: TransactionItemRequestOptions,
}

const createTransactionItem = (params: CreateTransactionItemParams) => {
  const { entityParams, dispatch, requestOptions = {} } = params
  const { createTransactionItem: createFn } = transactionItemActions

  return dispatch(createFn(entityParams, requestOptions))
}

type UpdateTransactionItemParams = {
  transactionItemParams: Partial<TransactionItemModel>,
  dispatch: AppDispatch,
  requestOptions?: TransactionItemRequestOptions,
}

export const updateTransactionItem = (params: UpdateTransactionItemParams) => {
  const { transactionItemParams, dispatch, requestOptions } = params
  const { updateTransactionItem: updateFn } = transactionItemActions

  return dispatch(updateFn(transactionItemParams, requestOptions))
}

type DeleteTransactionItemParams = {
  dispatch: AppDispatch,
  transactionItem: DeleteParams<TransactionItemModel>,
}

const deleteTransactionItem = (params: DeleteTransactionItemParams) => {
  const { transactionItem, dispatch } = params
  const { deleteTransactionItem: deleteFn } = transactionItemActions

  return dispatch(deleteFn(transactionItem))
}

export function useTransactionItemForm(transactionItem: TransactionItemModel, options: UseFormOptions = {}) {
  const { validateOn } = options

  const transactionItemForm = useForm(
    defaultTransactionItemState,
    { entity: transactionItem, requiredFields, validateOn },
    [transactionItem.cacheKey],
  )

  return {
    ...transactionItemForm,
  }
}

const defaultState = {
  editing: false,
}

function useTransactionItem(initEntity: TransactionItemModel = { id: null }, options: Options = {}) {
  const { performHttpRequests, requestOptions } = options || {}

  const { entity: transactionItem } = useLatestEntity(initEntity, 'transactionItems')
  const { id, transactionId } = transactionItem

  const [state, setState] = useSetState(defaultState)
  const { editing } = state

  const dispatch = useThunkDispatch()

  const transactionPayload = useTransaction({ id: transactionId }, { performHttpRequests: !!transactionId })
  
  const { callbacks: { loadTransaction : loadTransactionFn } } = transactionPayload

  useReduxAction(
    'transactionItems',
    'loadTransactionItem',
    {
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, requestOptions) => action(id, requestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedIds } = entityReducer
        return performHttpRequests && !loadedIds.includes(id)
      },
    },
  )

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.transactionItems)

  return {
    callbacks: {
      createTransactionItem: (entityParams: TransactionItemModel, requestOptions: TransactionItemRequestOptions) => createTransactionItem({ entityParams, dispatch, requestOptions }),
      deleteTransactionItem: () => deleteTransactionItem({ dispatch, transactionItem }),
      editTransactionItem: () => {setState({ editing: !editing }), (editing) ? loadTransactionFn() : null },
      updateTransactionItem: (transactionItemParams: TransactionItemModel, requestOptions: object) => updateTransactionItem({ transactionItemParams, dispatch, requestOptions }),
    },
    creating,
    deleting,
    editing,
    transactionItem,
    loading,
    updating,
  }
}

export default useTransactionItem

import { Comment as CommentBase } from '@campaignhub/suit-theme'
import { digObject, formatDate } from '@campaignhub/javascript-utils'

import type { CommentModel } from '@models/comment'

import useComment from '@hooks/useComment'

interface CommentProps {
  comment: Partial<CommentModel>,
  hideLastChildBottomBorder?: boolean,
}

const Comment = (props: CommentProps) => {
  const { comment, hideLastChildBottomBorder } = props

  const commentPayload = useComment(comment)
  const { comment: { body, public: isPublic }, user } = commentPayload

  const createdDateString = digObject(comment, 'dateCreated')

  return (
    <CommentBase
      commentBody={body}
      commentTime={
        createdDateString
          ? formatDate(createdDateString, 'ISO8601', "ccc, dd LLL yyyy 'at' h:mm a")
          : 'Not Set'
      }
      fullName={comment.userFullName}
      hideLastChildBottomBorder={hideLastChildBottomBorder}
      isPublic={isPublic}
    />
  )
}

export default Comment

import { useContext } from 'react'

import { Columns, LoadingModule } from '@campaignhub/suit-theme'

import useContact from '@hooks/useContact'
import useInvoices from '@hooks/useInvoices'

import PageContext from '@contexts/pageContext'

import DraftInvoices from '@components/DraftInvoices'
import ProcessedInvoices from '@components/ProcessedInvoices'

import PageLayout from '../../../../components/PageLayout'

import Tools from '../InvoiceTools'

interface PageContentProps {
  contactId: number,
}

const PageContent = (props: PageContentProps) => {
  const { contactId } = props

  const contactPayload = useContact({ id: contactId })
  const { loading: loadingContact } = contactPayload

  const invoicesPayload = useInvoices({ filters: { contactId, status: 'all', } })
  const { loading: loadingInvoices } = contactPayload

  const pageContext = useContext(PageContext)
  const {} = pageContext

  const loading = loadingContact && loadingInvoices

  return (
    <PageLayout entityPayload={contactPayload} activeTabBarItemKey="invoices">
      <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
        <Columns.Main>
          <LoadingModule loading={loading} />

          {!loading && (
            <>
              <DraftInvoices
                filters={{ contactId, status: 'draft' }}
                performHttpRequests={!!contactId}
                requestOptions={{ includeInvoiceContact: !!contactId }}
              />

              <ProcessedInvoices
                filters={{ contactId, status: 'processed' }}
                performHttpRequests={!!contactId}
                requestOptions={{ includeInvoiceContact: !!contactId }}
              />
            </>
          )}
        </Columns.Main>

        <Columns.Sidebar>
          <Tools invoicesPayload={invoicesPayload} />
        </Columns.Sidebar>
      </Columns>
    </PageLayout>
  )
}

export default PageContent

import { digObject } from '@campaignhub/javascript-utils'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import useInvoice, { generateUrls } from '@hooks/useInvoice'
import useSelector from '@hooks/useSelector'

import { InvoiceModel } from '@models/invoice'

import { formatMoney } from 'accounting'

interface InvoiceListItemProps {
  invoice: InvoiceModel,
}

const InvoiceListItem = (props: InvoiceListItemProps) => {
  const { invoice } = props
  const payload = useInvoice(invoice)

  const {
    invoice: {
      balance,
      contactId,
      daysOverdue,
      id,
      number,
    },
  } = payload

  const entities = useSelector(reduxState => reduxState.entities)
  const { contacts } = entities

  const contact = digObject(contacts, String(contactId), {})
  const { name } = contact

  const { editInvoiceUrl } = generateUrls({ id })

  return (
    <ListItem
      boxProps={{
        alignItems: 'center', as: 'a', borderBottom: '1px dashed', lineHeight: 1.3, padding: 'large',
      }}
      href={editInvoiceUrl}
      showAngleIcon
    >
      <Box
        background="rgba(226, 0, 26, 0.05)"
        border="1px solid"
        borderColor="rgba(255, 0, 0, 0.2)"
        borderRadius={5}
        flexDirection="column"
        marginRight="large"
        padding="medium"
        width="50px"
      >
        <Text color="red" fontSize="medium" fontWeight="bold" marginBottom="small" marginX="auto">
          {daysOverdue}
        </Text>

        <Text color="red" fontSize="xsmall">
          DAYS
        </Text>
      </Box>

      <Box flexDirection="column">
        <Text fontSize="medium" marginBottom="small" variant="ellipsis">
          {`Invoice #${number}`}
        </Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" variant="ellipsis">
          {name}
        </Text>
      </Box>

      <Box alignItems="flex-end" flexDirection="column" justifyContent="center">
        <Text fontSize="medium" marginBottom="small">
          {`${formatMoney(balance)}`}
        </Text>

        <Text color="bodyFontLightColor" display={['none', 'flex']} fontSize="xsmall">
          {`Overdue by ${daysOverdue} Days`}
        </Text>
      </Box>
    </ListItem>
  )
}

export default InvoiceListItem

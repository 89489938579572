import { useMemo } from 'react'
import { formatMoney } from 'accounting'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Link, StatusBadge, Text, Table,
} from '@campaignhub/suit-theme'
import { digObject, formatDate, goToEntity } from '@campaignhub/javascript-utils'

import { displaySubscriptionInterval } from '@functions/subscription'
import { statusColor } from '@functions/status'

import { generateUrls as generateContactUrls } from '@hooks/useContact'
import { generateUrls as generateSubscriptionUrls } from '@hooks/useSubscription'
import useSelector from '@hooks/useSelector'

import BusinessUnitLogo from '@components/BusinessUnitLogo'

import type { EntitiesState } from '@redux/entities'
import type { SubscriptionModel } from '@models/types'
import type { SubscriptionFilters } from '@hooks/useSubscriptions'

type BuildColumnsProps = {
  entities: EntitiesState,
  filters: SubscriptionFilters,
}

const buildColumns = (props: BuildColumnsProps) => {
  const { entities, filters } = props || {}
  const { businessUnits, statuses } = entities || {}

  return [
    {
      cellProps: {
        key: 'statusId',
        alignItems: 'center',
        width: '75px',
      },
      title: 'Status',
      dataKey: 'statusId',
      render: (statusId: number) => {
        const status = statuses[statusId]
        const statusKey = status?.key

        return (
          <StatusBadge boxProps={{ minWidth: '75px' }} color={statusColor(statusKey)} ghost text={statusKey} />
        )
      },
    },
    {
      cellProps: {
        key: 'subId',
        alignItems: 'center',
        width: '100px',
      },
      title: 'Sub #ID',
      dataKey: 'id',
      render: (id: number) => (
        <Link
          onClick={() => goToEntity({
            generateUrls: () => generateSubscriptionUrls({ id }),
            entityUrlKey: 'editSubscriptionUrl',
          })}
        >
          {`SUB-${id}`}
        </Link>
      ),
    },
    {
      cellProps: {
        key: 'contact',
        verticalAlign: 'middle',
      },
      hidden: !!filters.contactId,
      title: 'Contact',
      dataKey: 'contactName',
      render: (contactName: number, subscription: SubscriptionModel) => {
        if (filters.contactId) return null

        const { contactId } = subscription

        return (
          <Link
            onClick={() => goToEntity({
              generateUrls: () => generateContactUrls({ id: contactId }),
              entityUrlKey: 'editContactUrl',
            })}
          >
            {contactName}
          </Link>
        )
      },
    },
    {
      cellProps: {
        key: 'interval',
      },
      title: 'Billing',
      dataKey: 'interval',
      render: (interval: string) => (
        <Text>{displaySubscriptionInterval(interval)}</Text>
      ),
    },
    {
      cellProps: {
        key: 'date',
      },
      title: 'Since',
      dataKey: 'statusId',
      render: (statusId: string, subscription: SubscriptionModel) => {
        const status = statuses[statusId]
        const statusKey = status?.key

        const { subscriptionStart } = subscription

        return (
          <Text variant="ellipsis">{formatDate(subscriptionStart, 'ISO8601', 'DD')}</Text>
        )
      },
    },
    {
      cellProps: {
        key: 'products',
        verticalAlign: 'middle',
      },
      hidden: !!filters.businessUnitId,
      title: 'Products',
      dataKey: 'businessUnitIds',
      render: filters.businessUnitId ? null : (businessUnitIds: number[] = [6]) => (
        <Box flowDirection="column">
          {
            businessUnitIds.map((businessUnitId: number, index: number) => {
              const businessUnit = digObject(businessUnits, String(businessUnitId), {})
              const { key } = businessUnit

              const isLastKey = index === businessUnitIds.length - 1

              return (
                <BusinessUnitLogo
                  boxProps={{
                    height: 30,
                    marginRight: isLastKey ? 0 : 'medium',
                    width: 30,
                  }}
                  businessUnitKey={key}
                />
              )
            })
         }
        </Box>
      ),
    },
    {
      cellProps: {
        key: 'amount',
      },
      title: 'Amount ($)',
      dataKey: 'incTaxAmount',
      render: (incTaxAmount: number) => (
        <Text>{incTaxAmount ? formatMoney(incTaxAmount) : ''}</Text>
      ),
    },
    {
      cellProps: {
        key: 'edit',
      },
      dataKey: 'edit',
      render: (datakey: string, subscription: SubscriptionModel) => {
        const { editSubscriptionUrl } = generateSubscriptionUrls(subscription)

        return (
          <Link greyLink href={editSubscriptionUrl}>
            <FontAwesomeIcon icon={faPencil} />
          </Link>

        )
      },
    },
  ]
}

interface SubscriptionsTableProps {
  filters: SubscriptionFilters,
  filteredSubscriptions: SubscriptionModel[],
}

const SubscriptionsTable = (props: SubscriptionsTableProps) => {
  const { filters, filteredSubscriptions } = props

  const { entities } = useSelector(reduxState => reduxState)

  const memoColumns = useMemo(
    () => buildColumns(
      {
        entities,
        filters,
      },
    ),
    [filteredSubscriptions.length],
  )

  return (
    <Table
      boxProps={{ border: 'none', maxHeight: 500, overflowY: 'auto' }}
      columns={memoColumns}
      data={filteredSubscriptions}
      stickyHeader
    />
  )
}

export default SubscriptionsTable

import { useContext } from 'react'

import { digObject, launchModal } from '@campaignhub/javascript-utils'

import { useForm, useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'
import type { UseFormOptions, UseFormPayload } from '@campaignhub/react-hooks'

import defaultContactState, { requiredFields, statementFormState } from '@models/contact'
import type { ContactModel, ContactRequestOptions, StatementFormParams } from '@models/contact'

import * as contactActions from '@redux/modules/contact'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'

export const generateUrls = (contact?: ContactModel) => {
  const { id } = contact || {}

  return {
    editContactDetailsUrl: `#/admin/contacts/${id}/edit/details`,
    contactsIndexUrl: '#/admin/contacts',
    editContactCreditsUrl: `#/admin/contacts/${id}/edit/credits`,
    editContactInvoicesUrl: `#/admin/contacts/${id}/edit/invoices`,
    editContactOverviewUrl: `#/admin/contacts/${id}/edit/overview`,
    editContactStatementsUrl: `#/admin/contacts/${id}/edit/statements`,
    editContactSubscriptionsUrl: `#/admin/contacts/${id}/edit/subscriptions`,
    editContactUrl: `#/admin/contacts/${id}/edit/`,
  }
}

type DownloadStatementParams = {
  dispatch: AppDispatch,
  entityParams: StatementFormParams,
}

export const downloadStatementPDF = (params: DownloadStatementParams) => {
  const { dispatch, entityParams } = params
  const { downloadStatementPDF: downloadFn } = contactActions

  return dispatch(downloadFn(entityParams))
}

type ToggleActiveParams = {
  contact: ContactModel,
  dispatch: AppDispatch,
}

export const toggleActive = (params: ToggleActiveParams) => {
  const { contact, dispatch } = params
  const { toggleContactActive: toggleFn } = contactActions

  return dispatch(toggleFn(contact))
}

type UpdateContactParams = {
  contactParams: Partial<ContactModel>,
  dispatch: AppDispatch,
  requestOptions?: ContactRequestOptions,
}

export const updateContact = (params: UpdateContactParams) => {
  const { contactParams, dispatch, requestOptions } = params
  const { updateContact: updateFn } = contactActions

  return dispatch(updateFn(contactParams, requestOptions))
}

type ContactForm = { entityState: ContactModel }

export type ContactFormPayload = UseFormPayload & ContactForm

export function useContactForm(contact: ContactModel, options: UseFormOptions = {}): ContactFormPayload {
  const { validateOn } = options

  const contactForm = useForm(
    defaultContactState,
    { entity: contact, requiredFields, validateOn },
    [contact.cacheKey],
  )
  return {
    ...contactForm,
  }
}

export function useStatementForm(
  statement: Partial<StatementFormParams>,
  options: UseFormOptions = {}
) {
  const { customRequiredFields = [], validateOn } = options

  const statementForm = useForm(
    statementFormState,
    { entity: statement, requiredFields: [...customRequiredFields], validateOn },
    [statement.id],
  )

  return {
    ...statementForm,
  }
}

export const useRelations = (contact: ContactModel) => {
  const { contactTypeId, parentContactId } = contact

  const { contacts, contactTypes } = useSelector(reduxState => reduxState.entities)

  const contactType = digObject(contactTypes, `${contactTypeId}`, {})
  const parentContact = digObject(contacts, `${parentContactId}`, {})

  return {
    contactType,
    parentContact,
  }
}

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: {
    [key: string]: boolean,
  },
}

function useContact(initEntity: Partial<ContactModel> = {}, options?: Options) {
  const { performHttpRequests, requestOptions } = options || {}

  const { entity: contact } = useLatestEntity(initEntity, 'contacts')
  const { id } = contact

  const dispatch = useThunkDispatch()

  const { callbacks } = useContext(PageContext)

  useReduxAction(
    'contacts',
    'loadContact',
    {
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(id, actionRequestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedIds, loading } = entityReducer
        return performHttpRequests && !loadedIds.includes(id) && !loading
      },
    },
  )

  const { loadedIds, loading, updating } = useSelector(reduxState => reduxState.contacts)

  return {
    callbacks: {
      downloadStatementPDF: (entityParams: StatementFormParams) => downloadStatementPDF({ entityParams, dispatch }),
      generateStatement: () => launchModal({
        callbacks,
        modalKey: 'GenerateStatementModal',
        payload: {
          contact,
        },
      }),
      toggleActive: () => toggleActive({ contact, dispatch }),
      updateContact: (contactParams: ContactModel, contactRequestOptions: ContactRequestOptions) => updateContact({
        contact, contactParams, dispatch, requestOptions: contactRequestOptions,
      }),
    },
    contact,
    loading: loading && !loadedIds.includes(id),
    updating,
    urls: generateUrls(contact),
  }
}

export default useContact

import {
  Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis,
} from 'recharts'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/pro-light-svg-icons'

import {
  Box, DashboardModule, LoadingModule, Text,
} from '@campaignhub/suit-theme'

import useContactSummary from '@hooks/useContactSummary'

import styles from './styles.module.scss'

const NewCustomersGraph = () => {
  const {
    comparativeData, data, loading, newAccounts,
  } = useContactSummary()

  const { arrow, color, value } = comparativeData

  return (
    <DashboardModule loading={loading} title="Customers">
      <Box flexDirection="column" padding="large" paddingBottom="0">
        {loading && <LoadingModule />}

        {!loading && (
          <>
            <Box alignItems="center">
              <Text fontSize="large">{newAccounts}&nbsp;</Text>

              <Text color={color}>
                {`${value}% `}
                {arrow !== null && (
                  <FontAwesomeIcon color={color} icon={arrow === 'up' ? faArrowUp : faArrowDown} size="xs" />
                )}
              </Text>
            </Box>

            <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="small">
              New Customers (Month)
            </Text>

            <ResponsiveContainer width="100%" height={140} className={styles.chart}>
              <AreaChart data={data}>
                <CartesianGrid stroke="rgba(0, 0, 0, 0.1)" horizontal={false} strokeDasharray="3 3" strokeWidth={1} />

                <defs>
                  i
                  <linearGradient id="summary" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="lightGrey" stopOpacity={1} />
                    <stop offset="95%" stopColor="lightGrey" stopOpacity={0} />
                  </linearGradient>
                </defs>

                <Area dataKey="total" fill="url(#summary)" fillOpacity={1} stroke="darkGrey" type="monotone" />

                <XAxis axisLine={false} dataKey="month" tick={{ fontSize: 10 }} tickLine={false} />
                <Tooltip />
              </AreaChart>
            </ResponsiveContainer>
          </>
        )}
      </Box>
    </DashboardModule>
  )
}

export default NewCustomersGraph

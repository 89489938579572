import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

type Options = {
  performHttpRequests: boolean,
}

function useStatuses(options: Options) {
  const { performHttpRequests } = options || {}

  useReduxAction('statuses', 'loadStatuses', {}, [performHttpRequests], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loaded, loading } = entityReducer
      return performHttpRequests && !loaded && !loading && !errors.length
    },
  })

  const { statuses } = useSelector(reduxState => reduxState.entities)

  const { loaded, loading } = useSelector(reduxState => reduxState.statuses)

  return {
    statuses,
    loaded,
    loading,
  }
}

export default useStatuses

import { Box, ListItem, Text } from '@campaignhub/suit-theme'
import { formatDate } from '@campaignhub/javascript-utils'

import useUser from '@hooks/useUser'

import type { BillingNoteModel } from '@models/billingNote'

const BillingNote = (props: { billingNote: BillingNoteModel }) => {
  const { billingNote } = props || {}
  const {
    dateCreated, note, title, userId,
  } = billingNote || {}

  const userPayload = useUser({ id: userId }, { performHttpRequests: !!userId })
  const { user: { fullName } } = userPayload

  return (
    <Box
      backgroundColor="whiteGrey"
      border="1px solid"
      borderColor="lineColor"
      borderRadius={5}
      flexDirection="column"
      marginTop="large"
      padding="large"
    >
      <Text color="bodyFontLightColor" fontSize="small">
        {title || 'Billing Note'}
      </Text>

      <ListItem boxProps={{ marginTop: 'large' }} disableHover flexDirection="column">
        <Text fontSize="small">{note}</Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
          {fullName} - {formatDate(dateCreated, 'ISO8601', 'dd-MM-yyyy HH:MM')}
        </Text>
      </ListItem>
    </Box>
  )
}

export default BillingNote

import { Box, Link, Text } from '@campaignhub/suit-theme'

import { goToEntity } from '@campaignhub/javascript-utils'

import { generateUrls } from '@hooks/useContact'

import { getContactFullAddress } from '@functions/contact'

import type { ContactModel } from '@models/contact'

const Contact = (props: { contact: ContactModel }) => {
  const { contact } = props
  const { id, name, phone } = contact

  return (
    <Box flexDirection="column" maxWidth={['100%', '60%']}>
      <Link
        disabledUnderline
        greyLink
        onClick={() => goToEntity({
          generateUrls: () => generateUrls({ id }),
          entityUrlKey: 'editContactUrl',
        })}
        textProps={{ fontSize: 'small', marginBottom: 'small', variant: 'ellipsis' }}
      >
        {name}
      </Link>

      <Text color="bodyFontLightColor" fontSize="xsmall" variant="ellipsis">
        {getContactFullAddress(contact) || phone}
      </Text>
    </Box>
  )
}

export default Contact

import { formatMoney } from 'accounting'

import { DashboardModuleListItem } from '@campaignhub/suit-theme'

import useTransaction from '@hooks/useTransaction'

import { InvoiceModel } from '@models/invoice'

interface InvoiceListItemProps {
  callbacks: { deselectInvoice: (invoice: InvoiceModel) => void },
  invoice: InvoiceModel,
  selectedInvoicesCount: number,
}

const InvoiceListItem = (props: InvoiceListItemProps) => {
  const { callbacks, invoice, selectedInvoicesCount } = props
  const { deselectInvoice } = callbacks
  const { number, transactionId } = invoice

  const { transaction } = useTransaction({ id: transactionId }, { performHttpRequests: !!transactionId })
  const { incTaxTotal, referenceCode } = transaction || {}

  const canDeselectInvoice = selectedInvoicesCount > 2

  return (
    <DashboardModuleListItem
      boxProps={{ paddingX: 0 }}
      title={`INV #${number}`}
      secondaryText={`REF #${referenceCode}`}
    >
      <DashboardModuleListItem.TertiaryText>
        {formatMoney(incTaxTotal)}
      </DashboardModuleListItem.TertiaryText>

      {canDeselectInvoice && (
        <DashboardModuleListItem.Actions>
          <DashboardModuleListItem.Icon
            onClick={() => deselectInvoice(invoice)}
            marginRight="medium"
            type="delete"
          />
        </DashboardModuleListItem.Actions>
      )}
    </DashboardModuleListItem>
  )
}

export default InvoiceListItem

import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { deleteEntity, updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { TransactionItemModel } from '@models/transactionItem'
import type { AppDispatch } from '@redux/store'
import type {
  Action, ActionResult, EntityOptions, Errors, FetchRequestPayload, RootReducerState,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/transactionItem/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/transactionItem/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/transactionItem/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/transactionItem/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/transactionItem/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/transactionItem/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/transactionItem/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/transactionItem/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/transactionItem/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/transactionItem/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/transactionItem/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/transactionItem/DELETE_FAILURE'

export type TransactionItemModuleState = {
  creating: boolean,
  deleting: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loading: boolean,
  updating: boolean,
}

const initialState: TransactionItemModuleState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loadedIds: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}): Action {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(): Action {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []): Action {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(): Action {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(): Action {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []): Action {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(): Action {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(): Action {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []): Action {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(): Action {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(): Action {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors: Errors = []): Action {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadTransactionItems(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState): Promise<ActionResult<TransactionItemModel[]>> => {
    const loadedForKeys = [...getState().transactionItems.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/transaction-items', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.TRANSACTION_ITEM_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function loadTransactionItem(transactionItemId: number, options: EntityOptions = {}) {
  return (dispatch: AppDispatch, getState: () => RootReducerState): Promise<ActionResult<TransactionItemModel>> => {
    const state = getState()
    const loadedIds = [...state.transactionItems.loadedIds]

    if (transactionItemId && !loadedIds.includes(transactionItemId)){
      loadedIds.push(transactionItemId)
    }

    dispatch(fetchRequest({ loadedIds }))

    const promise = api(`/transaction-items/${transactionItemId}`, options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.INVOICE)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))
        return { success: false, errors }
      })

    return promise
  }
}

export function createTransactionItem(transactionItem: TransactionItemModel, options: EntityOptions = {}) {
  const config = {
    method: 'POST',
    data: JSON.stringify(transactionItem),
  }

  return (dispatch: AppDispatch): Promise<ActionResult<TransactionItemModel>> => {
    dispatch(createRequest())

    const promise = api('/transaction-items', options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.TRANSACTION_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function updateTransactionItem(transactionItem: TransactionItemModel, options: EntityOptions = {}) {
  const config = {
    method: 'PUT',
    data: JSON.stringify(transactionItem),
  }

  return (dispatch: AppDispatch): Promise<ActionResult<TransactionItemModel>> => {
    dispatch(updateRequest())

    const promise = api(`/transaction-items/${transactionItem.id}`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.TRANSACTION_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function deleteTransactionItem(transactionItem: TransactionItemModel) {
  const config = {
    method: 'DELETE',
  }

  return (dispatch: AppDispatch): Promise<ActionResult> => {
    dispatch(deleteRequest())

    const promise = api(`/transaction-items/${transactionItem.id}`, {}, config)
      .then(() => {
        const deletedEntity = { id: transactionItem.id }
        const normalizedJson = normalize(deletedEntity, Schemas.TRANSACTION_ITEM)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true, data: deletedEntity }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false, errors }
      })
    return promise
  }
}

// Reducer
export default function reducer(
  state: TransactionItemModuleState = initialState,
  action: Action = { type: '' },
): TransactionItemModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}

import { DashboardModule } from '@campaignhub/suit-theme'

import useSubscriptions from '@hooks/useSubscriptions'

import ListBlankState from '@components/ListBlankState'
import Subscription from './Subscription'

interface SubscriptionsProps {
  businessUnitId: number,
  href?: string,
}

const Subscriptions = (props: SubscriptionsProps) => {
  const { businessUnitId, href } = props

  const subscriptionsPayload = useSubscriptions({
    filters: {
      businessUnitId,
      // need to filter status to active subscriptions
    },
    performHttpRequests: !!businessUnitId,
    requestOptions: {
      includeSubscriptionTotals: !!businessUnitId,
    },
  })

  const {
    loading,
    filteredSubscriptions,
    hasFilteredSubscriptions,
  } = subscriptionsPayload || {}

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column' }}
      headerRight={href ? (
        <DashboardModule.Link href={href}>
          View All
        </DashboardModule.Link>
      ) : ''}
      loading={loading}
      title="Active Subscriptions"
    >
      {!loading && !hasFilteredSubscriptions && <ListBlankState title="No Subscriptions" text=" " />}

      {!loading && hasFilteredSubscriptions && (
        <DashboardModule.ScrollableSection>
          {filteredSubscriptions.map(subscription => (
            <Subscription
              key={subscription.id}
              subscription={subscription}
            />
          ))}
        </DashboardModule.ScrollableSection>
      )}
    </DashboardModule>
  )
}

export default Subscriptions

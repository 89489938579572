import { useContext } from 'react'

import { launchModal } from '@campaignhub/javascript-utils'
import { useForm, useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import * as contactUserActions from '@redux/modules/contactUser'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import PageContext from '@contexts/pageContext'

import defaultContactUserState, { requiredFields } from '@models/contactUser'

import type { AppDispatch } from '@redux/store'
import type { ContactUserModel } from '@models/types'
import type { DeleteParams } from '@redux/modules/types'

type Options = { performHttpRequests?: boolean }

type CreateContactUserParams = {
  contactUserParams: Partial<ContactUserModel>,
  dispatch: AppDispatch,
}

const createContactUser = (params: CreateContactUserParams) => {
  const { contactUserParams, dispatch } = params
  const { createContactUser: createFn } = contactUserActions

  return dispatch(createFn(contactUserParams))
}

type UpdateContactUserParams = {
  contactUser: ContactUserModel,
  contactUserParams: Partial<ContactUserModel>,
  dispatch: AppDispatch,
}

const updateContactUser = (params: UpdateContactUserParams) => {
  const { contactUser, contactUserParams, dispatch } = params
  const { updateContactUser: updateFn } = contactUserActions

  const updatedParams = {
    id: contactUser.id,
    ...contactUserParams,
  }

  return dispatch(updateFn(updatedParams))
}

type DeleteContactUserParams = {
  contactUser: DeleteParams<ContactUserModel>,
  dispatch: AppDispatch,
}

const deleteContactUser = (params: DeleteContactUserParams) => {
  const { contactUser, dispatch } = params
  const { deleteContactUser: deleteFn } = contactUserActions

  return dispatch(deleteFn(contactUser))
}

export function useContactUserForm(contactUser: ContactUserModel, options: UseFormOptions = {}) {
  const { validateOn } = options

  const contactUserForm = useForm(
    defaultContactUserState,
    { entity: contactUser, requiredFields, validateOn },
    [contactUser.cacheKey],
  )
  return {
    ...contactUserForm,
  }
}

export const useRelations = (contactUser: ContactUserModel) => {
  const { contactId, userId } = contactUser

  const { contacts, users } = useSelector(reduxState => reduxState.entities)

  const contact = contactId && contacts[contactId] ? contacts[contactId] : {}
  const user = userId && users[userId] ? users[userId] : {}

  return {
    contact,
    user,
  }
}

function useContactUser(initEntity: Partial<ContactUserModel> = {}, options?: Options) {
  const { performHttpRequests } = options || {}

  const { entity: contactUser } = useLatestEntity(initEntity, 'contactUsers')
  const { id } = contactUser

  const dispatch = useThunkDispatch()

  const { callbacks } = useContext(PageContext)

  const { contact, user } = useRelations(contactUser)

  useReduxAction(
    'contactUsers',
    'loadContactUser',
    {},
    [id, performHttpRequests],
    {
      dispatchAction: (action, requestOptions) => action(id, requestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedIds } = entityReducer
        return performHttpRequests && !loadedIds.includes(id)
      },
    },
  )

  const { loading, updating } = useSelector(reduxState => reduxState.contactUsers)

  return {
    callbacks: {
      createContactUser: (contactUserParams: Partial<ContactUserModel>) => createContactUser({
        contactUserParams, dispatch,
      }),
      deleteContactUser: () => deleteContactUser({ contactUser, dispatch }),
      manageContactUser: () => launchModal({
        callbacks,
        modalKey: 'ManageContactUserModal',
        payload: {
          contactUser,
        },
      }),
      updateContactUser: (contactUserParams: Partial<ContactUserModel>) => updateContactUser({
        contactUser, contactUserParams, dispatch,
      }),
    },
    contact,
    contactUser,
    loading,
    updating,
    user,
  }
}

export default useContactUser

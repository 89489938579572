import { Button, DashboardModule } from '@campaignhub/suit-theme'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useBanks } from '@hooks/useBanks'
import { BusinessUnitFormPayload } from '@hooks/useBusinessUnit'
import { default as defaultBankState } from "@models/bank"
import BankItem from './BankItem'

interface BankProps {
  businessUnitFormPayload: BusinessUnitFormPayload,
  setValidationStatus: React.Dispatch<React.SetStateAction<boolean>>,
}

const Bank = (props: BankProps) => {
  const { businessUnitFormPayload, setValidationStatus} = props || {}
  const {
    entityState: {
      banks,
    },
    setEntityState,
   } = businessUnitFormPayload

  const { formErrors, numberKeys} = useBanks(banks, setValidationStatus)

  const handleAddMoreClick = () => {
    const newItems = [...banks]
    newItems.push(defaultBankState)
    setEntityState({ banks: newItems })
  }

  const handleDelete = (id: number) => {
    const newItems = banks.filter((_, i) => i !== id)
    setEntityState({ banks: newItems })
  }

  const handleChange = (index: number, key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;
  
    if (numberKeys.includes(key)) {
      const numberValue = parseInt(newValue)
      if(!isNaN(numberValue)) {
        newValue = numberValue
      }
    }
    
    const newItems = [...banks]
    newItems[index] = { ...newItems[index], id: index, [key]: newValue }
    setEntityState({ banks: newItems })
  }
  
  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column', padding: 'large' }}
      title="Bank Account Details" 
    >
    {banks && banks.map((item, index) => (
      <BankItem key={index} index={index} bank={item} errors={formErrors} handleChange={handleChange} handleDelete={() => handleDelete(index)} />
    ))}
    
    <DashboardModule.FooterRight boxProps={{ flexDirection: ['column', 'row'], marginTop:"small" }}>
      <Button
          buttonStyle="primaryCreate"
          disabled={false}
          marginLeft={[0, 'medium']}
          marginTop={['medium', 0]}
          size="small"
          icon={<FontAwesomeIcon icon={faPlus} />}
          onClick={handleAddMoreClick}
        >
          Add More
        </Button>
    </DashboardModule.FooterRight>
    </DashboardModule>
  )
}

export default Bank

import { useContext, useEffect, useState } from 'react'
import {
  Button, Form,  LoadingModule,  ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'
import useBusinessUnit, { useBusinessUnitForm } from '@hooks/useBusinessUnit'

import Bank from './components/Bank'
import BusinessNumber from './components/BusinessNumber'
import Details from './components/Details'
import InvoicePrefix from './components/InvoicePrefix'
import TaxJurisdiction from './components/Tax'

const MODAL_KEY = 'CreateBusinessUnitModal'

type ModalCallbacks = {
  closeModal: () => void,
  createBusinessUnit: (payload: HandleCallbackActionParams) => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const CreateBusinessUnitModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, createBusinessUnit } = callbacks || {}

  const modalContext = useContext(ModalContext)

  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const { businessUnit: initBusinessUnit }  = digObject(modalPayload, 'businessUnit', {})

  const {
    callbacks: {
      createBusinessUnit: createFn,
    },
    businessUnit,
    loading,
  } = useBusinessUnit(initBusinessUnit)

  const businessUnitFormPayload = useBusinessUnitForm(businessUnit)
  
  const { entityState, saveEnabled } = businessUnitFormPayload

  const [isValid, setIsValid] = useState(false)
  const [bankValid, setBankValid] = useState(false)
  const [businessNumberValid, setBusinessNumberValid] = useState(false)

  useEffect(() => {
    setIsValid(bankValid && businessNumberValid)
  }, [bankValid, businessNumberValid])
  
  const createBusinessUnitPayload = {
    callbacks: {
      action: createFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    shouldRedirect: true,
    toastText: 'Business Unit Created',
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Create" titleSecondLine="Business Unit" />

      <LoadingModule boxProps={{ marginTop: 'large' }} loading={loading} />

      <SidebarModal.Content>
        <Form>  

            <Details businessUnitFormPayload={businessUnitFormPayload}/>
            
            <TaxJurisdiction businessUnitFormPayload={businessUnitFormPayload}/>

            <BusinessNumber businessUnitFormPayload={businessUnitFormPayload} setValidationStatus={setBusinessNumberValid}/>

            <Bank businessUnitFormPayload={businessUnitFormPayload} setValidationStatus={setBankValid} />

            <InvoicePrefix businessUnitFormPayload={businessUnitFormPayload}/>
            
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!(isValid && saveEnabled)}
          size="large"
          onClick={() => createBusinessUnit(createBusinessUnitPayload)}
        >
          Create Business Unit
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <CreateBusinessUnitModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal

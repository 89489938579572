import { ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import { Box, Button, Columns } from '@campaignhub/suit-theme'

import AdminPageHeader from '@components/AdminPageHeader'

import useContact, { useRelations } from '@hooks/useContact'

import generateTabBarItems from '../../utils/generateTabBarItems'

interface PageLayoutProps {
  activeTabBarItemKey: string,
  children: ReactNode,
  entityPayload: ReturnType<typeof useContact>,
}

const PageLayout = (props: PageLayoutProps) => {
  const { activeTabBarItemKey, children, entityPayload } = props

  const {
    contact,
    contact: { name: contactName },
    urls: { contactsIndexUrl },
  } = entityPayload

  const { contactType } = useRelations(contact)
  const { key: contactTypeKey } = contactType || {}

  const { redirect } = parsePermittedQueryParams(getQueryParamsFromHash(), ['redirect'])

  return (
    <>
      <Helmet>
        <title>Edit Contact | Billing</title>
      </Helmet>

      <AdminPageHeader
        actionContent={(
          <Button
            as="a"
            buttonStyle="secondaryUtility"
            href={redirect || `${contactsIndexUrl}${contactTypeKey ? `?contactType=${contactTypeKey}` : ''}`}
            size="medium"
          >
            Back
          </Button>
        )}
        activeTabBarItemKey={activeTabBarItemKey}
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={generateTabBarItems(contact)}
        title={contactName || 'Contact'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns flexDirection={['column', 'column', 'row']}>
          {children}
        </Columns>
      </Box>
    </>
  )
}

export default PageLayout

import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowAltToBottom, faHospital, faStore, faUserTie,
} from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, Tools,
} from '@campaignhub/suit-theme'

import {
  generateEntityStrings, getQueryParamsFromHash, parsePermittedQueryParams, snakeToTitleCase,
} from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'

import useContacts from '@hooks/useContacts'
import useCountries from '@hooks/useCountries'
import useCurrentUser from '@hooks/useCurrentUser'
import useSelectedContactType from '@hooks/useSelectedContactType'

import AdminPageHeader from '@components/AdminPageHeader'
import ListBlankState from '@components/ListBlankState'
import PageFilterModule from '@components/PageFilterModule'

import type { DefaultContext } from '@contexts/pageContext'
import type { ContactModel, ContactTypeModel, CountryModel } from '@models/types'
import type { PageContextPayload } from '../../index'

import ContactListItem from '../ContactListItem'

const viewOnly = JSON.parse(process.env.REACT_APP_VIEW_ONLY || "false")

type buildParentContactFilterFieldParams = {
  boxProps: { marginBottom: string },
  parentContactType: ContactTypeModel,
}

const buildParentContactFilterField = (params: buildParentContactFilterFieldParams) => {
  const { boxProps, parentContactType } = params || {}
  const { id: parentContactTypeId, key: parentContactTypeKey } = parentContactType || {}

  const parentContactStrings = generateEntityStrings(parentContactTypeKey)
  const {
    label: parentContactTypeName,
    labelPlural: parentContactTypeNamePlural,
  } = parentContactStrings

  // Parent Contact Entity Selector
  return {
    component: 'EntitySelector',
    componentProps: {
      boxProps,
      customEntitySettings: { contacts: { labelPlural: parentContactTypeNamePlural } },
      endpoint: '/contacts',
      entityKey: 'contacts',
      label: parentContactTypeName,
      searchRequestOptions: { contactTypeId: parentContactTypeId },
    },
    key: 'contactId',
  }
}

type buildPageFilterFieldsParams = {
  parentContactType: ContactTypeModel,
}

const buildPageFilterFields = (params: buildPageFilterFieldsParams) => {
  const { filteredCountries, parentContactType } = params || {}

  const boxProps = { marginBottom: 'large' }
  const style = { marginBottom: 16 }

  // If Parent Contact Type exists, Build Parent Contact Filter Field
  const parentContactFilterField = !!parentContactType?.id && buildParentContactFilterField({
    boxProps, parentContactType,
  })

  const pageFilterFields = [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'string',
      label: 'Filter by Title or ID', // ID or realbaseId ?
    },
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'select',
        style,
      },
      key: 'countryId',
      label: 'Country',
      values: filteredCountries.map((country: CountryModel) => ({ label: country.name, key: country.id })),
    },
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'select',
        style,
      },
      hidden:true, // need to be hidden for now till we finish phase 1
      key: 'active',
      label: 'Active',
      values: [
        { key: true, label: 'Yes' },
        { key: false, label: 'No' },
      ],
    },
  ]

  // Insert parentContactPageFilter Field as second filter field:
  if (parentContactFilterField) {
    pageFilterFields.splice(1, 0, parentContactFilterField)
  }

  return pageFilterFields
}

interface PageContentProps {
  pageFilters: {},
}

const PageContent = (props: PageContentProps) => {
  const { pageFilters } = props

  useLocation()

  const { isAdmin } = useCurrentUser()

  const { contactType } = parsePermittedQueryParams(getQueryParamsFromHash(), ['contactType'])
  const selectedContactTypeKey = contactType || 'organization'

  const { parentContactType, selectedContactType } = useSelectedContactType(selectedContactTypeKey)
  const { name: contactTypeName } = selectedContactType

  const contactsPayload = useContacts({
    filters: {
      selectedContactTypeKey,
      ...pageFilters,
    },
    performHttpRequests: !!selectedContactTypeKey,
  })
  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredContacts,
    filteredContactsCount,
    hasContacts,
    loading,
  } = contactsPayload

  const pageContext = useContext<DefaultContext<PageContextPayload>>(PageContext)
  const {
    callbacks: {
      resetFilters,
      showImportContactsModal,
      updateFilters,
    },
    defaultFilters,
  } = pageContext

  const { filteredCountries } = useCountries()

  return (
    <>
      <Helmet>
        <title>Contacts | Billing</title>
      </Helmet>

      <AdminPageHeader
        activeTabBarItemKey={selectedContactTypeKey}
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: '#/admin/contacts?contactType=brand',
            icon: faHospital,
            key: 'brand',
            title: 'Brands',
          },
          {
            href: '#/admin/contacts?contactType=organization',
            icon: faStore,
            key: 'organization',
            title: 'Organizations',
          },
          {
            href: '#/admin/contacts?contactType=individual',
            icon: faUserTie,
            key: 'individual',
            title: 'Individuals',
          },
        ]}
        title="Contacts"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <DashboardModule
              contentBoxProps={{ flexDirection: 'column' }}
              loading={loading}
              title={`${snakeToTitleCase(selectedContactTypeKey)} Contacts`}
            >
              {!hasContacts && <ListBlankState />}

              {hasContacts && (
                <Box flexDirection="column">
                  {filteredContacts.map(
                    (contact: ContactModel) => <ContactListItem key={contact.id} contact={contact} />,
                  )}
                </Box>
              )}

              <DashboardModule.LoadMoreFooter
                callbacks={{ loadMore }}
                canLoadMore={canLoadMore}
                entityCount={filteredContactsCount}
                loading={loading}
              />
            </DashboardModule>
          </Columns.Main>

          <Columns.Sidebar>
            {!viewOnly && isAdmin && (
              <Tools>
                <Button
                  buttonStyle="secondaryUtility"
                  icon={<FontAwesomeIcon icon={faArrowAltToBottom} />}
                  onClick={() => showImportContactsModal({ contactType: selectedContactType })}
                  size="medium"
                >
                  Import {contactTypeName} Contacts
                </Button>
              </Tools>
            )}

            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              defaultFilters={defaultFilters}
              filterFields={buildPageFilterFields({ filteredCountries, parentContactType })}
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent

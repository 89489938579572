import { Box } from '@campaignhub/suit-theme'

import { useInvoiceForm, InvoiceFormPayload } from '@hooks/useInvoice'
import type { InvoicePayload } from '@hooks/useInvoice'
import useTransaction, { useTransactionForm } from '@hooks/useTransaction'

import Actions from './components/Actions'
import TransactionItems from './components/TransactionItems'
import Totals from './components/Totals'

const viewOnly = JSON.parse(process.env.REACT_APP_VIEW_ONLY || "false")

interface TransactionProps {
  invoicePayload: InvoicePayload,
}

const Transaction = (props: TransactionProps) => {
  const { invoicePayload } = props
  const { editing, invoice, isDraftInvoice } = invoicePayload
  const { transactionId } = invoice

  const invoiceFormPayload: InvoiceFormPayload = useInvoiceForm(invoice)

  const transactionPayload = useTransaction({ id: transactionId }, { performHttpRequests: !!transactionId })
  const { transaction } = transactionPayload
  const transactionFormPayload = useTransactionForm(transaction)

  return (
    <Box flexDirection="column" variant="white">
      <TransactionItems editing={editing} transaction={transaction} />

      <Totals editing={editing} invoiceFormPayload={invoiceFormPayload} transactionFormPayload={transactionFormPayload} />

      {isDraftInvoice && !viewOnly && (
        <Actions
          invoicePayload={invoicePayload}
          transactionPayload={transactionPayload}
          transactionFormPayload={transactionFormPayload} 
        />
      )}
    </Box>
  )
}

export default Transaction

import { useMemo } from 'react'

import { sortArrayBy } from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import { BillingEntityModel, BillingEntityRequestOptions } from '@models/billingEntity'
import type { ModuleState } from "@redux/modules"

const watchEntityKeys = ['billingEntities']

type BillingEntityFilters = {
  string?: string,
}

type UseBillingEntitiesOptions = {
  filters?: BillingEntityFilters,
  performHttpRequests?: boolean,
  requestOptions?:  BillingEntityRequestOptions,
}

function useBillingEntities(options: UseBillingEntitiesOptions = {}) {
  const { performHttpRequests } = options

  const {
    updatedEntities: { billingEntities: billingEntitiesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { billingEntities } = useSelector(reduxState => reduxState.entities)

  const filteredBillingEntities = useMemo(() => {
    const filtered = Object.values(billingEntities)
    return sortArrayBy(filtered, 'asc', 'companyName')
  }, [billingEntitiesUpdatedAt])


  useReduxAction(
    'billingEntities',
    'loadBillingEntities',
    {},
    [performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    filteredBillingEntities,
  }
}

export default useBillingEntities
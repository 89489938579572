import { DashboardModule } from '@campaignhub/suit-theme'

import { CreditPayload } from '@hooks/useCredit'

import useSelector from '@hooks/useSelector'

import useTransaction from "@hooks/useTransaction";

import CreditRequestTable from './components/Table'

import Actions from './components/Actions'

interface CreditProps {
  creditPayload: CreditPayload,
}

const CreditRequest = (props: CreditProps) => {
  const { creditPayload } = props
  const { credit, loading: loadingCredit } = creditPayload || {}

  const { loading: loadingTransaction } = useSelector(reduxState => reduxState.transactions)

  const loading = loadingCredit && loadingTransaction

  const { transactionId } = credit || {};

  const transactionPayload = useTransaction({ id: transactionId });

  const { isDraftStatus } = transactionPayload;

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column' }}
      loading={loading}
      title="Credit Request"
    >
      {!loading && (<CreditRequestTable credit={credit} />)}

      {!loading && isDraftStatus && (<Actions creditPayload={creditPayload} />)}
    </DashboardModule>
  )
}

export default CreditRequest

import { useMemo } from 'react'

import { useFilters, useModals, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import GenerateInvoicesCsvModal from '@modals/GenerateInvoicesCsvModal'
import MergeInvoicesModal from '@modals/MergeInvoicesModal'

import PageContent from './components/PageContent'

export type PageContextPayload = {
  callbacks: {
    resetFilters: (resetFn?: (() => void) | undefined) => void,
    showGenerateInvoicesCsvModal: () => void,
    showMergeInvoicesModal: () => void,
    toggleFilters: () => void,
    updateFilters: (filters: object) => void,
  },
}

type SetState = (state: Partial<typeof defaultState>) => void

const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    GenerateInvoicesCsvModal: {
      closeModal: () => setState({ showGenerateInvoicesCsvModal: false }),
      generateInvoicesCSV: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
    MergeInvoicesModal: {
      closeModal: () => setState({ showMergeInvoicesModal: false }),
      mergeInvoices: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showGenerateInvoicesCsvModal: false,
  showMergeInvoicesModal: false,
}

const List = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    showGenerateInvoicesCsvModal,
    showMergeInvoicesModal,
  } = state

  const {
    callbacks: filterCallbacks,
    defaultFilters,
    defaultFiltersModifiedAt,
    pageFilters,
  } = useFilters()

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      ...filterCallbacks,
      showGenerateInvoicesCsvModal: (payload) => {
        setModalData('GenerateInvoicesCsvModal', payload)
        setState({ showGenerateInvoicesCsvModal: true })
      },
      showMergeInvoicesModal: (payload) => {
        setModalData('MergeInvoicesModal', payload)
        setState({ showMergeInvoicesModal: true })
      },
    },
    defaultFilters,
  }), [defaultFiltersModifiedAt])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent pageFilters={pageFilters} />

        <MergeInvoicesModal
          callbacks={callbacks('MergeInvoicesModal', setState)}
          showModal={showMergeInvoicesModal}
        />

        <GenerateInvoicesCsvModal
          callbacks={callbacks('GenerateInvoicesCsvModal', setState)}
          showModal={showGenerateInvoicesCsvModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default List

import { useEffect } from "react"
import { BusinessNumberModel, requiredFields } from "@models/businessNumber"
import { validateArray } from "@functions/validateArray"

export function useBusinessNumbers(businessNumbers: BusinessNumberModel[], setValidationStatus: React.Dispatch<React.SetStateAction<boolean>>) 
{
  const { formErrors, validate } = validateArray(businessNumbers, requiredFields)

  useEffect(() => {
    setValidationStatus(validate())
  }, [businessNumbers])
  
  return {
    formErrors,
  }
}

import { formatMoney } from 'accounting'

import {
  Box, DashboardModule, Line, Text,
} from '@campaignhub/suit-theme'

import { formatDate } from '@campaignhub/javascript-utils'

import type { SubscriptionPayload } from '@hooks/useSubscription'

import { displaySubscriptionInterval } from '@functions/subscription'

const Details = (props: { subscriptionPayload: SubscriptionPayload }) => {
  const { subscriptionPayload } = props
  const {
    subscription: {
      incTaxAmount,
      interval,
      nextBillingDate,
      subscriptionStart,
      totalRevenue,
    },
    loading,
  } = subscriptionPayload

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column', height: '100%', padding: 'large' }}
      loading={loading}
      title="Details"
    >
      <Box flexDirection="column" justifyContent="center">
        <Text fontSize="large">
          {incTaxAmount ? formatMoney(incTaxAmount) : ''} {interval ? `- Billed ${displaySubscriptionInterval(interval)}` : ''}
        </Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
          {nextBillingDate ? 'Next Billing Date -' : ''} {formatDate(nextBillingDate, 'ISO8601', 'dd LLLL yyyy')}
        </Text>
      </Box>

      <Line />

      <Box flexDirection="column" justifyContent="center">
        <Text color="green" fontSize="large" marginBottom="small">
          {totalRevenue ? formatMoney(totalRevenue) : ''}
        </Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
          Lifetime Subscription Revenue
        </Text>

        {subscriptionStart && (
          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
            Subscribed on {formatDate(subscriptionStart, 'ISO8601', 'dd LLLL yyyy')}
          </Text>
        )}
      </Box>
    </DashboardModule>
  )
}

export default Details

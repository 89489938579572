import { DashboardModuleListItem } from '@campaignhub/suit-theme'
import { digObject } from '@campaignhub/javascript-utils'

import type { ContactPaymentMethodModel, PaymentMethodData } from '@models/contactPaymentMethod'
import type { PaymentMethodCodes } from '@models/paymentMethod'

type RenderPaymentMethodSecondaryTextProps = {
  data: PaymentMethodData,
  paymentMethodCode: PaymentMethodCodes,
}

const renderPaymentMethodSecondaryText = (props: RenderPaymentMethodSecondaryTextProps) => {
  const { data = {}, paymentMethodCode = '' } = props
  const paymentMethodData = digObject(data, paymentMethodCode, {})

  if (paymentMethodCode === 'card'){
    const { exp_month, exp_year, last4 } = paymentMethodData

    const displayLast4 = last4 ? `•••• •••• •••• ${last4}` : ''
    const displayExpiry = exp_month && exp_year ? `- Exp ${exp_month}/${exp_year}` : ''

    return displayLast4 + displayExpiry
  }

  return null
}

interface PaymentMethodProps {
  boxProps?: object,
  contactPaymentMethod: ContactPaymentMethodModel,
  href?: string,
  isModal?: boolean,
  isSidebar?: boolean,
  onClick?: () => void,
}

const PaymentMethodListItem = (props: PaymentMethodProps) => {
  const {
    boxProps,
    contactPaymentMethod: {
      data,
      isDefault,
      paymentMethodCode,
      paymentMethodDescription,
    },
    href,
    isModal,
    isSidebar = true,
    onClick,
  } = props

  const modalBoxProps = isModal ? {
    borderBottom: 'none',
    paddingX: 0,
    paddingY: 'medium',
  } : {}

  return (
    <DashboardModuleListItem
      boxProps={{
        ...modalBoxProps,
        ...boxProps,
      }}
      href={href}
      isSidebar={isSidebar}
      onClick={onClick}
      secondaryText={renderPaymentMethodSecondaryText({ data, paymentMethodCode })}
      title={paymentMethodDescription}
    >
      <DashboardModuleListItem.TertiaryText>
        {isDefault ? 'Default' : 'Not in use'}
      </DashboardModuleListItem.TertiaryText>
    </DashboardModuleListItem>
  )
}

export default PaymentMethodListItem

export type AccountingSystemBillingEntityModel = {
  id: number,
  accountingSystemId: number,
  billingEntityId: number,
  businessUnitId: number,

}

export type AccountingSystemBillingEntityRequestOptions = {}

const state = {
  id: null,
  accountingSystemId: null,
  billingEntityId: null,
  businessUnitId: null,
}

export const requiredFields = [
  { key: 'accountingSystemId'},
  { key: 'billingEntityId' },
]

export default state
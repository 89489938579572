import { useMemo } from 'react'

import { useModals, useNumericParams, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import CreateOrEditPriceModal from '@modals/CreateOrEditPriceModal'
import EditSubscriptionItemModal from '@modals/EditSubscriptionItemModal'
import PauseSubscriptionModal from '@modals/PauseSubscriptionModal'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import PageContent from './components/PageContent'

const defaultState = {
  showCreateOrEditPriceModal: false,
  showEditSubscriptionItemModal: false,
  showPauseSubscriptionModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    CreateOrEditPriceModal: {
      closeModal: () => setState({ showCreateOrEditPriceModal: false }),
      createPrice: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
    EditSubscriptionItemModal: {
      closeModal: () => setState({ showEditSubscriptionItemModal: false }),
      deleteSubscriptionItem: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      updateSubscriptionItem: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
    PauseSubscriptionModal: {
      closeModal: () => setState({ showPauseSubscriptionModal: false }),
      updateSubscription: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const Edit = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    showCreateOrEditPriceModal,
    showEditSubscriptionItemModal,
    showPauseSubscriptionModal,
  } = state

  const { subscriptionId } = useNumericParams()

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      showCreateOrEditPriceModal: (payload) => {
        setModalData('CreateOrEditPriceModal', payload)
        setState({ showCreateOrEditPriceModal: true })
      },
      showEditSubscriptionItemModal: (payload) => {
        setModalData('EditSubscriptionItemModal', payload)
        setState({ showEditSubscriptionItemModal: true })
      },
      showPauseSubscriptionModal: (payload) => {
        setModalData('PauseSubscriptionModal', payload)
        setState({ showPauseSubscriptionModal: true })
      },
      updateSubscription: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent subscriptionId={subscriptionId} />

        <CreateOrEditPriceModal
          callbacks={callbacks('CreateOrEditPriceModal', setState)}
          showModal={showCreateOrEditPriceModal}
        />

        <EditSubscriptionItemModal
          callbacks={callbacks('EditSubscriptionItemModal', setState)}
          showModal={showEditSubscriptionItemModal}
        />

        <PauseSubscriptionModal
          callbacks={callbacks('PauseSubscriptionModal', setState)}
          showModal={showPauseSubscriptionModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Edit

import { useEffect } from "react"
import { BankModel, requiredFields } from '@models/bank'
import { validateArray } from "@functions/validateArray"

export function useBanks(banks: BankModel[], setValidationStatus: React.Dispatch<React.SetStateAction<boolean>>) 
{
  const numberKeys = ["accountNumber", "branchNumber", "bankTypeId"]
  
  const { formErrors, validate } = validateArray(banks, requiredFields)

  useEffect(() => {
    setValidationStatus(validate())
  }, [banks])
  
  return {
    formErrors,
    numberKeys
  }
}

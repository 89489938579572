import React, { useMemo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faCheckCircle, faCog } from '@fortawesome/pro-light-svg-icons'

import {
  Checkbox, IconWithMenu, Link, StatusBadge, Text, Table,
} from '@campaignhub/suit-theme'

import { digObject, formatDate, goToEntity } from '@campaignhub/javascript-utils'

import { getInvoiceStatusDisplayKey } from '@functions/invoice'
import { statusColor } from '@functions/status'

import { generateUrls as generateContactUrls } from '@hooks/useContact'
import { generateUrls as generateInvoiceUrls } from '@hooks/useInvoice'
import type { InvoicesPayload } from '@hooks/useInvoices'
import useSelector from '@hooks/useSelector'

import type { InvoiceModel } from '@models/types'
import type { EntitiesState } from '@redux/entities'

const viewOnly = JSON.parse(process.env.REACT_APP_VIEW_ONLY || "false")

interface InvoicesTableProps {
  invoicesPayload: InvoicesPayload,
  statusKey: string,
}

type InvoiceTableColumn = {
  dataKey?: string,
  render?: (dataKey: string, invoice?: InvoiceModel) => void,
  style?: object,
  title?: string,
}

const getConditionalColumn = (status: string) => {
  const column: InvoiceTableColumn = {
    style: {
      verticalAlign: 'middle',
    },
  }

  switch (status){
    case 'draft':
      column.title = 'Reference #'
      column.dataKey = 'referenceCode'
      column.render = (dataKey: string, invoice: InvoiceModel) => {
        const referenceCode = digObject(invoice, 'referenceCode', '')

        return (
          <Text variant="ellipsis">
            {referenceCode}
          </Text>
        )
      }
      break
    case 'approved':
      column.title = 'Due Date'
      column.dataKey = 'dateDue'
      column.render = (dateDue: string) => formatDate(dateDue, 'ISO8601', 'dd/MM/yy')
      break
    case 'overdue':
      column.title = 'Overdue By'
      column.dataKey = 'daysOverdue'
      column.render = (daysOverdue: string) => `${daysOverdue} ${daysOverdue > 1 ? 'Days' : 'Day'}`
      break
    case 'paid':
      column.title = 'Processed'
      column.dataKey = 'number'
      column.render = (dataKey: string, invoice: InvoiceModel) => {
        const processedDate = digObject(invoice, 'dateExported', '')

        return formatDate(processedDate, 'ISO8601', 'dd/MM/yy')
      }
      break
    default:
      null
      break
  }

  // if no matching status return null as we don't want a column
  if (!column.title) return null

  return column
}

const getInvoiceActions = (id: number, statusKey: string) => (
  <IconWithMenu icon={<FontAwesomeIcon icon={faCog} />}>
    <IconWithMenu.Menu closeOnClick>
      <Link
        greyLink
        onClick={() => goToEntity({
          generateUrls: () => generateInvoiceUrls({ id }),
          entityUrlKey: 'editInvoiceUrl',
        })}
        textProps={{ marginLeft: 'large' }}
      >
        <FontAwesomeIcon icon={faAngleRight} /> Go to Invoice
      </Link>

      {/* TODO: Add Any Additional Actions by Status */}
    </IconWithMenu.Menu>
  </IconWithMenu>
)

type BuildColumnsParams = InvoicesTableProps & {
  entities: EntitiesState,
}

const buildColumns = (props: BuildColumnsParams) => {
  const { entities, invoicesPayload, statusKey } = props || {}
  const { businessUnits, contacts, statuses } = entities || {}

  const showCheckbox = statusKey === 'draft' || statusKey === 'overdue'

  const {
    callbacks: {
      toggleSelectAllIds,
      toggleSelectedId,
    },
    isAllSelected,
    selectedInvoiceIds,
  } = invoicesPayload || {}

  const tableColumns = [
    {
      cellProps: {
        key: 'statusId',
        style: {
          verticalAlign: 'middle',
        },
      },
      title: 'Status',
      dataKey: 'statusId',
      render: (statusId: number, invoice: InvoiceModel) => {
        const displayStatusKey = getInvoiceStatusDisplayKey(invoice, statuses)

        return (
          <StatusBadge color={statusColor(displayStatusKey)} ghost text={displayStatusKey} />
        )
      },
    },
    {
      cellProps: {
        key: 'invoiceNumber',
        style: {
          verticalAlign: 'middle',
        },
      },
      title: 'Inv Number #',
      dataKey: 'number',
      render: (invoiceNumber: string, invoice: InvoiceModel) => {
        const { id } = invoice

        return (
          <Link
            disabledUnderline
            onClick={() => goToEntity({
              generateUrls: () => generateInvoiceUrls({ id }),
              entityUrlKey: 'editInvoiceUrl',
            })}
          >
            {invoiceNumber}
          </Link>
        )
      },
    },
    {
      cellProps: {
        key: 'contactId',
        style: {
          minWidth: '200px',
          verticalAlign: 'middle',
        },
      },
      title: 'Contact',
      dataKey: 'contactId',
      render: (contactId: number) => {
        const contact = digObject(contacts, String(contactId), {})
        const { id, name } = contact

        return (
          <Link
            disabledUnderline
            onClick={() => goToEntity({
              generateUrls: () => generateContactUrls({ id }),
              entityUrlKey: 'editContactUrl',
            })}
          >
            <Text color="blue" variant="ellipsis" width="200px">
              {name}
            </Text>
          </Link>
        )
      },
    },
    {
      cellProps: {
        key: 'dateCreated',
        style: {
          verticalAlign: 'middle',
        },
      },
      title: 'Inv. Date',
      dataKey: 'dateCreated',
      render: (dateCreated: string) => formatDate(dateCreated, 'ISO8601', 'dd/MM/yy'),
    },
    {
      cellProps: {
        key: 'businessUnitId',
        style: {
          verticalAlign: 'middle',
        },
      },
      title: 'Business Unit',
      dataKey: 'businessUnitId',
      render: (businessUnitId: number) => {
        const businessUnit = digObject(businessUnits, String(businessUnitId), {})
        const { name } = businessUnit

        return (
          <Text variant="ellipsis" width={160}>{name}</Text>
        )
      },
    },
    {
      cellProps: {
        key: 'balance',
        style: {
          verticalAlign: 'middle',
        },
      },
      title: statusKey === 'overdue' ? 'Owing ($)' : 'Amount ($)',
      dataKey: 'balance',
      render: (balance: number) => `$${balance.toFixed(2)}`,
    },
    {
      cellProps: {
        key: 'actions',
        style: {
          verticalAlign: 'middle',
          textAlign: 'center',
        },
      },
      title: 'Actions',
      dataKey: 'id',
      render: (id: number) => getInvoiceActions(id, statusKey),
    },
  ]

  // Add Checkbox if editable/selectable (statusKey === 'draft' || 'overdue')
  const checkboxColumn: InvoiceTableColumn = showCheckbox ? {
    cellProps: {
      key: 'select',
      style: {
        paddingRight: 0,
        verticalAlign: 'middle',
      },
    },
    title: <Checkbox
      marginRight={0}
      checked={isAllSelected}
      fontSize="small"
      onClick={() => toggleSelectAllIds()}
    />,
    dataKey: 'id',
    render: (id: number) => (
      <Checkbox
        marginRight={0}
        checked={selectedInvoiceIds?.includes(id)}
        fontSize="small"
        onClick={() => toggleSelectedId(id)}
      />
    ),
  } : null

  if (showCheckbox && !viewOnly){ tableColumns.unshift(checkboxColumn) }

  // Get Conditional Column based on status
  const conditionalColumn = getConditionalColumn(statusKey)

  // Add Conditional Column to table columns
  if (conditionalColumn){
    tableColumns.splice(5, 0, conditionalColumn)
  }

  return tableColumns
}

const InvoicesTable = (props: InvoicesTableProps) => {
  const { invoicesPayload, statusKey } = props
  const { filteredInvoices, filteredInvoicesCount, selectedInvoiceIds } = invoicesPayload

  const { entities } = useSelector(reduxState => reduxState)

  const memoColumns = useMemo(
    () => buildColumns(
      { entities, invoicesPayload, statusKey },
    ),
    [filteredInvoicesCount, selectedInvoiceIds],
  )

  return (
    <Table
      boxProps={{
        border: 'none', maxHeight: 500, overflowY: 'auto',
      }}
      columns={memoColumns}
      data={filteredInvoices}
      stickyHeader
    />
  )
}

export default React.memo(InvoicesTable)

import React from 'react'

import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js'
import type {
  StripeCardCvcElementChangeEvent, StripeCardExpiryElementChangeEvent, StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js'

import {
  Button, Form, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useStripePaymentMethod from '../../hooks/useStripePaymentMethod'

import StripeDisclaimer from '../../../StripeDisclaimer'

const FIELD_HOLDER_PROPS = {
  border: '1px solid',
  borderColor: 'lineColor',
  borderRadius: '5px',
  height: '37px',
  padding: 'medium',
}

interface StripePaymentMethodProps {
  callbacks: {
    closeModal: () => void,
    createContactPaymentMethod: () => void,
  },
  contactId: number,
  setupIntentId: string,
}

const StripePaymentMethod = (props: StripePaymentMethodProps) => {
  const { callbacks, contactId, setupIntentId } = props || {}
  const { closeModal, createContactPaymentMethod } = callbacks || {}

  const useStripePaymentMethodPayload = useStripePaymentMethod({ contactId, setupIntentId })
  const {
    callbacks: {
      createPaymentMethod: createFn,
      handleStripeElement,
      setState,
    },
    saveEnabled,
    state: {
      errors,
      name,
    },
  } = useStripePaymentMethodPayload

  const createContactPaymentMethodPayload = {
    callbacks: {
      action: createFn,
      afterAction: closeModal,
    },
    toastText: 'Payment Method Added',
  }

  return (
    <>
      <SidebarModal.Content>
        <Form backgroundColor="white" flexDirection="column">
          <Form.Field
            errorMessage={!name ? 'Required' : undefined}
            label="* Name on the Card"
          >
            <input
              name="title"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setState({ name: e.target.value })}
              type="text"
              value={name}
            />
          </Form.Field>

          <Form.Field
            errorMessage={digObject(errors, 'cardNumber')}
            fieldHolderProps={FIELD_HOLDER_PROPS}
            label="* Card Number"
            marginTop="large"
          >
            <CardNumberElement
              id="card-number-element"
              onChange={(event: StripeCardNumberElementChangeEvent) => handleStripeElement(event)}
              options={{ showIcon: true }}
            />
          </Form.Field>

          <Form.Row>
            <Form.Field
              errorMessage={digObject(errors, 'cardExpiry')}
              fieldHolderProps={FIELD_HOLDER_PROPS}
              hideErrorMessage
              label="* Expiry"
              marginTop="large"
            >
              <CardExpiryElement
                id="card-expiry-element"
                onChange={(event: StripeCardExpiryElementChangeEvent) => handleStripeElement(event)}
              />
            </Form.Field>

            <Form.Field
              errorMessage={digObject(errors, 'cardCvc')}
              fieldHolderProps={FIELD_HOLDER_PROPS}
              hideErrorMessage
              label="* CVC"
              marginTop="large"
            >
              <CardCvcElement
                id="card-cvc-element"
                onChange={(event: StripeCardCvcElementChangeEvent) => handleStripeElement(event)}
                options={{ placeholder: '123' }}
              />
            </Form.Field>
          </Form.Row>
        </Form>

        <StripeDisclaimer />
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          onClick={() => createContactPaymentMethod(createContactPaymentMethodPayload)}
          size="large"
        >
          {saveEnabled ? 'Create Payment Method' : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </>
  )
}

export default StripePaymentMethod

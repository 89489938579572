import { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import { Button, DashboardModule } from '@campaignhub/suit-theme'

import useContactPaymentMethod from '@hooks/useContactPaymentMethod'
import useContactPaymentMethods from '@hooks/useContactPaymentMethods'
import usePaymentMethods from '@hooks/usePaymentMethods'

import PageContext from '@contexts/pageContext'

import type { ContactPaymentMethodModel } from '@models/types'

import PaymentMethod from './PaymentMethod/index'

interface PaymentMethodsProps {
  contactId: number,
  isAdmin: boolean,
}

const viewOnly = JSON.parse(process.env.REACT_APP_VIEW_ONLY || "false")

const PaymentMethods = (props: PaymentMethodsProps) => {
  const { contactId, isAdmin } = props

  // load Payment Methods
  usePaymentMethods({ performHttpRequests: !!contactId })

  const contactPaymentMethodsPayload = useContactPaymentMethods({ filters: { contactId } })
  const {
    filteredContactPaymentMethods,
    hasContactPaymentMethod,
    loading,
  } = contactPaymentMethodsPayload

  const {
    callbacks: {
      createOrEditContactPaymentMethod,
    },
  } = useContactPaymentMethod()

  const pageContext = useContext(PageContext)
  const { callbacks: { showRequestPaymentMethodModal } } = pageContext

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column' }}
      loading={loading}
      title="Payment Methods"
    >
      {!loading && !hasContactPaymentMethod && (
        <DashboardModule.BlankState>
          <DashboardModule.BlankState.Title>
            No Payment Method
          </DashboardModule.BlankState.Title>

          {!isAdmin && (
            <DashboardModule.BlankState.Paragraph>
              Add a Payment Method to settle your invoices and subscriptions automatically.
            </DashboardModule.BlankState.Paragraph>
          )}

          {isAdmin && (
            <>
              <DashboardModule.BlankState.Paragraph>
                This Contact has no payment methods added.
                A payment method must be added in order for a Contact to use subscription products.
              </DashboardModule.BlankState.Paragraph>

              <DashboardModule.BlankState.Paragraph>
                You can send the contact an email/link to add a payment method with the button below.
              </DashboardModule.BlankState.Paragraph>
            </>
          )}
        </DashboardModule.BlankState>
      )}

      {!loading && hasContactPaymentMethod && (
        <DashboardModule.ScrollableSection>
          {filteredContactPaymentMethods.map((contactPaymentMethod: ContactPaymentMethodModel) => (
            <PaymentMethod
              key={contactPaymentMethod?.id}
              contactPaymentMethod={contactPaymentMethod}
              isAdmin={isAdmin}
            />
          ))}
        </DashboardModule.ScrollableSection>
      )}

      {!hasContactPaymentMethod && !viewOnly && (
        <DashboardModule.Footer>
          <Button
            buttonStyle="secondaryUtility"
            icon={<FontAwesomeIcon icon={faPlus} />}
            iconPosition="left"
            onClick={() => (isAdmin ? showRequestPaymentMethodModal() : createOrEditContactPaymentMethod())}
            size="medium"
          >
            {isAdmin ? 'Request Payment Method' : 'Add Payment Method'}
          </Button>
        </DashboardModule.Footer>
      )}

    </DashboardModule>
  )
}

export default PaymentMethods

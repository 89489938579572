import deepmerge from 'deepmerge'

import * as ModelTypes from '@models/types'

import type { ModuleState } from '@redux/modules/types'

const DELETE_ENTITY = 'realbase/entity/DELETE_ENTITY'
const REPLACE_ENTITY = 'realbase/entity/REPLACE_ENTITY'
const UPDATE_ENTITY = 'realbase/entity/UPDATE_ENTITY'

// Add Entities & their Model Type Reference here
export type EntitiesState = {
// START ENTITY TYPES
  _updates: {},
  activities: { [key: number]: ModelTypes.ActivityModel },
  accountingSystems: {[key:number]: ModelTypes.AccountingSystemModel },
  bankTypes: {[key:number]: ModelTypes.BankTypeModel },
  billingEntities: { [key: number]: ModelTypes.BillingEntityModel },
  billingNotes: { [key: number]: ModelTypes.BillingNoteModel },
  billingTypes: { [key: number]: ModelTypes.BillingTypeModel },
  businessUnitProducts: { [key: number]: ModelTypes.BusinessUnitProductModel },
  businessUnits: { [key: number]: ModelTypes.BusinessUnitModel },
  comments: { [key: number]: ModelTypes.CommentModel },
  contactPaymentMethods: { [key: number]: ModelTypes.ContactPaymentMethodModel },
  contactTypes: { [key: number]: ModelTypes.ContactTypeModel },
  contactUsers: { [key: number]: ModelTypes.ContactUserModel },
  contacts: { [key: number]: ModelTypes.ContactModel },
  conversations: { [key: number]: ModelTypes.ConversationModel },
  countries: { [key: number]: ModelTypes.CountryModel },
  creditAllocations: { [key: number]: ModelTypes.CreditAllocationModel },
  credits: { [key: number]: ModelTypes.CreditModel },
  currentUser: { [key: number]: ModelTypes.UserModel },
  invoices: { [key: number]: ModelTypes.InvoiceModel },
  paymentMethods: { [key: number]: ModelTypes.PaymentMethodModel },
  prices: { [key: number]: ModelTypes.PriceModel },
  statuses: { [key: number]: ModelTypes.StatusModel },
  subscribers: { [key: number]: ModelTypes.SubscriberModel },
  subscriptionItems: { [key: number]: ModelTypes.SubscriptionItemModel },
  subscriptionTransactions: { [key: number]: ModelTypes.SubscriptionTransactionModel },
  subscriptions: { [key: number]: ModelTypes.SubscriptionModel },
  taxJurisdictions: { [key: number]: ModelTypes.TaxJurisdictionModel }
  transactionItems: { [key: number]: ModelTypes.TransactionItemModel },
  transactionTypes: { [key: number]: ModelTypes.TransactionTypeModel },
  transactions: { [key: number]: ModelTypes.TransactionModel },
  userTypes: { [key: number]: ModelTypes.UserTypeModel },
  users: { [key: number]: ModelTypes.UserModel },
// END ENTITY TYPES
}

const initialState: EntitiesState = {
// START ENTITIES STATE
  _updates: {},
  activities: {},
  accountingSystems: {},
  bankTypes: {},
  billingEntities: {},
  billingNotes: {},
  billingTypes: {},
  businessUnitProducts: {},
  businessUnits: {},
  comments: {},
  contactPaymentMethods: {},
  contactTypes: {},
  contactUsers: {},
  contacts: {},
  conversations: {},
  countries: {},
  creditAllocations: {},
  credits: {},
  currentUser: {},
  invoices: {},
  paymentMethods: {},
  prices: {},
  statuses: {},
  subscribers: {},
  subscriptionItems: {},
  subscriptionTransactions: {},
  subscriptions: {},
  taxJurisdictions: {},
  transactionItems: {},
  transactionTypes: {},
  transactions: {},
  userTypes: {},
  users: {},
// END ENTITIES STATE
}

// Normalized Data
type EntitiesActionFunctionPayload = {
  entities: EntitiesState,
}

type ReducerResult = EntitiesActionFunctionPayload['entities'] & {
  type: string,
}

type Action = Partial<EntitiesState> &
  Partial<ModuleState> & {
    type?: string,
  }

export function updateEntities(payload: EntitiesActionFunctionPayload): ReducerResult {
  return { type: UPDATE_ENTITY, ...payload.entities }
}

export function replaceEntity(payload: EntitiesActionFunctionPayload): ReducerResult {
  return { type: REPLACE_ENTITY, ...payload.entities }
}

export function deleteEntity(payload: EntitiesActionFunctionPayload): ReducerResult {
  return { type: DELETE_ENTITY, ...payload.entities }
}

function cleanKeys(keys: (keyof Action)[] = [], removeKeys: (keyof Action)[] = []): (keyof EntitiesState)[] {
  removeKeys.forEach((removeKey) => {
    const index = keys.indexOf(removeKey)
    keys.splice(index, 1)
  })

  return keys
}

// Reducers
function addEntities(state: EntitiesState, action: Action): EntitiesState {
  const keys = cleanKeys(Object.keys(action), ['type'])
  const newState = { ...state }

  // Don't merge arrays
  const dontMerge = (_, source) => source
  const mergeOptions = { arrayMerge: dontMerge }

  keys.forEach((key) => {
    newState[key] = deepmerge(newState[key], action[key], mergeOptions)
  })

  return newState
}

function replaceEntities(state: EntitiesState, action: Action): EntitiesState {
  const keys = cleanKeys(Object.keys(action), ['type'])
  const newState = { ...state }

  keys.forEach((key) => {
    if (newState[key]){
      newState[key] = { ...state[key] }

      // Keys of the item we need to replace
      const itemKeys = Object.keys(action[key])
      itemKeys.forEach((itemKey) => {
        newState[key][itemKey] = action[key][itemKey]
      })
    }
  })

  return newState
}

function removeEntities(state: EntitiesState, action: Action): EntitiesState {
  const keys: (keyof EntitiesState)[] = cleanKeys(Object.keys(action), ['type'])
  const newState = { ...state }

  keys.forEach((key) => {
    if (newState[key]){
      newState[key] = { ...state[key] }

      // Keys of the item we need to remove
      const itemKeys = Object.keys(action[key])
      itemKeys.forEach((itemKey) => {
        delete newState[key][itemKey]
      })
    }
  })

  return newState
}

export default function reducer(state: EntitiesState = initialState, action: Action = {}) {
  switch (action.type){
    case UPDATE_ENTITY:
      return addEntities(state, action)
    case REPLACE_ENTITY:
      return replaceEntities(state, action)
    case DELETE_ENTITY:
      return removeEntities(state, action)
    default:
      return state
  }
}

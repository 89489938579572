import useReduxAction from '@hooks/useReduxAction'

import type { InvoiceSummaryByStatusModel } from '@models/invoiceSummary'

type InvoiceSummaryOptions = {
  filters?: {
    businessUnitId?: number,
    contactId?: number,
  },
  performHttpRequests?: boolean,
}

function useInvoiceSummaryByStatus(options: InvoiceSummaryOptions) {
  const { filters, performHttpRequests = true } = options || {}

  const invoiceSummaryPayload = useReduxAction(
    'invoiceSummary',
    'fetchInvoiceSummaryByStatus',
    {
      ...filters,
    },
    [performHttpRequests],
    {
      shouldPerformFn: (entityReducer) => {
        const { loading, errors } = entityReducer
        return !!performHttpRequests && !loading && !errors?.length
      },
    },
  )

  const { result, loading }: { result?: InvoiceSummaryByStatusModel, loading: boolean } = invoiceSummaryPayload

  const invoiceSummaryItems = Array.isArray(result) ? result : []
  const hasInvoiceSummaryItems = !!invoiceSummaryItems.length

  return {
    hasInvoiceSummaryItems,
    invoiceSummaryItems,
    loading,
  }
}

export default useInvoiceSummaryByStatus

import { Box, Checkbox, ListItem } from '@campaignhub/suit-theme'

import type { ContactModel } from '@models/contact'

interface ContactProps {
  alreadyImported: boolean,
  callbacks: {
    toggleContact: () => void,
  },
  checked: boolean,
  contact: ContactModel,
}

const Contact = (props: ContactProps) => {
  const {
    alreadyImported,
    callbacks: { toggleContact },
    checked,
    contact,
  } = props

  const { name } = contact

  return (
    <ListItem.WithCheckbox
      boxProps={{ border: 'none' }}
      checkbox={(
        <Checkbox
          checked={checked}
          onClick={() => toggleContact()}
        />
      )}
    >
      <Box
        color={alreadyImported ? 'lightGrey' : 'bodyFontLightColor'}
        fontSize="small"
      >
        {name} {alreadyImported && '(Imported)'}
      </Box>
    </ListItem.WithCheckbox>
  )
}

export default Contact

import { ActivityItem as ActivityItemBase, Text } from '@campaignhub/suit-theme'
import { formatDate } from '@campaignhub/javascript-utils'

import useUser from '@hooks/useUser'

import { ActivityModel } from '@models/types'

import { destructiveActions } from '@functions/activity'

const renderActionText = (key: ActivityModel['key']) => {
  if (key){
    const [entity, action] = key.split('.')
    return `${action} ${entity.split('_').join(' ')}`
  }

  return undefined
}

const renderActivityTitle = (description: string) => {
  if (description){
    return (
      <Text as="span" fontStyle="italic" marginRight="small">
        - {description}
      </Text>
    )
  }

  return undefined
}

interface ActivityItemProps {
  activity: ActivityModel,
  isLastActivity: boolean,
}

const ActivityItem = (props: ActivityItemProps) => {
  const {
    activity: {
      action,
      dateCreated,
      description,
      key,
      name,
      userId,
    },
    isLastActivity,
  } = props

  const { userType } = useUser({ id: userId }, { performHttpRequests: !!userId })
  const { name: userTypeName } = userType || {}

  return (
    <ActivityItemBase
      actionText={renderActionText(key)}
      actionType={action && destructiveActions.includes(action) ? 'destructive' : 'default'}
      boxProps={{ paddingTop: 'medium', paddingBottom: isLastActivity ? 0 : 'medium' }}
      dateTimeText={
        dateCreated
          ? formatDate(dateCreated, 'ISO8601', 'dd-MM-yyyy h:mm a')
          : 'Not Set'
      }
      description={renderActivityTitle(description)}
      hideImage
      roleName={userTypeName}
      title={name}
    />
  )
}

export default ActivityItem

import { useContext } from 'react'

import {
  Button,
  DashboardModule,
  Form,
  Link,
  LoadingModule,
  ModalContext,
  SectionDivider,
  SidebarModal,
  SidebarNoticeBox,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import type { ContactModel } from '@models/types'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import EntitySelector from '@components/EntitySelector'
import useImportContacts from './hooks/useImportContacts'

import Contact from './components/Contact'

const MODAL_KEY = 'ImportContactsModal'

type ImportContactsModalProps = {
  callbacks: {
    closeModal: () => void,
    importContacts: (payload: HandleCallbackActionParams) => void,
  },
  showModal: boolean,
}

const ImportContactsModal = (props: ImportContactsModalProps) => {
  const {
    callbacks,
    callbacks: {
      closeModal,
      importContacts,
    },
    showModal,
  } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const { contactType } = modalPayload

  const { id: contactTypeId, name: contactTypeName } = contactType

  const importContactsPayload = useImportContacts({ contactType })
  const {
    alreadyImportedRealbaseIds,
    callbacks: {
      importContacts: importContactsFn,
      loadMore,
      setState,
      toggleAllContacts,
      toggleContact,
    },
    canLoadMore,
    hasRealbaseContacts,
    hasParentContactType,
    hasSelectedContacts,
    limit,
    loading,
    parentContactId,
    parentContactStrings: {
      label: parentContactTypeName,
      labelPlural: parentContactTypeNamePlural,
    },
    parentContactTypeId,
    realbaseContacts,
    selectedContactIds,
    string,
  } = importContactsPayload

  const importPayload = {
    callbacks: {
      action: importContactsFn,
      afterAction: closeModal,
    },
    entityParams: { contactTypeId, selectedContactIds },
    toastText: 'Import Contacts Queued',
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header
        callbacks={callbacks}
        title={`Import ${contactTypeName}`}
        titleSecondLine="Contacts"
      />

      <SidebarModal.Content>
        <Form>
          {hasParentContactType && (
            <EntitySelector
              callbacks={{
                selectItem: (parentContact: ContactModel) => setState({
                  parentContactId: parentContact ? parentContact.id : undefined,
                }),
              }}
              customEntitySettings={{
                contacts: {
                  labelPlural: parentContactTypeNamePlural,
                },
              }}
              disabled={!contactTypeId}
              entityKey="contacts"
              label={`Filter by ${parentContactTypeName}`}
              searchRequestOptions={{ contactTypeId: parentContactTypeId }}
              selectedItemId={parentContactId}
              endpoint = {parentContactTypeId == 1 ? '/contacts/fetch-from-realbase' : '/contacts'}
            />
          )}

          <Form.Field
            label="Filter by Title"
            marginTop={hasParentContactType ? 'large' : 0}
          >
            <input
              name="string"
              onChange={e => setState({ string: e.target.value })}
              value={string}
              type="text"
            />
          </Form.Field>

          <Form.Field label="* Limit" marginTop="large">
            <select
              data-validate-field-on="change"
              onChange={e => setState({ limit: e.target.value })}
              value={limit}
            >
              <option key="50" value="50">50</option>
              <option key="100" value="100">100</option>
              <option key="150" value="150">150</option>
              <option key="200" value="200">200</option>
              <option key="250" value="250">250</option>
            </select>
          </Form.Field>

          {/* Loading External Contacts */}
          <LoadingModule boxProps={{ marginTop: 'large' }} loading={loading} times={1} />

          {!loading && (
            <>
              <SectionDivider boxProps={{ marginTop: 'large' }}>
                Available Contacts
              </SectionDivider>

              {!hasRealbaseContacts && (
                <SidebarNoticeBox boxProps={{ marginTop: 'large' }}>
                  <SidebarNoticeBox.Title>Nothing to Import</SidebarNoticeBox.Title>

                  <SidebarNoticeBox.Paragraph>
                    No {contactTypeName} Contacts Available. Please adjust your search query and try again.
                  </SidebarNoticeBox.Paragraph>
                </SidebarNoticeBox>
              )}

              {hasRealbaseContacts && (
                <Form.Field
                  label="Select Contacts"
                  labelRight={(
                    <Link onClick={toggleAllContacts} textProps={{ fontSize: 'xsmall' }}>
                      {hasSelectedContacts
                        ? 'Deselect All'
                        : 'Select All'}
                    </Link>
                  )}
                  marginTop="large"
                >
                  {realbaseContacts.map((contact: ContactModel) => {
                    const { realbaseId } = contact

                    return (
                      <Contact
                        alreadyImported={alreadyImportedRealbaseIds.includes(realbaseId)}
                        callbacks={{ toggleContact: () => toggleContact(realbaseId) }}
                        contact={contact}
                        checked={selectedContactIds.includes(realbaseId)}
                        key={realbaseId}
                      />
                    )
                  })}
                </Form.Field>
              )}
            </>
          )}

          <DashboardModule.LoadMoreFooter
            callbacks={{ loadMore }}
            canLoadMore={canLoadMore}
            entityCount={realbaseContacts.length}
            loading={loading}
          />
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!hasSelectedContacts}
          onClick={() => importContacts(importPayload)}
          size="large"
        >
          Import Contacts
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ImportContactsModalProps) => (
  <SidebarModal.RenderController {...props}>
    <ImportContactsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal

import { useMemo } from 'react'
import { formatMoney } from 'accounting'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-light-svg-icons'

import {
  Link, StatusBadge, Table, Text,
} from '@campaignhub/suit-theme'

import { digObject, goToEntity } from '@campaignhub/javascript-utils'

import { statusColor } from '@functions/status'

import { generateUrls as generateContactUrls } from '@hooks/useContact'
import { generateUrls as generateCreditUrls } from '@hooks/useCredit'
import { CreditFilters } from '@hooks/useCredits'
import useSelector from '@hooks/useSelector'

import type { EntitiesState } from '@redux/entities'
import { CreditModel } from '@models/types'

type BuildColumnsProps = {
  entities: EntitiesState,
  filters: CreditFilters,
}

const buildColumns = (props: BuildColumnsProps) => {
  const { entities, filters } = props || {}
  const { businessUnits, contacts, statuses } = entities || {}

  const tableColumns = [
    {
      cellProps: {
        key: 'statusId',
        style: {
          verticalAlign: 'middle',
        },
      },
      title: 'Status',
      dataKey: 'statusId',
      render: (statusId: number) => {
        const status = digObject(statuses, String(statusId), {})
        const { key, name } = status

        return (
          <StatusBadge color={statusColor(key)} ghost text={name} />
        )
      },
    },
    {
      cellProps: {
        key: 'creditNumber',
        style: {
          verticalAlign: 'middle',
        },
      },
      title: 'Credit #',
      dataKey: 'number',
      render: (creditNumber: string, credit: CreditModel) => {
        const { id } = credit

        return (
          <Link
            disabledUnderline
            onClick={() => goToEntity({
              generateUrls: () => generateCreditUrls({ id }),
              entityUrlKey: 'editCreditUrl',
            })}
          >
            {creditNumber}
          </Link>
        )
      },
    },
    {
      cellProps: {
        key: 'contactId',
        style: {
          minWidth: '200px',
          verticalAlign: 'middle',
        },
      },
      hidden: !!filters.contactId,
      title: 'Contact',
      dataKey: 'contactId',
      render: (contactId: number) => {
        const contact = digObject(contacts, String(contactId), {})
        const { id, name } = contact

        return (
          <Link
            disabledUnderline
            onClick={() => goToEntity({
              generateUrls: () => generateContactUrls({ id }),
              entityUrlKey: 'editContactUrl',
            })}
          >
            <Text color="blue" variant="ellipsis" width="200px">
              {name}
            </Text>
          </Link>
        )
      },
    },
    {
      cellProps: {
        key: 'businessUnitId',
        style: {
          verticalAlign: 'middle',
        },
      },
      hidden: !!filters.businessUnitId,
      title: 'Business Unit',
      dataKey: 'businessUnitId',
      render: (businessUnitId: number) => {
        const businessUnit = digObject(businessUnits, String(businessUnitId), {})
        const { name } = businessUnit

        return (
          <Text variant="ellipsis" width={50}>{name}</Text>
        )
      },
    },
    {
      cellProps: {
        key: 'title',
        style: {
          verticalAlign: 'middle',
        },
      },
      title: 'Item / Description',
      dataKey: 'title',
      render: (title: string) => (
        <Text variant="ellipsis" width={160}>{title}</Text>
      ),
    },
    {
      cellProps: {
        key: 'amount',
        style: {
          verticalAlign: 'middle',
        },
      },
      title: 'Credit Inc. GST',
      dataKey: 'amount',
      render: (amount: number) => `${formatMoney(amount)}`,
    },
    {
      cellProps: {
        key: 'remainingCredit',
        style: {
          verticalAlign: 'middle',
        },
      },
      title: 'Balance',
      dataKey: 'remainingCredit',
      render: (remainingCredit: number) => `${formatMoney(remainingCredit)}`,
    },
    {
      cellProps: {
        key: 'edit',
      },
      dataKey: 'edit',
      render: (datakey: string, subscription: CreditModel) => {
        const { editCreditUrl } = generateCreditUrls(subscription)

        return (
          <Link greyLink href={editCreditUrl}>
            <FontAwesomeIcon icon={faPencil} />
          </Link>

        )
      },
    },
  ]

  return tableColumns
}

interface CreditsTableProps {
  filters: CreditFilters,
  filteredCredits: CreditModel[],
}

const CreditsTable = (props: CreditsTableProps) => {
  const { filters, filteredCredits } = props

  const { entities } = useSelector(reduxState => reduxState)

  const memoColumns = useMemo(
    () => buildColumns(
      { entities, filters },
    ),
    [filteredCredits.length],
  )

  return (
    <Table
      boxProps={{
        border: 'none', maxHeight: 500, overflowY: 'auto',
      }}
      columns={memoColumns}
      data={filteredCredits}
      stickyHeader
    />
  )
}

export default CreditsTable

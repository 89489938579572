import { useMemo } from 'react'

import {
  digObject, formatDate, matchFilterBetweenDates, matchFilterNumber, sortArrayBy,
} from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import { getSortKey } from '@functions/getSortKey'
import { matchSubscriptionBusinessUnit, matchSubscriptionStatus } from '@functions/subscription'

import type { SubscriptionRequestOptions } from '@models/subscription'

export interface UseSubscriptionsOptions {
  filters: {
    businessUnitId?: number,
    contactId?: number,
    endDate?: string,
    sort?: string,
    startDate?: string,
    status?: string,
  },
  performHttpRequests: boolean,
  requestOptions?: SubscriptionRequestOptions,
}

export type SubscriptionFilters = UseSubscriptionsOptions['filters']

const watchEntityKeys = ['subscriptions']

function useSubscriptions(options: UseSubscriptionsOptions) {
  const { filters, requestOptions } = options || {}

  const {
    businessUnitId: filterBusinessUnitId,
    contactId: filterContactId,
    endDate: filterEndDate,
    sort,
    startDate: filterStartDate,
    status: filterStatus,
  } = filters || {}

  const {
    updatedEntities: { subscriptions: subscriptionsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { loading: loadingSubscriptions } = useSelector(reduxState => reduxState.subscriptions)

  const entities = useSelector(reduxState => reduxState.entities)
  const { contacts, statuses, subscriptions } = entities

  const order = (sort != null) ? sort.split('_')[0] : 'desc'
  const sortKey = getSortKey(sort, 'subscriptionStart')

  const filteredSubscriptions = useMemo(() => {
    const filtered = Object.values(subscriptions).filter((subscription) => {
      const { contactId, subscriptionStart, subscriptionEnd, statusId } = subscription

      const status = digObject(statuses, String(statusId))
      
      const contact = digObject(contacts, String(contactId), {})
      subscription.contact = contact

      const matchBusinessUnit = matchSubscriptionBusinessUnit(subscription, filterBusinessUnitId)
      const matchContact = matchFilterNumber(contactId, filterContactId)

      const formattedCreatedDate = formatDate(subscriptionStart, 'ISO8601', 'yyyy-MM-dd')

      const matchDate = matchFilterBetweenDates(formattedCreatedDate, filterStartDate, filterEndDate)

      const matchStatus = matchSubscriptionStatus({ filterStatus, status, subscriptionEnd })

      return (
        matchBusinessUnit
        && matchContact
        && matchDate
        && matchStatus
      )
    })

    return sortArrayBy(filtered, order, sortKey)
  }, [subscriptionsUpdatedAt, JSON.stringify(filters)])

  const filteredSubscriptionsCount = filteredSubscriptions?.length

  const loadMorePayload = useLoadMore({
    ...options,
    loadedCount: filteredSubscriptionsCount,
  })
  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  useReduxAction(
    'subscriptions',
    'loadSubscriptions',
    {
      ...filtersWithOffset,
      limit,
      ...requestOptions,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredSubscriptions,
    filteredSubscriptionsCount,
    hasFilteredSubscriptions: !!filteredSubscriptions.length,
    loading: loadingSubscriptions,
  }
}

export default useSubscriptions

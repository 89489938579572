import { Box } from '@campaignhub/suit-theme'

import useActivities from '@hooks/useActivities'

import BlankState from '@components/BlankState'

import type { EntityOptions } from '@redux/modules/types'

import ActivityItem from './ActivityItem'

import ActivityImageUrl from './assets/activity.svg'

import styles from './styles.module.scss'

interface ActivityProps {
  requestOptions?: EntityOptions,
  subjectId: number,
  subjectType: string,
}

const Activity = (props: ActivityProps) => {
  const { requestOptions, subjectId, subjectType } = props

  const activitiesPayload = useActivities({
    filters: {
      recipientId: subjectId,
      recipientType: subjectType,
    },
    performHttpRequests: !!subjectId,
    requestOptions,
  })

  const {
    filteredActivities,
    filteredActivitiesCount,
    hasFilteredActivities,
  } = activitiesPayload

  return (
    <Box
      alignItems={hasFilteredActivities ? undefined : 'center'}
      className={styles.activity}
      flexDirection="column"
      height="100%"
      justifyContent={hasFilteredActivities ? undefined : 'center'}
      marginTop="large"
      overflow="auto"
    >
      {!hasFilteredActivities && (
        <BlankState
          imageUrl={ActivityImageUrl}
          title="No Activity"
          subtitle="Activity will be logged here once work starts."
        />
      )}

      {filteredActivities.map((activity, index) => (
        <ActivityItem key={activity.id} activity={activity} isLastActivity={index === (filteredActivitiesCount - 1)} />
      ))}
    </Box>
  )
}

export default Activity

import { formatMoney } from 'accounting'

import {
  Box, FormField, Grid, Text,
} from '@campaignhub/suit-theme'

import type { InvoiceFormPayload } from '@hooks/useInvoice'

import { TransactionFormPayload } from '@hooks/useTransaction'

interface TotalsProps {
  editing: boolean,
  invoiceFormPayload: InvoiceFormPayload,
  transactionFormPayload:  TransactionFormPayload
}

const Totals = (props: TotalsProps) => {
  const { editing, invoiceFormPayload, transactionFormPayload} = props
  const { entityState: invoiceEntityState } = invoiceFormPayload

  const {
    amountPaid,
    balance,
  } = invoiceEntityState

  const {
    entityState: transactionEntityState,
    entityState: {
      currency,
      incTaxTotal,
      overrideTotal,
      tax,
    },
    handlers: transactionHandlers,
    setEntityState: setTransactionEntityState,
  } = transactionFormPayload

  return (
    <Box padding="large">
      <Grid
        alignItems="center"
        gridGap="large"
        gridTemplateColumns="auto 80px"
        justifyContent="flex-end"
        paddingRight="30px"
      >
        {!editing && (
          <>
            <Text fontSize="small" textAlign="right">Detail Total</Text>
            {/* what value is meant to be here instead of formatMoney(incTaxTotal) - Detail Total */}
            <Text fontSize="small" textAlign="right">{formatMoney(incTaxTotal)}</Text>

            <Text color="bodyFontLightColor" fontSize="small" textAlign="right">GST Taxable Total</Text>
            <Text color="bodyFontLightColor" fontSize="small" textAlign="right">{formatMoney('')}</Text>

            <Text color="bodyFontLightColor" fontSize="small" textAlign="right">GST Exempt Total</Text>
            <Text color="bodyFontLightColor" fontSize="small" textAlign="right">{formatMoney('')}</Text>

            <Text color="bodyFontLightColor" fontSize="small" textAlign="right">Tax Total</Text>
            <Text color="bodyFontLightColor" fontSize="small" textAlign="right">{formatMoney(tax)}</Text>

            <Text color="bodyFontLightColor" fontSize="small" textAlign="right">Balance</Text>
            <Text color="bodyFontLightColor" fontSize="small" textAlign="right">{formatMoney(balance)}</Text>

            <Text fontSize="medium" textAlign="right">Total Amount Payable</Text>
            <Text fontSize="medium" textAlign="right">{formatMoney(incTaxTotal)}</Text>
          </>
        )}

        {editing && (
          <>
            <Text fontSize="small">Subtotal</Text>
            <FormField>
              <input
                align="right"
                disabled
                name="incTaxTotal"
                type="number"
                value={incTaxTotal}
              />
            </FormField>

            <Text fontSize="small">Override Total</Text>
            <FormField>
              <input
                align="right"
                name="overrideTotal"
                type="number"
                value={overrideTotal}
                {...transactionHandlers}
              />
            </FormField>

            <Text fontSize="small">Total</Text>
            <FormField>
              <input
                align="right"
                disabled
                name="incTaxTotal"
                type="number"
                value={overrideTotal || incTaxTotal}
              />
            </FormField>

            <Text fontSize="small">Currency</Text>
            <FormField>
              <select
                name="currency"
                value={currency}
                {...transactionHandlers}
              >
                <option value="aud">AUD</option>
                <option value="nzd">NZD</option>
              </select>
            </FormField>
          </>
        )}
      </Grid>
    </Box>
  )
}

export default Totals

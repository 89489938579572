import { DateTime } from 'luxon'

import {
  digObject, formatDate, matchFilterBetweenDates,
} from '@campaignhub/javascript-utils'

import type { SubscriptionModel, StatusModel } from '@models/types'

export type SubscriptionStatus = 'all' | 'pending' | 'active' | 'expiring' | 'inactive'

export function matchSubscriptionBusinessUnit(subscription: SubscriptionModel = {}, filterBusinessUnitId: number) {
  if (!subscription?.id) return false

  const { businessUnitIds = [] } = subscription

  if (Number(filterBusinessUnitId)){
    return businessUnitIds?.some(businessUnitId => (
      businessUnitId === filterBusinessUnitId
    ))
  }

  return true
}

type MatchSubscriptionStatusParams = {
  filterStatus: SubscriptionStatus,
  status: StatusModel,
  subscriptionEnd: string,
}

export function matchSubscriptionStatus(params: MatchSubscriptionStatusParams): boolean {
  const { filterStatus, status, subscriptionEnd } = params
  const { key: statusKey } = status || {}

  // early return
  if (!filterStatus || typeof filterStatus !== 'string' || filterStatus === 'all') return true

  switch (filterStatus){
    case 'active':
      return statusKey === filterStatus
    case 'pending': // pending payment method or pending export
      return statusKey?.includes('pending')
    case 'expiring': // within 3 months from today
      if (statusKey !== 'inactive'){
        const formattedSubscriptionEnd = formatDate(subscriptionEnd, 'ISO8601') // yyyy-MM-dd
        const dateNow = DateTime.now().toFormat('yyyy-MM-dd')
        const dateThreeMonthsFromNow = DateTime.now().plus({ months: 3 }).toFormat('yyyy-MM-dd')

        return matchFilterBetweenDates(formattedSubscriptionEnd, dateNow, dateThreeMonthsFromNow)
      }
      return false
    case 'inactive':
      return statusKey === filterStatus
    default:
      return false
  }
}

export function displaySubscriptionInterval(interval: string): string {
  if (!interval || typeof (interval) !== 'string') return ''

  const subscriptionIntervals = {
    day: 'Daily',
    month: 'Monthly',
    year: 'Yearly',
  }

  const displayInterval = digObject(subscriptionIntervals, String(interval), interval)

  return displayInterval
}

export function getMinSubscriptionEndDate(startDate: string, interval: string): string {
  if (startDate && typeof startDate === 'string'){
    const startDateTime = DateTime.fromISO(startDate)
    const isValidDateTime = !startDateTime.invalidReason

    if (isValidDateTime){
      if (interval){
        if (interval === 'year'){
          // set min subscription end date as 1 year from start date
          return startDateTime?.plus({ years: 1 }).toFormat('yyyy-MM-dd')
        }

        // else month
        // set min subscription end date as 1 month from start date
        return startDateTime?.plus({ months: 1 }).toFormat('yyyy-MM-dd')
      }

      // if no interval, set subscription start date as min subscription end date
      return startDateTime.toFormat('yyyy-MM-dd')
    }

    // return today as min subscription end date
    return DateTime.now().toFormat('yyyy-MM-dd')
  }

  // return today as min subscription end date
  return DateTime.now().toFormat('yyyy-MM-dd')
}

export function getSubscriptionDateTitle(status: string) {
  switch (status){
    case 'inactive':
      return 'Expired'
    case 'expiring':
      return 'Expiring'
    default:
      return 'Since'
  }
}

import { BusinessUnitProductModel } from '@models/types'

export type TransactionItemRequestOptions = {
  includeTransactionItemBusinessUnitProduct?: boolean,
}

export type TransactionItemModel = {
  businessUnitProduct?: BusinessUnitProductModel,
  businessUnitProductId?: number,
  cacheKey?: string,
  id?: number,
  incTaxTotal?: number,
  qty?: number,
  referenceCode?: string,
  salesPersonRealbaseId?: number,
  tax?: number,
  title?: string,
  transactionId?: number,
  unitPrice?: number,
  unitPriceIncTax?: number,
  unitPriceTax?: number,
}

// Need Price Id returned in DTO through businessUnitProduct

const state = {
  businessUnitProductId: null,
  id: null,
  incTaxTotal: null,
  qty: null,
  referenceCode: '',
  salesPersonRealbaseId: null,
  statusId: null,
  tax: null,
  title: '',
  transactionId: null,
  unitPrice: null,
  unitPriceIncTax: null,
  unitPriceTax: null,
}

export const requiredFields = [
  { key: 'businessUnitProductId' },
  { key: 'incTaxTotal' },
  { key: 'qty' },
  { key: 'title' },
  { key: 'transactionId' },
  { key: 'unitPriceIncTax' },
]

export default state

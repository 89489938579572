import React from 'react'

import { useRouterScrollToTop } from '@campaignhub/react-hooks'

interface AdminProps {
  children: React.ReactElement,
}

const Admin = (props: AdminProps) => {
  const { children } = props

  // Scroll Page to top when route changes
  useRouterScrollToTop()

  return children
}

export default Admin

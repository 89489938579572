import { useContext } from 'react'

import { ModalContext, SidebarModal } from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useContactPaymentMethod from '@hooks/useContactPaymentMethod'

import type { ContactPaymentMethodModel } from '@models/types'

import CreateContactPaymentMethod from './components/CreateContactPaymentMethod'
import EditContactPaymentMethod from './components/EditContactPaymentMethod'

const MODAL_KEY = 'CreateOrEditContactPaymentMethodModal'

type ModalCallbacks = {
  closeModal: () => void,
  createContactPaymentMethod: (payload: HandleCallbackActionParams) => void,
  deleteContactPaymentMethod: (payload: HandleCallbackActionParams) => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  contactPaymentMethod: ContactPaymentMethodModel,
  showModal: boolean,
}

const CreateOrEditContactPaymentMethodModal = (props: ModalProps) => {
  const {
    callbacks,
    contactPaymentMethod: initContactPaymentMethod,
    showModal,
  } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const { contactPaymentMethod } = modalPayload

  const contactPaymentMethodPayload = useContactPaymentMethod({
    ...initContactPaymentMethod, ...contactPaymentMethod,
  })
  const {
    contactPaymentMethod: {
      id: contactPaymentMethodId,
      contactId,
    },
    loading,
  } = contactPaymentMethodPayload

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header
        callbacks={callbacks}
        title={contactPaymentMethodId ? 'Manage' : 'Add'}
        titleSecondLine="Payment Method"
      />

      {!contactPaymentMethodId && (
        <CreateContactPaymentMethod
          callbacks={callbacks}
          contactId={contactId}
        />
      )}

      {!loading && contactPaymentMethodId && (
        <EditContactPaymentMethod
          callbacks={callbacks}
          contactPaymentMethodPayload={contactPaymentMethodPayload}
        />
      )}
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditContactPaymentMethodModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal

import { DashboardModule } from '@campaignhub/suit-theme'

import ListBlankState from '@components/ListBlankState'

interface StatementsProps {
  businessUnitId: number,
  href?: string,
}

const Statements = (props: StatementsProps) => {
  const { businessUnitId, href } = props || {}

  // const statementsPayload = useStatements({ businessUnitId })

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column' }}
      headerRight={href ? (
        <DashboardModule.Link href={href}>
          View All
        </DashboardModule.Link>
      ) : ''}
      // loading={loading}
      title="Statements"
    >
      <ListBlankState title="TODO: STATEMENTS" text=" " />
    </DashboardModule>
  )
}

export default Statements

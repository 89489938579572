import { DashboardModule } from '@campaignhub/suit-theme'

import useSubscriptions from '@hooks/useSubscriptions'
import type { UseSubscriptionsOptions } from '@hooks/useSubscriptions'

import ListBlankState from '@components/ListBlankState'

import SubscriptionsTable from './SubscriptionsTable'

interface SubscriptionsProps {
  options: UseSubscriptionsOptions,
}

const Subscriptions = (props: SubscriptionsProps) => {
  const { options } = props || {}
  const { filters = {} } = options || {}

  const subscriptionsPayload = useSubscriptions(options)
  const { filteredSubscriptions, hasFilteredSubscriptions, loading } = subscriptionsPayload || {}

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column' }}
      loading={loading}
      title="Subscriptions"
    >
      {!loading && !hasFilteredSubscriptions && <ListBlankState title="No Subscriptions" text=" " />}

      {!loading && hasFilteredSubscriptions && (
        <SubscriptionsTable
          filters={filters}
          filteredSubscriptions={filteredSubscriptions}
        />
      )}
    </DashboardModule>
  )
}

export default Subscriptions

import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { PaymentMethodModel } from '@models/paymentMethod'
import type { AppDispatch } from '@redux/store'
import type {
  Action, ActionResult, EntityOptions, Errors, FetchRequestPayload, RootReducerState,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/paymentMethod/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/paymentMethod/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/paymentMethod/FETCH_FAILURE'

export type PaymentMethodModuleState = {
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loading: boolean,
}

const initialState: PaymentMethodModuleState = {
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loading: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}): Action {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
  }
}

export function fetchSuccess(): Action {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []): Action {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

// Action Creators
export function loadPaymentMethods(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState): Promise<ActionResult<PaymentMethodModel[]>> => {
    const loadedForKeys = [...getState().paymentMethods.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/payment-methods', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.PAYMENT_METHOD_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function loadPaymentMethod(paymentMethodId: number, options: EntityOptions = {}) {
  return (dispatch: AppDispatch): Promise<ActionResult<PaymentMethodModel>> => {
    dispatch(fetchRequest())

    const promise = api(`/paymentMethods/${paymentMethodId}`, options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.PAYMENT_METHOD)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))
        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: PaymentMethodModuleState = initialState,
  action: Action = { type: '' },
): PaymentMethodModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}

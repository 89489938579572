import React from 'react'

/*
 * Use this context to provide data to a specific page
 * Use PageContext.Provider in the page
 * Generally we use it to supply page specific params e.g. callbacks or props
 * that need to be available through deeply nested components
 */

/*
  * Type Usage as follows:
  * Context Provider: Define Payload
  * i.e. export type PageContextPayload = {
            callbacks: {
              resetFilters: () => void,
              showImportContactsModal: () => void,
              updateFilters: () => void,
            },
            defaultFilters: object,
          }
  *
  * Context Consumer: Import types
  * import type { DefaultContext } from '@contexts/pageContext'
  * import type { PageContextPayload } from '../../index'
  *
  * const pageContext = useContext<DefaultContext<PageContextPayload>>(PageContext)
  */

export type DefaultContext<Payload = any> = {} & Payload

const data: DefaultContext = {}

const PageContext = React.createContext<DefaultContext>(data)

export default PageContext

import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { deleteEntity, updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { CreditModel, CreditCsvFormParams } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, ActionResult, DeleteParams, EntityOptions, Errors, FetchRequestPayload, RootReducerState, UpdateParams,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/credit/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/credit/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/credit/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/credit/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/credit/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/credit/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/credit/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/credit/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/credit/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/credit/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/credit/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/credit/DELETE_FAILURE'

export type CreditModuleState = {
  creating: boolean,
  deleting: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loading: boolean,
  updating: boolean,
}

// Initial State
const initialState: CreditModuleState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loadedIds: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest() {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors: Errors = []) {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadCredits(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const loadedForKeys = [...getState().credits.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)) {
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/credits', options)
      .then(({ data }: { data: CreditModel[] }) => {
        const normalizedJson = normalize(data, Schemas.CREDIT_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function loadCredit(creditId: number, options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const state = getState()
    const loadedIds = [...state.credits.loadedIds]
    const loadedForKeys = [...state.credits.loadedForKeys]

    if (creditId && !loadedIds.includes(creditId)) {
      loadedIds.push(creditId)
    }

    if (entityKey && !loadedForKeys.includes(entityKey)) {
      loadedForKeys.push(entityKey)
    }

    // Set Loading
    dispatch(fetchRequest({ loadedForKeys, loadedIds }))

    const promise = api(`/credits/${creditId}`, options)
      .then(({ data }: { data: CreditModel }) => {
        const normalizedJson = normalize(data, Schemas.CREDIT)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function createCredit(credit: Partial<CreditModel>, options: EntityOptions = {}) {
  const config = {
    method: 'POST',
    data: JSON.stringify(credit),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api('/credits', options, config)
      .then(({ data }: { data: CreditModel }) => {
        const normalizedJson = normalize(data, Schemas.CREDIT)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function updateCredit(
  credit: UpdateParams<CreditModel>,
  options: EntityOptions = {},
) {
  const config = {
    method: 'PUT',
    data: JSON.stringify(credit),
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api(`/credits/${credit.id}`, options, config)
      .then(({ data }: { data: CreditModel }) => {
        const normalizedJson = normalize(data, Schemas.CREDIT)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function deleteCredit(credit: DeleteParams<CreditModel>) {
  const config = {
    method: 'DELETE',
  }

  return (dispatch: AppDispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/credits/${credit.id}`, {}, config)
      .then(({ data }: { data: { id: number } }) => {
        const normalizedJson = normalize(data, Schemas.CREDIT)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function downloadCreditPDF(id: number) {
  const config = {
    method: 'POST',
    data: JSON.stringify({
      id,
    }),
    responseType: 'blob', // API Returns PDF directly
  }
  return (dispatch: AppDispatch): Promise<ActionResult<object>> => {
    dispatch(updateRequest())
    const promise = api(`/credits/${id}/pdf`, {}, config)
      .then(({ data }) => {
        // API returns PDF directly. Open PDF in new tab.
        window.open(URL.createObjectURL(data))
        dispatch(updateSuccess())
        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))
        return { success: false, errors }
      })
    return promise
  }
}

export function downloadCreditsCSV(entityParams: CreditCsvFormParams) {

  const config = {
    responseType: 'blob',
  }

  return (dispatch: AppDispatch): Promise<ActionResult<object>> => {
    dispatch(updateRequest())

    const promise = api(`/credits/csv`, entityParams, config)
      .then(({ data }) => {
        // API returns PDF directly. Open PDF in new tab.
        window.open(URL.createObjectURL(data))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}


// Reducer
export default function reducer(
  state: CreditModuleState = initialState,
  action: Action = { type: '' },
): CreditModuleState {
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}

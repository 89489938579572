import { digObject } from '@campaignhub/javascript-utils'

import {
  useForm, useLatestEntity, useSetState, useThunkDispatch,
} from '@campaignhub/react-hooks'

import type { UseFormOptions, UseFormPayload } from '@campaignhub/react-hooks'

import * as transactionActions from '@redux/modules/transaction'

import PageContext from '@contexts/pageContext'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import { default as defaultTransactionState, requiredFields } from '@models/transaction'

import type { TransactionModel, TransactionRequestOptions } from '@models/transaction'
import type { AppDispatch } from '@redux/store'

interface Options {
  performHttpRequests?: boolean,
  requestOptions?: TransactionRequestOptions,
}

type TransactionForm = { entityState: TransactionModel }

export type TransactionFormPayload = UseFormPayload & TransactionForm;

export interface TransactionPayload {
  callbacks: {
    updateTransaction: (transactionParams: TransactionModel, requestOptions?: TransactionRequestOptions) => void,
    updateOverrideTotal: (transactionId: number, overrideTotal: number, requestOptions?: TransactionRequestOptions) => void,
    loadTransaction: (transactionId: number, requestOptions?: TransactionRequestOptions) => void,
  },
  editing: boolean,
  transaction: TransactionModel,
  loading: boolean,
  isDraftStatus: boolean
}

type LoadTransactionParams = {
  id: number,
  dispatch: AppDispatch,
  requestOptions?: TransactionRequestOptions,
}

export const loadTransaction = (params: LoadTransactionParams) => {
  const { id, dispatch, requestOptions} = params
  const { loadTransaction: loadFn } = transactionActions

  return dispatch(loadFn(id, requestOptions))
}

type UpdateTransactionParams = {
  transaction: TransactionModel,
  transactionParams: Partial<TransactionModel>,
  dispatch: AppDispatch,
  requestOptions?: TransactionRequestOptions,
}

export const updateTransaction = (params: UpdateTransactionParams) => {
  const {
    transaction, transactionParams, dispatch, requestOptions,
  } = params
  const { updateTransaction: updateFn } = transactionActions

  return dispatch(updateFn(transactionParams, requestOptions))
}

type UpdateOverrideTotalParams = {
  transactionParams: Partial<TransactionModel>,
  dispatch: AppDispatch,
  requestOptions?: TransactionRequestOptions,
}

export const updateOverrideTotal = (params: UpdateOverrideTotalParams) => {
  const {
    transactionParams, dispatch, requestOptions
  } = params
  const { updateOverrideTotal: updateOverrideTotalFn } = transactionActions
  return dispatch(updateOverrideTotalFn(transactionParams, requestOptions))
}

export function useTransactionForm(transaction: TransactionModel, options: UseFormOptions = {}) {
  const { validateOn } = options

  const transactionForm = useForm(
    defaultTransactionState,
    { entity: transaction, requiredFields, validateOn },
    [transaction.cacheKey],
  )

  return {
    ...transactionForm,
  }
}

const defaultState = {
  editing: false
}

function useTransaction(initEntity: TransactionModel = { id: null }, options: Options = {}) {
  const { performHttpRequests, requestOptions } = options || {}

  const { entity: transaction } = useLatestEntity(initEntity, 'transactions')
  const { id, statusId } = transaction

  const [state, setState] = useSetState(defaultState)
  const { editing } = state

  const dispatch = useThunkDispatch()
  
  useReduxAction(
    'transactions',
    'loadTransaction',
    {
      includeTransactionType: true,
      includeTransactionContact: true,
      includeTransactionStatus: true,
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, requestOptions) => action(id, requestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedIds } = entityReducer
        return performHttpRequests && !loadedIds.includes(id)
      },
    },
  )

  const { loading, updating } = useSelector(reduxState => reduxState.transactions)

  const { statuses } = useSelector(reduxState => reduxState.entities)
  const status = digObject(statuses, String(statusId), {});
  const isDraftStatus = status.key === "draft"

  return {
    callbacks: {
      updateTransaction: (transactionParams: TransactionModel, requestOptions?: TransactionRequestOptions) => updateTransaction({
        transaction, transactionParams, dispatch, requestOptions,
      }),
      updateOverrideTotal: (transactionParams: TransactionModel, requestOptions?: TransactionRequestOptions) => updateOverrideTotal({
        transaction, transactionParams, dispatch, requestOptions,
      }),
      loadTransaction: (requestOptions?: TransactionRequestOptions) => loadTransaction({
        id, dispatch, requestOptions,
      }),
    },
    transaction,
    loading,
    updating,
    isDraftStatus
  }
}

export default useTransaction

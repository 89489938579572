import { useEffect } from 'react'

import { digObject } from '@campaignhub/javascript-utils'
import { useSetState } from '@campaignhub/react-hooks'

import useContactPaymentMethods from '@hooks/useContactPaymentMethods'
import useContactUsers from '@hooks/useContactUsers'
import useSelector from '@hooks/useSelector'
import useSubscription, { useSubscriptionForm } from '@hooks/useSubscription'

const defaultState = {
  total: null,
}

const useCreateSubscription = () => {
  const [state, setState] = useSetState(defaultState)
  const { total } = state

  const subscriptionPayload = useSubscription()
  const {
    callbacks: {
      createSubscription: createSubscriptionFn,
    },
    subscription,
  } = subscriptionPayload

  const subscriptionFormPayload = useSubscriptionForm(subscription)
  const {
    entityState: {
      contactId,
      items,
    },
  } = subscriptionFormPayload

  const hasSubscriptionItems = !!items.length
  const hasMultipleSubscriptionItems = hasSubscriptionItems && items.length > 1

  const contactUsersPayload = useContactUsers({
    filters: {
      contactId,
    },
    performHttpRequests: !!contactId,
  })
  const { hasContactUsers, loading: loadingContactUsers } = contactUsersPayload

  const contactPaymentMethodsPayload = useContactPaymentMethods({
    filters: {
      contactId,
      isDefault: !!contactId,
    },
    performHttpRequests: !!contactId,
  })
  const { hasContactPaymentMethod, loading: loadingContactPaymentMethods } = contactPaymentMethodsPayload

  const { prices } = useSelector(reduxState => reduxState.entities)
  const { loading: loadingContact } = useSelector(reduxState => reduxState.contacts)
  const { loading: loadingPaymentMethods } = useSelector(reduxState => reduxState.paymentMethods)

  // Calculate Total of Subscription Items
  useEffect(() => {
    if (hasMultipleSubscriptionItems){
      const priceTotal = items.reduce((previousValue, currentValue) => {
        const { priceId, qty } = currentValue
        const price = digObject(prices, String(priceId), {})
        const { incTaxAmount } = price

        return previousValue + Number(incTaxAmount) * Number(qty)
      }, 0)

      return setState({ total: priceTotal })
    }
  }, [items])

  return {
    callbacks: {
      createSubscriptionFn,
    },
    contactPaymentMethodsPayload,
    hasContactPaymentMethod,
    hasContactUsers,
    hasMultipleSubscriptionItems,
    hasSubscriptionItems,
    loading: loadingContact && loadingContactPaymentMethods && loadingPaymentMethods && loadingContactUsers,
    prices,
    subscriptionFormPayload,
    total,
  }
}

export default useCreateSubscription

import { Route, Routes } from 'react-router-dom'

import CreditEdit from './screens/Credits/screens/Edit'
import Credits from './screens/Credits/screens/List'
import InvoiceEdit from './screens/Invoices/screens/Edit'
import Invoices from './screens/Invoices/screens/List'
import SubscriptionEdit from './screens/Subscriptions/screens/Edit'
import Subscriptions from './screens/Subscriptions/screens/List'

const AccountingRoutes = () => (
  <Routes>
    <Route path="/credits" element={<Credits />} />
    <Route path="/credits/:creditId/edit" element={<CreditEdit />} />
    <Route path="/invoices" element={<Invoices />} />
    <Route path="/invoices/:invoiceId/edit" element={<InvoiceEdit />} />
    <Route path="/subscriptions" element={<Subscriptions />} />
    <Route path="/subscriptions/:subscriptionId/edit" element={<SubscriptionEdit />} />
  </Routes>
)

export default AccountingRoutes

import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltToBottom, faFilePdf } from '@fortawesome/pro-light-svg-icons'

import { Button, Tools } from '@campaignhub/suit-theme'


interface ContactToolsProps {
  callbacks: {
    
  },
}

const ContactTools = (props: ContactToolsProps) => {
  const { callbacks } = props

  return (
    <Tools>
      Contact Tools
    </Tools>
  )
}

export default ContactTools

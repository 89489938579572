import { useMemo } from 'react'

import { sortArrayBy } from '@campaignhub/javascript-utils'
import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ContactTypeModel } from '@models/contactType'

const watchEntityKeys = ['contactTypes']

type UseContactTypesPayload = {
  contactTypes: ContactTypeModel[],
  loading: boolean,
}

interface Options {
  performHttpRequests?: boolean,
}

function useContactTypes(options?: Options): UseContactTypesPayload {
  const { performHttpRequests } = options || {}

  const {
    updatedEntities: { contactTypes: contactTypesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const entities = useSelector(reduxState => reduxState.entities)
  const { contactTypes } = entities || {}

  const sortedContactTypes = useMemo(() => {
    const array = Object.values(contactTypes)

    return sortArrayBy(array, 'asc', 'name')
  }, [contactTypesUpdatedAt])

  useReduxAction(
    'contactTypes',
    'loadContactTypes',
    {},
    [performHttpRequests],
    {
      shouldPerformFn: (entityReducer) => {
        const { loading, loaded, errors } = entityReducer
        return !loading && !loaded && !errors?.length
      },
    },
  )

  const { loading } = useSelector(reduxState => reduxState.contactTypes)

  return {
    contactTypes: sortedContactTypes,
    loading,
  }
}
export default useContactTypes

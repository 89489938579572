import { DashboardModule, Text } from '@campaignhub/suit-theme'

import useContact from '@hooks/useContact'

const Details = (props: { contactId: number }) => {
  const { contactId } = props

  const contactPayload = useContact({ id: contactId }, { performHttpRequests: !!contactId })
  const {
    contact,
    loading,
    urls: {
      editContactDetailsUrl,
    },
  } = contactPayload

  const { email, name, phone } = contact || {}

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column', lineHeight: 1.3, padding: 'large' }}
      headerRight={<DashboardModule.Link href={editContactDetailsUrl}>View All</DashboardModule.Link>}
      loading={loading}
      title="Details"
    >
      <Text>{name}</Text>
      <Text color="bodyFontLightColor" fontSize="small">{email}</Text>
      <Text color="bodyFontLightColor" fontSize="small">{phone}</Text>
    </DashboardModule>
  )
}

export default Details

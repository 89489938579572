import { useEffect } from 'react'

import { toggleArray } from '@campaignhub/javascript-utils'
import { useSetState } from '@campaignhub/react-hooks'

import useContactUsers from '@hooks/useContactUsers'

const defaultState = {
  message: '',
  userIds: [],
}

function useRequestContactPaymentMethod(contactId: number) {
  const [state, setState] = useSetState(defaultState)
  const { userIds } = state

  const contactUsersPayload = useContactUsers({
    filters: {
      contactId,
      isPrimary: !!contactId,
    },
  })
  const { hasContactUsers, loading, primaryContactUser } = contactUsersPayload || {}

  const saveEnabled = !!userIds.length

  // Auto Select Primary Contact User
  useEffect(() => {
    if (primaryContactUser?.id){
      const { userId } = primaryContactUser

      setState({ userIds: toggleArray(userIds, userId) })
    }
  }, [contactId])

  return {
    callbacks: {
      setState,
      toggleRecipient: (userId: number) => setState({ userIds: toggleArray(userIds, userId) }),
    },
    loading,
    hasContactUsers,
    saveEnabled,
    state,
  }
}

export default useRequestContactPaymentMethod

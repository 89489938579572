import { useEffect } from 'react'

import { loadStripe } from '@stripe/stripe-js'

import { useSetState } from '@campaignhub/react-hooks'

import { setupStripePaymentMethod } from '@functions/stripe'

// Load Realbase Stripe Account
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

const defaultState = {
  clientSecret: '',
  setupIntentId: '',
}

type UseSetupStripeProps = {
  contactId: number,
  userId: number,
}

function useSetupStripe(props: UseSetupStripeProps) {
  const { contactId, userId } = props || {}

  const [state, setState] = useSetState(defaultState)
  const { clientSecret, setupIntentId } = state

  useEffect(() => {
    if (contactId){
      setupStripePaymentMethod({ contactId, userId }).then(({ data, errors, success }) => {
        if (!success) return errors

        const {
          clientSecret: stripeClientSecret,
          setupIntentId: stripeSetupIntentId,
        } = data || {}

        setState({ clientSecret: stripeClientSecret, setupIntentId: stripeSetupIntentId })
      })
    }
  }, [contactId])

  return {
    clientSecret,
    setupIntentId,
    stripePromise,
  }
}

export default useSetupStripe

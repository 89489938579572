import { Route, Routes } from 'react-router-dom'

import ContactEdit from './screens/Contacts/screens/Edit'
import Contacts from './screens/Contacts/screens/List'

const AdminRoutes = () => (
  <Routes>
    <Route path="/contacts" element={<Contacts />} />
    <Route path="/contacts/:contactId/edit/*" element={<ContactEdit />} />
  </Routes>
)

export default AdminRoutes

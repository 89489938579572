import { isValidRequiredEntity } from '@campaignhub/javascript-utils'
import { AccountingSystemBillingEntityModel } from './accountingSystemBillingEntity'
import type { BankModel } from './bank'
import type { BusinessNumberModel } from './businessNumber'
import type { BusinessUnitProductModel } from './businessUnitProduct'

export type BusinessUnitRequestOptions = {
  includeBusinessUnitBusinessUnitProducts?: boolean,
  includeBusinessUnitBanks?: boolean, 
  includeBusinessUnitBusinessNumbers?: boolean,
}

export type BusinessUnitModel = {
  accountingSystemBillingEntities?: AccountingSystemBillingEntityModel[],
  accountingSystemId?: number,
  active?: boolean,
  banks?: BankModel[]
  billingEntityId?: number,
  branchCode?: string,
  businessNumbers?: BusinessNumberModel[],
  businessUnitProducts?: BusinessUnitProductModel[],
  countryId: number,
  defaultCreditAccountCode?: string,
  defaultInvoiceAccountCode?: string,
  id: number | null,
  invoicePrefix: string,
  key?: string,
  name?: string,
  taxJurisdictionId: number,
  tradingName?: string,
  totalCredits?: number,
  totalDraftInvoiceAmount?: number,
  totalDraftInvoices?: number,
  totalInvoiceAmount?: number,
  totalInvoices?: number,
  totalPaidInvoiceAmount?: number,
  totalPendingCredits?: number,
  totalPendingCreditsAmount?: number,
}

const state = {
  accountingSystemBillingEntities: [],
  accountingSystemId: null,
  active: true,
  billingEntityId: null,
  branchCode: '',
  banks: [],
  businessNumbers: [],
  businessUnitProducts: [],
  countryId: null,
  defaultCreditAccountCode: '',
  defaultInvoiceAccountCode: '',
  id: null,
  invoicePrefix: '',
  key: '',
  name: '',
  taxJurisdictionId: '',
  tradingName: '',
  totalCredits: null,
  totalDraftInvoiceAmount: null,
  totalDraftInvoices: null,
  totalInvoiceAmount: null,
  totalInvoices: null,
  totalPaidInvoiceAmount: null,
  totalPendingCredits: null,
  totalPendingCreditsAmount: null,
}


export const requiredFields = [
  { key: 'countryId' },
  { key: 'invoicePrefix' },
  { key: 'name' },
  { key: 'tradingName' },
  { key: 'taxJurisdictionId' },
]

export default state

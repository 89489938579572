import { Columns, LoadingModule } from '@campaignhub/suit-theme'

import useBusinessUnit from '@hooks/useBusinessUnit'

import Credits from '@components/Credits'
import PageLayout from '../../../../components/PageLayout'

import Tools from '../Tools'

const PageContent = (props: { businessUnitId: number }) => {
  const { businessUnitId } = props

  const businessUnitPayload = useBusinessUnit({ id: businessUnitId })

  const { callbacks, loading } = businessUnitPayload

  return (
    <PageLayout entityPayload={businessUnitPayload} activeTabBarItemKey="credits">
      <Columns flexDirection={['column', 'column', 'row']}>
        <Columns.Main>
          <LoadingModule loading={loading} />

          <Credits
            options={{
              filters: {
                businessUnitId,
              },
              performHttpRequests: !!businessUnitId,
              requestOptions: {
                includeContact: !!businessUnitId,
                includeTransaction: !!businessUnitId,
              },
            }}
          />
        </Columns.Main>

        <Columns.Sidebar>
          <Tools callbacks={callbacks} />
        </Columns.Sidebar>
      </Columns>
    </PageLayout>
  )
}

export default PageContent

import {
  Box, DashboardModule, NumberWithIconGrid, Text,
} from '@campaignhub/suit-theme'

import useSubscriptionSummary from '@hooks/useSubscriptionSummary'

interface SubscriptionStatisticsProps {
  href?: string,
}

const SubscriptionStatistics = (props?: SubscriptionStatisticsProps) => {
  const { href } = props || {}

  const subscriptionSummaryPayload = useSubscriptionSummary()
  const { loading, subscriptionSummary } = subscriptionSummaryPayload
  const {
    currentCancelledSubscription,
    currentNewSubscription,
    currentRevenue,
    totalSubscription,
  } = subscriptionSummary

  return (
    <DashboardModule
      headerRight={href ? <DashboardModule.Link href={href}>Manage</DashboardModule.Link> : null}
      loading={loading}
      title="Subscriptions"
    >
      <Box flexDirection="column">
        {!loading
        && (
          <NumberWithIconGrid>
            <NumberWithIconGrid.Item
              disableHover
              label="Revenue"
              value={`$${currentRevenue ? currentRevenue.toFixed(2) : 0}`}
            />

            <NumberWithIconGrid.Item
              disableHover
              label="New Subscribers"
              value={currentNewSubscription || 0}
            />

            <NumberWithIconGrid.Item
              disableHover
              label="Total Subscribers"
              value={totalSubscription || 0}
            />

            <NumberWithIconGrid.Item
              disableHover
              label="Cancelled"
              value={<Text color={currentCancelledSubscription > 0 ? 'red' : 'bodyFontColor'}>{currentCancelledSubscription || 0}</Text>}
            />
          </NumberWithIconGrid>
        )}
      </Box>
    </DashboardModule>
  )
}

export default SubscriptionStatistics

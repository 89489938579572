import useConversation from '@hooks/useConversation'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { EntityOptions } from '@redux/modules/types'

type UseFindEntityConversationParams = {
  conversation: {
    subjectId?: number,
    subjectType?: string,
    title?: string,
  },
}

function useFindEntityConversation(params: UseFindEntityConversationParams) {
  const {
    conversation: conversationParams,
    conversation: {
      subjectId: filterSubjectId,
      subjectType: filterSubjectType,
    },
  } = params

  const filteredConversations = useSelector((reduxState) => {
    const array = Object.values(reduxState.entities.conversations)
    const filtered = array.filter((conversation) => {
      const { subjectId, subjectType } = conversation

      return subjectType === filterSubjectType && subjectId === filterSubjectId
    })

    return filtered
  })

  const conversation = filteredConversations[0] || conversationParams

  return {
    conversation,
    hasMultipleConversations: filteredConversations.length > 1,
  }
}

type UseEntityConversationOptions = {
  conversation: {
    subjectId?: number | undefined,
    subjectType?: string,
    title?: string,
  },
  performHttpRequests?: boolean,
  publicCommentsOnly?: boolean,
  requestOptions?: EntityOptions,
}

function useEntityConversation(options: UseEntityConversationOptions) {
  const {
    conversation: conversationParams,
    conversation: {
      subjectId,
      subjectType,
    },
    performHttpRequests,
    requestOptions = {},
  } = options

  const entityConversationPayload = useFindEntityConversation({
    conversation: conversationParams,
  })
  const { conversation } = entityConversationPayload

  const conversationPayload = useConversation(conversation, options)

  const { loading } = useSelector(reduxState => reduxState.conversations)

  // Load Conversation
  const entityKey = `${subjectType}${subjectId}`

  useReduxAction(
    'conversations',
    'loadConversationForEntity',
    {
      subjectId,
      subjectType,
      ...requestOptions,
    },
    [subjectType, subjectId, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(subjectType, subjectId, actionRequestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedForKeys } = entityReducer
        return performHttpRequests && subjectType && subjectId && !loadedForKeys.includes(entityKey)
      },
    },
  )

  const hasConversation = !!conversation.id

  return {
    ...conversationPayload,
    hasConversation,
    loading,
    subjectType,
    subjectId,
  }
}

export default useEntityConversation

import { useEffect } from 'react'
import { useForm, useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'
import type { UseFormOptions, UseFormPayload } from '@campaignhub/react-hooks'

import { default as defaultBusinessUnitState, BusinessUnitModel, requiredFields, BusinessUnitRequestOptions } from '@models/businessUnit'

import * as businessUnitActions from '@redux/modules/businessUnit'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'
import { AppDispatch } from '@redux/store'

import { default as defaultBankState } from "@models/bank"
import { default as defaultBusinessNumberState } from "@models/businessNumber"

type BusinessUnitForm = { entityState: BusinessUnitModel }

export type BusinessUnitFormPayload = UseFormPayload & BusinessUnitForm

interface Options {
  performHttpRequests?: boolean,
  requestOptions?: BusinessUnitRequestOptions,
}

export const generateUrls = (businessUnit: BusinessUnitModel) => {
  const { id } = businessUnit || {}

  return {
    businessUnitCreditsUrl: `#/business-units/${id}/edit/credits`,
    businessUnitDetailsUrl: `#/business-units/${id}/edit/details`,
    businessUnitInvoicesUrl: `#/business-units/${id}/edit/invoices`,
    businessUnitOverviewUrl: `#/business-units/${id}/edit`,
    businessUnitStatementsUrl: `#/business-units/${id}/edit/statements`,
    businessUnitSubscriptionsUrl: `#/business-units/${id}/edit/subscriptions`,
    businessUnitsIndexUrl: '#/business-units',
  }
}

type CreateBusinessUnitParams = {
  businessUnitParams: Partial<BusinessUnitModel>,
  dispatch: AppDispatch,
  requestOptions?: BusinessUnitRequestOptions,
}

const createBusinessUnit = (params: CreateBusinessUnitParams) => {
  const { businessUnitParams, dispatch, requestOptions = {} } = params
  const { createBusinessUnit: createFn } = businessUnitActions

  return dispatch(createFn(businessUnitParams, requestOptions))
}

type UpdateBusinessUnitParams = {
  businessUnitParams: Partial<BusinessUnitModel>,
  dispatch: AppDispatch,
  requestOptions?: BusinessUnitRequestOptions,
}

export const updateBusinessUnit = (params: UpdateBusinessUnitParams) => {
  const { businessUnitParams, dispatch, requestOptions } = params
  const { updateBusinessUnit: updateFn } = businessUnitActions

  return dispatch(updateFn(businessUnitParams, requestOptions))
}

export function useBusinessUnitForm(businessUnit: BusinessUnitModel, options: UseFormOptions = {}): BusinessUnitFormPayload {
  const { validateOn } = options
  const {banks, businessNumbers} = businessUnit

  const businessUnitForm = useForm(
    defaultBusinessUnitState,
    { entity: businessUnit, requiredFields, validateOn },
    [businessUnit.id],
  )

  const { setEntityState } = businessUnitForm
  
  useEffect(() => {
    if (!(businessNumbers && businessNumbers.length > 0)) setEntityState({ businessNumbers: [ defaultBusinessNumberState ] })
    if (!(banks && banks.length > 0)) setEntityState({ banks: [defaultBankState] })
  }, [])

  return {
    ...businessUnitForm,
  }
}

function useBusinessUnit(initEntity: Partial<BusinessUnitModel> = {}, options?: Options) {
  const { performHttpRequests, requestOptions } = options || {}
  
  const { entity: businessUnit } = useLatestEntity(initEntity, 'businessUnits')
  const { id } = businessUnit

  const dispatch = useThunkDispatch()

  useReduxAction(
    'businessUnits', 
    'loadBusinessUnit', 
    {
      includeBusinessUnitBanks: true,
      includeBusinessUnitBusinessNumbers: true,
      ...requestOptions,
    }, 
    [id, performHttpRequests], 
    {
      dispatchAction: (action, requestOptions) => action(id, requestOptions),
      shouldPerformFn: ({ loading }) => performHttpRequests && !loading,
    },
  )

  const { loading, updating } = useSelector(reduxState => reduxState.businessUnits)

  return {
    callbacks: {
      createBusinessUnit: (businessUnitParams: Partial<BusinessUnitModel>) => createBusinessUnit({ businessUnitParams, dispatch }),
      updateBusinessUnit: (businessUnitParams: BusinessUnitModel, requestOptions: BusinessUnitRequestOptions) => updateBusinessUnit({ 
        businessUnitParams, dispatch }),
    },
    businessUnit,
    loading: loading,
    updating,
    urls: generateUrls(businessUnit),
  }
}

export default useBusinessUnit

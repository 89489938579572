import { DashboardModule } from '@campaignhub/suit-theme'

import useCreditSummaryByStatus from '@hooks/useCreditSummaryByStatus'

import CreditSummaryItem from './CreditSummaryItem'

interface CreditsSummaryProps {
  filters?: {
    businessUnitId?: number,
    contactId?: number,
  },
  href?: string,
}

const CreditsSummary = (props: CreditsSummaryProps) => {
  const { filters, href } = props

  const creditsSummaryPayload = useCreditSummaryByStatus({ filters })
  const { creditSummaryItems, loading } = creditsSummaryPayload

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column' }}
      headerRight={href ? (
        <DashboardModule.Link href={href}>
          View All
        </DashboardModule.Link>
      ) : ''}
      loading={loading}
      title="Recent Credits"
    >
      {!loading && creditSummaryItems.map(creditSummaryItem => (
        <CreditSummaryItem
          key={creditSummaryItem.key}
          creditSummaryItem={creditSummaryItem}
        />
      ))}
    </DashboardModule>
  )
}

export default CreditsSummary

import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'

import {
  faCheckCircle, faClock, faLink, faList,
} from '@fortawesome/pro-light-svg-icons'

import {
  Box, Columns,
} from '@campaignhub/suit-theme'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'

import useCredits from '@hooks/useCredits'
import { generateUrls } from '@hooks/useCredit'

import { defaultRequestOptions } from '@sections/Client/packs/Accounting'

import PageFilterModule from '@components/PageFilterModule'
import AdminPageHeader from '@components/AdminPageHeader'

import Credits from '../Credits'
import Tools from '../CreditTools'

const buildPageFilterFields = () => {
  const style = { marginBottom: 16 }
  const boxProps = { marginBottom: 'large' }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'select',
        style,
      },
      key: 'sort',
      label: 'Sort By',
      values: [
        { key: 'asc_dateCreated', label: 'Oldest First' },
        { key: 'desc_dateCreated', label: 'Newest First' },
        { key: 'desc_amount', label: 'Highest Amount First' },
        { key: 'asc_amount', label: 'Lowest Amount First' },
        { key: 'asc_contact.name', label: 'Contact (A-Z)' },
        { key: 'desc_contact.name', label: 'Contact (Z-A)' },
      ],
    },
    {
      component: 'EntitySelector',
      componentProps: {
        boxProps,
        endpoint: '/business-units',
        entityKey: 'businessUnits',
      },
      key: 'businessUnitId',
    },
    {
      components: [
        {
          component: 'DynamicInput',
          componentProps: {
            fieldName: 'minAmount',
            inputType: 'string',
            style,
          },
          key: 'minAmount',
        },
        {
          component: 'DynamicInput',
          componentProps: {
            fieldName: 'maxAmount',
            inputType: 'string',
            style,
          },
          key: 'maxAmount',
        },
      ],
      label: 'Credit Amount',
    },
  ]
}

interface PageContentProps {
  pageFilters: {},
}

const PageContent = (props: PageContentProps) => {
  const { pageFilters } = props

  // useLocation to refresh the window.location. If not used location won't reload if URL changes
  useLocation()

  const { status } = parsePermittedQueryParams(getQueryParamsFromHash(), ['status'])
  const currentStatus = status || 'all'

  const creditsPayload = useCredits({
    filters: {
      ...pageFilters,
      status: currentStatus,
    },
    performHttpRequests: true,
    requestOptions: {
      ...defaultRequestOptions.credit,
    },
  })

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      resetFilters,
      updateFilters,
    },
    defaultFilters,
  } = pageContext

  const { creditsIndexUrl } = generateUrls()

  return (
    <>
      <Helmet>
        <title>Credits | Billing</title>
      </Helmet>

      <AdminPageHeader
        activeTabBarItemKey={currentStatus}
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: '#/accounting/credits',
            icon: faList,
            key: 'all',
            title: 'All',
          },
          {
            href: '#/accounting/credits?status=pending',
            icon: faClock,
            key: 'pending',
            title: 'Pending',
          },
          {
            href: '#/accounting/credits?status=approved',
            icon: faCheckCircle,
            key: 'approved',
            title: 'Approved',
          },
          {
            href: '#/accounting/credits?status=applied',
            icon: faLink,
            key: 'applied',
            title: 'Applied',
          },
        ]}
        title="Credits"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <Credits
              creditsPayload={creditsPayload}
              status={currentStatus}
            />
          </Columns.Main>

          <Columns.Sidebar>
            <Tools creditsPayload={creditsPayload} />

            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              defaultFilters={defaultFilters}
              filterFields={buildPageFilterFields()}
              pageFilters={pageFilters}
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent

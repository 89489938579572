import { DashboardModule, ListItem } from '@campaignhub/suit-theme'

import useBillingNotes from '@hooks/useBillingNotes'
import useContact from '@hooks/useContact'
import useContactUsers from '@hooks/useContactUsers'

import BillingNote from './components/BillingNote'
import Contact from './components/Contact'
import PrimaryContactUser from './components/PrimaryContactUser'

type EntityModel = {
  contactId?: number,
}

const ContactDetails = (props: { entity: EntityModel }) => {
  const { entity } = props || {}
  const { contactId } = entity || {}

  const {
    contact,
    loading: loadingContact,
  } = useContact({ id: contactId }, { performHttpRequests: !!contactId })

  const {
    loading: loadingContactUser,
    primaryContactUser,
  } = useContactUsers({
    filters: {
      contactId,
      isPrimary: true,
    },
    performHttpRequests: !!contactId,
  })

  const {
    latestBillingNote,
    hasFilteredBillingNotes,
    loading: loadingBillingNotes,
  } = useBillingNotes({
    filters: { contactId },
    performHttpRequests: !!contactId,
  })

  const loading = loadingContact && loadingContactUser && loadingBillingNotes

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column', height: '100%', padding: 'large' }}
      loading={loading}
      title="Contact"
    >
      <ListItem disableHover flexDirection={['column', 'row']}>
        <Contact contact={contact} />

        <PrimaryContactUser primaryContactUser={primaryContactUser} />
      </ListItem>

      {hasFilteredBillingNotes && (
        <BillingNote billingNote={latestBillingNote} />
      )}
    </DashboardModule>
  )
}

export default ContactDetails

import { DashboardModule } from '@campaignhub/suit-theme'

import useCredits, { UseCreditsOptions } from '@hooks/useCredits'

import ListBlankState from '@components/ListBlankState'

import CreditsTable from './CreditsTable'

interface CreditsProps {
  options: UseCreditsOptions,
}

const Credits = (props: CreditsProps) => {
  const { options } = props || {}
  const { filters = {} } = options || {}

  const creditsPayload = useCredits(options)
  const { filteredCredits, hasFilteredCredits, loading } = creditsPayload || {}

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column' }}
      loading={loading}
      title="Credits"
    >
      {!loading && !hasFilteredCredits && <ListBlankState title="No Credits" text=" " />}

      {!loading && hasFilteredCredits && (
        <CreditsTable
          filters={filters}
          filteredCredits={filteredCredits}
        />
      )}
    </DashboardModule>
  )
}

export default Credits

import { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltToBottom } from '@fortawesome/pro-light-svg-icons'

import { Button, DashboardModule } from '@campaignhub/suit-theme'

import useContactUsers from '@hooks/useContactUsers'

import type { ContactUserModel } from '@models/types'

import PageContext from '@contexts/pageContext'

import ContactUser from './ContactUser'

const viewOnly = JSON.parse(process.env.REACT_APP_VIEW_ONLY || "false")

interface ContactUsersProps {
  contactId: number,
  isAdmin: boolean,
}

const ContactUsers = (props: ContactUsersProps) => {
  const { contactId, isAdmin } = props || {}

  const pageContext = useContext(PageContext)
  const { callbacks: { showImportUsersModal } } = pageContext

  const contactUsersPayload = useContactUsers({ filters: { contactId } })
  const {
    contactUsersCount,
    filteredContactUsers,
    hasContactUsers,
    loading,
  } = contactUsersPayload

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column' }}
      loading={loading}
      title="Contact Users"
    >
      {!loading && !hasContactUsers && (
        <DashboardModule.BlankState>
          <DashboardModule.BlankState.Title>
            No Contact Users
          </DashboardModule.BlankState.Title>

          <DashboardModule.BlankState.Paragraph>
            No users have been imported for this contact.
          </DashboardModule.BlankState.Paragraph>
        </DashboardModule.BlankState>
      )}

      {!loading && hasContactUsers && (
        <DashboardModule.ScrollableSection>
          {filteredContactUsers.map((contactUser: ContactUserModel) => (
            <ContactUser
              key={contactUser?.id}
              contactUser={contactUser}
              editable={contactUsersCount > 1}
            />
          ))}
        </DashboardModule.ScrollableSection>
      )}

      {!viewOnly && isAdmin && (
        <DashboardModule.Footer>
          <Button
            buttonStyle="secondaryUtility"
            icon={<FontAwesomeIcon icon={faArrowAltToBottom} />}
            iconPosition="left"
            onClick={() => showImportUsersModal()}
            size="medium"
          >
            Import Users
          </Button>
        </DashboardModule.Footer>
      )}
    </DashboardModule>
  )
}

export default ContactUsers

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/pro-light-svg-icons'

import { Button, Tools } from '@campaignhub/suit-theme'

import useContact from '@hooks/useContact'

import { ContactModel } from '@models/types'

interface ContactToolsProps {
  contact: ContactModel,
}

const ContactTools = (props: ContactToolsProps) => {
  const { contact } = props

  const {
    callbacks: {
      generateStatement,
    }
  } = useContact(contact)

  return (
    <Tools>
      <Button
        buttonStyle="secondaryUtility"
        icon={<FontAwesomeIcon icon={faFilePdf} />}
        onClick={() => generateStatement()}
        size="medium"
      >
        Generate Statement
      </Button>
    </Tools>
  )
}

export default ContactTools

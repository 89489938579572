import { useEffect, useState } from 'react'

import { digObject } from '@campaignhub/javascript-utils'
import useSelector from '@hooks/useSelector'

function useTaxJurisdiction(taxJurisdictionId: number) {
  const { entities } = useSelector(reduxState => reduxState)
  
  const { countries } = entities
  const { taxJurisdictions } = entities
  
  const [taxRate, setTaxRate] = useState(0)
  const [currencyPrefix, setCurrencyPrefix] = useState('')

  useEffect(() => {
    const taxJurisdiction = digObject(taxJurisdictions, String(taxJurisdictionId), {})
    const country = digObject(countries, String(taxJurisdiction.countryId), {})
    setTaxRate(country.tax)
    setCurrencyPrefix(country.currency)
  }, [taxJurisdictionId])
  
  return {
    taxRate,
    currencyPrefix,
  }
}

export default useTaxJurisdiction

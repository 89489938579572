import { MainContent, TopBar } from '@campaignhub/suit-theme'

import { ADMIN_SIDEBAR_WIDTH } from '@constants/sizes'

import useMainNavigation from '@hooks/useMainNavigation'

import LoggedInUser from '@components/LoggedInUser'

import MainNavigation from '@sections/Admin/components/MainNavigation'

import DashboardRoutes from './routes'

const Dashboard = () => {
  const { callbacks: { toggleNavigation } } = useMainNavigation()

  return (
    <>
      <TopBar
        callbacks={{ toggleNavigation }}
        loggedInUserComponent={<LoggedInUser />}
        offset={{ left: ADMIN_SIDEBAR_WIDTH }}
        searchComponent={<TopBar.Search callbacks={{ search: null }} />}
      />

      <MainNavigation width={ADMIN_SIDEBAR_WIDTH} />

      <MainContent offset={{ left: ADMIN_SIDEBAR_WIDTH, top: TopBar.topBarHeight }}>
        <DashboardRoutes />
      </MainContent>
    </>
  )
}

export default Dashboard

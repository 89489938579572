import useReduxAction from '@hooks/useReduxAction'

import type { InvoiceSummaryModel } from '@models/invoiceSummary'

type InvoiceSummaryOptions = {
  filters?: {
    businessUnitId?: number,
    contactId?: number,
  },
  performHttpRequests?: boolean,
}

function useInvoiceStatistics(options: InvoiceSummaryOptions) {
  const { filters, performHttpRequests = true } = options || {}

  const invoiceSummaryPayload = useReduxAction(
    'invoiceSummary',
    'fetchInvoiceSummary',
    {
      ...filters,
    },
    [performHttpRequests],
    {
      shouldPerformFn: (entityReducer) => {
        const { loading, errors } = entityReducer
        return !!performHttpRequests && !loading && !errors?.length
      },
    },
  )

  const { result: invoicesSummary, loading }: { result?: InvoiceSummaryModel, loading: boolean } = invoiceSummaryPayload

  return {
    invoicesSummary,
    loading,
  }
}

export default useInvoiceStatistics

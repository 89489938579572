import { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltToBottom, faShare } from '@fortawesome/pro-light-svg-icons'

import { Button, Form, SidebarModal } from '@campaignhub/suit-theme'

import useContactPaymentMethod from '@hooks/useContactPaymentMethod'

import EntitySelector from '@components/EntitySelector'

import PageContext from '@contexts/pageContext'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useRequestContactPaymentMethod from './hooks/useRequestContactPaymentMethod'

import Recipients from './components/Recipients'

const MODAL_KEY = 'RequestPaymentMethodModal'

type ModalCallbacks = {
  closeModal: () => void,
  requestContactPaymentMethod: (payload: HandleCallbackActionParams) => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  contactId: number,
  showModal: boolean,
}

const RequestPaymentMethodModal = (props: ModalProps) => {
  const { callbacks, contactId, showModal } = props
  const { closeModal, requestContactPaymentMethod } = callbacks || {}

  const pageContext = useContext(PageContext)
  const { callbacks: { showImportUsersModal } } = pageContext

  const contactPaymentMethodPayload = useContactPaymentMethod()
  const {
    callbacks: {
      requestContactPaymentMethod: requestFn,
    },
  } = contactPaymentMethodPayload

  const {
    callbacks: {
      setState,
      toggleRecipient,
    },
    hasContactUser,
    saveEnabled,
    state,
    state: {
      message,
      userIds,
    },
  } = useRequestContactPaymentMethod(contactId)

  const requestContactPaymentMethodPayload = {
    callbacks: {
      action: requestFn,
      afterAction: closeModal,
    },
    entityParams: { contactId, ...state },
    toastText: 'Email Sent',
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Request" titleSecondLine="Payment Method" />

      <SidebarModal.Content>
        <Form>
          {!hasContactUser && (
            <Form.Field label="Users">
              <SidebarModal.NoticeBox boxProps={{ marginBottom: 'medium' }}>
                <SidebarModal.NoticeBox.Title>
                  No Contact Users
                </SidebarModal.NoticeBox.Title>

                <SidebarModal.NoticeBox.Paragraph>
                  No users have been imported for this contact.
                </SidebarModal.NoticeBox.Paragraph>

                <SidebarModal.NoticeBox.Paragraph>
                  Import a user to manage this contact and add a payment method.
                </SidebarModal.NoticeBox.Paragraph>
              </SidebarModal.NoticeBox>
            </Form.Field>
          )}

          {hasContactUser && (
            <>
              <EntitySelector
                callbacks={{
                  selectItem: user => (user?.id ? toggleRecipient(user.id) : null),
                }}
                entityKey="users"
                entityTitleKey="fullName"
                label="Search Users"
                showSelectedEntity={false}
                selectedIds={userIds}
              />

              <Recipients
                callbacks={{ toggleRecipient }}
                userIds={userIds}
              />

              <Form.Field label="Message" marginTop="large">
                <textarea
                  name="message"
                  onChange={e => setState({ message: e.target.value })}
                  style={{ height: 120 }}
                  type="text"
                  value={message}

                />
              </Form.Field>
            </>
          )}
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        {!hasContactUser && (
          <Button
            buttonStyle="secondaryUtility"
            icon={<FontAwesomeIcon icon={faArrowAltToBottom} />}
            iconPosition="left"
            onClick={() => showImportUsersModal()}
            size="large"
          >
            Import Users
          </Button>
        )}

        {hasContactUser && (
          <Button
            buttonStyle="primaryCreate"
            disabled={!saveEnabled}
            icon={<FontAwesomeIcon icon={faShare} />}
            onClick={() => requestContactPaymentMethod(requestContactPaymentMethodPayload)}
            size="large"
          >
            {saveEnabled ? 'Send via Email' : 'Complete Fields'}
          </Button>
        )}
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <RequestPaymentMethodModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal

import { useContext } from 'react'
import Swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck, faPencil, faPlus, faTimes,
} from '@fortawesome/pro-light-svg-icons'

import { Button, DashboardModule } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useSelector from '@hooks/useSelector'

import { getStatusIdByKey } from '@functions/status'

import { swalColors } from '@constants/swal'

import type { EntitiesState } from '@redux/entities'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'
import type { InvoicePayload } from '@hooks/useInvoice'
import type { TransactionFormPayload, TransactionPayload } from '@hooks/useTransaction'
import type { TransactionModel } from '@models/types'

type SwalConfirmParams = {
  action: (payload: HandleCallbackActionParams) => void,
  actionPayload: HandleCallbackActionParams,
  buttonColor: string,
  buttonText: string,
  icon: string,
  text: string,
  title: string,
}

const confirmAction = (params: SwalConfirmParams) => {
  const {
    action,
    actionPayload,
    buttonColor,
    buttonText,
    icon,
    text,
    title,
  } = params

  // TODO: Do we want to redirect to Invoices List Screen after successfully voiding an invoice?

  Swal
    .fire({
      confirmButtonColor: buttonColor,
      confirmButtonText: buttonText,
      icon,
      showCancelButton: true,
      text,
      title,
    })
    .then(({ value }) => {
      if (value){
        action(actionPayload)
      }
    })
}

interface ActionsProps {
  invoicePayload: InvoicePayload,
  transactionPayload: TransactionPayload,
  transactionFormPayload: TransactionFormPayload
}

const Actions = (props: ActionsProps) => {
  const { invoicePayload, transactionPayload, transactionFormPayload  } = props
  const {
    callbacks: {
      editInvoice,
      updateInvoice: updateFn,
    },
    editing,
    invoice,
  } = invoicePayload
  const { number } = invoice

  const {
    callbacks: {
      updateOverrideTotal :  updateOverrideFn
    },
    transaction
  } = transactionPayload

  const { businessUnitId, id: transactionId } = transaction || {}

  const pageContext = useContext(PageContext)
  
  const { callbacks: { showCreateTransactionItemModal, updateInvoice, updateOverrideTotal } } = pageContext

  const { approval, deletion } = swalColors

  const { statuses } = useSelector(reduxState => reduxState.entities)

  const approvedStatusId = getStatusIdByKey('approved', statuses)

  const voidStatusId = getStatusIdByKey('void', statuses)

  const { entityState, entityState: { overrideTotal } } = transactionFormPayload
 
  const updateOverrideTotalPayload = {
    action: updateOverrideTotal,
    callbacks: {
      afterAction: editInvoice,
      action: updateOverrideFn,
    },
    entityParams: entityState,
    toastText: 'Invoice Updated',
  }

  return (
    <DashboardModule.Footer boxProps={{ flexDirection: ['column', 'row'] }}>
      {!editing && (
        <Button
          buttonStyle="secondaryUtility"
          icon={<FontAwesomeIcon icon={faTimes} />}
          onClick={() => confirmAction(
            {
              action: updateInvoice,
              actionPayload: {
                callbacks: {
                  action: updateFn,
                },
                entityParams: {
                  ...invoice,
                  statusId: voidStatusId,
                },
                toastText: 'Invoice Voided',
              },
              buttonColor: deletion,
              buttonText: 'Void Invoice',
              icon: 'error',
              text: `Confirming you are about to void ${!number ? 'this' : ''} Invoice
                  ${number && `#${number}`}. This cannot be undone.`,
              title: 'Void Invoice?',
            },
          )}
          size="medium"
          width={['100%', 'auto']}
        >
          Void Invoice
        </Button>
      )}

      {editing && (
        <Button
          buttonStyle="ghostEdit"
          icon={<FontAwesomeIcon icon={faPlus} />}
          onClick={() => showCreateTransactionItemModal({
            businessUnitId,
            transactionItem: {
              transactionId,
            },
          })}
          size="medium"
          width={['100%', 'auto']}
        >
          Add Item
        </Button>
      )}

      <DashboardModule.FooterRight boxProps={{ flexDirection: ['column', 'row'] }}>
        <Button
          buttonStyle="secondaryUtility"
          icon={<FontAwesomeIcon icon={editing ? faTimes : faPencil} />}
          marginTop={['medium', 0]}
          onClick={() => editInvoice()}
          size="medium"
          width={['100%', 'auto']}
        >
          {editing ? 'Cancel' : 'Edit Invoice'}
        </Button>

        {!editing && (
          <Button
            buttonStyle="primaryCreate"
            disabled={editing}
            height="37.5px"
            icon={<FontAwesomeIcon icon={faCheck} />}
            marginLeft={[0, 'medium']}
            marginTop={['medium', 0]}
            onClick={() => confirmAction(
              {
                action: updateInvoice,
                actionPayload: {
                  callbacks: {
                    action: updateFn,
                  },
                  entityParams: {
                    ...invoice,
                    statusId: approvedStatusId,
                  },
                  toastText: 'Invoice Approved',
                },
                buttonColor: approval,
                buttonText: 'Approve Invoice',
                icon: 'success',
                text: `Confirming you are about to approve ${!number ? 'this' : ''} Invoice
                    ${number && `#${number}`}. This cannot be undone.`,
                title: 'Approve Invoice?',
              },
            )}
            size="medium"
            width={['100%', 'auto']}
          >
            Approve
          </Button>
        )}

        {/* TODO: Add Route for OverrideTotal */}
        {editing && (
          <Button
            buttonStyle="primaryCreate"
            marginLeft={[0, 'medium']}
            marginTop={['medium', 0]}
            onClick={() => updateOverrideTotal(updateOverrideTotalPayload)}
            size="medium"
            width={['100%', 'auto']}
            disabled={!overrideTotal}
          >
            Save Invoice
          </Button>
        )}
      </DashboardModule.FooterRight>
    </DashboardModule.Footer>
  )
}

export default Actions

import { formatMoney } from 'accounting'

import {
  DashboardModule, Form, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import { digObject, formatDate } from '@campaignhub/javascript-utils'

import { getInvoiceStatusDisplayKey } from '@functions/invoice'
import { statusColor } from '@functions/status'

import useSelector from '@hooks/useSelector'
import useTransaction from '@hooks/useTransaction'

import type { CreditPayload } from '@hooks/useCredit'

const CreditDetails = (props: { creditPayload: CreditPayload }) => {
  const { creditPayload } = props
  const { credit, loading: loadingCredit } = creditPayload || {}

  const { remainingCredit, transactionId } = credit || {}

  const transactionPayload = useTransaction({ id: transactionId })
  const {
    transaction: {
      businessUnitId,
      countryId,
      dateCreated,
      statusId,
    },
    loading: loadingTransaction,
  } = transactionPayload

  const { businessUnits, countries, statuses } = useSelector(reduxState => reduxState.entities)

  const businessUnit = digObject(businessUnits, String(businessUnitId), {})
  const { name: businessUnitName } = businessUnit

  const country = digObject(countries, String(countryId), {})
  const { currency } = country

  const status = digObject(statuses, String(statusId), {})
  const { key, name } = status

  const loading = loadingCredit && loadingTransaction

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column' }}
      loading={loading}
      title="Credit Details"
    >
      {!loading && (
        <Form padding="large">
          <Form.Row alignItems="center" justifyContent="space-between">
            <Text color="bodyFontLightColor" fontSize="small">Date Requested</Text>

            <Text fontSize="small" textAlign="right">
              {dateCreated && formatDate(dateCreated, 'ISO8601', 'dd LLLL yyyy')}
            </Text>
          </Form.Row>

          <Form.Row alignItems="center" justifyContent="space-between" marginTop="large">
            <Text color="bodyFontLightColor" fontSize="small">Status</Text>

            <StatusBadge
              boxProps={{ justifySelf: 'end' }}
              color={statusColor(key)}
              ghost
              text={name}
            />
          </Form.Row>

          {remainingCredit && (
            <Form.Row alignItems="center" justifyContent="space-between" marginTop="large">
              <Text color="bodyFontLightColor" fontSize="small">Remaining Credit</Text>

              <Text fontSize="small" textAlign="right">{formatMoney(remainingCredit)}</Text>
            </Form.Row>
          )}

          <Form.Row alignItems="center" justifyContent="space-between" marginTop="large">
            <Text color="bodyFontLightColor" fontSize="small">Currency</Text>

            <Text fontSize="small" textAlign="right">{currency}</Text>
          </Form.Row>

          <Form.Row alignItems="center" justifyContent="space-between" marginTop="large">
            <Text color="bodyFontLightColor" fontSize="small">Platform</Text>

            <Text fontSize="small" textAlign="right">{businessUnitName}</Text>
          </Form.Row>
        </Form>
      )}
    </DashboardModule>
  )
}

export default CreditDetails

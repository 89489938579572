import { useContext } from 'react'

import { Columns } from '@campaignhub/suit-theme'

import useBusinessUnit from '@hooks/useBusinessUnit'

import DraftInvoices from '@components/DraftInvoices'
import InvoiceStatistics from '@components/InvoiceStatistics'
import ProcessedInvoices from '@components/ProcessedInvoices'

import PageContext from '@contexts/pageContext'

import PageLayout from '../../../../components/PageLayout'

import Tools from '../Tools'

interface PageContentProps {
  businessUnitId: number,
}

const PageContent = (props: PageContentProps) => {
  const { businessUnitId } = props

  const businessUnitPayload = useBusinessUnit({ id: businessUnitId })
  const { callbacks } = businessUnitPayload

  const pageContext = useContext(PageContext)
  const {} = pageContext

  return (
    <PageLayout entityPayload={businessUnitPayload} activeTabBarItemKey="invoices">
      <Columns flexDirection={['column', 'column', 'row']}>
        <Columns.Main>
          <DraftInvoices
            filters={{ businessUnitId, status: 'draft' }}
            performHttpRequests={!!businessUnitId}
            requestOptions={{ includeInvoiceContact: !!businessUnitId }}
          />

          <ProcessedInvoices
            filters={{ businessUnitId, status: 'processed' }}
            performHttpRequests={!!businessUnitId}
            requestOptions={{ includeInvoiceContact: !!businessUnitId }}
          />
        </Columns.Main>

        <Columns.Sidebar>
          {/* <Tools callbacks={callbacks} /> */}

          <InvoiceStatistics filters={{ businessUnitId }} />
        </Columns.Sidebar>
      </Columns>
    </PageLayout>
  )
}

export default PageContent

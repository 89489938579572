import { Box, DashboardModule } from '@campaignhub/suit-theme'

import useContactsInvoiceSummary from '@hooks/useContactsInvoiceSummary'

import ListBlankState from '@components/ListBlankState'

import ContactListItem from './components/ContactListItem'

const OverdueAccounts = () => {
  const contactsInvoiceSummaryPayload = useContactsInvoiceSummary({
    filters: { status: 'overdue' },
    performHttpRequests: true,
  })
  const {
    contactsInvoiceSummary,
    hasSummary,
    loading,
  } = contactsInvoiceSummaryPayload

  return (
    <DashboardModule
      loading={loading}
      title="Contacts with Overdue Invoices"
    >
      <Box flexDirection="column">

        {!loading && !hasSummary && <ListBlankState />}

        {!loading && contactsInvoiceSummary.map(summary => (
          <ContactListItem key={summary.contactId} contact={summary} />
        ))}
      </Box>
    </DashboardModule>
  )
}

export default OverdueAccounts

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons'

import { Button, Tools } from '@campaignhub/suit-theme'

interface StatementToolsProps {
  callbacks: {},
}

const StatementTools = (props: StatementToolsProps) => {
  const { callbacks } = props

  return (
    <Tools>
      <Button
        buttonStyle="secondaryUtility"
        icon={<FontAwesomeIcon icon={faExclamationCircle} />}
        iconPosition="left"
        onClick={() => console.log('Statement Tool Button Click')}
        size="medium"
      >
        Statement Tool Button
      </Button>
    </Tools>
  )
}

export default StatementTools

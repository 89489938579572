import { DashboardModule, DashboardModuleListItem } from '@campaignhub/suit-theme'

import { generateRedirectUrl } from '@campaignhub/javascript-utils'

import useContact from '@hooks/useContact'
import useContactType from '@hooks/useContactType'

const ParentContact = (props: { parentContactId: number }) => {
  const { parentContactId } = props || {}

  const parentContactPayload = useContact({ id: parentContactId })
  const { contact, loading, urls } = parentContactPayload
  const { contactTypeId, name: parentContactName } = contact || {}
  const { editContactUrl } = urls || {}

  const contactTypePayload = useContactType({ id: contactTypeId })
  const { contactType } = contactTypePayload || {}
  const { name: parentContactTypeName } = contactType || {}

  return (
    <DashboardModule
      loading={loading}
      title={parentContactTypeName || 'Parent Contact'}
    >
      <DashboardModuleListItem
        title={parentContactName}
      >
        <DashboardModuleListItem.Actions>
          <DashboardModuleListItem.Icon
            href={`${editContactUrl}?redirect=${generateRedirectUrl()}`}
            marginRight="medium"
            type="link"
          />
        </DashboardModuleListItem.Actions>
      </DashboardModuleListItem>
    </DashboardModule>
  )
}

export default ParentContact

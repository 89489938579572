import { useContext } from 'react'

import { useForm, useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import * as subscriptionItemActions from '@redux/modules/subscriptionItem'

import PageContext from '@contexts/pageContext'

import useBillingTypes from '@hooks/useBillingTypes'
import usePriceTotal from '@hooks/usePriceTotal'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import { default as defaultState, SubscriptionItemModel, requiredFields } from '@models/subscriptionItem'

import type { BillingTypeModel } from '@models/billingType'
import type { DeleteParams } from '@redux/modules/types'
import type { AppDispatch } from '@redux/store'

interface Options {
  performHttpRequests?: boolean,
  requestOptions: {
    [key: string]: boolean,
  },
}

export const generateUrls = (subscriptionItem?: SubscriptionItemModel) => {
  const { id } = subscriptionItem || {}

  return {
    editSubscriptionItemUrl: `#/accounting/subscription-items/${id}/edit`,
    subscriptionItemsIndexUrl: '#/accounting/subscription-items',
  }
}

type CreateSubscriptionItemParams = {
  dispatch: AppDispatch,
  entityParams: Partial<SubscriptionItemModel>,
}

const createSubscriptionItem = (params: CreateSubscriptionItemParams) => {
  const { entityParams, dispatch } = params
  const { createSubscriptionItem: createFn } = subscriptionItemActions

  return dispatch(createFn(entityParams))
}

type UpdateSubscriptionItemParams = {
  dispatch: AppDispatch,
  subscriptionItem: SubscriptionItemModel,
  entityParams: Partial<SubscriptionItemModel>,
}

export const updateSubscriptionItem = (params: UpdateSubscriptionItemParams) => {
  const { entityParams, dispatch } = params
  const { updateSubscriptionItem: updateFn } = subscriptionItemActions

  return dispatch(updateFn(entityParams))
}

type DeleteSubscriptionItemParams = {
  dispatch: AppDispatch,
  subscriptionItem: DeleteParams<SubscriptionItemModel>,
}

const deleteSubscriptionItem = (params: DeleteSubscriptionItemParams) => {
  const { subscriptionItem, dispatch } = params
  const { deleteSubscriptionItem: deleteFn } = subscriptionItemActions

  return dispatch(deleteFn(subscriptionItem))
}

type EditSubscriptionItemParams = {
  subscriptionItem: SubscriptionItemModel,
  showEditSubscriptionItemModal: (payload: { subscriptionItem: SubscriptionItemModel }) => void,
}

const editSubscriptionItem = (params: EditSubscriptionItemParams) => new Promise((resolve, reject) => {
  const { subscriptionItem, showEditSubscriptionItemModal } = params

  if (showEditSubscriptionItemModal){
    const payload = {
      subscriptionItem,
    }

    showEditSubscriptionItemModal(payload)

    resolve({ success: true, result: payload })
  }

  reject(new Error('showEditSubscriptionItemModal not defined in PageContext callbacks'))
})

export function useSubscriptionItemForm(subscriptionItem: SubscriptionItemModel, options: UseFormOptions = {}) {
  const { validateOn } = options

  const subscriptionItemForm = useForm(
    defaultState,
    { entity: subscriptionItem, requiredFields, validateOn },
    [subscriptionItem.cacheKey],
  )

  return {
    ...subscriptionItemForm,
  }
}

export const useRelations = (subscriptionItem: SubscriptionItemModel) => {
  const { businessUnitProductId, priceId, subscriptionId } = subscriptionItem || {}

  const {
    businessUnitProducts, businessUnits, prices, subscriptions,
  } = useSelector(reduxState => reduxState.entities)

  const businessUnitProduct = businessUnitProductId
  && businessUnitProducts[businessUnitProductId] ? businessUnitProducts[businessUnitProductId] : {}
  const price = priceId && prices[priceId] ? prices[priceId] : {}
  const subscription = subscriptionId && subscriptions[subscriptionId] ? subscriptions[subscriptionId] : {}

  const { businessUnitId } = businessUnitProduct
  const businessUnit = businessUnitId && businessUnits[businessUnitId] ? businessUnits[businessUnitId] : {}

  return {
    businessUnit,
    businessUnitProduct,
    price,
    subscription,
  }
}

function useSubscriptionItem(initEntity: SubscriptionItemModel = { id: null }, options?: Options) {
  const { performHttpRequests, requestOptions } = options || {}

  const { entity: subscriptionItem } = useLatestEntity(initEntity, 'subscriptionItems')
  const { id, priceId, qty } = subscriptionItem

  const dispatch = useThunkDispatch()

  const { callbacks } = useContext(PageContext)
  const { showEditSubscriptionItemModal } = callbacks || {}

  const billingTypesPayload = useBillingTypes({ performHttpRequests: true })
  const { filteredBillingTypes } = billingTypesPayload

  const subscriptionBillingType = filteredBillingTypes.find(
    (billingType: BillingTypeModel) => billingType.key === 'subscription',
  ) || {}

  const { total } = usePriceTotal(priceId, qty)

  useReduxAction(
    'subscriptionItems',
    'loadSubscriptionItem',
    {
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, requestOptions) => action(id, requestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedIds } = entityReducer
        return performHttpRequests && !loadedIds.includes(id)
      },
    },
  )

  const { loading } = useSelector(reduxState => reduxState.subscriptionItems)

  return {
    callbacks: {
      createSubscriptionItem: (entityParams: SubscriptionItemModel) => (
        createSubscriptionItem({ entityParams, dispatch })
      ),
      deleteSubscriptionItem: () => deleteSubscriptionItem({ subscriptionItem, dispatch }),
      editSubscriptionItem: () => editSubscriptionItem({ subscriptionItem, showEditSubscriptionItemModal }),
      updateSubscriptionItem: (entityParams: SubscriptionItemModel) => (
        updateSubscriptionItem({ subscriptionItem, entityParams, dispatch })
      ),
    },
    loading,
    subscriptionBillingType,
    subscriptionItem,
    total,
    urls: generateUrls(subscriptionItem),
  }
}

export default useSubscriptionItem

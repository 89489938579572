import deepmerge from 'deepmerge'

import { EntitySelector as EntitySelectorBase } from '@campaignhub/suit-theme'
import type { EntitySelectorProps as EntitySelectorBaseProps, FormFieldProps } from '@campaignhub/suit-theme'

import searchEndpoint from '@functions/search'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { RootReducerState } from '@redux/modules'

import defaultEntitySettings from './entitySettings'

type LocalEntitySelectorProps = {
  customEntitySettings: Pick<EntitySelectorBaseProps, 'entitySettings'>,
}

type EntitySelectorProps = Omit<EntitySelectorBaseProps, 'entities' | 'entitySettings' | 'useReduxAction'>
& Partial<FormFieldProps> & LocalEntitySelectorProps

const EntitySelector = (props: EntitySelectorProps) => {
  const {
    callbacks,
    customEntitySettings,
    entityTitleKey = 'name',
    ...restProps
  } = props

  const entities = useSelector((reduxState: RootReducerState) => reduxState.entities)

  const entitySettings = customEntitySettings ? deepmerge(defaultEntitySettings, customEntitySettings)
    : defaultEntitySettings

  return (
    <EntitySelectorBase
      callbacks={{ ...callbacks, search: searchEndpoint }}
      entities={entities}
      entitySettings={entitySettings}
      entityTitleKey={entityTitleKey}
      useReduxAction={useReduxAction}
      {...restProps}
    />
  )
}

export default EntitySelector

export default {
  activity: {
    includeActivityOwner: true,
  },
  comment: {
    includeCommentUser: true,
  },
  conversation: {
    includeConversationComments: true,
    includeConversationSubscribers: true,
  },
  subscriber: {
    includeSubscriberUser: true,
  },
}

import { formatMoney } from 'accounting'

import { DashboardModuleListItem } from '@campaignhub/suit-theme'
import { generateRedirectUrl } from '@campaignhub/javascript-utils'

import useSubscription from '@hooks/useSubscription'

import { displaySubscriptionInterval } from '@functions/subscription'

import type { SubscriptionModel } from '@models/subscription'

interface SubscriptionProps {
  subscription: SubscriptionModel,
}

const Subscription = (props: SubscriptionProps) => {
  const {
    subscription: {
      id: subscriptionId,
    },
  } = props

  const {
    subscription: {
      incTaxAmount,
      interval,
      title,
    },
    urls: {
      editSubscriptionUrl,
    },
  } = useSubscription({ id: subscriptionId })

  return (
    <DashboardModuleListItem
      href={`${editSubscriptionUrl}?redirect=${generateRedirectUrl()}`}
      title={title || 'Subscription Title'}
      secondaryText={displaySubscriptionInterval(interval)}
    >
      <DashboardModuleListItem.TertiaryText>
        {formatMoney(incTaxAmount)}
      </DashboardModuleListItem.TertiaryText>
    </DashboardModuleListItem>
  )
}

export default Subscription

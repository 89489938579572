import { digObject } from '@campaignhub/javascript-utils'
import type { Entity } from '@campaignhub/react-hooks'

// sortKey examples 'asc_subscriptionStart' || 'desc_contact.name'
export const getSortKey = (sort: string, defaultSortKey: string) => {
  if (!sort || sort == null || typeof sort !== 'string') return defaultSortKey

  const sortKey = sort.split('_')[1] || sort // 'subscriptionStart' || 'contact.name'
  const isDigSortKey = sortKey.includes('.') // i.e. 'contact.name'

  // param from/passing this function into sortArrayBy
  const digSortKeyFn = (entity: Entity) => digObject(entity, sortKey) // i.e. digObject(subscription, 'contact.name')

  return isDigSortKey ? digSortKeyFn : sortKey
}

import { useMemo } from 'react'

import { matchFilterNumber, sortArrayBy } from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import { SubscriptionTransactionModel } from '@models/subscriptionTransaction'

const watchEntityKeys = ['subscriptionTransactions']

type Options = {
  filters: {
    subscriptionId: number,
    transactionId: number,
  },
  performHttpRequests: boolean,
}

function useSubscriptionTransactions(options: Options): {
  filteredSubscriptionTransactions: SubscriptionTransactionModel[],
  hasFilteredSubscriptionTransactions: boolean,
  loading: boolean,
} {
  const { filters, performHttpRequests } = options || {}
  const {
    subscriptionId: filterSubscriptionId,
    transactionId: filterTransactionId,
  } = filters

  const {
    updatedEntities: { subscriptionTransactions: subscriptionTransactionsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { loading } = useSelector(reduxState => reduxState.subscriptionTransactions)

  const entities = useSelector(reduxState => reduxState.entities)
  const { subscriptionTransactions } = entities || {}

  const filteredSubscriptionTransactions = useMemo(() => {
    const filtered = Object.values(subscriptionTransactions).filter((subscriptionTransaction) => {
      const { subscriptionId, transactionId } = subscriptionTransaction

      const matchSubscription = matchFilterNumber(subscriptionId, filterSubscriptionId)
      const matchTransaction = matchFilterNumber(transactionId, filterTransactionId)

      return (
        matchSubscription
				&& matchTransaction
      )
    })

    return sortArrayBy(filtered, 'asc', 'name')
  }, [subscriptionTransactionsUpdatedAt, JSON.stringify(filters)])

  useReduxAction(
    'subscriptionTransactions',
    'loadSubscriptionTransactions',
    {
      ...filters,
    },
    [performHttpRequests],
    {
    	shouldPerformFn: (entityReducer) => {
        const { loading, loaded, errors } = entityReducer
        return performHttpRequests && !loading && !loaded && !errors.length
      },
    },
  )

  return {
    filteredSubscriptionTransactions,
    hasFilteredSubscriptionTransactions: !!filteredSubscriptionTransactions.length,
    loading,
  }
}
export default useSubscriptionTransactions

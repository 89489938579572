import { useMemo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/pro-light-svg-icons'

import { Link, Table, Text } from '@campaignhub/suit-theme'

import { formatDate } from '@campaignhub/javascript-utils'

import SubscriptionTransactionModel from '@hmodels/subscriptionTransaction'

const buildColumns = (params: { [key: string]: any }) => {
  const { editing, filteredSubscriptionTransactions } = params

  return [
    {
      cellProps: {
        key: 'date',
        width: 'fit-content',
      },
      dataKey: 'date',
      title: 'Date',
      render: date => (
        <Text fontSize="xsmall" variant="ellipsis">{date}</Text>
      ),
    },
    {
      cellProps: {
        key: 'products',
        width: 'fit-content',
      },
      dataKey: 'products',
      title: 'Products',
      render: products => (
        <Text fontSize="xsmall">{products}</Text>
      ),
    },
    {
      cellProps: {
        key: 'paymentMethod',
        width: 'fit-content',
      },
      dataKey: 'paymentMethod',
      title: 'Payment Method',
      render: paymentMethod => (
        <Text fontSize="xsmall" variant="ellipsis">{paymentMethod}</Text>
      ),
    },
    {
      cellProps: {
        key: 'amount',
        textAlign: 'right',
        width: 'fit-content',
      },
      dataKey: 'amount',
      title: 'Amount ($)',
      render: amount => (
        <Text fontSize="xsmall">{amount}</Text>
      ),
    },
    {
      cellProps: {
        key: 'download',
        verticalAlign: 'middle',
        width: '50px',
      },
      dataKey: 'download',
      render: (dataKey: string, subscriptionTransaction: SubscriptionTransactionModel) => {
        const { id } = subscriptionTransaction

        // callback from useTransactionItem hook to download the transaction receipt

        return (
          <Link greyLink onClick={() => console.log('Download Transaction Invoice PDF')}>
            <FontAwesomeIcon icon={faDownload} />
          </Link>
        )
      },
    },
  ]
}

const mapItems = (subscriptionTransactions: SubscriptionTransactionModel[]) => {
  const mappedSubscriptionTransactions = subscriptionTransactions.map((subscriptionTransaction) => {
    const { subscription, transaction } = subscriptionTransaction || {}

    const { paymentMethod, subscriptionItems } = subscription || {}
    const { code } = paymentMethod || {}

    const { dateCreated, incTaxTotal } = transaction || {}

    return ({
      amount: incTaxTotal?.toFixed(2),
      date: dateCreated ? formatDate(dateCreated, 'ISO8601', 'EEEE DD') : '',
      paymentMethod: code,
      products: subscriptionItems?.length,
    })
  })

  return mappedSubscriptionTransactions
}

const TransactionsTable = (props: { subscriptionTransactionsPayload: SubscriptionTransactionModel }) => {
  const { subscriptionTransactionsPayload } = props || {}
  const { filteredSubscriptionTransactions } = subscriptionTransactionsPayload || {}

  const memoColumns = useMemo(() => buildColumns({ filteredSubscriptionTransactions }), [filteredSubscriptionTransactions])

  return (
    <Table
      boxProps={{
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderLeft: '0px',
        borderRight: '0px',
      }}
      columns={memoColumns}
      data={mapItems(filteredSubscriptionTransactions)}
      scroll={{ x: 0 }}
    />
  )
}

export default TransactionsTable

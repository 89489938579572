import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/pro-light-svg-icons'

import { Button, DashboardModule, Form } from '@campaignhub/suit-theme'

import type { ContactFormPayload } from '@hooks/useContact'

import EntitySelector from '@components/EntitySelector'

const viewOnly = JSON.parse(process.env.REACT_APP_VIEW_ONLY || 'false')

interface DetailsProps {
  contactFormPayload: ContactFormPayload,
  contactTypeName: string,
  editInRealbaseUrl: string,
}

const Details = (props: DetailsProps) => {
  const { contactFormPayload, contactTypeName, editInRealbaseUrl } = props
  const {
    entityState: {
      businessNumber,
      email,
      name,
      parentContactId,
      phone,
      tradingName,
      website,
    },
    errors,
    setEntityState,
    handlers,
  } = contactFormPayload

  return (
    <DashboardModule contentBoxProps={{ flexDirection: 'column' }} title="Contact Details">
      <Form padding="large">
        <Form.Row>
          <Form.Field errorMessage={errors.contactTypeId} label="* Contact Type">
            <input
              disabled
              name="contactTypeName"
              value={contactTypeName}
              type="text"
              {...handlers}
            />
          </Form.Field>

          <EntitySelector
            boxProps={{ marginTop: ['large', 0] }}
            callbacks={{
              selectItem: contact => setEntityState({ parentContactId: contact ? contact.id : null }),
            }}
            entityKey="contacts"
            label="Parent Contact"
            selectedItemId={parentContactId}
          />
        </Form.Row>

        <Form.Row marginTop="large">
          <Form.Field errorMessage={errors.name} label="* Name">
            <input
              disabled
              name="name"
              value={name}
              type="text"
              {...handlers}
            />
          </Form.Field>

          <Form.Field label="Trading Name" marginTop={['large', 0]}>
            <input
              disabled
              name="tradingName"
              value={tradingName}
              type="text"
              {...handlers}
            />
          </Form.Field>
        </Form.Row>

        <Form.Row marginTop="large">
          <Form.Field errorMessage={errors.email} label="* Email">
            <input
              disabled
              name="email"
              value={email}
              type="text"
              {...handlers}
            />
          </Form.Field>

          <Form.Field errorMessage={errors.phone} label="* Phone" marginTop={['large', 0]}>
            <input
              disabled
              name="phone"
              value={phone}
              type="text"
              {...handlers}
            />
          </Form.Field>
        </Form.Row>

        <Form.Row marginTop="large">
          <Form.Field errorMessage={errors.website} label="* Website">
            <input
              disabled
              name="website"
              value={website}
              type="text"
              {...handlers}
            />
          </Form.Field>

          <Form.Field errorMessage={errors.businessNumber} label="* Business Number" marginTop={['large', 0]}>
            <input
              disabled
              name="businessNumber"
              value={businessNumber}
              type="text"
              {...handlers}
            />
          </Form.Field>
        </Form.Row>
      </Form>

      <DashboardModule.Footer boxProps={{ borderTop: '1px solid' }}>
        {!viewOnly && (
          <DashboardModule.FooterRight>
            <Button
              buttonStyle="secondaryEdit"
              icon={<FontAwesomeIcon icon={faEdit} />}
              as="a"
              href={editInRealbaseUrl}
              size={['medium', 'small']}
              width={['100%', 'fit-content']}
            >
              Edit in Realbase
            </Button>
          </DashboardModule.FooterRight>
        )}
      </DashboardModule.Footer>
    </DashboardModule>
  )
}

export default Details

import React, { useMemo } from 'react'

import {
  Link, StatusBadge, Table, Text,
} from '@campaignhub/suit-theme'

import { digObject, goToEntity } from '@campaignhub/javascript-utils'

import statusColor from '@functions/status'

import { generateUrls as generateContactUrls } from '@hooks/useContact'
import { generateUrls as generateCreditUrls } from '@hooks/useCredit'
import { CreditsPayload } from '@hooks/useCredits'
import useSelector from '@hooks/useSelector'

import { CreditModel } from '@models/types'
import { EntitiesState } from '@redux/entities'

import { formatMoney } from 'accounting'

interface CreditsTableProps {
  creditsPayload: CreditsPayload,
  statusKey: string,
}

type BuildColumnsParams = CreditsTableProps & {
  entities: EntitiesState,
}

const buildColumns = (props: BuildColumnsParams) => {
  const { entities } = props || {}
  const {
    businessUnits, contacts, statuses, transactions,
  } = entities || {}

  const tableColumns = [
    {
      cellProps: {
        key: 'statusId',
        style: {
          verticalAlign: 'middle',
        },
      },
      title: 'Status',
      dataKey: 'statusId',
      render: (statusId: number) => {
        const status = digObject(statuses, String(statusId), {})
        const { key, name } = status

        return (
          <StatusBadge color={statusColor(key)} ghost text={name} />
        )
      },
    },
    {
      cellProps: {
        key: 'creditNumber',
        style: {
          verticalAlign: 'middle',
        },
      },
      title: 'Credit #',
      dataKey: 'number',
      render: (creditNumber: string, credit: CreditModel) => {
        const { id } = credit

        return (
          <Link
            disabledUnderline
            onClick={() => goToEntity({
              generateUrls: () => generateCreditUrls({ id }),
              entityUrlKey: 'editCreditUrl',
            })}
          >
            {creditNumber}
          </Link>
        )
      },
    },
    {
      cellProps: {
        key: 'contactId',
        style: {
          minWidth: '200px',
          verticalAlign: 'middle',
        },
      },
      title: 'Contact',
      dataKey: 'contactId',
      render: (contactId: number) => {
        const contact = digObject(contacts, String(contactId), {})
        const { id, name } = contact

        return (
          <Link
            disabledUnderline
            onClick={() => goToEntity({
              generateUrls: () => generateContactUrls({ id }),
              entityUrlKey: 'editContactUrl',
            })}
          >
            <Text color="blue" variant="ellipsis" width="200px">
              {name}
            </Text>
          </Link>
        )
      },
    },
    {
      cellProps: {
        key: 'transactionId',
        style: {
          verticalAlign: 'middle',
        },
      },
      title: 'Business Unit',
      dataKey: 'transactionId',
      render: (transactionId: number) => {
        const transaction = digObject(transactions, String(transactionId), {})
        const { businessUnitId } = transaction

        const businessUnit = digObject(businessUnits, String(businessUnitId), {})
        const { name } = businessUnit

        return (
          <Text variant="ellipsis" width={50}>{name}</Text>
        )
      },
    },
    {
      cellProps: {
        key: 'title',
        style: {
          verticalAlign: 'middle',
        },
      },
      title: 'Item / Description',
      dataKey: 'title',
      render: (title: string) => (
        <Text variant="ellipsis" width={160}>{title}</Text>
      ),
    },
    {
      cellProps: {
        key: 'amount',
        style: {
          verticalAlign: 'middle',
        },
      },
      title: 'Credit Inc. GST',
      dataKey: 'amount',
      render: (amount: number) => `${formatMoney(amount)}`,
    },
    {
      cellProps: {
        key: 'remainingCredit',
        style: {
          verticalAlign: 'middle',
        },
      },
      title: 'Balance',
      dataKey: 'remainingCredit',
      render: (remainingCredit: number) => `${formatMoney(remainingCredit)}`,
    },
  ]

  return tableColumns
}

const CreditsTable = (props: CreditsTableProps) => {
  const { creditsPayload, statusKey } = props
  const { filteredCredits, filteredCreditsCount } = creditsPayload

  const { entities } = useSelector(reduxState => reduxState)

  const memoColumns = useMemo(
    () => buildColumns(
      { entities, creditsPayload, statusKey },
    ),
    [filteredCreditsCount],
  )

  return (
    <Table
      boxProps={{
        border: 'none', maxHeight: 500, overflowY: 'auto',
      }}
      columns={memoColumns}
      data={filteredCredits}
      stickyHeader
    />
  )
}

export default React.memo(CreditsTable)

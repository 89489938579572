import { useLatestEntity } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ModuleState } from '@redux/modules/types'
import type { BusinessUnitProductModel, BusinessUnitProductRequestOptions } from '@models/businessUnitProduct'

export const useRelations = (businessUnitProduct: BusinessUnitProductModel) => {
  const { businessUnitId } = businessUnitProduct || {}

  const { businessUnits } = useSelector(reduxState => reduxState.entities)

  const businessUnit = businessUnitId && businessUnits[businessUnitId] ? businessUnits[businessUnitId] : {}

  return {
    businessUnit,
  }
}

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: BusinessUnitProductRequestOptions,
}

function useBusinessUnitProduct(initEntity: Partial<BusinessUnitProductModel> = {}, options: Options = {}) {
  const { performHttpRequests, requestOptions } = options

  const { entity: businessUnitProduct }: { entity: BusinessUnitProductModel} = useLatestEntity(initEntity, 'businessUnitProducts')
  const { id } = businessUnitProduct

  useReduxAction(
    'businessUnitProducts',
    'loadBusinessUnitProduct',
    {
      entityId: id,
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(id, actionRequestOptions),
      shouldPerformFn: ({ loading }: ModuleState) => !!performHttpRequests && !loading,
    },
  )

  const { loadedIds, loading } = useSelector(reduxState => reduxState.businessUnitProducts)

  return {
    businessUnitProduct,
    loading: loading && !loadedIds.includes(businessUnitProduct.id),
  }
}

export default useBusinessUnitProduct

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/pro-light-svg-icons'

import { Button, DashboardModule, Form } from '@campaignhub/suit-theme'

import useCountry from '@hooks/useCountry'

import type { ContactFormPayload } from '@hooks/useContact'

const viewOnly = JSON.parse(process.env.REACT_APP_VIEW_ONLY || 'false')

interface AddressProps {
  contactFormPayload: ContactFormPayload,
  editInRealbaseUrl: string,
}

const Address = (props: AddressProps) => {
  const { contactFormPayload, editInRealbaseUrl } = props || {}
  const {
    entityState: {
      addressLine1,
      addressLine2,
      countryId,
      postCode,
      regionName,
      stateName,
    },
    errors,
    handlers,
  } = contactFormPayload

  const { country, loading } = useCountry({ id: countryId })
  const { name: countryName } = country || {}

  return (
    <DashboardModule contentBoxProps={{ flexDirection: 'column' }} loading={loading} title="Address">
      <Form padding="large">
        <Form.Row>
          <Form.Field errorMessage={errors.addressLine1} label="* Address Line 1">
            <input disabled name="addressLine1" value={addressLine1} type="text" {...handlers} />
          </Form.Field>

          <Form.Field label="Address Line 2" marginTop={['large', 0]}>
            <input disabled name="addressLine2" value={addressLine2} type="text" {...handlers} />
          </Form.Field>
        </Form.Row>

        <Form.Row marginTop="large">
          <Form.Field errorMessage={errors.regionName} label="Suburb/Region">
            <input disabled name="regionName" value={regionName} type="text" {...handlers} />
          </Form.Field>

          <Form.Field errorMessage={errors.postCode} label="* Post Code" marginTop={['large', 0]}>
            <input disabled name="postCode" value={postCode} type="text" {...handlers} />
          </Form.Field>
        </Form.Row>

        <Form.Row marginTop="large">
          <Form.Field errorMessage={errors.stateName} label="* State">
            <input disabled name="stateName" value={stateName} type="text" {...handlers} />
          </Form.Field>

          <Form.Field label="* Country" marginTop={['large', 0]}>
            <input
              disabled
              name="countryName"
              value={countryName}
              type="text"
              {...handlers}
            />
          </Form.Field>
        </Form.Row>
      </Form>

      <DashboardModule.Footer boxProps={{ borderTop: '1px solid' }}>
        {!viewOnly && (
          <DashboardModule.FooterRight>
            <Button
              buttonStyle="secondaryEdit"
              icon={<FontAwesomeIcon icon={faEdit} />}
              as="a"
              href={editInRealbaseUrl}
              size={['medium', 'small']}
              width={['100%', 'fit-content']}
            >
              Edit in Realbase
            </Button>
          </DashboardModule.FooterRight>
        )}
      </DashboardModule.Footer>
    </DashboardModule>
  )
}

export default Address

import { useState } from "react"
import { Entity } from "@campaignhub/react-hooks/build/hooks/useLatestEntity"

export const validateArray = <T extends Entity>(entities: T[], requiredFields:[]) => 
{
  const [formErrors, setFormErrors] = useState([])

  const validate = () => {
    const message = 'Required'
    const errors = entities.map((entity) => {
      const error = {}
      requiredFields.forEach((field) => {
        if (!entity[field.key]) {
          error[field.key] = message;
        }
      })
      return error
    })
  
    setFormErrors(errors)
    return errors.every((error) => Object.keys(error).length === 0)
  }

  return {
    formErrors, 
    validate
  }
}

import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { TransactionModel } from '@models/transaction'
import type { AppDispatch } from '@redux/store'
import type {
  Action, ActionResult, EntityOptions, Errors, FetchRequestPayload, RootReducerState,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/transaction/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/transaction/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/transaction/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/transaction/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/transaction/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/transaction/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/transaction/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/transaction/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/transaction/UPDATE_FAILURE'

export type TransactionModuleState = {
  creating: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loading: boolean,
  updating: boolean,
}

const initialState: TransactionModuleState = {
  creating: false,
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loadedIds: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}): Action {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(): Action {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []): Action {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(): Action {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(): Action {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []): Action {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(): Action {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(): Action {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []): Action {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadTransactions(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState): Promise<ActionResult<TransactionModel[]>> => {
    const loadedForKeys = [...getState().transactions.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/transactions', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.TRANSACTION_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function loadTransaction(transactionId: number, options: EntityOptions = {}) {
  return (dispatch: AppDispatch, getState: () => RootReducerState): Promise<ActionResult<TransactionModel>> => {
    const state = getState()
    const loadedIds = [...state.transactions.loadedIds]

    if (transactionId && !loadedIds.includes(transactionId)){
      loadedIds.push(transactionId)
    }

    dispatch(fetchRequest({ loadedIds }))

    const promise = api(`/transactions/${transactionId}`, options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.TRANSACTION)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))
        return { success: false, errors }
      })

    return promise
  }
}

export function createTransaction(transaction: TransactionModel, options: EntityOptions = {}) {
  const config = {
    method: 'POST',
    data: JSON.stringify({
      transaction,
    }),
  }

  return (dispatch: AppDispatch): Promise<ActionResult<TransactionModel>> => {
    dispatch(createRequest())

    const promise = api('/transactions', options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.TRANSACTION)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function updateTransaction(transaction: TransactionModel, options: EntityOptions = {}) {
  const config = {
    method: 'PUT',
    data: JSON.stringify({
      transaction,
    }),
  }

  return (dispatch: AppDispatch): Promise<ActionResult<TransactionModel>> => {
    dispatch(updateRequest())

    const promise = api(`/transactions/${transaction.id}`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.TRANSACTION)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function updateOverrideTotal(transaction: TransactionModel, options: EntityOptions = {}) {
  const config = {
    method: 'PUT',
    data: JSON.stringify({
      overrideTotal: transaction.overrideTotal,
    }),
  }

  return (dispatch: AppDispatch): Promise<ActionResult<TransactionModel>> => {
   dispatch(updateRequest())
    const promise = api(`/transactions/${transaction.id}/overridetotal`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.TRANSACTION)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: TransactionModuleState = initialState,
  action: Action = { type: '' },
): TransactionModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}

import {
  Box, DashboardModule, Image, ListItem, Text,
} from '@campaignhub/suit-theme'

import { generateRedirectUrl } from '@campaignhub/javascript-utils'

import useContact from '@hooks/useContact'

import { getContactShortAddress } from '@functions/contact'

import contactPlaceholderImage from './assets/contact_blank.svg'

const ContactDetails = (props: { contactId: number }) => {
  const { contactId } = props

  const contactPayload = useContact({ id: contactId }, { performHttpRequests: true })
  const {
    contact,
    contact: {
      name,
    },
    loading,
    urls: {
      editContactUrl,
    },
  } = contactPayload

  // TODO: Get imageUrl from Contact Metadata
  const imageUrl = ''

  return (
    <DashboardModule loading={loading} title="Contact">
      <ListItem
        boxProps={{ as: 'a', borderBottom: '1px dashed', padding: 'large' }}
        href={`${editContactUrl}?redirect=${generateRedirectUrl()}`}
        showAngleIcon
      >
        <Image
          boxProps={{ borderRadius: 5, marginRight: 'large' }}
          height="40px"
          placeholderUrl={contactPlaceholderImage}
          url={imageUrl}
          width="60px"
        />

        <Box flexDirection="column" maxWidth="250px">
          <Text color="bodyFontColor" fontSize="small" marginBottom="small" variant="ellipsis">
            {name}
          </Text>

          <Text color="bodyFontLightColor" fontSize="xsmall" variant="ellipsis">
            {getContactShortAddress(contact)}
          </Text>
        </Box>
      </ListItem>
    </DashboardModule>
  )
}

export default ContactDetails

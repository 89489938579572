import { useMemo } from 'react'

import { sortArrayBy } from '@campaignhub/javascript-utils'
import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

const watchEntityKeys = ['paymentMethods']

interface Options {
  performHttpRequests?: boolean,
}

function usePaymentMethods(options: Options = {}) {
  const { performHttpRequests } = options || {}

  const {
    updatedEntities: { paymentMethods: paymentMethodsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const entities = useSelector(reduxState => reduxState.entities)
  const { paymentMethods } = entities || {}

  const filteredPaymentMethods = useMemo(() => {
    const array = Object.values(paymentMethods)
    return sortArrayBy(array, 'asc', 'name')
  }, [paymentMethodsUpdatedAt])

  useReduxAction(
    'paymentMethods',
    'loadPaymentMethods',
    {},
    [performHttpRequests],
    { shouldPerformFn: ({ loaded, loading }) => performHttpRequests && !loaded && !loading },
  )

  const { loading } = useSelector(reduxState => reduxState.paymentMethods)

  return {
    filteredPaymentMethods,
    loading,
  }
}
export default usePaymentMethods

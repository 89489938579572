import { Box } from '@campaignhub/suit-theme'

import useBusinessUnitProduct from '@hooks/useBusinessUnitProduct'
import useTransactionItem from '@hooks/useTransactionItem'

import type { TransactionItemModel } from '@models/types'

import EditTransactionItem from './components/EditTransactionItem'
import ShowTransactionItem from './components/ShowTransactionItem'

interface TransactionItemProps {
  editingInvoice: boolean,
  transactionItem: TransactionItemModel,
}

const TransactionItem = (props: TransactionItemProps) => {
  const { editingInvoice, transactionItem: initTransactionItem } = props

  const transactionItemPayload = useTransactionItem(initTransactionItem)
  const {
    callbacks,
    editing: editingTransactionItem,
    transactionItem,
  } = transactionItemPayload

  const { businessUnitProductId } = initTransactionItem

  useBusinessUnitProduct({ id: businessUnitProductId }, { performHttpRequests: true })

  return (
    <Box
      alignItems="center"
      borderBottom="1px solid"
      borderColor="lineColor"
      paddingX="large"
      paddingY="medium"
    >
      {!editingTransactionItem && (
        <ShowTransactionItem
          callbacks={callbacks}
          editingInvoice={editingInvoice}
          transactionItem={transactionItem}
        />
      )}

      {editingTransactionItem && (
        <EditTransactionItem
          callbacks={callbacks}
          transactionItem={transactionItem}
        />
      )}

    </Box>
  )
}

export default TransactionItem

import { useMemo } from 'react'

import { matchFilterNumber, sortArrayBy } from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

const watchEntityKeys = ['businessUnitProducts']

type BusinessUnitProductOptions = {
  filters: {
    businessUnitId: number,
    isSubscribable?: boolean,
  },
  performHttpRequests: boolean,
}

function useBusinessUnitProducts(options: BusinessUnitProductOptions) {
  const { filters, performHttpRequests } = options || {}
  const {
    businessUnitId: filterBusinessUnitId,
    isSubscribable: filterIsSubscribable,
  } = filters

  const {
    updatedEntities: { businessUnitProducts: businessUnitProductsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { loading } = useSelector(reduxState => reduxState.businessUnitProducts)

  const entities = useSelector(reduxState => reduxState.entities)
  const { businessUnitProducts } = entities || {}

  const filteredBusinessUnitProducts = useMemo(() => {
    const filtered = Object.values(businessUnitProducts).filter((businessUnitProduct) => {
      const { businessUnitId, isSubscribable } = businessUnitProduct

      const matchBusinessUnit = matchFilterNumber(businessUnitId, filterBusinessUnitId)
      const matchSubscribable = filterIsSubscribable ? isSubscribable : true

      return (
        matchBusinessUnit && matchSubscribable
      )
    })

    return sortArrayBy(filtered, 'asc', 'name')
  }, [businessUnitProductsUpdatedAt, JSON.stringify(filters)])

  useReduxAction(
    'businessUnitProducts',
    'loadBusinessUnitProducts',
    {
      ...filters,
    },
    [performHttpRequests],
    {
    	shouldPerformFn: (entityReducer) => {
        const { loading, errors } = entityReducer
        return performHttpRequests && !loading && !errors.length
      },
    },
  )

  return {
    filteredBusinessUnitProducts,
    hasFilteredBusinessUnitProducts: !!filteredBusinessUnitProducts.length,
    loading,
  }
}
export default useBusinessUnitProducts

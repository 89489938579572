import { Box, DashboardModule } from '@campaignhub/suit-theme'

import useBusinessUnits from '@hooks/useBusinessUnits'

import ListBlankState from '@components/ListBlankState'

import BusinessUnitListItem from './components/BusinessUnitListItem'

const DraftInvoices = () => {
  const businessUnitsPayLoad = useBusinessUnits({ filters: { filterActive: true } })

  const {
    filteredBusinessUnits,
    hasBusinessUnits,
    loading,
  } = businessUnitsPayLoad

  return (
    <DashboardModule
      loading={loading}
      title="Draft Invoices"
    >
      <Box flexDirection="column" overflow="auto">
        {!loading && !hasBusinessUnits && <ListBlankState />}

        {filteredBusinessUnits.map(businessUnit => (
          <BusinessUnitListItem key={businessUnit.id} businessUnit={businessUnit} />
        ))}
      </Box>
    </DashboardModule>
  )
}

export default DraftInvoices

import React from 'react'

import { DashboardModule, Text } from '@campaignhub/suit-theme'

const Statements = (props: { contactId: number }) => {
  const { contactId } = props
  
  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column', padding: 'large' }}
      // loading={loading}
      title="Statements"
    >
      <Text fontSize="small">Place Statements content here</Text>
    </DashboardModule>
  )
}

export default Statements

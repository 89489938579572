import { Route, Routes } from 'react-router-dom'

import BusinessUnitEdit from './screens/Edit/index'

const BusinessUnitsRoutes = () => (
  <Routes>
    <Route path="/:businessUnitId/edit/*" element={<BusinessUnitEdit />} />
  </Routes>
)

export default BusinessUnitsRoutes

import { DynamicInput, PageFilterModule as PageFilterModuleBase } from '@campaignhub/suit-theme'
import type { PageFilterModuleProps as PageFilterModuleBaseProps } from '@campaignhub/suit-theme'

import searchEndpoint from '@functions/search'

import useSelector from '@hooks/useSelector'

import EntitySelector from '@components/EntitySelector'

const supportedComponents = {
  DynamicInput,
  EntitySelector,
}

type PageFilterModuleProps = Omit<PageFilterModuleBaseProps, 'entities' | 'supportedComponents'>

const PageFilterModule = (props: PageFilterModuleProps) => {
  const { callbacks, ...restProps } = props

  const entities = useSelector(reduxState => reduxState.entities)

  return (
    <PageFilterModuleBase
      callbacks={{ ...callbacks, searchEndpoint }}
      entities={entities}
      supportedComponents={supportedComponents}
      {...restProps}
    />
  )
}

export default PageFilterModule

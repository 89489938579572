import useReduxAction from '@hooks/useReduxAction'

import type { ContactSummaryModel } from '@models/contactSummary'

type ComparativeData = {
  arrow: string,
  color: string,
  value: number,
}

type ContactSummaryPayload = Partial<ContactSummaryModel> & {
  comparativeData: ComparativeData,
  loading: boolean | undefined,
}

type ContactSummaryOptions = {
  businessUnitId?: number,
}

function useContactSummary(options?: ContactSummaryOptions): ContactSummaryPayload {
  const { businessUnitId } = options || {}

  const contactSummaryPayload = useReduxAction(
    'contactSummary',
    'fetchContactSummary',
    {
      ...options,
    },
    [],
    {
      shouldPerformFn: (entityReducer) => {
        const { loading, loaded, errors } = entityReducer
        return !loading && !loaded && !errors?.length
      },
    },
  )

  // TODO: Figure out how to overide/reassign a payload TYPE.
  const { loading, result: contactSummary }: { result?: ContactSummaryModel, loading: boolean } = contactSummaryPayload

  const {
    comparative,
    data,
    newAccounts,
    overdueAccounts,
    totalAccounts,
    totalBilled,
  } = contactSummary || {}

  let comparativeData = {}

  if (comparative === 0){
    comparativeData = {
      arrow: null,
      color: 'black',
      value: comparative,
    }
  }

  if (comparative > 0){
    comparativeData = {
      arrow: 'up',
      color: 'green',
      value: comparative,
    }
  }

  if (comparative < 0){
    comparativeData = {
      arrow: 'down',
      color: 'red',
      value: comparative,
    }
  }

  return {
    comparativeData,
    data,
    loading,
    newAccounts,
    overdueAccounts,
    totalAccounts,
    totalBilled,
  }
}

export default useContactSummary

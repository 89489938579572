import { useMemo } from 'react'

import { useModals, useSetState, useFilters } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import CreateOrEditPriceModal from '@modals/CreateOrEditPriceModal'
import CreateSubscriptionModal from '@modals/CreateSubscriptionModal'
import CreateSubscriptionItemModal from '@modals/CreateSubscriptionItemModal'

import PageContent from './components/PageContent'

const defaultState = {
  showCreateOrEditPriceModal: false,
  showCreateSubscriptionItemModal: false,
  showCreateSubscriptionModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    CreateOrEditPriceModal: {
      closeModal: () => setState({ showCreateOrEditPriceModal: false }),
      createPrice: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
    CreateSubscriptionItemModal: {
      closeModal: () => setState({ showCreateSubscriptionItemModal: false }),
      createSubscriptionItem: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
    CreateSubscriptionModal: {
      closeModal: () => setState({ showCreateSubscriptionModal: false }),
      createSubscription: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const List = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    showCreateOrEditPriceModal,
    showCreateSubscriptionItemModal,
    showCreateSubscriptionModal,
  } = state

  const {
    callbacks: filterCallbacks,
    defaultFilters,
    pageFilters,
  } = useFilters()

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      showCreateOrEditPriceModal: (payload) => {
        setModalData('CreateOrEditPriceModal', payload)
        setState({ showCreateOrEditPriceModal: true })
      },
      showCreateSubscriptionModal: () => {
        setState({ showCreateSubscriptionModal: true })
      },
      showCreateSubscriptionItemModal: (payload) => {
        setModalData('CreateSubscriptionItemModal', payload)
        setState({ showCreateSubscriptionItemModal: true })
      },
      ...filterCallbacks,
    },
    defaultFilters,
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent pageFilters={pageFilters} />

        <CreateOrEditPriceModal
          callbacks={callbacks('CreateOrEditPriceModal', setState)}
          showModal={showCreateOrEditPriceModal}
        />

        <CreateSubscriptionItemModal
          callbacks={callbacks('CreateSubscriptionItemModal', setState)}
          showModal={showCreateSubscriptionItemModal}
        />

        <CreateSubscriptionModal
          callbacks={callbacks('CreateSubscriptionModal', setState)}
          showModal={showCreateSubscriptionModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default List

import { useContext, useEffect } from 'react'

import {
  Box, Button, Form, LoadingModule, ModalContext, SidebarModal, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useBillingTypes from '@hooks/useBillingTypes'
import useContact from '@hooks/useContact'
import useCountries from '@hooks/useCountries'
import usePrice, { usePriceForm, useRelations } from '@hooks/usePrice'

import type { BillingTypeModel, PriceModel } from '@models/types'

import { getBillingPeriod, getBillingPeriods } from '@functions/billingPeriod'

import { displaySubscriptionInterval } from '@functions/subscription'

import BusinessUnitLogo from '@components/BusinessUnitLogo'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

const MODAL_KEY = 'CreateOrEditPriceModal'

type CreateOrEditPriceModalProps = {
  callbacks: {
    closeModal: () => void,
    createPrice: (payload: HandleCallbackActionParams) => void,
    updatePrice: (payload: HandleCallbackActionParams) => void,
  },
  showModal: boolean,
}

type CreateOrUpdatePriceParams = {
  createFn: (entityParams: PriceModel) => void,
  entityState: PriceModel,
  updateFn: (entityParams: PriceModel) => void,
}

const createOrUpdatePrice = (params: CreateOrUpdatePriceParams & Pick<CreateOrEditPriceModalProps, 'callbacks'>) => {
  const {
    callbacks, createFn, entityState, updateFn,
  } = params
  const { closeModal, createPrice, updatePrice } = callbacks
  const { id } = entityState

  const createOrEditPricePayload = {
    callbacks: {
      action: id ? updateFn : createFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    toastText: `Price ${id ? 'Updated' : 'Created'}`,
  }

  const action = id ? updatePrice : createPrice

  return action(createOrEditPricePayload)
}

const CreateOrEditPriceModal = (props: CreateOrEditPriceModalProps) => {
  const { callbacks, showModal } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const { contactId, price: initPrice } = modalPayload

  const pricePayload = usePrice(initPrice)
  const {
    callbacks: {
      createPrice: createFn,
      updatePrice: updateFn,
    },
    loading: loadingPrice,
    price,
  } = pricePayload

  const priceFormPayload = usePriceForm(price)
  const {
    entityState,
    entityState: {
      billingTypeId,
      countryId,
      id,
      incTaxAmount,
      interval,
      isDefault,
      name,
    },
    errors,
    handlers,
    saveEnabled,
    setEntityState,
  } = priceFormPayload

  const { businessUnit, businessUnitProduct } = useRelations(price)

  const billingTypesPayload = useBillingTypes({ performHttpRequests: true })
  const { filteredBillingTypes, loading: loadingBillingTypes } = billingTypesPayload

  const isSubscription = !!filteredBillingTypes.find(
    (billingType: BillingTypeModel) => ((billingType.id === billingTypeId) && (billingType.key === 'subscription')),
  )

  const billingPeriods = getBillingPeriods(interval)
  const selectedBillingPeriod = getBillingPeriod(interval)

  const { contact, loading: loadingContact } = useContact({ id: contactId }, { performHttpRequests: !!contactId })
  const { countryId: contactCountryId } = contact

  const { filteredCountries, loading: loadingCountries } = useCountries()

  const loading = loadingCountries && loadingPrice && loadingBillingTypes && loadingContact

  // Auto Select Currency as contacts default Currency
  useEffect(() => {
    if (!countryId && contactCountryId){
      setEntityState({ countryId: contactCountryId })
    }
  }, [countryId, contactCountryId])

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title={id ? 'Edit' : 'Create'} titleSecondLine="Price" />

      <SidebarModal.SubHeader>
        <BusinessUnitLogo businessUnitKey={businessUnit?.key} />

        <Box flexDirection="column" lineHeight="1.3">
          <Text fontSize="small">{businessUnitProduct?.name}</Text>

          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
            {businessUnit?.name}
            {businessUnitProduct?.interval
              ? ` - ${displaySubscriptionInterval(businessUnitProduct?.interval)} Subscription`
              : ''}
          </Text>
        </Box>
      </SidebarModal.SubHeader>

      <SidebarModal.Content>
        <>
          <LoadingModule loading={loading} />

          {!loading && (
            <Form>
              <Form.Field errorMessage={errors.incTaxAmount} label="* Amount">
                <input
                  disabled={!!id}
                  name="incTaxAmount"
                  value={incTaxAmount}
                  type="number"
                  {...handlers}
                />
              </Form.Field>

              <Form.Field
                errorMessage={errors.countryId}
                label="* Currency"
                marginTop="large"
              >
                <select
                  data-value-type="number"
                  disabled={!!id}
                  name="countryId"
                  value={countryId}
                  {...handlers}
                >
                  <option value="">Please Select...</option>
                  {filteredCountries.map(country => (
                    <option key={country.id} value={country.currency}>
                      {country.currency}
                    </option>
                  ))}
                </select>
              </Form.Field>

              {!billingTypeId && (
                <Form.Field
                  errorMessage={errors.billingTypeId}
                  label="* Billing Type"
                  marginTop="large"
                >
                  <select
                    data-value-type="number"
                    name="billingTypeId"
                    value={billingTypeId}
                    {...handlers}
                  >
                    <option value="">Please Select...</option>
                    {filteredBillingTypes.map((billingType: BillingTypeModel) => (
                      <option key={billingType.key} value={billingType.id}>
                        {billingType.name}
                      </option>
                    ))}
                  </select>
                </Form.Field>
              )}

              {isSubscription && (
                <Form.Field
                  errorMessage={errors.interval}
                  label="* Billing Period"
                  marginTop="large"
                >
                  <select
                    data-value-type="string"
                    disabled={!!id}
                    name="interval"
                    value={interval}
                    {...handlers}
                  >
                    <option value={interval}>
                      {interval ? digObject(selectedBillingPeriod, 'name') : 'Please Select...'}
                    </option>

                    {billingPeriods.map(billingPeriod => (
                      <option key={billingPeriod.key} value={billingPeriod.key}>
                        {billingPeriod.name}
                      </option>
                    ))}
                  </select>
                </Form.Field>
              )}

              <Form.Field label="Name" marginTop="large">
                <input
                  name="name"
                  value={name}
                  type="text"
                  {...handlers}
                />
              </Form.Field>

              <Form.Field direction="column" label="Set As Default Price" marginTop="large">
                <select
                  data-value-type="boolean"
                  name="isDefault"
                  value={isDefault}
                  {...handlers}
                >
                  <option value={false}>No</option>
                  <option value>Yes</option>
                </select>
              </Form.Field>
            </Form>
          )}
        </>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={loading}
          onClick={() => createOrUpdatePrice({
            callbacks, createFn, entityState, updateFn,
          })}
          size="large"
        >
          {id ? 'Update Price' : 'Add Price'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: CreateOrEditPriceModalProps) => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditPriceModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal

import useUser from '@hooks/useUser'

import UserListItem from '@components/UserListItem'

interface RecipientProps {
  callbacks: {
    toggleRecipient: () => void,
  },
  userId: number,
}

const Recipient = (props: RecipientProps) => {
  const { callbacks, userId } = props || {}
  const { toggleRecipient } = callbacks || {}

  const userPayload = useUser({ id: userId })
  const { user } = userPayload
  const { email, fullName } = user

  return (
    <UserListItem
      deleteFn={() => toggleRecipient()}
      isModal
      secondaryText={email}
      title={fullName}
    />
  )
}

export default Recipient

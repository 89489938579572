import { Box, DashboardModule } from '@campaignhub/suit-theme'

import useBusinessUnits from '@hooks/useBusinessUnits'

import ListBlankState from '@components/ListBlankState'

import BusinessUnitCreditsListItem from './components/BusinessUnitCreditsListItem'

const PendingCredits = () => {
  const businessUnitsPayLoad = useBusinessUnits({
    filters: {
      filterActive: true,
      filterPendingCredits: true,
    },
  })

  const {
    filteredBusinessUnits,
    hasBusinessUnits,
    loading,
  } = businessUnitsPayLoad

  return (
    <DashboardModule
      loading={loading}
      title="Pending Credits"
    >
      <Box flexDirection="column" overflow="auto">
        {!loading && !hasBusinessUnits && <ListBlankState />}

        {filteredBusinessUnits.map(businessUnit => (
          <BusinessUnitCreditsListItem key={businessUnit.id} businessUnit={businessUnit} />
        ))}
      </Box>
    </DashboardModule>
  )
}

export default PendingCredits

import { useMemo } from 'react'

import { matchFilterNumber, matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import { PriceModel } from '@models/price'

interface Options {
  performHttpRequests?: boolean,
  filters?: {
    businessUnitProductId?: number,
    contactId?: number,
    countryId?: number,
    interval?: string,
  },
  requestOptions?: {
    [key: string]: boolean,
  },
}

const watchEntityKeys = ['prices']

function usePrices(options: Options): {
  filteredPrices: PriceModel[],
  hasFilteredPrices: boolean,
  loading: boolean,
} {
  const { filters, performHttpRequests, requestOptions } = options || {}
  const {
    businessUnitProductId: filterBusinessUnitProductId,
    countryId: filterCountryId,
    interval: filterInterval,
  } = filters || {}

  const {
    updatedEntities: { prices: pricesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { loading } = useSelector(reduxState => reduxState.prices)

  const entities = useSelector(reduxState => reduxState.entities)
  const { prices } = entities || {}

  const filteredPrices = useMemo((): PriceModel[] => {
    const filtered = Object.values(prices).filter((price: PriceModel) => {
      const { businessUnitProductId, countryId, interval } = price

      const matchBusinessUnitProduct = matchFilterNumber(businessUnitProductId, filterBusinessUnitProductId)
      const matchCountry = matchFilterNumber(countryId, filterCountryId)
      const matchInterval = matchFilterString(interval, filterInterval)

      return (
        matchBusinessUnitProduct && matchCountry && matchInterval
      )
    })

    return sortArrayBy(filtered, 'asc', 'name')
  }, [pricesUpdatedAt, JSON.stringify(filters)])

  useReduxAction(
    'prices',
    'loadPrices',
    {
      ...filters,
      ...requestOptions,
    },
    [performHttpRequests],
    {
    	shouldPerformFn: (entityReducer) => {
        const { loading, errors } = entityReducer
        return performHttpRequests && !loading && !errors.length
      },
    },
  )

  return {
    filteredPrices,
    hasFilteredPrices: !!filteredPrices.length,
    loading,
  }
}
export default usePrices

import { billingPeriods } from '@constants/billingPeriods'
import type { BillingPeriod } from '@constants/billingPeriods'

export const getBillingPeriod = (key: string): BillingPeriod | {} => {
  if (!key || typeof (key) !== 'string') return {}

  const billingPeriod = Object.values(billingPeriods)?.find(billingPeriod => billingPeriod.key === key) || {}

  return billingPeriod
}

export const getBillingPeriods = (key: string = ''): BillingPeriod[] => {
  // if no selected billing period, return all billing periods
  if (!key || typeof (key) !== 'string') return Object.values(billingPeriods)

  // else return all remaining billing periods
  return Object.values(billingPeriods)?.filter((billingPeriod: BillingPeriod) => billingPeriod.key !== key)
}

import { useContext } from 'react'
import Swal from 'sweetalert2'

import {
  Button, Form, LoadingModule, ModalContext, SectionDivider, SidebarModal,
} from '@campaignhub/suit-theme'

import { useForm } from '@campaignhub/react-hooks'
import type { UseFormPayload } from '@campaignhub/react-hooks'

import { digObject, formatDate, toggleArray } from '@campaignhub/javascript-utils'
import type { HandleCallbackActionParams } from '@campaignhub/javascript-utils'

import { swalColors } from '@constants/swal'

import useInvoice from '@hooks/useInvoice'
import useSelector from '@hooks/useSelector'
import useTransaction from '@hooks/useTransaction'

import type { InvoiceModel } from '@models/invoice'

import InvoiceListItem from './components/InvoiceListItem'

const MODAL_KEY = 'MergeInvoicesModal'

type MergeInvoicesModalProps = {
  callbacks: {
    closeModal: () => void,
    mergeInvoices: (payload: HandleCallbackActionParams) => void,
  },
  showModal: boolean,
}

export const requiredFields = [
  { key: 'dateDue' },
  { key: 'referenceCode' },
  { key: 'title' },
]

type ConfirmMergeParams = {
  mergeInvoicesPayload: HandleCallbackActionParams,
  mergeInvoices: (payload: HandleCallbackActionParams) => void,
}

const confirmMerge = (params: ConfirmMergeParams & MergeInvoicesModalProps['callbacks']) => {
  const { mergeInvoicesPayload, mergeInvoices } = params

  const { approval } = swalColors

  Swal
    .fire({
      confirmButtonColor: approval,
      confirmButtonText: 'Merge Invoices',
      icon: 'success',
      showCancelButton: true,
      text: 'Confirming you are about to merge the selected invoices. This cannot be undone.',
      title: 'Merge Invoices?',
    })
    .then(({ value }) => {
      if (value){
        mergeInvoices(mergeInvoicesPayload)
      }
    })
}

const MergeInvoicesModal = (props: MergeInvoicesModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, mergeInvoices } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const { sortedSelectedInvoices } = modalPayload

  const invoicePayload = useInvoice()
  const {
    callbacks: {
      createMergedInvoice: createMergedInvoiceFn,
    },
    loading: loadingInvoices,
  } = invoicePayload

  // Set Default State with First Invoice
  const firstInvoice = sortedSelectedInvoices[0]
  const { dateDue: firstInvoiceDateDue, transactionId } = firstInvoice || {}

  const { transaction } = useTransaction({ id: transactionId }, { performHttpRequests: !!transactionId })
  const mergedInvoiceDefaultReferenceCode = digObject(transaction, 'referenceCode', '')
  const mergedInvoiceDefaulttitle = digObject(transaction, 'title', '')

  const selectedInvoiceIds = sortedSelectedInvoices.map((invoice: InvoiceModel) => invoice.id)

  const defaultState = {
    dateDue: firstInvoiceDateDue,
    referenceCode: mergedInvoiceDefaultReferenceCode,
    invoiceIds: selectedInvoiceIds,
    selectedInvoices: sortedSelectedInvoices,
    title: mergedInvoiceDefaulttitle,
  }

  const mergedInvoiceFormPayload: UseFormPayload = useForm(defaultState, { requiredFields })
  const {
    entityState,
    entityState: {
      dateDue,
      referenceCode,
      invoiceIds,
      selectedInvoices,
      title,
    },
    errors,
    handlers,
    saveEnabled,
    setEntityState,
  } = mergedInvoiceFormPayload

  const { loading: loadingTransactions } = useSelector(reduxState => reduxState.transactions)
  const loading = loadingInvoices && loadingTransactions

  const mergeInvoicesPayload = {
    callbacks: {
      action: createMergedInvoiceFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    shouldRedirect: true,
    toastText: 'Invoices Merged',
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal} size="small">
      <SidebarModal.Header callbacks={callbacks} title="Merge" titleSecondLine="Invoices" />

      {/* TODO: DO we want SidebarModal.Subheader with contact name? */}

      <SidebarModal.Content>
        <Form>
          <Form.Field errorMessage={errors.title} label="* Title">
            <input
              name="title"
              type="text"
              value={title}
              {...handlers}
            />
          </Form.Field>

          <Form.Field errorMessage={errors.referenceCode} label="* Reference" marginTop="large">
            <input
              name="referenceCode"
              type="text"
              value={referenceCode}
              {...handlers}
            />
          </Form.Field>

          <Form.Field errorMessage={errors.dateDue} label="* Date Due" marginTop="large">
            <input
              name="dateDue"
              type="date"
              value={formatDate(dateDue, 'ISO8601')}
              {...handlers}
            />
          </Form.Field>

          <SectionDivider marginTop="large">
            Invoices to be merged:
          </SectionDivider>

          <LoadingModule loading={loading} />

          {!loading && (
            <Form.Field>
              {selectedInvoices.map((invoice: InvoiceModel) => (
                <InvoiceListItem
                  key={invoice.id}
                  callbacks={{
                    deselectInvoice: (selectedInvoice: InvoiceModel) => setEntityState({
                      invoiceIds: toggleArray(invoiceIds, selectedInvoice.id),
                      selectedInvoices: toggleArray(selectedInvoices, selectedInvoice, { deepCompare: true }),
                    }),
                  }}
                  invoice={invoice}
                  selectedInvoicesCount={invoiceIds.length}
                />
              ))}
            </Form.Field>
          )}
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={loading}
          onClick={() => confirmMerge({ mergeInvoicesPayload, mergeInvoices })}
          size="large"
        >
          {saveEnabled ? 'Merge Invoices' : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: MergeInvoicesModalProps) => (
  <SidebarModal.RenderController {...props}>
    <MergeInvoicesModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal

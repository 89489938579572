import { useRelations } from '@hooks/useContactType'

import useSelector from '@hooks/useSelector'

function useSelectedContactType(selectedContactTypeKey: string) {
  const { contactTypes } = useSelector(reduxState => reduxState.entities)

  const selectedContactType = Object.values(contactTypes).find(
    contactType => contactType.key === selectedContactTypeKey,
  ) || {}

  const { parentContactType } = useRelations(selectedContactType)

  return {
    parentContactType,
    selectedContactType,
  }
}

export default useSelectedContactType

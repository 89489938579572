import { Errors } from "@campaignhub/react-hooks/build/hooks/useForm"
import { Button, DashboardModule, Form, Grid } from "@campaignhub/suit-theme"
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import useBankTypes from "@hooks/useBankTypes"
import { BankModel } from "@models/bank"

interface BankItemProps {
  index: number,
  bank: BankModel, 
  errors: {} | Errors,
  handleChange: (index: number, newData: any) => void,
  handleDelete: (index: number) => void,
}

const BankItem = (props: BankItemProps) => {
  const { index, bank, errors, handleChange, handleDelete } = props || {}
  const { accountNumber, bankIdentifierCode, bankTypeId, branchNumber, internationalBankAccountNumber, name} = bank
  const { filteredBankTypes, isInternational } = useBankTypes({ filters: {bankTypeId: bankTypeId} })

  return (
    <div key={index} style={{ paddingBottom: '20px', paddingTop: '10px', borderTop: index === 0 ? 'none' : '2px solid black'}}>
      <Grid 
          alignItems="center"
          gridGap="large"
          gridTemplateColumns="auto"
          marginTop="large">
        <Form>
          <Form.Row>
              <Form.Field label="* Bank Name" errorMessage={errors[index]?.name}>
                  <input
                  type="text"
                  value={name}
                  onChange={handleChange(index, "name")}
                  />
              </Form.Field>
              <Form.Field label="* Bank Type" errorMessage={errors[index]?.bankTypeId}>
                <select
                  data-value-type="number"
                  disabled={false}
                  name="bankTypeId"
                  value={bankTypeId}
                  onChange={handleChange(index, "bankTypeId")}
                >
                  <option value="">Please Select...</option>
                  {filteredBankTypes.map(bankType => (
                    <option key={bankType.id} value={bankType.id}>
                      {bankType.name}
                    </option>
                  ))}
                </select>
              </Form.Field>
          </Form.Row>
          <Form.Row marginTop="large">
              <Form.Field label="* Branch Number" errorMessage={errors[index]?.branchNumber}>
                  <input
                  type="text"
                  value={branchNumber}
                  onChange={handleChange(index, "branchNumber")}
                  />
              </Form.Field>
              <Form.Field label="* Account Number" errorMessage={errors[index]?.accountNumber}>
                  <input
                  type="text"
                  value={accountNumber}
                  onChange={handleChange(index, "accountNumber")}
                  />
              </Form.Field>
          </Form.Row>
        </Form>
      
      {isInternational ? 
        <Form.Row>
          <Form.Field label="BIC / SWIFT">
              <input
              type="text"
              value={bankIdentifierCode}
              onChange={handleChange(index, "bankIdentifierCode")}
              />
          </Form.Field>
          <Form.Field label="IBAN">
              <input
              type="text"
              value={internationalBankAccountNumber}
              onChange={handleChange(index, "internationalBankAccountNumber")}
              />
          </Form.Field>
        </Form.Row>
      : null} 

      {index != 0 ? 
      <DashboardModule.FooterRight boxProps={{ flexDirection: ['column', 'row']}}>
        <Button
            buttonStyle="secondaryUtility"
            disabled={false}
            marginLeft={[0, 'medium']}
            marginTop={['medium', 0]}
            size="small"
            icon={<FontAwesomeIcon icon={faTrashAlt} />}
            onClick={handleDelete}
          >
            Delete
        </Button>
      </DashboardModule.FooterRight> : null}
      </Grid> 
    </div>
  )
}

export default BankItem

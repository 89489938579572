import { formatMoney } from 'accounting'

import {
  Box, DashboardModule, ListItem, Text,
} from '@campaignhub/suit-theme'

import useInvoiceSummary from '@hooks/useInvoiceSummary'

interface InvoiceStatisticsProps {
  filters?: {
    businessUnitId?: number,
    contactId?: number,
  },
  href?: string,
  title?: string,
}

const InvoiceStatistics = (props: InvoiceStatisticsProps) => {
  const { filters, href, title } = props

  const { invoicesSummary, loading } = useInvoiceSummary({ filters })
  const { totalDraftInvoices, totalDueAmount, totalOverdueAmount } = invoicesSummary || {}

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column' }}
      headerRight={href ? (
        <DashboardModule.Link href={href}>
          View All
        </DashboardModule.Link>
      ) : ''}
      loading={loading}
      title={title || 'Invoice Statistics'}
    >
      <ListItem
        boxProps={{ borderBottom: '1px solid', borderColor: 'lineColor', padding: 'large' }}
        disableHover
        key="draft"
      >
        <Box flexDirection="column">
          <Text fontSize="small">Draft Invoices</Text>

          <Text color="bodyFontColor" fontSize="xlarge" marginTop="medium">{formatMoney(totalDraftInvoices)}</Text>
        </Box>
      </ListItem>

      <ListItem
        boxProps={{ borderBottom: '1px solid', borderColor: 'lineColor', padding: 'large' }}
        disableHover
        key="due"
      >
        <Box flexDirection="column">
          <Text fontSize="small">Due</Text>

          <Text color="orange" fontSize="xlarge" marginTop="medium">{formatMoney(totalDueAmount)}</Text>
        </Box>
      </ListItem>

      <ListItem
        boxProps={{ padding: 'large' }}
        disableHover
        key="overdue"
      >
        <Box flexDirection="column">
          <Text fontSize="small">Overdue</Text>

          <Text color="red" fontSize="xlarge" marginTop="medium">{formatMoney(totalOverdueAmount)}</Text>
        </Box>
      </ListItem>
    </DashboardModule>
  )
}

export default InvoiceStatistics

import { useMemo } from 'react'

import { sortArrayBy } from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import { BusinessUnitModel } from '@models/businessUnit'

const watchEntityKeys = ['businessUnits']

interface useBusinessUnitOptions {
  filters?: {
    filterActive?: boolean,
    filterPendingCredits?: boolean,
  },
  performHttpRequests?: boolean,
}

function useBusinessUnits(options: useBusinessUnitOptions): {
  filteredBusinessUnits: BusinessUnitModel[],
  hasBusinessUnits: boolean,
  loading: boolean,
} {
  const { filters, performHttpRequests } = options || {}
  const { filterActive, filterPendingCredits } = filters || {}

  const {
    updatedEntities: { businessUnits: businessUnitsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { loading } = useSelector(reduxState => reduxState.businessUnits)

  const entities = useSelector(reduxState => reduxState.entities)
  const { businessUnits } = entities || {}

  const filteredBusinessUnits = useMemo(() => {
    const filtered = Object.values(businessUnits).filter((businessUnit: BusinessUnitModel) => {
      const { active, totalPendingCredits } = businessUnit

      const activeBusinessUnit = filterActive ? active : !active
      const hasPendingCredits = filterPendingCredits ? !!totalPendingCredits : true

      return activeBusinessUnit && hasPendingCredits
    })

    return sortArrayBy(filtered, 'asc', 'name')
  }, [businessUnitsUpdatedAt, JSON.stringify(filters)])

  useReduxAction(
    'businessUnits',
    'loadBusinessUnits',
    {},
    [performHttpRequests],
    {
      shouldPerformFn: (entityReducer) => {
        const { loading, loaded, errors } = entityReducer
        return performHttpRequests && !loading && !loaded && !errors.length
      },
    },
  )

  return {
    filteredBusinessUnits,
    hasBusinessUnits: !!filteredBusinessUnits.length,
    loading,
  }
}
export default useBusinessUnits

import { useContext } from 'react'
import Swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck, faTimes,
} from '@fortawesome/pro-light-svg-icons'

import { Button, DashboardModule } from '@campaignhub/suit-theme'

import { swalColors } from '@constants/swal'

import PageContext from '@contexts/pageContext'

import useSelector from '@hooks/useSelector'

import { getStatusIdByKey } from '@functions/status'

import type { CreditPayload } from '@hooks/useCredit'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

const viewOnly = JSON.parse(process.env.REACT_APP_VIEW_ONLY || "false")

type SwalConfirmParams = {
  action: (payload: HandleCallbackActionParams) => void,
  actionPayload: HandleCallbackActionParams,
  buttonColor: string,
  buttonText: string,
  icon: string,
  text: string,
  title: string,
}

const confirmAction = (params: SwalConfirmParams) => {
  const {
    action,
    actionPayload,
    buttonColor,
    buttonText,
    icon,
    text,
    title,
  } = params

  Swal
    .fire({
      confirmButtonColor: buttonColor,
      confirmButtonText: buttonText,
      icon,
      showCancelButton: true,
      text,
      title,
    })
    .then(({ value }) => {
      if (value){
        action(actionPayload)
      }
    })
}

interface CreditActionsProps {
  creditPayload: CreditPayload,
}

const Actions = (props: CreditActionsProps) => {
  const { creditPayload } = props || {}
  const {
    callbacks: {
      updateCredit: updateCreditFn,
    },
    credit,
    credit: {
      number,
    },
  } = creditPayload || {}

  const pageContext = useContext(PageContext)
  const { callbacks: { updateCredit } } = pageContext

  const { statuses } = useSelector(reduxState => reduxState.entities)

  const approvedStatusId = getStatusIdByKey('approved', statuses)
  const rejectedStatusId = getStatusIdByKey('rejected', statuses)

  const { approval, deletion } = swalColors

  return (
    <DashboardModule.Footer boxProps={{ flexDirection: ['column', 'row'] }}>
      <DashboardModule.FooterRight boxProps={{ flexDirection: ['column', 'row'] }}>
        {!viewOnly && (
          <>  
            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faTimes} />}
              marginTop={['medium', 0]}
              onClick={() => confirmAction(
                {
                  action: updateCredit,
                  actionPayload: {
                    callbacks: {
                      action: updateCreditFn,
                    },
                    entityParams: {
                      ...credit,
                      statusId: rejectedStatusId,
                    },
                    toastText: 'Credit Rejected',
                  },
                  buttonColor: deletion,
                  buttonText: 'Reject Credit',
                  icon: 'error',
                  text: `Confirming you are about to reject ${number && `#${number}`}. This cannot be undone.`,
                  title: 'Reject Credit?',
                }
                )}
                size="medium"
                width={['100%', 'auto']}
            >
              Reject Credit
            </Button>
            <Button
              buttonStyle="primaryCreate"
              height="37.5px"
              icon={<FontAwesomeIcon icon={faCheck} />}
              marginLeft={[0, 'medium']}
              marginTop={['medium', 0]}
              onClick={() => confirmAction(
                {
                  action: updateCredit,
                  actionPayload: {
                    callbacks: {
                      action: updateCreditFn,
                    },
                    entityParams: {
                      ...credit,
                      statusId: approvedStatusId,
                    },
                    toastText: 'Credit Approved',
                  },
                  buttonColor: approval,
                  buttonText: 'Approve Credit',
                  icon: 'success',
                  text: `Confirming you are about to approve ${number && `#${number}`}.
                This cannot be undone.`,
                  title: 'Approve Credit?',
                }
              )}
              size="medium"
              width={['100%', 'auto']}
            >
              Approve
            </Button>
          </>
        )}
      </DashboardModule.FooterRight>
    </DashboardModule.Footer>
  )
}

export default Actions

import useReduxAction from '@hooks/useReduxAction'

type SubscriptionSummaryOptions = {
  businessUnitId?: number,
}

function useSubscriptionSummary(options?: SubscriptionSummaryOptions) {
  const { businessUnitId } = options || {}

  const subscriptionSummaryPayload = useReduxAction(
    'subscriptionSummary',
    'fetchSubscriptionSummary',
    {
      ...options,
    },
    [],
    {
      shouldPerformFn: (entityReducer) => {
        const { loading, loaded, errors } = entityReducer
        return !loading && !loaded && !errors?.length
      },
    },
  )

  // TODO: Figure out how to overide/reassign a payload TYPE.
  const { loading, result: subscriptionSummary } = subscriptionSummaryPayload

  const {
    comparative,
    currentNewSubscription,
    data,
  } = subscriptionSummary

  let comparativeData = {}

  if (comparative === 0){
    comparativeData = {
      arrow: null,
      color: 'black',
      value: comparative,
    }
  }

  if (comparative > 0){
    comparativeData = {
      arrow: 'up',
      color: 'green',
      value: comparative,
    }
  }

  if (comparative < 0){
    comparativeData = {
      arrow: 'down',
      color: 'red',
      value: comparative,
    }
  }

  return {
    comparativeData,
    currentNewSubscription,
    data,
    loading,
    subscriptionSummary,
  }
}

export default useSubscriptionSummary

import { Box, DashboardModule } from '@campaignhub/suit-theme'
import { formatDate } from '@campaignhub/javascript-utils'

import useSubscriptionTransactions from '@hooks/useSubscriptionTransactions'

import ListBlankState from '@components/ListBlankState'

import type { SubscriptionModel } from '@models/subscription'
import TransactionsTable from './components/TransactionsTable'

const Transactions = (props: { subscription: SubscriptionModel }) => {
  const { subscription } = props || {}

  const {
    billingStart,
    id: subscriptionId,
  } = subscription

  const subscriptionTransactionsPayload = useSubscriptionTransactions({
    filters: {
      subscriptionId,
    },
    performHttpRequests: !!subscriptionId,
  })

  const { hasFilteredSubscriptionTransactions, loading } = subscriptionTransactionsPayload || {}

  const formattedBillingStart = formatDate(billingStart, 'ISO8601', 'dd LLLL yy')

  return (
    <>
      {!loading && !hasFilteredSubscriptionTransactions && (
        <DashboardModule
          contentBoxProps={{ flexDirection: 'column' }}
          loading={loading}
          title="Transactions"
        >
          <ListBlankState
            title="No Transactions"
            text="There are no transactions yet for this subscription"
            secondaryText={formattedBillingStart
              ? `The billing period commences on ${formattedBillingStart}` : ''}
          />
        </DashboardModule>
      )}

      {!loading && hasFilteredSubscriptionTransactions && (
        <Box
          backgroundColor="white"
          border="1px solid"
          borderColor="lineColor"
          borderRadius={5}
          flexDirection="column"
        >
          <TransactionsTable subscriptionTransactionsPayload={subscriptionTransactionsPayload} />
        </Box>
      )}
    </>
  )
}

export default Transactions

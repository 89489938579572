import { useNumericParams } from '@campaignhub/react-hooks'

import BusinessUnitRoutes from './routes'

const BusinessUnitEdit = () => {
  const { businessUnitId } = useNumericParams()

  return (
    <BusinessUnitRoutes businessUnitId={businessUnitId} />
  )
}

export default BusinessUnitEdit

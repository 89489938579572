import { useMemo } from 'react'
import { formatMoney } from 'accounting'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faPencil } from '@fortawesome/pro-light-svg-icons'

import {
  DashboardModule, Box, Checkbox, Link, StatusBadge, Text, Table,
} from '@campaignhub/suit-theme'
import { digObject, formatDate, goToEntity } from '@campaignhub/javascript-utils'

import { getInvoiceStatusDisplayKey } from '@functions/invoice'
import { statusColor } from '@functions/status'

import { generateUrls as generateContactUrls } from '@hooks/useContact'
import { generateUrls as generateInvoiceUrls } from '@hooks/useInvoice'
import useInvoices from '@hooks/useInvoices'
import useSelector from '@hooks/useSelector'

import type { EntitiesState } from '@redux/entities'
import type { InvoiceModel, InvoiceRequestOptions } from '@models/invoice'
import type { InvoicesFilters, InvoicesPayload } from '@hooks/useInvoices'

import ListBlankState from '@components/ListBlankState'

type BuildColumnParams = {
  entities: EntitiesState,
  filters: InvoicesFilters,
  invoicesPayload: InvoicesPayload,
}

const buildColumns = (params: BuildColumnParams) => {
  const { entities, filters, invoicesPayload } = params
  const { businessUnits, contacts, statuses } = entities

  const {
    callbacks: {
      toggleSelectedId,
    },
    selectedInvoiceIds,
  } = invoicesPayload || {}

  return [
    {
      cellProps: {
        key: 'select',
        style: {
          paddingRight: 0,
          verticalAlign: 'middle',
        },
      },
      title: <FontAwesomeIcon icon={faCheckCircle} />,
      dataKey: 'id',
      hidden: filters.status !== 'draft',
      render: (id: number) => (
        <Checkbox
          boxProps={{ width: '12px' }}
          marginRight={0}
          checked={selectedInvoiceIds?.includes(id)}
          fontSize="small"
          onClick={() => toggleSelectedId(id)}
        />
      ),
    },
    {
      cellProps: {
        key: 'statusId',
        alignItems: 'center',
        width: '75px',
      },
      title: 'Status',
      dataKey: 'statusId',
      render: (statusId: number, invoice: InvoiceModel) => {
        const displayStatusKey = getInvoiceStatusDisplayKey(invoice, statuses)

        return (
          <StatusBadge
            boxProps={{ minWidth: '75px' }}
            color={statusColor(displayStatusKey)}
            ghost
            text={displayStatusKey}
          />
        )
      },
    },
    {
      cellProps: {
        key: 'dateCreated',
        style: {
          verticalAlign: 'middle',
          width: '60px',
        },
      },
      title: 'Created',
      dataKey: 'dateCreated',
      render: (dateCreated: string) => formatDate(dateCreated, 'ISO8601', 'dd/MM/yy'),
    },
    {
      cellProps: {
        key: 'invoiceNumber',
        style: {
          verticalAlign: 'middle',
          width: '60px',
        },
      },
      title: 'Invoice #',
      dataKey: 'number',
      render: (invoiceNumber: string, invoice: InvoiceModel) => {
        const { id } = invoice

        return (
          <Link
            disabledUnderline
            onClick={() => goToEntity({
              generateUrls: () => generateInvoiceUrls({ id }),
              entityUrlKey: 'editInvoiceUrl',
            })}
          >
            {invoiceNumber}
          </Link>
        )
      },
    },
    {
      cellProps: {
        key: 'referenceCode',
        style: {
          verticalAlign: 'middle',
          width: '60px',
        },
      },
      title: 'Reference',
      dataKey: 'referenceCode',
      render: (referenceCode: string) => (
        <Text variant="ellipsis" width={100}>{referenceCode}</Text>
      ),
    },
    {
      cellProps: {
        key: 'businessUnitId',
        style: {
          verticalAlign: 'middle',
          width: 160,
        },
      },
      title: 'Business Unit',
      dataKey: 'businessUnitId',
      hidden: filters?.businessUnitId,
      render: (businessUnitId: number) => {
        const businessUnit = digObject(businessUnits, String(businessUnitId), {})
        const { name } = businessUnit

        return (
          <Text variant="ellipsis" width={100}>{name}</Text>
        )
      },
    },
    {
      cellProps: {
        key: 'contactId',
        style: {
          verticalAlign: 'middle',
        },
      },
      title: 'Contact',
      dataKey: 'contactId',
      hidden: filters?.contactId,
      render: (contactId: number) => {
        const contact = digObject(contacts, String(contactId), {})
        const { id, name } = contact

        return (
          <Link
            disabledUnderline
            onClick={() => goToEntity({
              generateUrls: () => generateContactUrls({ id }),
              entityUrlKey: 'editContactUrl',
            })}
          >
            <Text color="blue" variant="ellipsis" width="200px">
              {name}
            </Text>
          </Link>
        )
      },
    },
    {
      cellProps: {
        key: 'balance',
        style: {
          verticalAlign: 'middle',
        },
      },
      title: 'Balance',
      dataKey: 'balance',
      render: (balance: number) => formatMoney(balance),
    },
    {
      cellProps: {
        key: 'edit',
        style: {
          alignSelf: 'flex-end',
        },
      },
      dataKey: 'id',
      render: (id: number) => (
        <Box justifyContent="flex-end">
          <Link
            greyLink
            onClick={() => goToEntity({
              generateUrls: () => generateInvoiceUrls({ id }),
              entityUrlKey: 'editInvoiceUrl',
            })}
          >
            <FontAwesomeIcon icon={faPencil} />
          </Link>
        </Box>
      ),
    },
  ]
}

interface DraftInvoicesProps {
  filters: {
    businessUnitId?: number,
    contactId?: number,
    status?: string,
  },
  performHttpRequests?: boolean,
  requestOptions?: InvoiceRequestOptions,
}

const DraftInvoices = (props: DraftInvoicesProps) => {
  const { filters, performHttpRequests, requestOptions } = props

  const invoicesPayload = useInvoices({ filters, performHttpRequests, requestOptions })
  const {
    filteredInvoices,
    filteredInvoicesCount,
    hasFilteredInvoices,
    loading,
    selectedInvoiceIds,
  } = invoicesPayload

  const { entities } = useSelector(reduxState => reduxState)

  const memoColumns = useMemo(
    () => buildColumns(
      {
        entities,
        filters,
        invoicesPayload,
      },
    ),
    [filteredInvoicesCount, selectedInvoiceIds],
  )

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column' }}
      loading={loading}
      title="Draft Invoices"
    >
      {!loading && !hasFilteredInvoices && <ListBlankState title="No Invoices" text=" " />}

      {!loading && hasFilteredInvoices && (
        <Table
          boxProps={{ border: 'none', maxHeight: 300, overflowY: 'auto' }}
          columns={memoColumns}
          data={filteredInvoices}
          scroll={{ x: 700 }}
          stickyHeader
        />
      )}
    </DashboardModule>
  )
}

export default DraftInvoices

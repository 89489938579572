import useReduxAction from '@hooks/useReduxAction'

import type { ModuleState } from '@redux/modules/types'

import type { CreditSummaryByStatusModel } from '@models/creditSummary'

type CreditSummaryOptions = {
  filters?: {
    businessUnitId?: number,
    contactId?: number,
  },
  performHttpRequests?: boolean,
}

function useCreditSummaryByStatus(options: CreditSummaryOptions) {
  const { filters, performHttpRequests = true } = options || {}

  const creditSummaryPayload = useReduxAction(
    'creditSummary',
    'fetchCreditSummaryByStatus',
    {
      ...filters,
    },
    [performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    }
  )

  const { result, loading }: { result?: CreditSummaryByStatusModel, loading: boolean } = creditSummaryPayload

  const creditSummaryItems = Array.isArray(result) ? result : []
  const hasCreditSummaryItems = !!creditSummaryItems.length

  return {
    creditSummaryItems,
    hasCreditSummaryItems,
    loading,
  }
}

export default useCreditSummaryByStatus

import { useNumericParams } from '@campaignhub/react-hooks'

import useContactTypes from '@hooks/useContactTypes'

import ContactEditRoutes from './routes'

const ContactEdit = () => {
  const { contactId } = useNumericParams()

  // load Contact Types
  useContactTypes({ performHttpRequests: !!contactId })

  return (
    <ContactEditRoutes contactId={contactId} />
  )
}

export default ContactEdit

import { useLatestEntity } from '@campaignhub/react-hooks'

import useSelector from '@hooks/useSelector'

import type { CountryModel } from '@models/types'

function useCountry(initEntity: Partial<CountryModel> = {}) {
  const { entity: country }: { entity: CountryModel} = useLatestEntity(initEntity, 'countries')

  const { loadedIds, loading } = useSelector(reduxState => reduxState.countries)

  // Countries are loaded top level

  return {
    country,
    loading: loading && !loadedIds.includes(country.id),
  }
}

export default useCountry

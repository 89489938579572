import React from 'react'

import { PageHeader } from '@campaignhub/suit-theme'

import { ADMIN_SIDEBAR_WIDTH } from '@constants/sizes'

type TabBarItem = {
  href: string,
  icon: React.ReactNode,
  key: string,
  title: string,
}

interface AdminPageHeaderProps {
  activeTabBarItemKey: string,
  boxProps: object,
  tabBarItems: TabBarItem[],
  title: string,
}

const AdminPageHeader = (props: AdminPageHeaderProps) => (
  <PageHeader offset={{ left: ADMIN_SIDEBAR_WIDTH }} {...props} />
)

export default AdminPageHeader

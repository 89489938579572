import { useContext } from 'react'

import { useForm, useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'
import type { UseFormOptions, UseFormPayload } from '@campaignhub/react-hooks'

import * as priceActions from '@redux/modules/price'

import PageContext from '@contexts/pageContext'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import { default as defaultState, requiredFields } from '@models/price'

import type { PriceModel, PriceRequestOptions } from '@models/price'
import type { AppDispatch } from '@redux/store'

type PriceForm = { entityState: PriceModel }

export type PriceFormPayload = UseFormPayload & PriceForm;

export interface PricePayload {
  callbacks: {},
  price : PriceModel,
  loading: boolean,
}
interface Options {
  performHttpRequests?: boolean,
  requestOptions: {
    [key: string]: boolean,
  },
}

type CreatePriceParams = {
  dispatch: AppDispatch,
  entityParams: Partial<PriceModel>,
  requestOptions?: PriceRequestOptions,
}

const createPrice = (params: CreatePriceParams) => {
  const { entityParams, dispatch, requestOptions } = params
  const { createPrice: createFn } = priceActions

  return dispatch(createFn(entityParams, requestOptions))
}

// We can pass the contactId to the Create Or Edit Price Modal in order to auto set
// the Price currency based on the Contacts defaultCurrency attribute
type CustomCreateOrEditPricePayload = {
  contactId: number,
}

type CreateOrEditPriceParams = {
  customPayload: CustomCreateOrEditPricePayload,
  price: PriceModel,
  showCreateOrEditPriceModal: (payload: { price: PriceModel, customPayload?: CustomCreateOrEditPricePayload }) => void,
}

const createOrEditPrice = (params: CreateOrEditPriceParams) => new Promise((resolve, reject) => {
  const { customPayload = {}, price, showCreateOrEditPriceModal } = params

  if (showCreateOrEditPriceModal){
    const payload = {
      ...customPayload,
      price,
    }

    showCreateOrEditPriceModal(payload)

    resolve({ success: true, result: payload })
  }

  reject(new Error('showCreateOrEditPriceModal not defined in PageContext callbacks'))
})

type UpdatePriceParams = {
  dispatch: AppDispatch,
  entityParams: Partial<PriceModel>,
  price: PriceModel,
  requestOptions?: PriceRequestOptions,
}

export const updatePrice = (params: UpdatePriceParams) => {
  const {
    dispatch, entityParams, price, requestOptions,
  } = params
  const { updatePrice: updateFn } = priceActions

  const updatedParams = {
    id: price.id,
    ...entityParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

export function usePriceForm(price: PriceModel, options: UseFormOptions = {}): PriceFormPayload {
  const { validateOn } = options

  const priceForm = useForm(
    defaultState,
    { entity: price, requiredFields, validateOn },
    [price?.id],
  )

  return {
    ...priceForm,
  }
}

export const useRelations = (price: PriceModel) => {
  const { billingTypeId, businessUnitProductId } = price

  const { billingTypes, businessUnitProducts, businessUnits } = useSelector(reduxState => reduxState.entities)

  const billingType = billingTypeId && billingTypes[billingTypeId] ? billingTypes[billingTypeId] : {}
  const businessUnitProduct = businessUnitProductId
  && businessUnitProducts[businessUnitProductId] ? businessUnitProducts[businessUnitProductId] : {}

  const { businessUnitId } = businessUnitProduct
  const businessUnit = businessUnitId && businessUnits[businessUnitId] ? businessUnits[businessUnitId] : {}

  return {
    billingType,
    businessUnit,
    businessUnitProduct,
  }
}

function usePrice(initEntity: Partial<PriceModel> = {}, options?: Options) {
  const { performHttpRequests, requestOptions } = options || {}

  const { entity: price } = useLatestEntity(initEntity, 'prices')
  const { id } = price

  const dispatch = useThunkDispatch()

  const { callbacks } = useContext(PageContext)
  const { showCreateOrEditPriceModal } = callbacks || {}

  useReduxAction(
    'prices',
    'loadPrice',
    {
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, requestOptions) => action(id, requestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedIds } = entityReducer
        return performHttpRequests && !loadedIds.includes(id)
      },
    },
  )

  const { loading } = useSelector(reduxState => reduxState.prices)

  return {
    callbacks: {
      createPrice: (entityParams: PriceModel, requestOptions) => createPrice({ entityParams, dispatch, requestOptions }),
      createOrEditPrice: (customPayload?: CustomCreateOrEditPricePayload) => createOrEditPrice({ customPayload, price, showCreateOrEditPriceModal }),
      updatePrice: (entityParams: PriceModel, requestOptions) => updatePrice({ entityParams, dispatch, requestOptions }),
    },
    price,
    loading,
  }
}

export default usePrice

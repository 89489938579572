import { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltToBottom } from '@fortawesome/pro-light-svg-icons'

import { Button, Tools } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

interface InvoiceToolsProps {
  callbacks: {
    downloadInvoicePDF: () => void,
  },
}

const InvoiceTools = (props: InvoiceToolsProps) => {
  const { callbacks } = props
  const { downloadInvoicePDF: downloadFn } = callbacks || {}

  const pageContext = useContext(PageContext)
  const { callbacks: { downloadInvoicePDF } } = pageContext

  const downloadPDFPayload = {
    callbacks: {
      action: downloadFn,
    },
    toastText: 'Invoice PDF Generated',
  }

  return (
    <Tools boxProps={{ marginTop: ['large', 0] }}>
      <Button
        buttonStyle="secondaryUtility"
        icon={<FontAwesomeIcon icon={faArrowAltToBottom} />}
        onClick={() => downloadInvoicePDF(downloadPDFPayload)}
        size="medium"
      >
        Download PDF
      </Button>
    </Tools>
  )
}

export default InvoiceTools

import { useEffect } from 'react'

import {
  CardNumberElement, useStripe, useElements,
} from '@stripe/react-stripe-js'
import type {
  Stripe,
  StripeElements,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js'

import { digObject, toggleArray } from '@campaignhub/javascript-utils'
import { useSetState } from '@campaignhub/react-hooks'

import { confirmStripePaymentMethod, createStripePaymentMethod } from '@functions/stripe'

import useContact from '@hooks/useContact'

type StripeElementChangeEvent = StripeCardCvcElementChangeEvent
| StripeCardExpiryElementChangeEvent
| StripeCardNumberElementChangeEvent

type CreatePaymentMethodParams = {
  elements: StripeElements,
  name: string,
  setupIntentId: string,
  stripe: Stripe,
}

const createPaymentMethod = (params: CreatePaymentMethodParams) => {
  const {
    setupIntentId, name, stripe, elements,
  } = params

  if (!stripe || !elements || !setupIntentId){
    return {
      success: false, errors: ['Stripe Configuration Error'],
    }
  }

  // Get Card Params
  const cardNumberElement = elements.getElement(CardNumberElement)

  return createStripePaymentMethod({ cardNumberElement, name, stripe })
    .then((stripeResult) => {
      const { success, paymentMethod } = stripeResult || {}
      if (!success) return stripeResult

      const { id: paymentMethodId } = paymentMethod

      // Confirm Stripe Payment Method Creation
      return confirmStripePaymentMethod({ paymentMethodId, setupIntentId })
    })
}

type SetState = (state: Partial<typeof defaultState>) => void

type HandleStripeElementParams = {
  event: StripeElementChangeEvent,
  setState: SetState,
  state: Partial<typeof defaultState>,
}

export const handleStripeElement = (params: HandleStripeElementParams) => {
  const { event, setState, state } = params || {}
  const { complete, elementType, error } = event || {}
  const { completedStripeFields, errors } = state || {}

  // Add elementType to completedStripeFields array
  if (complete){
    // clear errors
    const elementTypeErrors = digObject(errors, elementType)

    if (elementTypeErrors){
      const updatedErrors = { ...errors }
      delete updatedErrors[elementType]

      return setState({
        completedStripeFields: toggleArray(completedStripeFields, elementType),
        errors: updatedErrors,
      })
    }

    setState({ completedStripeFields: toggleArray(completedStripeFields, elementType) })
  } else if (error){
    // Set Errors
    // if element was completed, remove from completedStripeFields
    if (completedStripeFields?.includes(elementType)){
      return setState({
        completedStripeFields: toggleArray(completedStripeFields, elementType),
        errors: { ...errors, [elementType]: error.message },
      })
    }

    setState({
      errors: { ...errors, [elementType]: error.message },
    })
  }
}

const defaultState = {
  completedStripeFields: [],
  errors: {},
  name: '',
}

type UseStripePaymentMethodParams = {
  contactId: number,
  setupIntentId: string,
}

function useStripePaymentMethod(params: UseStripePaymentMethodParams) {
  const { contactId, setupIntentId } = params || {}

  const [state, setState] = useSetState(defaultState)
  const { completedStripeFields, name } = state

  const { contact } = useContact({ id: contactId })
  const { name: contactName } = contact || {}

  const stripe = useStripe()
  const elements = useElements()

  const saveEnabled = name && completedStripeFields.length === 3

  // Set Contact Name as Name on Card
  useEffect(() => {
    if (contactName){
      setState({ name: contactName })
    }
  }, [contactId])

  return {
    callbacks: {
      createPaymentMethod: () => createPaymentMethod({
        elements, name, setupIntentId, stripe,
      }),
      handleStripeElement: (event: StripeElementChangeEvent) => handleStripeElement({ event, setState, state }),
      setState,
    },
    saveEnabled,
    state,
  }
}

export default useStripePaymentMethod

import { useMemo } from 'react'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'
import { matchFilterNumber, sortArrayBy } from '@campaignhub/javascript-utils'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import { ContactPaymentMethodModel, ContactPaymentMethodRequestOptions } from '@models/contactPaymentMethod'

const watchEntityKeys = ['contactPaymentMethods']

interface ContactPaymentMethodsOptions {
  filters: {
    contactId: number,
    isDefault?: boolean,
    paymentMethodId?: number,
  },
  performHttpRequests?: boolean,
  requestOptions?: ContactPaymentMethodRequestOptions,
}

function useContactPaymentMethods(options: ContactPaymentMethodsOptions) {
  const { filters, performHttpRequests, requestOptions } = options || {}
  const { contactId: filterContactId, isDefault: fitlerIsDefault, paymentMethodId: filterPaymentMethodId } = filters

  const {
    updatedEntities: { contactPaymentMethods: contactPaymentMethodsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const entities = useSelector(reduxState => reduxState.entities)
  const { contactPaymentMethods } = entities || {}

  const filteredContactPaymentMethods = useMemo(() => {
    const filtered = Object.values(contactPaymentMethods).filter((contactPaymentMethod: ContactPaymentMethodModel) => {
      const { contactId, isDefault, paymentMethodId } = contactPaymentMethod

      const matchContact = matchFilterNumber(contactId, filterContactId)
      const matchIsDefault = fitlerIsDefault ? isDefault : true
      const matchPaymentMethod = matchFilterNumber(paymentMethodId, filterPaymentMethodId)

      return matchContact && matchIsDefault && matchPaymentMethod
    })

    return sortArrayBy(filtered, 'asc', 'name')
  }, [contactPaymentMethodsUpdatedAt, JSON.stringify(filters)])

  const { loading } = useReduxAction(
    'contactPaymentMethods',
    'loadContactPaymentMethods',
    {
      contactId: filterContactId,
      paymentMethodId: filterPaymentMethodId,
      ...requestOptions,
    },
    [JSON.stringify(filters), performHttpRequests],
    {
      shouldPerformFn: ({ loading }) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {},
    filteredContactPaymentMethods,
    hasContactPaymentMethod: !!filteredContactPaymentMethods.length,
    loading: !!loading,
  }
}
export default useContactPaymentMethods

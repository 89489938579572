import { Columns, LoadingModule } from '@campaignhub/suit-theme'

import useBusinessUnit from '@hooks/useBusinessUnit'

import PageLayout from '../../../../components/PageLayout'
import Statements from '../../../../components/Statements'

import Tools from '../Tools'

const PageContent = (props: { businessUnitId: number }) => {
  const { businessUnitId } = props

  const businessUnitPayload = useBusinessUnit({ id: businessUnitId })

  const {
    callbacks,
    businessUnit,
    loading,
    urls,
  } = businessUnitPayload

  const { name } = businessUnit || {}

  return (
    <PageLayout entityPayload={businessUnitPayload} activeTabBarItemKey="statements">
      <Columns flexDirection={['column', 'column', 'row']}>
        <Columns.Main>
          <LoadingModule loading={loading} />

          <Statements businessUnitId={businessUnitId} />
        </Columns.Main>

        <Columns.Sidebar>
          <Tools callbacks={callbacks} />
        </Columns.Sidebar>
      </Columns>
    </PageLayout>
  )
}

export default PageContent

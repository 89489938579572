import { CardNumberElement } from '@stripe/react-stripe-js'
import type { Stripe } from '@stripe/stripe-js'

import api from '@functions/api'

import handleError from '@redux/utils'

type setupStripePaymentMethodProps = {
  contactId: number,
  userId: number,
}

export function setupStripePaymentMethod(props: setupStripePaymentMethodProps) {
  const { contactId, userId } = props || {}

  const promise = api(
    '/payment-methods/create-setup-intent',
    {},
    {
      method: 'POST',
      data: JSON.stringify({
        contactId,
        userId,
      }),
    },
  ).then(({ data }) => ({ success: true, data })).catch((data) => {
    const errors = handleError(data)
    return { success: false, errors }
  })

  return promise
}

type CreateStripePaymentMethodProps = {
  cardNumberElement: typeof CardNumberElement,
  name: string,
  stripe: Stripe,
}

export function createStripePaymentMethod(props: CreateStripePaymentMethodProps) {
  const { cardNumberElement, name, stripe } = props || {}

  const promise = stripe.createPaymentMethod({
    type: 'card',
    card: cardNumberElement,
    billing_details: {
      name,
    },
  }).then(({ error, paymentMethod }) => {
    if (paymentMethod){
      return { success: true, paymentMethod }
    }

    const { message } = error || {}

    return { success: false, errors: [message || 'Stripe Error.'] }
  })

  return promise
}

type ConfirmStripePaymentMethodProps = {
  paymentMethodId: string,
  setupIntentId: string,
}

export function confirmStripePaymentMethod(props: ConfirmStripePaymentMethodProps) {
  const { paymentMethodId, setupIntentId } = props || {}

  const promise = api(
    '/payment-methods/confirm-setup-intent',
    {},
    {
      method: 'POST',
      data: JSON.stringify({
        setupIntentId,
        paymentMethodId,
      }),
    },
  ).then(() => ({ success: true })).catch((response) => {
    const errors = handleError(response)
    return { success: false, errors }
  })

  return promise
}

import { Route, Routes } from 'react-router-dom'

import Credits from './screens/Credits'
import Details from './screens/Details'
import Invoices from './screens/Invoices'
import Overview from './screens/Overview'
import Statements from './screens/Statements'
import Subscriptions from './screens/Subscriptions'

const BusinessUnitRoutes = (props: { businessUnitId: number }) => {
  const { businessUnitId } = props || {}

  return (
    <Routes>
      <Route index element={<Overview businessUnitId={businessUnitId} />} />
      <Route path="/credits" element={<Credits businessUnitId={businessUnitId} />} />
      <Route path="/details" element={<Details businessUnitId={businessUnitId} />} />
      <Route path="/invoices" element={<Invoices businessUnitId={businessUnitId} />} />
      <Route path="/statements" element={<Statements businessUnitId={businessUnitId} />} />
      <Route path="/subscriptions" element={<Subscriptions businessUnitId={businessUnitId} />} />
    </Routes>
  )
}

export default BusinessUnitRoutes

import {
  Button,
  Form,
  LoadingModule,
  SectionDivider,
  SidebarModal,
  SidebarNoticeBox,
  Text,
} from '@campaignhub/suit-theme'

import UserListItem from '@components/UserListItem'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useImportUsers from './hooks/useImportUsers'

import type { RealbaseUser } from './hooks/useImportUsers'

const MODAL_KEY = 'ImportUsersModal'

type ModalCallbacks = {
  closeModal: () => void,
  contactId: number,
  importUsers: (payload: HandleCallbackActionParams) => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const ImportUsersModal = (props: ModalProps) => {
  const { callbacks, contactId, showModal } = props
  const { closeModal, importUsers } = callbacks

  const {
    alreadyImportedRealbaseIds,
    callbacks: {
      importUsers: importUsersFn,
      setState,
      toggleUser,
    },
    realbaseUsers,
    hasRealbaseUsers,
    hasSelectedUsers,
    loading,
    selectedUserIds,
    showAvailableUsers,
    string,
  } = useImportUsers({ contactId })

  const importUsersPayload = {
    callbacks: {
      action: importUsersFn,
      afterAction: closeModal,
    },
    entityParams: { contactId, selectedUserIds },
    toastText: selectedUserIds.length > 1 ? 'Import Users Queued' : 'Imported User',
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title="Import"
        titleSecondLine="Users"
      />

      <SidebarModal.Content>
        <Form>
          <Form.Field direction="column" label="Search Users">
            <input
              onChange={e => setState({ string: e.target.value })}
              type="text"
              value={string}
            />
          </Form.Field>

          {/* Loading Realbase Users */}
          <LoadingModule boxProps={{ marginTop: 'large' }} loading={loading} times={1} />

          {showAvailableUsers && !loading && (
            <>
              <SectionDivider boxProps={{ marginTop: 'large' }}>
                Available Users
              </SectionDivider>

              {!hasRealbaseUsers && (
                <SidebarNoticeBox boxProps={{ marginTop: 'medium' }}>
                  <SidebarNoticeBox.Title>Not Users to Import</SidebarNoticeBox.Title>
                </SidebarNoticeBox>
              )}

              {hasRealbaseUsers && (
                <Form.Field
                  direction="column"
                  label="Select Users"
                  marginTop="large"
                >
                  {realbaseUsers.map((user: RealbaseUser) => {
                    const { id, fullName } = user || {}
                    const isImported = alreadyImportedRealbaseIds.includes(id)

                    return (
                      <UserListItem
                        disableHover
                        isChecked={selectedUserIds.includes(id)}
                        isModal
                        key={id}
                        onClick={isImported ? undefined : () => toggleUser(id)}
                        showAngleIcon={false}
                        showCheckbox
                        title={(
                          <Text color={isImported ? 'bodyFontLightColor' : 'bodyFontColor'}>
                            {fullName}{isImported ? ' (Imported)' : ''}
                          </Text>
                        )}
                      />
                    )
                  })}
                </Form.Field>
              )}
            </>
          )}
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!hasSelectedUsers}
          onClick={() => importUsers(importUsersPayload)}
          size="large"
        >
          Import Users
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <ImportUsersModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal

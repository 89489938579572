import {
  faCoins,
  faCopy,
  faCreditCardBlank,
  faFileInvoice,
  faStore,
  faTachometer,
} from '@fortawesome/pro-light-svg-icons'

import { generateUrls } from '@hooks/useContact'

import type { ContactModel } from '@models/contact'

const generateTabBarItems = (contact: ContactModel) => {
  const urls = generateUrls(contact)
  const {
    editContactDetailsUrl,
    editContactCreditsUrl,
    editContactInvoicesUrl,
    editContactStatementsUrl,
    editContactSubscriptionsUrl,
    editContactUrl,
  } = urls

  return [
    {
      href: editContactUrl,
      icon: faTachometer,
      key: 'overview',
      title: 'Overview',
    },
    {
      href: editContactDetailsUrl,
      icon: faStore,
      key: 'details',
      title: 'Details',
    },
    {
      href: editContactInvoicesUrl,
      icon: faFileInvoice,
      key: 'invoices',
      title: 'Invoices',
    },
    {
      href: editContactCreditsUrl,
      icon: faCreditCardBlank,
      key: 'credits',
      title: 'Credits',
    },
    // {
    //   href: editContactStatementsUrl,
    //   icon: faCopy,
    //   key: 'statements',
    //   title: 'Statements',
    // },
    {
      href: editContactSubscriptionsUrl,
      icon: faCoins,
      key: 'subscriptions',
      title: 'Subscriptions',
    },
  ]
}

export default generateTabBarItems

import { useContext } from 'react'

import { useForm, useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import * as contactPaymentMethodActions from '@redux/modules/contactPaymentMethod'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import PageContext from '@contexts/pageContext'

import { default as defaultContactPaymentMethodState, requiredFields } from '@models/contactPaymentMethod'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams, UpdateParams } from '@redux/modules/types'
import type { ContactPaymentMethodModel, ContactPaymentMethodRequestOptions } from '@models/contactPaymentMethod'

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: ContactPaymentMethodRequestOptions,
}

export type RequestContactPaymentMethodPayload = {
  contactId: number,
  message: string,
  userIds: number[],
}

type RequestContactPaymentMethodParams = {
  dispatch: AppDispatch,
  payload: RequestContactPaymentMethodPayload,
}

// Fire off API Request to send Email job
const requestContactPaymentMethod = (params: RequestContactPaymentMethodParams) => {
  const { payload, dispatch } = params
  const { requestContactPaymentMethod: requestFn } = contactPaymentMethodActions

  return dispatch(requestFn(payload))
}

type DeleteContactPaymentMethodParams = {
  contactPaymentMethod: DeleteParams<ContactPaymentMethodModel>,
  dispatch: AppDispatch,
}

const deleteContactPaymentMethod = (params: DeleteContactPaymentMethodParams) => {
  const { contactPaymentMethod, dispatch } = params
  const { deleteContactPaymentMethod: deleteFn } = contactPaymentMethodActions

  return dispatch(deleteFn(contactPaymentMethod))
}

type UpdateContactPaymentMethodParams = {
  contactPaymentMethodParams: UpdateParams<ContactPaymentMethodModel>,
  contactPaymentMethod: ContactPaymentMethodModel,
  dispatch: AppDispatch,
  requestOptions: ContactPaymentMethodRequestOptions,
}

const updateContactPaymentMethod = (params: UpdateContactPaymentMethodParams) => {
  const {
    contactPaymentMethodParams, contactPaymentMethod, dispatch, requestOptions = {},
  } = params
  const { updateContactPaymentMethod: updateFn } = contactPaymentMethodActions

  const updatedContactPaymentMethod = {
    ...contactPaymentMethod,
    ...contactPaymentMethodParams,
  }

  return dispatch(updateFn(updatedContactPaymentMethod, requestOptions))
}

type CreateOrEditParams = {
  contactPaymentMethod: ContactPaymentMethodModel,
  showCreateOrEditContactPaymentMethodModal: (payload: { contactPaymentMethod: ContactPaymentMethodModel }) => void,
}

const createOrEditContactPaymentMethod = (params: CreateOrEditParams) => new Promise((resolve, reject) => {
  const { contactPaymentMethod, showCreateOrEditContactPaymentMethodModal } = params

  if (showCreateOrEditContactPaymentMethodModal){
    const payload = {
      contactPaymentMethod,
    }

    showCreateOrEditContactPaymentMethodModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showcreateOrEditContactPaymentMethodModal not defined in PageContext callbacks'))
})

export function useContactPaymentMethodForm(contactPaymentMethod: ContactPaymentMethodModel, options?: UseFormOptions) {
  const { validateOn } = options || {}

  const contactPaymentMethodForm = useForm(
    defaultContactPaymentMethodState,
    { entity: contactPaymentMethod, requiredFields, validateOn },
    [contactPaymentMethod.id],
  )

  return {
    ...contactPaymentMethodForm,
  }
}

export const useRelations = (contactPaymentMethod: ContactPaymentMethodModel) => {
  const { contactId, paymentMethodId } = contactPaymentMethod

  const { contacts, paymentMethods } = useSelector(reduxState => reduxState.entities)

  const contact = contactId && contacts[contactId] ? contacts[contactId] : {}
  const paymentMethod = paymentMethodId && paymentMethods[paymentMethodId] ? paymentMethods[paymentMethodId] : {}

  return {
    contact,
    paymentMethod,
  }
}

function useContactPaymentMethod(initEntity: ContactPaymentMethodModel = { id: null }, options?: Options) {
  const { performHttpRequests, requestOptions } = options || {}

  const { entity: contactPaymentMethod } = useLatestEntity(initEntity, 'contactPaymentMethods')
  const { id } = contactPaymentMethod

  const dispatch = useThunkDispatch()

  const pageContext = useContext(PageContext)
  const { callbacks: { showCreateOrEditContactPaymentMethodModal } } = pageContext

  const { contact, paymentMethod } = useRelations(contactPaymentMethod)

  useReduxAction(
    'contactPaymentMethods',
    'loadContactPaymentMethod',
    {
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, requestOptions) => action(id, requestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedIds, loaded, loading } = entityReducer
        return performHttpRequests && !loading && !loaded && !loadedIds.includes(id)
      },
    },
  )

  const { loading } = useSelector(reduxState => reduxState.contactPaymentMethods)

  // Note: ContactPaymentMethods are created in useStripePaymentMethod

  return {
    callbacks: {
      createOrEditContactPaymentMethod: () => createOrEditContactPaymentMethod({
        contactPaymentMethod, showCreateOrEditContactPaymentMethodModal,
      }),
      deleteContactPaymentMethod: () => deleteContactPaymentMethod({ contactPaymentMethod, dispatch }),
      requestContactPaymentMethod: (payload: RequestContactPaymentMethodPayload) => requestContactPaymentMethod(
        { payload, dispatch },
      ),
      updateContactPaymentMethod: (contactPaymentMethodParams: ContactPaymentMethodModel, requestOptions: ContactPaymentMethodRequestOptions) => updateContactPaymentMethod({
        contactPaymentMethodParams, contactPaymentMethod, dispatch, requestOptions,
      }),
    },
    contact,
    contactPaymentMethod,
    loading,
    paymentMethod,
  }
}

export default useContactPaymentMethod

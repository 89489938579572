import api from '@functions/api'

import type { ContactSummaryModel } from '@models/contactSummary'
import type { AppDispatch } from '@redux/store'
import type { Action, ActionResult, Errors } from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/contactSummary/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/contactSummary/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/contactSummary/FETCH_FAILURE'

export type ContactSummaryModuleState = {
  errors: string[],
  loaded: boolean,
  loading: boolean,
  result: ContactSummaryModel | {},
}

const initialState: ContactSummaryModuleState = {
  errors: [],
  loaded: false,
  loading: false,
  result: {},
}

// Actions
export function fetchRequest(): Action {
  return {
    type: FETCH_REQUEST,
  }
}

type FetchRequestPayload = {
  result: ContactSummaryModel,
  type: string,
}

export function fetchSuccess(payload: FetchRequestPayload): Action {
  return {
    type: FETCH_SUCCESS,
    result: payload.result,
  }
}

export function fetchFailure(errors: Errors = []): Action {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

// Action Creators
export function fetchContactSummary(){
  return (dispatch: AppDispatch): Promise<ActionResult> => {
    dispatch(fetchRequest())

    const promise = api('/contacts/summary')
      .then(({ data }) => {
        dispatch(fetchSuccess({ result: data }))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: ContactSummaryModuleState = initialState,
  action: Action = { type: '' },
): ContactSummaryModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        errors: [],
        loaded: true,
        loading: false,
        result: action.result || state.result,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        errors: action.errors || [],
        loaded: false,
        loading: false,
      }
    default:
      return state
  }
}

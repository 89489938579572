export type BankModel = {
    accountNumber: number,
    bankIdentifierCode?: string,
    branchNumber: number,
    id: number | null,
    internationalBankAccountNumber?: string,
    name: string,
    bankTypeId: number,
}

const state = {
    accountNumber: null,
    bankIdentifierCode: '',
    branchNumber: null,
    id: null,
    internationalBankAccountNumber: '',
    name: '',
    bankTypeId: null,
}
  
export const requiredFields = [
    { key: 'accountNumber' },
    { key: 'branchNumber' },
    { key: 'name' },
    { key: 'bankTypeId' },
]
  
export default state
  
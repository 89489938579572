import { Elements } from '@stripe/react-stripe-js'

import useCurrentUser from '@hooks/useCurrentUser'

import useSetupStripe from './hooks/useSetupStripe'

import StripePaymentMethod from './components/StripePaymentMethod'

interface CreateContactPaymentMethodProps {
  callbacks: {
    closeModal: () => void,
    createContactPaymentMethod: () => void,
  },
  contactId: number,
}

const CreateContactPaymentMethod = (props: CreateContactPaymentMethodProps) => {
  const { callbacks, contactId } = props || {}

  const { currentUser } = useCurrentUser({ performHttpRequests: true })
  const { id: userId } = currentUser || {}

  // Initialize Realbase Stripe
  const stripePayload = useSetupStripe({ contactId, userId })
  const { setupIntentId, stripePromise } = stripePayload || {}

  return (
    <Elements stripe={stripePromise}>
      <StripePaymentMethod
        callbacks={callbacks}
        contactId={contactId}
        setupIntentId={setupIntentId}
      />
    </Elements>
  )
}

export default CreateContactPaymentMethod

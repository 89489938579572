import { useContext } from 'react'

import { useForm, useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'
import type { UseFormOptions, UseFormPayload } from '@campaignhub/react-hooks'

import * as subscriptionActions from '@redux/modules/subscription'

import PageContext from '@contexts/pageContext'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import { default as defaultState, requiredFields } from '@models/subscription'

import type { SubscriptionModel, SubscriptionRequestOptions } from '@models/subscription'
import type { AppDispatch } from '@redux/store'

type SubscriptionForm = { entityState: SubscriptionModel }

export type SubscriptionFormPayload = UseFormPayload & SubscriptionForm;

export interface SubscriptionPayload {
  callbacks: {
    cancelSubscription: (entityParams: Partial<SubscriptionModel>, requestOptions: SubscriptionRequestOptions) => void,
    createSubscription: (entityParams: Partial<SubscriptionModel>) => void,
    updateSubscription: (entityParams: Partial<SubscriptionModel>, requestOptions: SubscriptionRequestOptions) => void,
  },
  subscription: SubscriptionModel,
  loading: boolean,
  urls: {
    [key: string]: string,
  },
}
interface Options {
  performHttpRequests?: boolean,
  requestOptions: {
    [key: string]: boolean,
  },
}

export const generateUrls = (subscription?: SubscriptionModel) => {
  const { id } = subscription || {}

  return {
    editSubscriptionUrl: `#/accounting/subscriptions/${id}/edit`,
    subscriptionsIndexUrl: '#/accounting/subscriptions',
  }
}

type CreateSubscriptionParams = {
  dispatch: AppDispatch,
  entityParams: Partial<SubscriptionModel>,
  requestOptions?: SubscriptionRequestOptions,
}

const createSubscription = (params: CreateSubscriptionParams) => {
  const { entityParams, dispatch, requestOptions = {} } = params
  const { createSubscription: createFn } = subscriptionActions

  return dispatch(createFn(entityParams, requestOptions))
}

type UpdateSubscriptionParams = {
  dispatch: AppDispatch,
  entityParams: Partial<SubscriptionModel>,
  subscription: SubscriptionModel,
  requestOptions?: SubscriptionRequestOptions,
}

export const updateSubscription = (params: UpdateSubscriptionParams) => {
  const {
    dispatch, entityParams, subscription, requestOptions,
  } = params
  const { updateSubscription: updateFn } = subscriptionActions

  const updatedParams = {
    id: subscription.id,
    ...entityParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

export function useSubscriptionForm(subscription: SubscriptionModel, options: UseFormOptions = {}): SubscriptionFormPayload {
  const { validateOn } = options

  const subscriptionForm = useForm(
    defaultState,
    { entity: subscription, requiredFields, validateOn },
    [subscription.cacheKey],
  )

  return {
    ...subscriptionForm,
  }
}

export const useRelations = (subscription: SubscriptionModel) => {
  const { contactId, contactPaymentMethodId } = subscription

  const { contacts, contactPaymentMethods, paymentMethods } = useSelector(reduxState => reduxState.entities)

  const contact = contactId && contacts[contactId] ? contacts[contactId] : {}
  const contactPaymentMethod = contactPaymentMethodId
  && contactPaymentMethods[contactPaymentMethodId] ? contactPaymentMethods[contactPaymentMethodId] : {}

  const { paymentMethodId } = contactPaymentMethod || {}
  const paymentMethod = paymentMethodId
  && paymentMethods[paymentMethodId] ? paymentMethods[paymentMethodId] : {}

  return {
    contact,
    contactPaymentMethod,
    paymentMethod,
  }
}

function useSubscription(initEntity: SubscriptionModel = { id: null }, options?: Options) {
  const { performHttpRequests, requestOptions } = options || {}

  const { entity: subscription } = useLatestEntity(initEntity, 'subscriptions')
  const { id } = subscription

  const dispatch = useThunkDispatch()

  const { callbacks } = useContext(PageContext)

  useReduxAction(
    'subscriptions',
    'loadSubscription',
    {
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, requestOptions) => action(id, requestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedIds } = entityReducer
        return performHttpRequests && !loadedIds.includes(id)
      },
    },
  )

  const { loading, updating } = useSelector(reduxState => reduxState.subscriptions)

  return {
    callbacks: {
      cancelSubscription: (entityParams: Partial<SubscriptionModel>, requestOptions: SubscriptionRequestOptions) => updateSubscription({
        entityParams, dispatch, requestOptions, subscription,
      }),
      createSubscription: (entityParams: Partial<SubscriptionModel>) => createSubscription({ entityParams, dispatch }),
      pauseSubscription: (entityParams: Partial<SubscriptionModel>, requestOptions: SubscriptionRequestOptions) => updateSubscription({
        entityParams, dispatch, requestOptions, subscription,
      }),
      updateSubscription: (entityParams: Partial<SubscriptionModel>, requestOptions: SubscriptionRequestOptions) => updateSubscription({
        entityParams, dispatch, requestOptions, subscription,
      }),
    },
    subscription,
    loading,
    updating,
    urls: generateUrls(subscription),
  }
}

export default useSubscription

import { Helmet } from 'react-helmet-async'
import { useAuth0 } from '@auth0/auth0-react'

import {
  faClock,
  faCreditCardBlank,
  faPencil,
  faStore,
  faTachometer,
} from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule,
} from '@campaignhub/suit-theme'

import Accounts from '@components/Accounts'
import AdminPageHeader from '@components/AdminPageHeader'
import InvoiceStatistics from '@components/InvoiceStatistics'
import NewCustomersGraph from '@components/NewCustomersGraph'
import SubscribersGraph from '@components/SubscribersGraph'
import SubscriptionStatistics from '@components/SubscriptionStatistics'

import DraftInvoices from './components/DraftInvoices'
import OverdueAccounts from './components/OverdueAccounts'
import OverdueInvoices from './components/OverdueInvoices'
import PendingCredits from './components/PendingCredits'

const Overview = () => {
  const { logout } = useAuth0()

  return (
    <>
      <Helmet>
        <title>Dashboard | Billing</title>
      </Helmet>

      <AdminPageHeader
        activeTabBarItemKey="dashboard"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: '#/',
            icon: faTachometer,
            key: 'dashboard',
            title: 'ALL',
          },
          {
            href: '#/',
            icon: faPencil,
            key: 'draftInvoice',
            title: 'Draft Invoices',
          },
          {
            href: '#/',
            icon: faClock,
            key: 'overdue',
            title: 'Overdue',
          },
          {
            href: '#/',
            icon: faStore,
            key: 'overdueOffices',
            title: 'Overdue Offices',
          },
          {
            href: '#/',
            icon: faCreditCardBlank,
            key: 'credits',
            title: 'Credits',
          },
        ]}
        title="Dashboard"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <DraftInvoices />

            <OverdueInvoices />

            <OverdueAccounts />

            <PendingCredits />
          </Columns.Main>

          <Columns.Sidebar>
            <DashboardModule title="Actions">
              <Box flexDirection="column" padding="large">
                <Button
                  buttonStyle="secondary"
                  onClick={() => logout({ returnTo: window.location.origin })}
                  size="medium"
                >
                  Log Out
                </Button>
              </Box>
            </DashboardModule>

            <Accounts />

            <SubscriptionStatistics />

            <InvoiceStatistics />

            <NewCustomersGraph />

            <SubscribersGraph />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default Overview

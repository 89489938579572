import { DashboardModule } from '@campaignhub/suit-theme'

import { CreditPayload } from '@hooks/useCredit'

import useCreditAllocations from '@hooks/useCreditAllocations'

import ListBlankState from '@components/ListBlankState'

import CreditAllocationHistoryTable from './components/Table'

interface CreditProps {
  creditPayload: CreditPayload,
}

const CreditAllocationHistory = (props: CreditProps) => {
  const { creditPayload } = props
  const { credit, loading } = creditPayload || {}

  const creditAllocationsPayload = useCreditAllocations({ filters: { creditId: credit.id } })
  const { hasFilteredCreditAllocations } = creditAllocationsPayload

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column' }}
      loading={loading}
      title="Credit Allocation History"
    >

      {!loading && !hasFilteredCreditAllocations && <ListBlankState title="No Credit Allocations yet" text=" " />}

      {!loading && hasFilteredCreditAllocations && (<CreditAllocationHistoryTable credit={credit} />)}

    </DashboardModule>
  )
}

export default CreditAllocationHistory

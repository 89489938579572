import { useSelector } from 'react-redux'
import getSymbolFromCurrency from 'currency-symbol-map'

import { DashboardModule, Form } from '@campaignhub/suit-theme'

import  { BusinessUnitFormPayload } from '@hooks/useBusinessUnit'
import useTaxJurisdictions from '@hooks/useTaxJurisdictions'
import useTaxJurisdiction from '@hooks/useTaxJurisdiction'

interface TaxJurisdictionProps {
  businessUnitFormPayload: BusinessUnitFormPayload,
}

const TaxJurisdiction = (props: TaxJurisdictionProps) => {
  const { businessUnitFormPayload } = props || {}

  const { entities } = useSelector(reduxState => reduxState)
 
  const {
    entityState: {
      taxJurisdictionId
    },
    errors,
    handlers
   } = businessUnitFormPayload

  const { filteredTaxJurisdictions } = useTaxJurisdictions()
  
  const { currencyPrefix, taxRate } = useTaxJurisdiction(taxJurisdictionId)

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column', padding: 'large' }} title="Tax">
      <Form>
        <Form.Row>
          <Form.Field       
            label="* Tax Jurisdiction" errorMessage={errors.taxJurisdictionId}
          >
            <select
              data-value-type="number"
              disabled={false}
              name="taxJurisdictionId"
              value={taxJurisdictionId}
              {...handlers}
            >
            <option value="">Please Select...</option>
              {filteredTaxJurisdictions.map(tax => (
                <option key={tax.id} value={tax.id}>
                  {tax.name}
                </option>
              ))}
            </select>
          </Form.Field>
          <Form.Field label="Tax Rate"  >
                <input
                disabled={true}
                name="taxRate"
                type="text"
                value={taxRate}
                />
            </Form.Field>
        </Form.Row>
        <Form.Row marginTop="large">
            <Form.Field label="Currency Symbol"  >
                <input
                disabled={true}
                name="currencySymbol"
                type="text"
                value={getSymbolFromCurrency(currencyPrefix)}
                />
            </Form.Field>
            <Form.Field label="Currency Prefix"  >
                <input
                disabled={true}
                name="currencyPrefix"
                type="text"
                value={currencyPrefix}
                />
            </Form.Field>
        </Form.Row>
      </Form>
    </DashboardModule>
  )
}

export default TaxJurisdiction

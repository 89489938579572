import { ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'
import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'
import { useModals, useSetState } from '@campaignhub/react-hooks'
import { Box, Button, Columns, ModalContext } from '@campaignhub/suit-theme'
import AdminPageHeader from '@components/AdminPageHeader'
import handleCallbackAction, { HandleCallbackActionParams } from '@functions/handleCallbackAction'
import useBusinessUnit from '@hooks/useBusinessUnit'
import CreateBusinessUnitModal from '@modals/CreateBusinessUnitModal'
import generateTabBarItems from '../../utils/generateTabBarItems'

interface PageLayoutProps {
  activeTabBarItemKey?: string,
  children: ReactNode,
  entityPayload: ReturnType<typeof useBusinessUnit>,
}

const defaultState = {  
  showCreateBusinessUnitModal: false 
}

const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    CreateBusinessUnitModal: {
      closeModal: () => setState({ showCreateBusinessUnitModal: false }),
      createBusinessUnit: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const PageLayout = (props: PageLayoutProps) => {
  const {
    activeTabBarItemKey,
    entityPayload,
    children,
  } = props

  const {
    businessUnit,
    urls,
  } = entityPayload || {}

  const { name } = businessUnit || {}
  const { businessUnitOverviewUrl } = urls || {}

  const [state, setState] = useSetState(defaultState)
  const {
    showCreateBusinessUnitModal,
  } = state
  
  const { redirect } = parsePermittedQueryParams(getQueryParamsFromHash(), ['redirect'])

  const showCreateModal = () => {
    setState({ showCreateBusinessUnitModal: true })
  }

  const modalContext = useModals()
  
  return (
    <>
      <ModalContext.Provider value={modalContext}>
        <CreateBusinessUnitModal
              callbacks={callbacks('CreateBusinessUnitModal', setState)}
              showModal={showCreateBusinessUnitModal}
            />
      </ModalContext.Provider>

      <Helmet>
        <title>Business Unit | Core Billing</title>
      </Helmet>

      <AdminPageHeader
        actionContent={(
        <div>
          <Button
            as="a"
            buttonStyle="primaryCreate"
            href={redirect || businessUnitOverviewUrl}
            size="medium"
            width="auto" 
             onClick={(e: any) => {
              e.preventDefault(); 
              showCreateModal(); 
            }}
          >
            Create
          </Button>
          <Button
            marginLeft="large"
            as="a"
            buttonStyle="secondaryUtility"
            href={redirect || businessUnitOverviewUrl}
            size="medium"
            width="auto"
          >
            Back
          </Button>
        </div>
        )}
        activeTabBarItemKey={activeTabBarItemKey}
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={generateTabBarItems(businessUnit)}
        title={name || 'Business Unit'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          {children}
        </Columns>
      </Box>
    </>
  )
}

export default PageLayout

import type { ContactModel, TransactionModel } from './types'

export type InvoiceRequestOptions = {
  includeInvoiceBusinessUnit?: boolean,
  includeInvoiceContact?: boolean,
  includeInvoiceCreditApplied?: boolean,
  includeInvoiceInvoiceMetaData?: boolean,
  includeInvoiceTransaction?: boolean,
}

type InvoiceMetaData = {
  campaignId?: string,
  campaignAddress?: string,
  campaignAgentName?: string,
  orderId?: string,
}

export type InvoiceModel = {
  amountPaid?: number,
  balance?: number,
  businessUnitId?: number,
  cacheKey?: string,
  contact?: ContactModel,
  contactId?: number,
  dateCreated?: string,
  dateDue?: string,
  dateExported?: string,
  daysOverdue?: number,
  id: number | null,
  invoiceMetaData?: InvoiceMetaData,
  number?: string,
  paymentDate?: string,
  referenceCode?: string,
  statusId?: number, // statusId is returned in DTO from invoice.transaction
  transaction?: TransactionModel,
  transactionId?: number,
}

export type InvoiceCsvFormParams = {
  contactId?: number,
  endDate?: string | null,
  startDate: string,
  status: string,
}

export const invoiceFormState = {
  contactId: null,
  endDate: '',
  startDate: '',
  status: null,
}

const state = {
  amountPaid: null,
  balance: null,
  contactId: null,
  dateDue: '',
  daysOverdue: null,
  id: null,
  number: '',
  paymentDate: '',
  transactionId: null,
}

export const requiredFields = [
  { key: 'transactionId' },
  { key: 'contactId' },
]

export default state

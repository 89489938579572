import { useContext } from 'react'
import Swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faFilePdf, faTimesCircle } from '@fortawesome/pro-light-svg-icons'

import { Button, Tools } from '@campaignhub/suit-theme'

import { swalColors } from '@constants/swal'

import useContact from '@hooks/useContact'

import { ContactModel } from '@models/contact'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import PageContext from '@contexts/pageContext'

const viewOnly = JSON.parse(process.env.REACT_APP_VIEW_ONLY || "false")

type ConfirmActionParams = {
  contact: ContactModel,
  toggleActive: (updateContactPayload: HandleCallbackActionParams) => void,
  updateContactPayload: HandleCallbackActionParams,
}

const confirmAction = (params: ConfirmActionParams) => {
  const { contact, toggleActive, updateContactPayload } = params
  const { active, name } = contact

  const confirmButtonColor = active ? swalColors.deletion : swalColors.approval

  Swal
    .fire({
      confirmButtonColor,
      confirmButtonText: `Set ${active ? 'Inactive' : 'Active'}`,
      icon: active ? 'error' : 'success',
      showCancelButton: true,
      text: `Yes, set ${name || 'contact'} to ${active ? 'inactive.' : 'active.'}`,
      title: `${active ? 'Set Contact to Inactive' : 'Set Contact to Active'}?`,
    })
    .then(({ value }) => {
      if (value) {
        toggleActive(updateContactPayload)
      }
    })
}

interface ContactToolsProps {
  contact: ContactModel,
  isAdmin: boolean,
}

const ContactTools = (props: ContactToolsProps) => {
  const { contact, isAdmin } = props
  const { active } = contact

  const pageContext = useContext(PageContext)
  const { callbacks: { toggleActive } } = pageContext

  const {
    callbacks: {
      generateStatement,
      toggleActive: toggleFn
    }
  } = useContact(contact)

  const updateContactPayload = {
    callbacks: {
      action: toggleFn,
    },
    toastText: 'Contact Updated',
  }

  return (
    <Tools>
      {!viewOnly && isAdmin && (
        <Button
          buttonStyle="secondaryUtility"
          icon={<FontAwesomeIcon icon={active ? faTimesCircle : faCheckCircle} />}
          iconPosition="left"
          onClick={() => confirmAction({ contact, toggleActive, updateContactPayload })}
          size="medium"
        >
          {active ? 'Set Contact Inactive' : 'Set Contact Active'}
        </Button>
      )}

      <Button
        buttonStyle="secondaryUtility"
        icon={<FontAwesomeIcon icon={faFilePdf} />}
        marginTop="medium"
        onClick={() => generateStatement()}
        size="medium"
      >
        Generate Statement
      </Button>
    </Tools>
  )
}

export default ContactTools

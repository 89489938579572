import { useContext } from 'react'

import { digObject } from '@campaignhub/javascript-utils'
import {
  useForm, useLatestEntity, useSetState, useThunkDispatch,
} from '@campaignhub/react-hooks'
import type { UseFormOptions, UseFormPayload } from '@campaignhub/react-hooks'

import * as invoiceActions from '@redux/modules/invoice'

import PageContext from '@contexts/pageContext'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import { default as defaultInvoiceState, requiredFields } from '@models/invoice'

import type { InvoiceModel, InvoiceRequestOptions } from '@models/invoice'
import type { MergeInvoiceParams } from '@functions/invoice'
import type { AppDispatch } from '@redux/store'

type InvoiceForm = { entityState: InvoiceModel }

export type InvoiceFormPayload = UseFormPayload & InvoiceForm;

export interface InvoicePayload {
  callbacks: {
    createMergedInvoice: (mergeInvoiceParams: MergeInvoiceParams) => void,
    downloadInvoicePDF: () => void,
    editInvoice: () => void,
    shareInvoiceStatement: () => void,
    updateInvoice: (invoiceParams: InvoiceModel) => void,
  },
  editing: boolean,
  invoice: InvoiceModel,
  isDraftInvoice: boolean,
  loading: boolean,
  urls: {
    [key: string]: string,
  },
}
interface Options {
  performHttpRequests?: boolean,
  requestOptions?: InvoiceRequestOptions,
}

export const generateUrls = (invoice?: InvoiceModel) => {
  const { id } = invoice || {}

  return {
    editInvoiceUrl: `#/accounting/invoices/${id}/edit`,
    invoicesIndexUrl: '#/accounting/invoices',
  }
}

const createMergedInvoice = (params: { mergeInvoiceParams: MergeInvoiceParams, dispatch: AppDispatch}) => {
  const { mergeInvoiceParams, dispatch } = params
  const { mergeInvoices: mergeFn } = invoiceActions

  return dispatch(mergeFn(mergeInvoiceParams)).then((response) => {
    const { success, data } = response
    const { id } = data || {}

    const { editInvoiceUrl } = generateUrls({ id })

    if (success){
      return {
        success,
        data,
        redirectUrl: editInvoiceUrl,
      }
    }
  })
}

type DownloadInvoiceParams = {
  id: number,
  dispatch: AppDispatch,
}

export const downloadInvoicePDF = (params: DownloadInvoiceParams) => {
  const { id, dispatch } = params
  const { downloadInvoicePDF: downloadFn } = invoiceActions

  return dispatch(downloadFn(id))
}

type LoadInvoiceParams = {
  id: number,
  dispatch: AppDispatch,
  requestOptions?: InvoiceRequestOptions,
}

export const loadInvoice = (params: LoadInvoiceParams) => {
  const { id, dispatch, requestOptions} = params
  const { loadInvoice: loadFn } = invoiceActions

  return dispatch(loadFn(id, requestOptions))
}

type UpdateInvoiceParams = {
  invoiceParams: Partial<InvoiceModel>,
  dispatch: AppDispatch,
  requestOptions?: InvoiceRequestOptions,
}

export const updateInvoice = (params: UpdateInvoiceParams) => {
  const { invoiceParams, dispatch, requestOptions } = params
  const { updateInvoice: updateFn } = invoiceActions

  return dispatch(updateFn(invoiceParams, requestOptions))
}

export function useInvoiceForm(invoice: InvoiceModel, options: UseFormOptions = {}): InvoiceFormPayload {
  const { validateOn } = options

  const invoiceForm = useForm(
    defaultInvoiceState,
    { entity: invoice, requiredFields, validateOn },
    [invoice.cacheKey],
  )

  return {
    ...invoiceForm,
  }
}

const defaultState = {
  editing: false,
}

function useInvoice(initEntity: InvoiceModel = { id: null }, options?: Options) {
  const { performHttpRequests, requestOptions } = options || {}

  const { entity: invoice } = useLatestEntity(initEntity, 'invoices')
  const { id, statusId } = invoice

  const [state, setState] = useSetState(defaultState)
  const { editing } = state

  const dispatch = useThunkDispatch()

  const { callbacks } = useContext(PageContext)

  useReduxAction(
    'invoices',
    'loadInvoice',
    {
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, invoiceRequestOptions) => action(id, invoiceRequestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedIds } = entityReducer
        return performHttpRequests && !loadedIds.includes(id)
      },
    },
  )

  const { loading, updating } = useSelector(reduxState => reduxState.invoices)
  const { statuses } = useSelector(reduxState => reduxState.entities)

  const status = digObject(statuses, String(statusId), {})
  const { key: statusKey } = status
  const isDraftInvoice = statusKey === 'draft'

  return {
    callbacks: {
      createMergedInvoice: (mergeInvoiceParams: MergeInvoiceParams) => createMergedInvoice({
        mergeInvoiceParams, dispatch,
      }),
      downloadInvoicePDF: () => downloadInvoicePDF({ id, dispatch }),
      editInvoice: () => { setState({ editing: !editing });  (editing) ? loadInvoice({ id, dispatch }) : null },
      updateInvoice: (invoiceParams: InvoiceModel, requestOptions?: InvoiceRequestOptions) => updateInvoice({
        invoiceParams, dispatch, requestOptions,
      }),
    },
    editing,
    invoice,
    isDraftInvoice,
    loading,
    updating,
    urls: generateUrls(invoice),
  }
}

export default useInvoice

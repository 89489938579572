const stripLoginDetailsFromUrl = () => {
  const {
    hash, origin, pathname, search,
  } = window.location

  const urlParams = new URLSearchParams(search)
  const otpToken = urlParams.get('otpToken')
  const username = urlParams.get('username')

  // Nothing to do here...
  if (!otpToken && !username){
    return
  }

  // Remove from Params
  urlParams.delete('otpToken')
  urlParams.delete('username')

  // Setup new href
  let newHref = `${origin}${pathname}`

  const searchString = urlParams.toString()
  if (searchString){
    newHref = `${newHref}?${searchString}`
  }

  newHref = `${newHref}${hash}`

  if (window.location.href !== newHref){
    window.location.href = newHref
  }
}

export default stripLoginDetailsFromUrl

import { useMemo } from 'react'

import { useFilters, useModals, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useContactTypes from '@hooks/useContactTypes'

import ImportContactsModal from '@modals/ImportContactsModal'

import type { ContactTypeModel } from '@models/contactType'

import PageContent from './components/PageContent'

type ShowImportContactsModalPayload = {
  contactType: ContactTypeModel,
}

export type PageContextPayload = {
  callbacks: {
    resetFilters: () => void,
    showImportContactsModal: (payload: ShowImportContactsModalPayload) => void,
    updateFilters: () => void,
  },
  defaultFilters: object,
}

const defaultState = {
  showImportContactsModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    ImportContactsModal: {
      closeModal: () => setState({ showImportContactsModal: false }),
      importContacts: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const List = () => {
  const [state, setState] = useSetState(defaultState)
  const { showImportContactsModal } = state

  // Load Contact Types
  useContactTypes({ performHttpRequests: true })

  const {
    callbacks: filterCallbacks,
    defaultFilters,
    pageFilters,
  } = useFilters()

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      ...filterCallbacks,
      showImportContactsModal: (payload: ShowImportContactsModalPayload) => {
        setModalData('ImportContactsModal', payload)
        setState({ showImportContactsModal: true })
      },
    },
    defaultFilters,
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent pageFilters={pageFilters} />

        <ImportContactsModal
          callbacks={callbacks('ImportContactsModal', setState)}
          showModal={showImportContactsModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default List

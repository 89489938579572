import { ListItem, Text } from '@campaignhub/suit-theme'
import { formatDate } from '@campaignhub/javascript-utils'

import type { BillingNoteModel } from '@models/types'

interface BillingNoteProps {
  billingNote: BillingNoteModel,
  index: number,
  showArchive: boolean,
}

const BillingNote = (props: BillingNoteProps) => {
  const { billingNote, index, showArchive } = props || {}
  const {
    dateCreated, note, title, userFullName,
  } = billingNote || {}

  const isLatest = index === 0

  if (!showArchive && !isLatest) return null

  return (
    <ListItem
      boxProps={{
        borderBottom: '1px dashed',
        padding: 'large',
        paddingY: isLatest ? 'large' : 'medium',
      }}
      disableHover
      flexDirection="column"
    >
      {title && (
        <Text
          color={isLatest ? 'bodyFontColor' : 'faintGrey'}
          fontSize={isLatest ? 'small' : 'xsmall'}
          marginBottom={isLatest ? 'medium' : 'small'}
          variant={isLatest ? '' : 'twoLineEllipsis'}
        >
          {title}
        </Text>
      )}

      <Text
        color={isLatest ? 'bodyFontColor' : 'faintGrey'}
        fontSize={isLatest ? 'small' : 'xsmall'}
        variant="twoLineEllipsis"
      >
        {note}
      </Text>

      <Text
        color={isLatest ? 'bodyFontLightColor' : 'faintGrey'}
        fontSize={isLatest ? 'xsmall' : 'xxsmall'}
        marginTop="small"
        variant={isLatest ? '' : 'twoLineEllipsis'}
      >
        {userFullName ? `${userFullName} - ` : ''}{formatDate(dateCreated, 'ISO8601', 'dd/MM/yyyy')}
      </Text>
    </ListItem>
  )
}

export default BillingNote

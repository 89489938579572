import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { ContactModel, StatementFormParams } from '@models/contact'
import type { AppDispatch } from '@redux/store'
import type {
  Action, ActionResult, EntityOptions, Errors, FetchRequestPayload, RootReducerState,
} from '@redux/modules/types'
import type { LoadFromRealbaseParams } from '@modals/ImportContactsModal/hooks/useImportContacts'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/contact/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/contact/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/contact/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/contact/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/contact/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/contact/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/contact/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/contact/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/contact/UPDATE_FAILURE'

export type ContactModuleState = {
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loading: boolean,
  updating: boolean,
}

const initialState: ContactModuleState = {
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loadedIds: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}): Action {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(): Action {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []): Action {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(): Action {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(): Action {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []): Action {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(): Action {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(): Action {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []): Action {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadContacts(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState): Promise<ActionResult<ContactModel>> => {
    const loadedForKeys = [...getState().contacts.loadedForKeys]
    if (entityKey) loadedForKeys.push(entityKey)

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/contacts', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.CONTACT_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function loadContact(contactId: number, options: EntityOptions = {}) {
  return (dispatch: AppDispatch, getState: () => RootReducerState): Promise<ActionResult<ContactModel>> => {
    const { entityKey } = options

    const state = getState()

    const loadedIds = [...state.contacts.loadedIds]
    const loadedForKeys = [...state.contacts.loadedForKeys]

    if (contactId && !loadedIds.includes(contactId)) loadedIds.push(contactId)
    if (entityKey) loadedForKeys.push(entityKey)

    // Set Loading
    dispatch(fetchRequest({ loadedForKeys, loadedIds }))

    const promise = api(`/contacts/${contactId}`, options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.CONTACT)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))
        return { success: false, errors }
      })

    return promise
  }
}

export function updateContact(contact: ContactModel, options: EntityOptions = {}) {
  const config = {
    method: 'PUT',
    data: JSON.stringify(contact),
  }

  return (dispatch: AppDispatch): Promise<ActionResult<ContactModel>> => {
    dispatch(updateRequest())

    const promise = api(`/contacts/${contact.id}`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.CONTACT)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function toggleContactActive(contact: ContactModel, options: EntityOptions = {}) {
  const config = {
    method: 'PUT',
    data: JSON.stringify(contact),
  }

  return (dispatch: AppDispatch): Promise<ActionResult<ContactModel>> => {
    dispatch(updateRequest())

    const promise = api(`/contacts/${contact.id}/toggle-active`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.CONTACT)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Result Directly Displayed as List Items in ImportContactsModal
export function loadFromRealbase(options: LoadFromRealbaseParams) {
  return (): Promise<ActionResult<ContactModel[]>> => {
    const promise = api('/contacts/fetch-from-realbase', options)
      .then(({ data }) => ({ success: true, data }))
      .catch((data) => {
        const errors = handleError(data)
        return { success: false, errors }
      })

    return promise
  }
}

type ImportContactsParams = {
  contactTypeId: number,
  selectedContactIds: number[],
}

// API Queues Bulk Import Job & Should return success: true || success: false & errors
export function importContacts(params: ImportContactsParams, options: EntityOptions = {}) {
  const { contactTypeId, selectedContactIds } = params || {}

  const config = {
    method: 'POST',
    data: JSON.stringify({
      contactTypeId,
      realbaseIds: selectedContactIds,
    }),
  }

  return (): Promise<ActionResult<{ errors?: string[], queued?: boolean }>> => {
    const promise = api('/contacts/import-from-realbase', options, config)
      .then(({ data }) => ({ success: true, data }))
      .catch((data) => {
        const errors = handleError(data)
        return { success: false, errors }
      })

    return promise
  }
}

export function downloadStatementPDF(entityParams: StatementFormParams) {
  const config = {
    method: 'POST',
    data: JSON.stringify(entityParams),
    responseType: 'blob', // API Returns PDF directly
  }

  return (dispatch: AppDispatch): Promise<ActionResult<object>> => {
    dispatch(createRequest())

    const promise = api(`/contacts/${entityParams.id}/pdf`, {}, config)
      .then(({ data }) => {
        // API returns PDF directly. Open PDF in new tab.
        window.open(URL.createObjectURL(data))
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: ContactModuleState = initialState,
  action: Action = { type: '' },
): ContactModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}

import { useMemo } from 'react'

import { sortArrayBy } from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ModuleState } from "@redux/modules"
import { BankTypeRequestOptions } from '@models/bankType'

const watchEntityKeys = ['bankTypes']

type BankTypeFilters = {
  bankTypeId?: number,
}

type UseBankTypesOptions = {
  filters?: BankTypeFilters,
  performHttpRequests?: boolean,
  requestOptions?: BankTypeRequestOptions,
}


function useBankTypes(options: UseBankTypesOptions = {}) {
  const { filters, performHttpRequests } = options || {}
 
  const {
    updatedEntities: { bankTypes: bankTypesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { bankTypes } = useSelector(reduxState => reduxState.entities)

  const filteredBankTypes = useMemo(() => {
    const filtered = Object.values(bankTypes)
    return sortArrayBy(filtered, 'asc', 'name')
  }, [bankTypesUpdatedAt])

  const isInternational = filters?.bankTypeId == 2 ? true : false

  useReduxAction(
    'bankTypes',
    'loadBankTypes',
    {},
    [performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    filteredBankTypes,
    isInternational,
  }
}

export default useBankTypes
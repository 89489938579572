import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { BusinessUnitProductModel } from '@models/businessUnitProduct'
import type { AppDispatch } from '@redux/store'
import type {
  Action, ActionResult, EntityOptions, Errors, FetchRequestPayload, RootReducerState,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/businessUnitProduct/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/businessUnitProduct/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/businessUnitProduct/FETCH_FAILURE'

export type BusinessUnitProductModuleState = {
  creating: boolean,
  deleting: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loading: boolean,
  updating: boolean,
}

const initialState: BusinessUnitProductModuleState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loadedIds: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}): Action {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(): Action {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []): Action {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

// Action Creators
export function loadBusinessUnitProducts(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState): Promise<ActionResult<BusinessUnitProductModel[]>> => {
    const loadedForKeys = [...getState().businessUnitProducts.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/business-unit-products', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.BUSINESS_UNIT_PRODUCT_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function loadBusinessUnitProduct(businessUnitProductId: number, options: EntityOptions = {}) {
  return (dispatch: AppDispatch, getState: () => RootReducerState): Promise<ActionResult<BusinessUnitProductModel>> => {
    const state = getState()
    const loadedIds = [...state.businessUnitProducts.loadedIds]

    if (businessUnitProductId && !loadedIds.includes(businessUnitProductId)){
      loadedIds.push(businessUnitProductId)
    }

    dispatch(fetchRequest({ loadedIds }))

    const promise = api(`/business-unit-products/${businessUnitProductId}`, options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.BUSINESS_UNIT_PRODUCT)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: BusinessUnitProductModuleState = initialState,
  action: Action = { type: '' },
): BusinessUnitProductModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}

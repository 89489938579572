import { useContext } from 'react'

import Swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Button, Form, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useContactUser, { useContactUserForm } from '@hooks/useContactUser'
import useContactUsers from '@hooks/useContactUsers'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import { swalColors } from '@constants/swal'

const MODAL_KEY = 'ManageContactUserModal'

type ConfirmActionParams = {
  action: (params?: HandleCallbackActionParams) => void,
  actionPayload?: HandleCallbackActionParams,
  buttonColorKey: string,
  confirmButtonText: string,
  icon: string,
  text: string,
  title: string,
}

const confirmAction = (params: ConfirmActionParams) => {
  const {
    action,
    actionPayload,
    buttonColorKey,
    confirmButtonText,
    icon,
    text,
    title,
  } = params

  const buttonColor = digObject(swalColors, String(buttonColorKey))

  Swal
    .fire({
      title,
      text,
      icon,
      showCancelButton: true,
      confirmButtonText,
      confirmButtonColor: buttonColor,
    })
    .then(({ value }) => {
      if (value){
        actionPayload ? action(actionPayload) : action()
      }
    })
}

type ModalCallbacks = {
  closeModal: () => void,
  deleteContactUser: (payload: HandleCallbackActionParams) => void,
  updateContactUser: (payload: HandleCallbackActionParams) => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const ManageContactUserModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const {
    closeModal,
    deleteContactUser,
    updateContactUser,
  } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const { contactUser: initContactUser } = modalPayload

  const {
    callbacks: {
      deleteContactUser: deleteFn,
      updateContactUser: updateFn,
    },
    contact: {
      id: contactId,
      name: contactName,
    },
    contactUser,
    updating,
    user: {
      fullName,
      email,
    },
  } = useContactUser(initContactUser)

  const contactUserFormPayload = useContactUserForm(contactUser)
  const {
    entityState,
    entityState: {
      isAdmin,
      isOwner,
      isPrimary,
    },
    handlers,
    saveEnabled,
    setEntityState,
  } = contactUserFormPayload

  const { contactUsersCount } = useContactUsers({ filters: { contactId } })
  const isOnlyContactUser = contactUsersCount === 1

  const deleteContactUserPayload = {
    callbacks: {
      action: deleteFn,
      afterAction: closeModal,
    },
    toastText: 'Contact User Deleted',
  }

  // To be used with handleCallbackAction
  const updateContactUserPayload = {
    callbacks: {
      action: updateFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    toastText: 'Contact User Updated',
  }

  // Do we need Set Contact Primary ContactUser and Set Owner specific routes
  // which remove the previous primary/owner and then update the new one server side.

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Manage" titleSecondLine="Contact User" />

      <SidebarModal.Content>
        <Form>
          <Form.Field label="* Contact">
            <input disabled name="contactName" type="text" value={contactName} />
          </Form.Field>

          <Form.Field label="* Name" marginTop="large">
            <input disabled name="fullName" type="text" value={fullName} />
          </Form.Field>

          <Form.Field label="* Email" marginTop="large">
            <input disabled name="email" type="text" value={email} />
          </Form.Field>

          <Form.Field label="* Admin" marginTop="large">
            <select
              data-value-type="boolean"
              disabled={isOnlyContactUser || isOwner || isPrimary}
              name="isAdmin"
              value={isAdmin}
              {...handlers}
            >
              <option value={false}>No</option>
              <option value>Yes</option>
            </select>
          </Form.Field>

          <Form.Field direction="column" label="More Options" marginTop="large">
            <Button
              buttonStyle="secondaryUtility"
              disabled={isOnlyContactUser || isOwner || isPrimary}
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              onClick={() => confirmAction({
                action: deleteContactUser,
                actionPayload: deleteContactUserPayload,
                buttonColorKey: 'deletion',
                confirmButtonText: 'Yes, delete it',
                icon: 'error',
                text: 'Are you sure? This cannot be undone',
                title: 'Delete Contact User?',
              })}
              size={['large', 'medium']}
              width="100%"
            >
              Delete Contact User
            </Button>

            <Button
              buttonStyle="secondaryUtility"
              disabled={isPrimary}
              icon={<FontAwesomeIcon icon={faThumbsUp} />}
              marginTop="medium"
              onClick={() => confirmAction({
                action: () => setEntityState({ isPrimary: true }),
                buttonColorKey: 'approval',
                confirmButtonText: 'Yes, Set Primary',
                icon: 'warning',
                text: 'Are you sure? This user will replace the current primary contact user.',
                title: 'Set as Primary User?',
              })}
              size={['large', 'medium']}
              width="100%"
            >
              Set as Primary Contact User
            </Button>

            <Button
              buttonStyle="secondaryUtility"
              disabled={isOwner}
              icon={<FontAwesomeIcon icon={faThumbsUp} />}
              marginTop="medium"
              onClick={() => confirmAction({
                action: () => setEntityState({ isOwner: true }),
                buttonColorKey: 'approval',
                confirmButtonText: 'Yes, set owner',
                icon: 'warning',
                text: 'Are you sure? This user will replace the current contact owner.',
                title: 'Set User as Owner?',
              })}
              size={['large', 'medium']}
              width="100%"
            >
              Set as Contact Owner
            </Button>
          </Form.Field>
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={updating}
          onClick={() => updateContactUser(updateContactUserPayload)}
          size="large"
        >
          Update Contact User
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <ManageContactUserModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal

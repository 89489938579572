import { Box, Text } from '@campaignhub/suit-theme'

import useUser from '@hooks/useUser'

import type { ContactUserModel } from '@models/contactUser'

const PrimaryContactUser = (props: { primaryContactUser: ContactUserModel }) => {
  const { primaryContactUser } = props || {}
  const { userId } = primaryContactUser || {}

  const {
    user: {
      email,
      fullName,
    },
  } = useUser({ id: userId }, { performHttpRequests: !!userId })

  return (
    <Box
      alignItems={['flex-start', 'flex-end']}
      flexDirection="column"
      justifyContent="center"
      marginTop={['large', 0]}
      maxWidth={['100%', '40%']}
    >
      <Text fontSize="small" variant="ellipsis">
        {fullName}
      </Text>

      <Text color="bodyFontLightColor" fontSize="xsmall" variant="ellipsis">
        {email}
      </Text>
    </Box>
  )
}

export default PrimaryContactUser

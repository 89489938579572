import { normalize } from 'normalizr'

import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { ConversationModel } from '@models/conversation'
import type { AppDispatch } from '@redux/store'
import type {
  Action, ActionResult, EntityOptions, Errors, FetchRequestPayload, RootReducerState,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/conversation/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/conversation/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/conversation/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/conversation/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/conversation/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/conversation/CREATE_FAILURE'

export type ConversationModuleState = {
  creating: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loading: boolean,
}

// Initial State
const initialState: ConversationModuleState = {
  creating: false,
  errors: [],
  loaded: false,
  loadedIds: [],
  loadedForKeys: [],
  loading: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadConversationForEntity(subjectType: string, subjectId: number, options: EntityOptions = {}) {
  const entityKey = `${subjectType}${subjectId}`

  return (dispatch: AppDispatch, getState: () => RootReducerState): Promise<ActionResult<ConversationModel>> => {
    const { conversations } = getState()

    const loadedForKeys = [...conversations.loadedForKeys]
    if (!loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const url = `/conversations/${subjectType}/${subjectId}`

    const promise = api(url, options)
      .then(({ data }) => {
        if (data.id){
          const normalizedJson = normalize(data, Schemas.CONVERSATION)
          dispatch(updateEntities(normalizedJson))
        }

        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function createEntityConversation(conversation: ConversationModel, options: EntityOptions = {}) {
  const config = {
    method: 'POST',
    data: JSON.stringify(conversation),
  }

  return (dispatch: AppDispatch): Promise<ActionResult<ConversationModel>> => {
    dispatch(createRequest())

    const promise = api('/conversations', options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.CONVERSATION)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: ConversationModuleState = initialState,
  action: Action = { type: '' },
): ConversationModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}

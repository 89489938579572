import { useMemo } from 'react'

import { useFilters, useModals, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import PageContent from './components/PageContent'

import GenerateCreditsCsvModal from '@modals/GenerateCreditsCsvModal'

export type PageContextPayload = {
  callbacks: {
    showGenerateCreditsCsvModal: () => void,
  },
}

type SetState = (state: Partial<typeof defaultState>) => void

const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    GenerateCreditsCsvModal: {
      closeModal: () => setState({ showGenerateCreditsCsvModal: false }),
      generateCreditsCSV: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showGenerateCreditsCsvModal: false,
}

const List = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    showGenerateCreditsCsvModal,
  } = state

  const filtersPayload = useFilters({ persistFilters: true })
  const {
    callbacks: filterCallbacks,
    defaultFilters,
    defaultFiltersModifiedAt,
    pageFilters,
  } = filtersPayload

  const modalContext = useModals()

  const { callbacks: { setModalData } } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      ...filterCallbacks,
      showGenerateCreditsCsvModal: (payload) => {
        setModalData('GenerateCreditsCsvModal', payload)
        setState({ showGenerateCreditsCsvModal: true })
      },
    },
    defaultFilters,
  }), [defaultFiltersModifiedAt]) // modalContext dependency required if layering modal

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent pageFilters={pageFilters} />

        <GenerateCreditsCsvModal
          callbacks={callbacks('GenerateCreditsCsvModal', setState)}
          showModal={showGenerateCreditsCsvModal}
        />

      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default List

import { useMemo } from 'react'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import GenerateInvoicesCsvModal from '@modals/GenerateInvoicesCsvModal'

import PageContent from './components/PageContent'

const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    GenerateInvoicesCsvModal: {
      closeModal: () => setState({ showGenerateInvoicesCsvModal: false }),
      generateInvoicesCSV: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showGenerateInvoicesCsvModal: false,
}

const Invoices = (props: { contactId: number }) => {
  const { contactId } = props || {}

  const [state, setState] = useSetState(defaultState)
  const {
    showGenerateInvoicesCsvModal,
  } = state
  
  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      showGenerateInvoicesCsvModal: (payload) => {
        setModalData('GenerateInvoicesCsvModal', payload)
        setState({ showGenerateInvoicesCsvModal: true })
      },
    },
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent contactId={contactId} />
        <GenerateInvoicesCsvModal
          callbacks={callbacks('GenerateInvoicesCsvModal', setState)}
          showModal={showGenerateInvoicesCsvModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Invoices

import { useMemo } from 'react'
import type { AppDispatch } from '@redux/store'

import { matchFilterNumber, multiFilteredObjectArraySelector } from '@campaignhub/javascript-utils'
import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useComment from '@hooks/useComment'
import useCurrentUser from '@hooks/useCurrentUser'
import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as conversationActions from '@redux/modules/conversation'

import type { EntityOptions } from '@redux/modules/types'

import {
  CommentModel, ConversationModel, ConversationRequestOptions, UserModel,
} from '@models/types'

const watchEntityKeys = [
  'comments',
  'subscribers',
]

type CreateEntityConversationParams = {
  entityParams: Partial<ConversationModel>,
  dispatch: AppDispatch,
  entityRequestOptions?: ConversationRequestOptions,
}

const createEntityConversation = (params: CreateEntityConversationParams) => {
  const { entityParams, dispatch, entityRequestOptions } = params
  const { createEntityConversation: createFn } = conversationActions

  return dispatch(createFn(entityParams, entityRequestOptions))
}

type CreateCommentParams = {
  comment: Partial<CommentModel>,
  conversation: Partial<ConversationModel>,
  currentUser: Partial<UserModel>,
  createComment: (comment: CommentModel, entityRequestOptions: EntityOptions) => any,
  dispatch: AppDispatch,
  entityRequestOptions?: EntityOptions,
}

const createCommentWithConversation = (params: CreateCommentParams) => {
  const {
    comment, conversation, createComment, currentUser, dispatch, entityRequestOptions = {},
  } = params

  // If we have a conversation already we can just create a comment
  // If not we create a conversation for the entity with the first comment
  const updatedComment = {
    conversationId: conversation.id,
    userId: currentUser.id,
    ...comment,
  }

  if (conversation.id) return createComment(updatedComment, entityRequestOptions)

  return createEntityConversation({
    dispatch,
    entityParams: {
      ...conversation,
      comment: updatedComment,
      subscribers: [{ userId: currentUser.id }],
    },
    entityRequestOptions,
  })
}

type UseConversationOptions = {
  publicCommentsOnly?: boolean,
}

function useConversation(conversation: Partial<ConversationModel>, options: UseConversationOptions = {}) {
  const { publicCommentsOnly } = options || {}

  const dispatch = useDispatch()

  const {
    updatedEntities: {
      comments: commentsUpdatedAt,
      subscribers: subscribersUpdatedAt,
    },
  } = useWatchEntityUpdates(watchEntityKeys, { customUseSelectorFn: useSelector })

  const entities = useSelector(reduxState => reduxState.entities)
  const { comments, users } = entities

  const { creating } = useSelector(reduxState => reduxState.conversations)

  const { callbacks: { createComment }, creating: creatingComment } = useComment({ conversationId: conversation.id })

  const { currentUser } = useCurrentUser()

  const filteredComments = useMemo(() => {
    const array = Object.values(comments)

    const filtered = array.filter((comment) => {
      const { conversationId, public: isPublic } = comment

      const conversationMatch = matchFilterNumber(conversationId, conversation.id)
      const publicMatch = publicCommentsOnly ? isPublic : true

      return conversationMatch && publicMatch
    })

    return filtered
  }, [conversation.id, commentsUpdatedAt, publicCommentsOnly])

  const filteredSubscribers = useMemo(
    () => multiFilteredObjectArraySelector({ entities }, 'subscribers', [
      { key: 'subscribableId', value: conversation.id },
      { key: 'subscribableType', value: 'Conversation' },
    ]),
    [conversation.id, subscribersUpdatedAt],
  )

  const lastComment = filteredComments[filteredComments.length - 1] || {}
  const commentCount = filteredComments.length

  return {
    callbacks: {
      createCommentWithConversation: (
        comment: Partial<CommentModel>,
        entityRequestOptions?: EntityOptions,
      ) => createCommentWithConversation({
        comment,
        conversation,
        createComment,
        currentUser,
        dispatch,
        entityRequestOptions,
      }),
      createEntityConversation: (entityParams: Partial<ConversationModel>, entityRequestOptions: EntityOptions) => (
        createEntityConversation({ entityParams, dispatch, entityRequestOptions })
      ),
    },
    commentCount,
    comments: filteredComments,
    conversation,
    creating: creating || creatingComment,
    entities: {
      users,
    },
    lastComment,
    subscribers: filteredSubscribers,
  }
}

export default useConversation

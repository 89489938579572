import { useContext } from 'react'
import { formatMoney } from 'accounting'
import Swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { Box, Link, Text } from '@campaignhub/suit-theme'
import { digObject } from '@campaignhub/javascript-utils'

import useSelector from '@hooks/useSelector'

import PageContext from '@contexts/pageContext'

import { swalColors } from '@constants/swal'

import type { TransactionItemModel } from '@models/types'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'
import type { SwalConfirmParams } from '@constants/swal'

type LocalSwalConfirmParams = {
  deleteTransactionItem: (deleteTransactionItemPayload: HandleCallbackActionParams) => void,
  deleteTransactionItemPayload: HandleCallbackActionParams,
}

const confirmAction = (params: SwalConfirmParams & LocalSwalConfirmParams) => {
  const { deleteTransactionItem, deleteTransactionItemPayload } = params

  const { deletion } = swalColors

  Swal
    .fire({
      confirmButtonColor: deletion,
      confirmButtonText: 'Delete Transaction Item',
      icon: 'error',
      showCancelButton: true,
      text: 'Confirming you are about to delete this transaction item. This cannot be undone.',
      title: 'Delete Transaction Item?',
    })
    .then(({ value }) => {
      if (value){
        deleteTransactionItem(deleteTransactionItemPayload)
      }
    })
}

interface ShowTransactionItemProps {
  callbacks: {
    editTransactionItem: () => void,
    updateTransactionItem: (payload: HandleCallbackActionParams) => void,
  },
  editingInvoice: boolean,
  transactionItem: TransactionItemModel,
}

const ShowTransactionItem = (props: ShowTransactionItemProps) => {
  const { callbacks, editingInvoice, transactionItem } = props

  const {
    deleteTransactionItem: deleteTransactionItemFn,
    editTransactionItem,
  } = callbacks

  const {
    businessUnitProductId,
    incTaxTotal,
    qty,
    title,
    unitPriceIncTax,
  } = transactionItem

  const pageContext = useContext(PageContext)
  const { callbacks: { deleteTransactionItem } } = pageContext

  const { businessUnitProducts } = useSelector(reduxState => reduxState.entities)

  const businessUnitProduct = digObject(businessUnitProducts, String(businessUnitProductId), {})
  const { name: businessUnitProductName } = businessUnitProduct

  const deleteTransactionItemPayload = {
    callbacks: {
      action: deleteTransactionItemFn,
    },
    toastText: 'Transaction Item Deleted',
  }

  return (
    <Box
      alignItems="center"
      height="38px" // Match FormField Height (Form Fields on edit)
      paddingY="medium"
    >
      <Text fontSize="small" width="100%" variant="ellipsis">
        {businessUnitProductName}
      </Text>

      <Text fontSize="small" width="100%" variant="ellipsis">
        {title}
      </Text>

      <Box alignItems="center" justifyContent="flex-end" width="290px">
        <Text fontSize="small" width="80px">
          {qty}
        </Text>

        <Text fontSize="small" width="80px">
          {formatMoney(unitPriceIncTax)}
        </Text>

        <Text fontSize="small" width="80px">
          {formatMoney(incTaxTotal)}
        </Text>

        {editingInvoice && (
          <Box alignItems="center" justifyContent="flex-end" width="50px">
            <Link
              greyLink
              onClick={() => editTransactionItem()}
            >
              <FontAwesomeIcon icon={faPencil} />
            </Link>

            <Link
              greyLink
              onClick={() => confirmAction({ deleteTransactionItem, deleteTransactionItemPayload })}
              textProps={{ marginLeft: 'medium' }}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Link>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ShowTransactionItem

import { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons'

import { Box, FormField, Link } from '@campaignhub/suit-theme'
import { digObject } from '@campaignhub/javascript-utils'

import useSelector from '@hooks/useSelector'

import PageContext from '@contexts/pageContext'

import { useTransactionItemForm } from '@hooks/useTransactionItem'

import type { TransactionItemModel } from '@models/types'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

interface EditTransactionItemProps {
  callbacks: {
    editTransactionItem: () => void,
    updateTransactionItem: (payload: HandleCallbackActionParams) => void,
  },
  transactionItem: TransactionItemModel,
}

const EditTransactionItem = (props: EditTransactionItemProps) => {
  const { callbacks, transactionItem } = props
  const {
    editTransactionItem,
    updateTransactionItem: updateTransactionItemFn,
  } = callbacks

  const transactionItemFormPayload = useTransactionItemForm(transactionItem)
  const {
    entityState,
    entityState: {
      businessUnitProductId,
      incTaxTotal,
      qty,
      title,
      unitPriceIncTax,
    },
    handlers,
    setEntityState,
  } = transactionItemFormPayload

  const pageContext = useContext(PageContext)
  const { callbacks: { updateTransactionItem } } = pageContext

  const { businessUnitProducts } = useSelector(reduxState => reduxState.entities)

  const businessUnitProduct = digObject(businessUnitProducts, String(businessUnitProductId), {})
  const { name: businessUnitProductName } = businessUnitProduct

  const updateTransactionItemPayload = {
    callbacks: {
      action: updateTransactionItemFn,
      afterAction: editTransactionItem,
    },
    entityParams: entityState,
    toastText: 'Transaction Item Updated',
  }

  return (
    <Box alignItems="center">
      <Box alignItems="center">
        <FormField boxProps={{ flex: 1, marginRight: 'medium' }}>
          <input
            disabled
            name="businessUnitProductName"
            type="text"
            value={businessUnitProductName}
          />
        </FormField>

        <FormField boxProps={{ flex: 1, marginRight: 'medium' }}>
          <input
            name="title"
            type="text"
            value={title}
            {...handlers}
          />
        </FormField>
      </Box>

      <Box alignItems="center" justifyContent="flex-end" width="290px">
        <FormField boxProps={{ marginRight: 'medium', width: '72px' }}>
          <input
            name="qty"
            type="number"
            value={qty}
            onChange={e => setEntityState({
              incTaxTotal: Number(e.target.value) * unitPriceIncTax,
              qty: e.target.value,
            })}
          />
        </FormField>

        <FormField boxProps={{ marginRight: 'medium', width: '72px' }}>
          <input
            name="unitPriceIncTax"
            type="number"
            value={unitPriceIncTax}
            onChange={e => setEntityState({
              incTaxTotal: qty * Number(e.target.value),
              unitPriceIncTax: e.target.value,
            })}
          />
        </FormField>

        <FormField boxProps={{ marginRight: 'medium', width: '72px' }}>
          <input
            disabled
            name="incTaxTotal"
            type="number"
            value={incTaxTotal}
          />
        </FormField>

        <Box alignItems="center" justifyContent="flex-end" width="50px">
          <Link
            greyLink
            onClick={() => editTransactionItem()}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Link>

          <Link
            greyLink
            onClick={() => updateTransactionItem(updateTransactionItemPayload)}
            textProps={{ marginLeft: 'medium' }}
          >
            <FontAwesomeIcon icon={faCheck} />
          </Link>
        </Box>
      </Box>
    </Box>
  )
}

export default EditTransactionItem

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons'

import { Button, Tools } from '@campaignhub/suit-theme'

interface DetailsToolsProps {
  callbacks: {},
}

const DetailsTools = (props: DetailsToolsProps) => {
  const { callbacks } = props

  // return (
  //   <Tools>
  //     <Button
  //       buttonStyle="secondaryUtility"
  //       icon={<FontAwesomeIcon icon={faExclamationCircle} />}
  //       iconPosition="left"
  //       onClick={() => console.log('Business Unit Tools Button Click')}
  //       size="medium"
  //     >
  //       Business Unit Tool Button
  //     </Button>
  //   </Tools>
  // )
}

export default DetailsTools

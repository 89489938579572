import { DashboardModule } from '@campaignhub/suit-theme'

import useContactSummary from '@hooks/useContactSummary'

import AccountIconGrid from './components/AccountIconGrid'

interface AccountsOptions {
  businessUnitId?: number,
}

const Accounts = (props: AccountsOptions) => {
  const { businessUnitId } = props || {}

  const {
    loading,
    newAccounts,
    overdueAccounts,
    totalAccounts,
    totalBilled,
  } = useContactSummary({ businessUnitId })

  return (
    <DashboardModule loading={loading} title="Last 30 Days">
      {!loading && (
        <AccountIconGrid
          contactSummary={{
            newAccounts,
            overdueAccounts,
            totalAccounts,
            totalBilled,
          }}
        />
      )}
    </DashboardModule>
  )
}

export default Accounts

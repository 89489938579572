import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { UserModel } from '@models/user'
import type { AppDispatch } from '@redux/store'
import type {
  Action, ActionResult, EntityOptions, Errors, FetchRequestPayload, RootReducerState,
} from '@redux/modules/types'
import type { LoadFromRealbaseParams } from '@modals/ImportUsersModal/hooks/useImportUsers'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/user/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/user/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/user/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/user/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/user/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/user/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/user/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/user/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/user/UPDATE_FAILURE'

export type UserModuleState = {
  creating: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loading: boolean,
  updating: boolean,
}

const initialState: UserModuleState = {
  creating: false,
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loadedIds: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}): Action {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(): Action {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []): Action {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(): Action {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(): Action {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []): Action {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(): Action {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(): Action {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []): Action {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadUsers(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState): Promise<ActionResult<UserModel[]>> => {
    const loadedForKeys = [...getState().users.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/users.json', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.USER_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function loadUser(userId: number, options: EntityOptions = {}) {
  return (dispatch: AppDispatch, getState: () => RootReducerState): Promise<ActionResult<UserModel>> => {
    const state = getState()
    const loadedIds = [...state.users.loadedIds]

    if (userId && !loadedIds.includes(userId)){
      loadedIds.push(userId)
    }

    // Set Loading
    dispatch(fetchRequest({ loadedIds }))

    const promise = api(`/users/${userId}`, options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.USER)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function createUser(user: UserModel, options: EntityOptions = {}) {
  const config = {
    method: 'POST',
    data: JSON.stringify({
      user,
    }),
  }

  return (dispatch: AppDispatch): Promise<ActionResult<UserModel>> => {
    dispatch(createRequest())

    const promise = api('/users', options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.USER)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function updateUser(user: UserModel, options: EntityOptions = {}) {
  const config = {
    method: 'PUT',
    data: JSON.stringify({
      user,
    }),
  }

  return (dispatch: AppDispatch): Promise<ActionResult<UserModel>> => {
    dispatch(updateRequest())

    const promise = api(`/users/${user.id}`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.USER)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

// Fetch Users from Realbase to display and select for import
export function loadFromRealbase(options: LoadFromRealbaseParams) {
  return (): Promise<ActionResult> => {
    const promise = api('/users/fetch-from-realbase', options)
      .then(({ data }) => ({ success: true as const, data }))
      .catch((data) => {
        const errors = handleError(data)
        return { success: false as const, errors }
      })

    return promise
  }
}

type ImportRequestPayload = {
  contactId: number,
  selectedUserIds: number[],
}

// Import Users From Realbase
export function importUsers(payload: ImportRequestPayload, options: EntityOptions = {}) {
  const { contactId, selectedUserIds } = payload || {}

  const config = {
    method: 'POST',
    data: JSON.stringify({
      contactId,
      realbaseIds: selectedUserIds,
    }),
  }

  return (): Promise<ActionResult<UserModel[]>> => {
    const promise = api('/users/import-from-realbase', options, config)
      .then(({ data }) => ({ success: true as const, data: data.data }))
      .catch((data) => {
        const errors = handleError(data)
        return { success: false as const, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: UserModuleState = initialState,
  action: Action = { type: '' },
): UserModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}

import { useForm, useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'
import type { UseFormOptions, UseFormPayload } from '@campaignhub/react-hooks'

import { default as defaultUserState, requiredFields } from '@models/user'

import * as userActions from '@redux/modules/user'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { UserModel, UserRequestOptions } from '@models/user'

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: UserRequestOptions,
}

type UserForm = { entityState: UserModel }

export type UserFormPayload = UseFormPayload & UserForm

export interface UserPayload {
  callbacks: {
    updateuser: (userParams: UserModel) => void,
  },
  user: UserModel,
  loading: boolean,
  urls: {
    [key: string]: string,
  },
}

export const updateUser = (params) => {
  const { userParams, dispatch, requestOptions } = params
  const { updateUser: updateFn } = userActions

  return dispatch(updateFn(userParams, requestOptions))
}

export function useUserForm(user: UserModel, options: UseFormOptions = {}): UserFormPayload {
  const { validateOn } = options

  const userForm = useForm(
    defaultUserState,
    { entity: user, requiredFields, validateOn },
    [user.id],
  )

  return {
    ...userForm,
  }
}

export const useRelations = (user: UserModel) => {
  const { userTypeId } = user

  const { userTypes } = useSelector(reduxState => reduxState.entities)

  const userType = userTypes[userTypeId] || {}

  return {
    userType,
  }
}

function useUser(initEntity: UserModel = { id: null }, options?: Options) {
  const { performHttpRequests, requestOptions } = options || {}

  const { entity: user } = useLatestEntity(initEntity, 'users')
  const { id } = user

  const dispatch = useThunkDispatch()

  const { userType } = useRelations(user)

  useReduxAction(
    'users',
    'loadUser',
    {
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, requestOptions) => action(id, requestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedIds } = entityReducer
        return performHttpRequests && !loadedIds.includes(id)
      },
    },
  )

  const { loading, updating } = useSelector(reduxState => reduxState.users)

  return {
    callbacks: {
      updateUser: (userParams: UserModel, requestOptions: object) => updateUser({ userParams, dispatch, requestOptions }),
    },
    user,
    userType,
    loading,
    updating,
  }
}

export default useUser

import { Columns, LoadingModule } from '@campaignhub/suit-theme'

import useContact from '@hooks/useContact'

import Credits from '@components/Credits'
import PageLayout from '../../../../components/PageLayout'
import Tools from '../Tools'

const PageContent = (props: { contactId: number }) => {
  const { contactId } = props

  const contactPayload = useContact({ id: contactId })

  const { callbacks, loading } = contactPayload

  return (
    <PageLayout entityPayload={contactPayload} activeTabBarItemKey="credits">
      <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
        <Columns.Main>
          <LoadingModule loading={loading} />

          <Credits
            options={{
              filters: {
                contactId,
              },
              performHttpRequests: !!contactId,
              requestOptions: {
                includeContact: !!contactId,
                includeTransaction: !!contactId,
              },
            }}
          />
        </Columns.Main>
      </Columns>
    </PageLayout>
  )
}

export default PageContent

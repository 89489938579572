import { Columns, LoadingModule } from '@campaignhub/suit-theme'

import useBusinessUnit from '@hooks/useBusinessUnit'

import validateSubscriptionProducts from '@functions/businessUnit'

import Accounts from '@components/Accounts'

import CreditsSummary from '@components/CreditsSummary'
import InvoiceStatistics from '@components/InvoiceStatistics'
import InvoicesSummary from '@components/InvoicesSummary'
import NewCustomersGraph from '@components/NewCustomersGraph'
import SubscribersGraph from '@components/SubscribersGraph'
import SubscriptionStatistics from '@components/SubscriptionStatistics'

// Business Unit Edit Screens Shared Components
import PageLayout from '../../../../components/PageLayout'
import Statements from '../../../../components/Statements'

// Business Unit Edit Overview Screen Components
import Details from '../Details'
import Subscriptions from '../Subscriptions'

const PageContent = (props: { businessUnitId: number }) => {
  const { businessUnitId } = props

  const businessUnitPayload = useBusinessUnit({ id: businessUnitId })

  const {
    businessUnit, callbacks, loading, urls,
  } = businessUnitPayload

  const { name, key } = businessUnit || {}
  const {
    businessUnitCreditsUrl,
    businessUnitInvoicesUrl,
    businessUnitStatementsUrl,
    businessUnitSubscriptionsUrl,
  } = urls

  const hasSubcriptionProducts = validateSubscriptionProducts(key)

  return (
    <PageLayout entityPayload={businessUnitPayload} activeTabBarItemKey="overview">
      <Columns flexDirection={['column', 'column', 'row']}>
        <Columns.Main>
          <LoadingModule loading={loading} />

          {!loading && (
            <>
              <InvoicesSummary
                filters={{ businessUnitId }}
                href={businessUnitInvoicesUrl}
              />

              <CreditsSummary
                filters={{ businessUnitId }}
                href={businessUnitCreditsUrl}
              />

              <Subscriptions
                businessUnitId={businessUnitId}
                href={businessUnitSubscriptionsUrl}
              />

              <Statements
                businessUnitId={businessUnitId}
                href={businessUnitStatementsUrl}
              />
            </>
          )}
        </Columns.Main>

        <Columns.Sidebar>
          <Details businessUnitId={businessUnitId} />

          <Accounts />

          {hasSubcriptionProducts && (
            <SubscriptionStatistics href={businessUnitSubscriptionsUrl} />
          )}

          <InvoiceStatistics
            filters={{ businessUnitId }}
            href={businessUnitInvoicesUrl}
          />

          <NewCustomersGraph />

          {hasSubcriptionProducts && <SubscribersGraph />}
        </Columns.Sidebar>
      </Columns>
    </PageLayout>
  )
}

export default PageContent

import { digObject } from '@campaignhub/javascript-utils'

import type { EntitiesState } from '@redux/entities'
import type { StatusModel } from '@models/status'

export function getStatusIdByKey(statusKey: string, statuses: EntitiesState['statuses'] = {}) {
  if (!statusKey || typeof statusKey !== 'string') return null
  if (!statuses || typeof statuses !== 'object' || !Object.values(statuses)?.length) return null

  const selectedStatus = Object.values(statuses)?.find((status: StatusModel) => status.key === statusKey) || {}
  const { id: statusId } = selectedStatus

  return statusId || null
}

export function getStatusByKey(statusKey: string, statuses: EntitiesState['statuses'] = {}) {
  if (!statusKey || typeof statusKey !== 'string') return {}
  if (!statuses || typeof statuses !== 'object' || !Object.values(statuses)?.length) return {}

  const selectedStatus = Object.values(statuses)?.find((status: StatusModel) => status.key === statusKey) || {}

  return selectedStatus
}

export function statusColor(statusKey: string, fallbackColor: string = 'faintGrey'): string {
  if (!statusKey || typeof statusKey !== 'string') return fallbackColor

  const colors = {
    active: '#43AC6A', // green
    applied: '#02b7e2', // blue
    approved: '#43AC6A', // green
    draft: 'faintGrey',
    inactive: 'faintGrey',
    invoiced: '#e78d2d', // orange
    overdue: '#E2001A', // red
    paid: '#43AC6A', // green
    rejected: '#E2001A', // red
    reminded: '#E78D2D', // orange
    requested: '#02B7E2', // blue
    unassigned: '#02b7e2', // blue
  }

  const color = digObject(colors, statusKey, fallbackColor)

  return color
}

export default statusColor

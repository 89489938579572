import useReduxAction from '@hooks/useReduxAction'

import type { ContactsInvoiceSummaryModel, ContactsInvoiceSummaryOptions } from '@models/contactsInvoiceSummary'

type ContactsInvoiceSummaryPayload = {
  contactsInvoiceSummary?: ContactsInvoiceSummaryModel[],
  hasSummary: boolean,
  loading: boolean,
}

function useContactsInvoiceSummary(options?: ContactsInvoiceSummaryOptions): ContactsInvoiceSummaryPayload {
  const { filters, performHttpRequests } = options

  const contactsInvoiceSummaryPayload = useReduxAction(
    'contactsInvoiceSummary',
    'fetchContactsInvoiceSummary',
    {
      ...filters,
    },
    [performHttpRequests],
    {
      shouldPerformFn: (entityReducer) => {
        const { loading, loaded, errors } = entityReducer
        return !loading && !loaded && !errors?.length
      },
    },
  )

  const { loading, result: contactsInvoiceSummary }: { result?: ContactsInvoiceSummaryModel[], loading: boolean } = contactsInvoiceSummaryPayload

  const hasSummary = contactsInvoiceSummary?.length > 0

  return {
    contactsInvoiceSummary,
    hasSummary,
    loading,
  }
}

export default useContactsInvoiceSummary

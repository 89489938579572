import type { ContactModel } from '@models/contact'

export function getContactShortAddress(contact: ContactModel) {
  const { addressLine1, addressLine2, regionName } = contact || {}

  if (!addressLine1 || !regionName) return ''

  const street = addressLine2 ? `${addressLine1}, ${addressLine2}` : addressLine1

  return `${street}, ${regionName}`
}

export function getContactFullAddress(contact: ContactModel) {
  const {
    addressLine1, addressLine2, postCode, regionName, stateName,
  } = contact || {}

  if (!addressLine1 || !regionName || !postCode || !stateName) return ''

  const street = addressLine2 ? `${addressLine1}, ${addressLine2}` : addressLine1

  return `${street}, ${regionName}, ${postCode}, ${stateName}`
}

export type GetAlreadyImportedContactIdsParams = {
  filteredContacts: ContactModel[],
  realbaseContacts: ContactModel[],
}

export const getAlreadyImportedContactIds = (params: GetAlreadyImportedContactIdsParams) => {
  const { filteredContacts: existingContacts, realbaseContacts } = params || {}

  const alreadyImportedRealbaseIds = existingContacts.reduce((acc, existingContact) => {
    const { realbaseId } = existingContact

    if (realbaseContacts.some((realbaseContact) => {
      const realbaseIdMatch = realbaseContact.realbaseId === existingContact.realbaseId
      const coreBillingContactTypematch = realbaseContact.contactTypeId === existingContact.contactTypeId

      return realbaseIdMatch && coreBillingContactTypematch
    })){
      acc.push(realbaseId)
    }

    return acc
  }, [])

  return alreadyImportedRealbaseIds
}

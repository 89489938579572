import { Box, ListItem, Text } from '@campaignhub/suit-theme'
import { formatMoney } from 'accounting'

import { SubscriptionItem } from '@models/types'

import useSubscriptionItem, { useRelations } from '@hooks/useSubscriptionItem'

import { displaySubscriptionInterval } from '@functions/subscription'

interface SubscriptionItemListItemProps {
  subscriptionItem: SubscriptionItem,
}

const SubscriptionItemListItem = (props: SubscriptionItemListItemProps) => {
  const { subscriptionItem } = props
  const { qty } = subscriptionItem

  const { total } = useSubscriptionItem(subscriptionItem)

  const {
    businessUnit,
    businessUnitProduct,
    price,
  } = useRelations(subscriptionItem)

  const { name: businessUnitname } = businessUnit
  const { name: businessUnitProductName } = businessUnitProduct
  const { incTaxAmount, interval } = price

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        border: '1px solid',
        lineHeight: 1.3,
        marginBottom: 'medium',
        padding: 'large',
      }}
      disableHover
    >
      <Box flexDirection="column" padding>
        <Text fontSize="small">{businessUnitname}</Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="small">
          {formatMoney(incTaxAmount)} {businessUnitProductName}
        </Text>

        {qty > 1 && (
          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="small">
            Quantity: {qty}
          </Text>
        )}
      </Box>

      <Box flexDirection="column" textAlign="end">
        <Text fontSize="small">{formatMoney(total)}</Text>

        <Text color="bodyFontLightColor" fontSize="xsmall">{displaySubscriptionInterval(interval)}</Text>
      </Box>
    </ListItem>
  )
}

export default SubscriptionItemListItem

import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { CountryModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, EntityOptions, Errors, FetchRequestPayload, RootReducerState,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/country/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/country/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/country/FETCH_FAILURE'

export type CountryModuleState = {
  creating: boolean,
  deleting: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loading: boolean,
  updating: boolean,
}

// Initial State
const initialState: CountryModuleState = {
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loadedIds: [],
  loading: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

// Action Creators
export function loadCountries(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const loadedForKeys = [...getState().countries.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/countries', options)
      .then(({ data }: { data: CountryModel[] }) => {
        const normalizedJson = normalize(data, Schemas.COUNTRY_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: CountryModuleState = initialState,
  action: Action = { type: '' },
): CountryModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}

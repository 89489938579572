import { useContext, useEffect, useState } from 'react'
import { Button, Columns, LoadingModule, SaveFormModule } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'
import useBusinessUnit, { useBusinessUnitForm } from '@hooks/useBusinessUnit'
import AccountingSystem from '../AccountingSystem'
import Bank from '../Bank'
import BusinessNumber from '../BusinessNumber'
import Details from '../Details'
import InvoicePrefix from '../InvoicePrefix'
import PageLayout from '../../../../components/PageLayout'
import TaxJurisdiction from '../Tax'
import Tools from '../Tools'

const PageContent = (props: { businessUnitId: number }) => {
  const { businessUnitId } = props

  const pageContext = useContext(PageContext)
  const { callbacks: { updateBusinessUnit } } = pageContext

  const businessUnitPayload = useBusinessUnit({ id: businessUnitId }, {
    performHttpRequests: !!businessUnitId,
    requestOptions: {
      includeBusinessUnitBusinessNumbers: !!businessUnitId,
      includeBusinessUnitBanks: !!businessUnitId,
    },
  })

  const { 
    businessUnit, 
    callbacks,
    callbacks: {
      updateBusinessUnit: updateFn,
    }, 
    loading,
    updating
  } = businessUnitPayload

  const businessUnitFormPayload = useBusinessUnitForm(businessUnit)

  const { entityState, saveEnabled } = businessUnitFormPayload

  const [isValid, setIsValid] = useState(false)
  const [bankValid, setBankValid] = useState(false)
  const [businessNumberValid, setBusinessNumberValid] = useState(false)
  const [destinationsSystemValid, setDestinationsSystem] = useState(false)

  useEffect(() => {
    setIsValid(bankValid && businessNumberValid && destinationsSystemValid)
  }, [bankValid, businessNumberValid, destinationsSystemValid])
  
  const updateBusinessUnitPayload = {
    callbacks: {
      action: updateFn,
    },
    entityParams: entityState,
    toastText: 'Business Unit Updated',
  }

  return (
    <PageLayout entityPayload={businessUnitPayload} activeTabBarItemKey="details">
      <Columns flexDirection={['column', 'column', 'row']}>
        <Columns.Main>
          <LoadingModule loading={loading} times={3} />

          {!loading && (
          <>
            <Details businessUnitFormPayload={businessUnitFormPayload}/>
            
            <TaxJurisdiction businessUnitFormPayload={businessUnitFormPayload}/>

            <BusinessNumber businessUnitFormPayload={businessUnitFormPayload} setValidationStatus={setBusinessNumberValid}/>

            <Bank businessUnitFormPayload={businessUnitFormPayload} setValidationStatus={setBankValid} />

            <InvoicePrefix businessUnitFormPayload={businessUnitFormPayload}/>

            <AccountingSystem businessUnitFormPayload={businessUnitFormPayload} setValidationStatus={setDestinationsSystem}/>

            <SaveFormModule>
              <Button
                buttonStyle="primaryCreate"
                disabled={!(isValid && saveEnabled)}
                marginLeft="auto"
                loading={updating}
                size="medium"
                onClick={() => updateBusinessUnit(updateBusinessUnitPayload)}
              >
                Save Details
              </Button>
            </SaveFormModule>
          </>
          )}
        </Columns.Main>

        <Columns.Sidebar>
          <Tools callbacks={callbacks} />
        </Columns.Sidebar>
      </Columns>
    </PageLayout>
  )
}

export default PageContent

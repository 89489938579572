import { useMemo } from 'react'

import { useModals, useNumericParams, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import CreateTransactionItemModal from '@modals/CreateTransactionItemModal'

import type { TransactionItemModel } from '@models/types'

import PageContent from './components/PageContent'

const defaultState = {
  showCreateTransactionItemModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    CreateTransactionItemModal: {
      closeModal: () => setState({ showCreateTransactionItemModal: false }),
      createTransactionItem: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const Edit = () => {
  const [state, setState] = useSetState(defaultState)
  const { showCreateTransactionItemModal } = state

  const { invoiceId } = useNumericParams()

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      approveInvoice: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      deleteTransactionItem: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      downloadInvoicePDF: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      showCreateTransactionItemModal: (payload: TransactionItemModel) => {
        setModalData('CreateTransactionItemModal', payload)
        setState({ showCreateTransactionItemModal: true })
      },
      updateInvoice: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),  
      updateOverrideTotal: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      updateTransactionItem: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      voidInvoice: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent invoiceId={invoiceId} />

        <CreateTransactionItemModal
          callbacks={callbacks('CreateTransactionItemModal', setState)}
          showModal={showCreateTransactionItemModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Edit

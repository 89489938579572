import { useContext, useMemo } from 'react'

import {
  digObject, launchModal, matchFilterKey, matchFilterNumber, sortArrayBy,
} from '@campaignhub/javascript-utils'

import { UseFormOptions, useForm, useLoadMore, useThunkDispatch, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import { matchCreditStatus } from '@functions/credit'
import { getSortKey } from '@functions/getSortKey'

import type { ModuleState } from '@redux/modules/types'

import * as creditActions from '@redux/modules/credit'

import { creditFormState } from '@models/credit'
import type { CreditCsvFormParams, CreditModel, CreditRequestOptions } from '@models/credit'
import { AppDispatch } from '@redux/store'
import PageContext from '@contexts/pageContext'


export interface CreditsPayload {
  callbacks: {
    generateCreditsCSV: () => void,
    loadMore: () => void,
  },
  canLoadMore: boolean,
  filteredCredits: CreditModel[],
  filteredCreditsCount: number,
  hasFilteredCredits: boolean,
  loading: boolean,
}

const watchEntityKeys = ['credits']

type DownloadCreditsCsvParams = {
  dispatch: AppDispatch,
  entityParams: CreditCsvFormParams,
}

export const downloadCreditsCSV = (params: DownloadCreditsCsvParams) => {
  const { dispatch, entityParams } = params
  const { downloadCreditsCSV: downloadFn } = creditActions

  return dispatch(downloadFn(entityParams))
}

export function useCreditForm(
  credit: Partial<CreditCsvFormParams>,
  options: UseFormOptions = {}
) {
  const { customRequiredFields = [{ key: 'startDate' }], validateOn } = options

  const creditCsvForm = useForm(
    creditFormState,
    { entity: credit, requiredFields: [...customRequiredFields], validateOn },
    [credit.status],
  )

  return {
    ...creditCsvForm,
  }
}

export type CreditFilters = {
  businessUnitId?: number,
  contactId?: number,
  status?: string,
  sort?: string,
}

export type UseCreditsOptions = {
  filters?: CreditFilters,
  performHttpRequests?: boolean,
  requestOptions?: CreditRequestOptions,
}

function useCredits(options: UseCreditsOptions) {
  const { filters, requestOptions } = options || {}
  const {
    businessUnitId: filterBusinessUnitId,
    contactId: filterContactId,
    status: filterStatusKey,
    sort
  } = filters || {}

  const dispatch = useThunkDispatch()

  const { callbacks } = useContext(PageContext)

  const {
    updatedEntities: { credits: creditsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { contacts, credits, statuses } = useSelector(reduxState => reduxState.entities)

  const order = (sort != null) ? sort.split('_')[0] : 'desc'
  const sortKey = getSortKey(sort, 'dateCreated')

  const filteredCredits = useMemo(() => {
    const filtered = Object.values(credits).filter((credit: CreditModel) => {
      const { businessUnitId, contactId, statusId } = credit || {}

      const status = digObject(statuses, String(statusId), {})
      const { key: statusKey } = status || {}

      const contact = digObject(contacts, String(contactId), {})
      credit.contact = contact

      const matchBusinessUnit = matchFilterNumber(Number(businessUnitId), Number(filterBusinessUnitId))
      const matchContact = matchFilterNumber(Number(contactId), Number(filterContactId))
      const matchStatus = matchFilterKey(statusKey, filterStatusKey)

      return (matchBusinessUnit
        && matchContact
        && matchStatus)
    })

    return sortArrayBy(filtered, order, sortKey)
  }, [creditsUpdatedAt, JSON.stringify(filters)])

  const filteredCreditsCount = filteredCredits.length
  const hasFilteredCredits = !!filteredCreditsCount

  const loadMorePayload = useLoadMore({
    ...options,
    loadedCount: filteredCreditsCount,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingCredits } = useReduxAction(
    'credits',
    'loadCredits',
    {
      ...requestOptions,
      limit,
      ...filtersWithOffset,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      downloadCreditsCSV: (entityParams: CreditCsvFormParams) => downloadCreditsCSV({ entityParams, dispatch }),
      generateCreditsCSV: () => launchModal({
        callbacks,
        modalKey: 'GenerateCreditsCsvModal',
        payload: {
          filterStatusKey,
        },
      }),
      loadMore,
    },
    canLoadMore,
    filteredCredits,
    filteredCreditsCount,
    hasFilteredCredits,
    loading: loadingCredits,
  }
}

export default useCredits

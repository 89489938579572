import {
  useForm, useLatestEntity, useSetState, useThunkDispatch,
} from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import * as creditActions from '@redux/modules/credit'

import defaultFormState, { requiredFields } from '@models/credit'

import type { AppDispatch } from '@redux/store'
import type { CreditModel, CreditRequestOptions } from '@models/types'

export interface CreditPayload {
  callbacks: {
    downloadCreditPDF: () => void,
    editCredit: () => void,
    updateCredit: (creditParams: CreditModel) => void,
  },
  editing: boolean,
  credit: CreditModel,
  loading: boolean,
  urls: {
    [key: string]: string,
  },
}

export const generateUrls = (credit?: Partial<CreditModel>) => {
  const { id } = credit || {}

  return {
    editCreditUrl: `#/accounting/credits/${id}/edit/`,
    creditsIndexUrl: '#/accounting/credits',
  }
}

type UpdateCreditParams = {
  creditParams: CreditModel,
  dispatch: AppDispatch,
  requestOptions?: CreditRequestOptions,
}

const updateCredit = (params: UpdateCreditParams) => {
  const {
    creditParams, dispatch, requestOptions,
  } = params
  const { updateCredit: updateFn } = creditActions

  return dispatch(updateFn(creditParams, requestOptions))
}

type DownloadCreditParams = {
  id: number,
  dispatch: AppDispatch,
}

export const downloadCreditPDF = (params: DownloadCreditParams) => {
  const { id, dispatch } = params
  const { downloadCreditPDF: downloadFn } = creditActions
  
  return dispatch(downloadFn(id))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useCreditForm(
  credit: CreditModel,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const creditForm = useForm(
    defaultFormState,
    { entity: credit, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [credit.id, credit.cacheKey],
  )

  return {
    ...creditForm,
  }
}
interface Options {
  performHttpRequests?: boolean,
  requestOptions?: CreditRequestOptions,
}

const defaultState = {
  editing: false,
}

function useCredit(initEntity: Partial<CreditModel> = {}, options: Options = {}) {
  const { performHttpRequests, requestOptions } = options || {}

  const { entity: credit }: { entity: CreditModel} = useLatestEntity(initEntity, 'credits')
  const { id } = credit

  const [state, setState] = useSetState(defaultState)
  const { editing } = state

  const dispatch = useThunkDispatch()

  useReduxAction(
    'credits',
    'loadCredit',
    {
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(id, actionRequestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedIds } = entityReducer
        return performHttpRequests && !loadedIds.includes(id)
      },
    },
  )

  const {
    loading, updating,
  } = useSelector(reduxState => reduxState.credits)

  return {
    callbacks: {
      downloadCreditPDF: () => downloadCreditPDF({ id, dispatch }),
      editCredit: () => setState({ editing: !editing }),
      updateCredit: (creditParams: CreditModel, requestOptions?: CreditRequestOptions) => (
        updateCredit({
          creditParams, dispatch, requestOptions,
        })
      ),
    },
    credit,
    editing,
    loading,
    updating,
    urls: generateUrls(credit),
  }
}

export default useCredit

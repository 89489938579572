import { useContext } from 'react'

import {
  Button, Form, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject, formatDate } from '@campaignhub/javascript-utils'

import useSelector from '@hooks/useSelector'
import useSubscription, { useSubscriptionForm } from '@hooks/useSubscription'

import { getStatusByKey } from '@functions/status'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

const MODAL_KEY = 'PauseSubscriptionModal'

type ModalCallbacks = {
  closeModal: () => void,
  updateSubscription: (payload: HandleCallbackActionParams) => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const PauseSubscriptionModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, updateSubscription } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const { subscription: initSubscription } = modalPayload

  const {
    callbacks: { updateSubscription: updateSubscriptionFn },
    subscription,
    updating,
  } = useSubscription(initSubscription)

  const {
    entityState,
    entityState: {
      resumeDate,
      statusId,
    },
    handlers,
  } = useSubscriptionForm(subscription)

  const { statuses } = useSelector(reduxState => reduxState.entities)

  const pausedStatus = getStatusByKey('paused_payment_collection', statuses)
  const { id: pausedStatusId, name: pausedStatusName } = pausedStatus || {}

  const onHoldStatus = getStatusByKey('on_hold', statuses)
  const { id: onHoldStatusId, name: onHoldStatusName } = onHoldStatus || {}

  const saveEnabled = (statusId === pausedStatusId) || (statusId === onHoldStatusId)

  // To be used with handleCallbackAction
  const pauseSubscriptionPayload = {
    callbacks: {
      action: updateSubscriptionFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    toastText: 'Subscription Paused',
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Pause" titleSecondLine="Subscription" />

      <SidebarModal.Content>
        <Form>
          <Form.Field label="* Pause Type">
            <select
              data-value-type="number"
              name="statusId"
              value={statusId}
              {...handlers}
            >
              <option value="">Please Select...</option>
              <option key={pausedStatusId} value={pausedStatusId}>
                {pausedStatusName} (Free Access)
              </option>
              <option key={onHoldStatusId} value={onHoldStatusId}>
                {onHoldStatusName} (No Access)
              </option>
            </select>
          </Form.Field>

          <Form.Field label="Resume Date" marginTop="large">
            <input
              name="resumeDate"
              type="date"
              value={formatDate(resumeDate, 'ISO8601')}
              {...handlers}
            />
          </Form.Field>
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={updating}
          onClick={() => updateSubscription(pauseSubscriptionPayload)}
          size="large"
        >
          {saveEnabled ? 'Pause Subscription' : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <PauseSubscriptionModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal

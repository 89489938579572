import type { EntitiesState } from '../entities'
import * as ModuleStates from './types'

// START MODULE IMPORTS
import * as activities from './activity'
import * as accountingSystems from './accountingSystem'
import * as bankTypes from './bankType'
import * as billingEntities from './billingEntity'
import * as billingNotes from './billingNote'
import * as billingTypes from './billingType'
import * as businessUnitProducts from './businessUnitProduct'
import * as businessUnits from './businessUnit'
import * as comments from './comment'
import * as contactPaymentMethods from './contactPaymentMethod'
import * as contacts from './contact'
import * as contactsInvoiceSummary from './contactsInvoiceSummary'
import * as contactSummary from './contactSummary'
import * as contactTypes from './contactType'
import * as contactUsers from './contactUser'
import * as conversations from './conversation'
import * as countries from './country'
import * as creditAllocations from './creditAllocation'
import * as credits from './credit'
import * as creditSummary from './creditSummary'
import * as currentUser from './currentUser'
import * as entities from '../entities'
import * as invoices from './invoice'
import * as invoiceSummary from './invoiceSummary'
import * as navigation from './navigation'
import * as paymentMethods from './paymentMethod'
import * as prices from './price'
import * as statuses from './status'
import * as subscriptionItems from './subscriptionItem'
import * as subscriptions from './subscription'
import * as subscriptionSummary from './subscriptionSummary'
import * as subscriptionTransactions from './subscriptionTransaction'
import * as taxJurisdictions from './taxJurisdiction'
import * as transactionItems from './transactionItem'
import * as transactions from './transaction'
import * as transactionTypes from './transactionType'
import * as users from './user'
import * as userTypes from './userType'
// END MODULE IMPORTS

export * from './module'

export type RootReducerState = {
// START ROOT REDUCER STATE
  activities: ModuleStates.ActivityModuleState,
  accountingSystems: ModuleStates.AccountingSystemModuleState,
  bankTypes: ModuleStates.BankTypeModuleState,
  billingEntities: ModuleStates.BillingEntityModuleState,
  billingNotes: ModuleStates.BillingNoteModuleState,
  billingTypes: ModuleStates.BillingTypeModuleState,
  businessUnitProducts: ModuleStates.BusinessUnitProductModuleState,
  businessUnits: ModuleStates.BusinessUnitModuleState,
  comments: ModuleStates.CommentModuleState,
  contactPaymentMethods: ModuleStates.ContactPaymentMethodModuleState,
  contactSummary: ModuleStates.ContactSummaryModuleState,
  contactTypes: ModuleStates.ContactTypeModuleState,
  contactUsers: ModuleStates.ContactUserModuleState,
  contacts: ModuleStates.ContactModuleState,
  contactsInvoiceSummary: ModuleStates.ContactsInvoiceSummaryModuleState,
  conversations: ModuleStates.ConversationModuleState,
  countries: ModuleStates.CountryModuleState,
  creditAllocations: ModuleStates.CreditAllocationModuleState,
  creditSummary: ModuleStates.CreditSummaryModuleState,
  credits: ModuleStates.CreditModuleState,
  currentUser: ModuleStates.CurrentUserModuleState,
  entities: EntitiesState,
  invoiceSummary: ModuleStates.InvoiceSummaryModuleState,
  invoices: ModuleStates.InvoiceModuleState,
  navigation: ModuleStates.NavigationModuleState,
  paymentMethods: ModuleStates.PaymentMethodModuleState,
  prices: ModuleStates.PriceModuleState,
  statuses: ModuleStates.StatusModuleState,
  subscriptionItems: ModuleStates.SubscriptionItemModuleState,
  subscriptionSummary: ModuleStates.SubscriptionSummaryModuleState,
  subscriptionTransactions: ModuleStates.SubscriptionTransactionModuleState,
  subscriptions: ModuleStates.SubscriptionModuleState,
  taxJurisdictions: ModuleStates.TaxJurisdictionModuleState,
  transactionItems: ModuleStates.TransactionItemModuleState,
  transactionTypes: ModuleStates.TransactionTypeModuleState,
  transactions: ModuleStates.TransactionModuleState,
  userTypes: ModuleStates.UserTypeModuleState,
  users: ModuleStates.UserModuleState,
// END ROOT REDUCER STATE
}

const modules = {
// START MODULES LIST
  activities,
  accountingSystems,
  bankTypes,
  billingEntities,
  billingNotes,
  billingTypes,
  businessUnitProducts,
  businessUnits,
  comments,
  contactPaymentMethods,
  contactSummary,
  contactTypes,
  contactUsers,
  contacts,
  contactsInvoiceSummary,
  conversations,
  countries,
  creditAllocations,
  creditSummary,
  credits,
  currentUser,
  entities,
  invoiceSummary,
  invoices,
  navigation,
  paymentMethods,
  prices,
  statuses,
  subscriptionItems,
  subscriptionSummary,
  subscriptionTransactions,
  subscriptions,
  taxJurisdictions,
  transactionItems,
  transactionTypes,
  transactions,
  userTypes,
  users,
// END MODULES LIST
}

export const reducers = Object.keys(modules).reduce((acc, moduleKey) => {
  const module = modules[moduleKey]
  acc[moduleKey] = module.default

  return acc
}, {})

export default modules

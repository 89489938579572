import { useMemo } from 'react'

import { ModalContext } from '@campaignhub/suit-theme'
import { useModals, useSetState } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import PageContent from './components/PageContent'
import handleCallbackAction from '@functions/handleCallbackAction'

export type PageContextPayload = {
  callbacks: {},
}

const defaultState = {}

type SetState = (state: Partial<typeof defaultState>) => void

const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {}

  return componentCallbacks[component] || {}
}

const Details = (props: { businessUnitId: number }) => {
  const { businessUnitId } = props || {}

  const [state, setState] = useSetState(defaultState)
  const {} = state

  const modalContext = useModals()

  const pageContext = useMemo(() => ({
    callbacks: {
      updateBusinessUnit: (payload: HandleCallbackActionParams) => handleCallbackAction(payload)},
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent businessUnitId={businessUnitId} />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Details

import { formatMoney } from 'accounting'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCog, faPencil, faBadgeDollar, faGift,
} from '@fortawesome/pro-light-svg-icons'

import {
  DashboardModuleListItem, IconWithMenu, Link,
} from '@campaignhub/suit-theme'

import useSubscriptionItem, { useRelations } from '@hooks/useSubscriptionItem'

import type { SubscriptionItemModel } from '@models/subscriptionItem'

import BusinessUnitLogo from '@components/BusinessUnitLogo'

const SubscriptionItem = (props: { subscriptionItem: SubscriptionItemModel }) => {
  const { subscriptionItem } = props

  const subscriptionItemPayload = useSubscriptionItem(subscriptionItem)
  const {
    callbacks: {
      editSubscriptionItem,
    },
    subscriptionItem: {
      qty,
      totalAmount,
    },
  } = subscriptionItemPayload

  const { businessUnit, businessUnitProduct, price } = useRelations(subscriptionItem)
  const { key: businessUnitKey, name: businessUnitName } = businessUnit
  const { name } = businessUnitProduct
  const { incTaxAmount } = price

  return (
    <DashboardModuleListItem
      imageComponent={<BusinessUnitLogo businessUnitKey={businessUnitKey} />}
      imageVariant="square"
      title={`${businessUnitName} - ${name}`}
      secondaryText={qty > 1 ? `${formatMoney(incTaxAmount)} - ${qty} Users` : ''}
    >
      <DashboardModuleListItem.TertiaryText>
        {formatMoney(totalAmount)}
      </DashboardModuleListItem.TertiaryText>

      <DashboardModuleListItem.Actions>
        <IconWithMenu
          boxProps={{ alignSelf: 'center' }}
          icon={(
            <Link greyLink>
              <FontAwesomeIcon icon={faCog} />
            </Link>
        )}
          style={{ marginLeft: 'auto', width: 'auto' }}
        >
          <IconWithMenu.Menu closeOnClick>
            <Link key="you" onClick={() => editSubscriptionItem()}>
              <FontAwesomeIcon icon={faPencil} /> Edit Item
            </Link>

            <Link key="organization" onClick={() => console.log('GoToEntity Product')}>
              <FontAwesomeIcon icon={faGift} /> View Product
            </Link>

            <Link key="brand" onClick={() => console.log('GoToEntity Price')}>
              <FontAwesomeIcon icon={faBadgeDollar} /> View Prices
            </Link>
          </IconWithMenu.Menu>
        </IconWithMenu>
      </DashboardModuleListItem.Actions>
    </DashboardModuleListItem>
  )
}

export default SubscriptionItem

import { useEffect } from "react"
import { SetEntityState } from "@campaignhub/react-hooks/build/hooks/useForm"
import { validateArray } from "@functions/validateArray"
import { default as defaultAccountingSystemBillingState , AccountingSystemBillingEntityModel, requiredFields } from "@models/accountingSystemBillingEntity"

export function useAccountingSystemBillingEntities(accountingSystemBillingEntities: AccountingSystemBillingEntityModel[], 
                                                   setValidationStatus: React.Dispatch<React.SetStateAction<boolean>>,
                                                   setEntityState: SetEntityState) 
{
  const { formErrors, validate } = validateArray(accountingSystemBillingEntities, requiredFields)

  useEffect(() => {

    if (!(accountingSystemBillingEntities && accountingSystemBillingEntities.length > 0)) {
      setEntityState({ accountingSystemBillingEntities: [defaultAccountingSystemBillingState] })
    }
    else
    {
      setEntityState({ 
        accountingSystemId: accountingSystemBillingEntities[0].accountingSystemId,
        billingEntityId: accountingSystemBillingEntities[0].billingEntityId
       })
    }

    setValidationStatus(validate())
  }, [accountingSystemBillingEntities])
  
  return {
    formErrors,
  }
}
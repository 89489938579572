import { useMemo } from 'react'
import { toast } from 'react-toastify'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import { ContactModel } from '@models/contact'

import GenerateStatementModal from '@modals/GenerateStatementModal'

import PageContent from './components/PageContent'

type ActionFn = (entityState: ContactModel, options: {}) => void

type ContactActionParams = {
  actionFn: ActionFn,
  entityParams: ContactModel,
  requestOptions: { [key: string]: string } | object,
}

const updateContact = (params: ContactActionParams) => {
  const { actionFn, entityParams, requestOptions } = params

  actionFn(entityParams, requestOptions).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    toast('Contact Updated Successfully')
  })
}

const defaultState = {
  showGenerateStatementModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    GenerateStatementModal: {
      closeModal: () => setState({ showGenerateStatementModal: false }),
      generateStatement: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const Overview = (props: { contactId: number }) => {
  const { contactId } = props || {}

  const [state, setState] = useSetState(defaultState)
  const { showGenerateStatementModal } = state

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      showGenerateStatementModal: (payload) => {
        setModalData('GenerateStatementModal', payload)
        setState({ showGenerateStatementModal: true })
      },
      updateContact: (entityParams: ContactModel, actionFn: ActionFn, requestOptions: object = {}) => updateContact({ actionFn, entityParams, requestOptions }),
    },
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent contactId={contactId} />

        <GenerateStatementModal
          callbacks={callbacks('GenerateStatementModal', setState)}
          showModal={showGenerateStatementModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Overview

import useContactPaymentMethod from '@hooks/useContactPaymentMethod'

import PaymentMethodListItem from '@components/PaymentMethodListItem'

import type { ContactPaymentMethodModel } from '@models/types'

interface PaymentMethodProps {
  contactPaymentMethod: ContactPaymentMethodModel,
  isAdmin: boolean,
}

const PaymentMethod = (props: PaymentMethodProps) => {
  const { contactPaymentMethod: initContactPaymentMethod, isAdmin } = props

  const {
    callbacks: {
      createOrEditContactPaymentMethod,
    },
    contactPaymentMethod,
  } = useContactPaymentMethod(initContactPaymentMethod)

  return (
    <PaymentMethodListItem
      contactPaymentMethod={contactPaymentMethod}
      // TODO: When we allow multiple/editing contact payment methods
      onClick={isAdmin ? null : () => createOrEditContactPaymentMethod()}
    />
  )
}

export default PaymentMethod

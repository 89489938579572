import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import useDispatch from '@hooks/useDispatch'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import * as billingNoteActions from '@redux/modules/billingNote'

import defaultFormState, { requiredFields } from '@models/billingNote'

import type { AppDispatch } from '@redux/store'
import type { ModuleState } from '@redux/modules/types'
import type { BillingNoteModel, BillingNoteRequestOptions } from '@models/billingNote'

type CreateBillingNoteParams = {
  billingNoteParams: Partial<BillingNoteModel>,
  dispatch: AppDispatch,
  requestOptions?: BillingNoteRequestOptions,
}

const createBillingNote = (params: CreateBillingNoteParams) => {
  const { dispatch, billingNoteParams, requestOptions } = params
  const { createBillingNote: createFn } = billingNoteActions

  return dispatch(createFn(billingNoteParams, requestOptions))
}

export function useBillingNoteForm(
  billingNote: Partial<BillingNoteModel>,
  options: UseFormOptions,
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const billingNoteForm = useForm(
    defaultFormState,
    { entity: billingNote, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [billingNote.id, billingNote.cacheKey],
  )

  return {
    ...billingNoteForm,
  }
}

export const useRelations = (billingNote: Partial<BillingNoteModel> = {}) => {
  const { contactId, userId } = billingNote

  const { contacts, users } = useSelector(reduxState => reduxState.entities)

  const contact = contactId && contacts[contactId] ? contacts[contactId] : {}
  const user = userId && users[userId] ? users[userId] : {}

  return {
    contact,
    user,
  }
}

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: BillingNoteRequestOptions,
}

function useBillingNote(initEntity: Partial<BillingNoteModel> = {}, options: Options = {}) {
  const { performHttpRequests, requestOptions } = options

  const { entity: billingNote }: { entity: BillingNoteModel} = useLatestEntity(initEntity, 'billingNotes')
  const { id } = billingNote

  const dispatch = useDispatch()

  const { contact, user } = useRelations(billingNote)

  useReduxAction(
    'billingNotes',
    'loadBillingNote',
    {
      entityId: id,
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(id, actionRequestOptions),
      shouldPerformFn: ({ loading }: ModuleState) => !!performHttpRequests && !loading,
    },
  )

  const { creating, loadedIds, loading } = useSelector(reduxState => reduxState.billingNotes)

  return {
    callbacks: {
      createBillingNote: (billingNoteParams: Partial<BillingNoteModel>, entityOptions?: BillingNoteRequestOptions) => (
        createBillingNote({ billingNoteParams, dispatch, requestOptions: entityOptions })
      ),
    },
    billingNote,
    contact,
    creating,
    loading: loading && !loadedIds.includes(billingNote.id),
    user,
  }
}

export default useBillingNote

import { useMemo } from 'react'

import { ModalContext } from '@campaignhub/suit-theme'
import { useModals, useNumericParams } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import PageContent from './components/PageContent'

const Edit = () => {
  const { creditId } = useNumericParams()

  const modalContext = useModals()

  const pageContext = useMemo(() => ({
    callbacks: {
      downloadCreditPDF: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      updateCredit: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent creditId={creditId} />

      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Edit

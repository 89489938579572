import { useMemo } from 'react'

import { matchFilterNumber, sortArrayBy } from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import { TransactionItemRequestOptions } from '@models/transactionItem'

const watchEntityKeys = ['transactionItems']

type Options = {
  filters: {
    transactionId: number,
  },
  performHttpRequests?: boolean,
  requestOptions?: TransactionItemRequestOptions,
}

function useTransactionItems(options: Options) {
  const { filters, performHttpRequests, requestOptions } = options || {}
  const { transactionId: filterTransactionId } = filters

  const {
    updatedEntities: { transactionItems: transactionItemsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const entities = useSelector(reduxState => reduxState.entities)
  const { transactionItems } = entities || {}

  const filteredTransactionItems = useMemo(() => {
    const filtered = Object.values(transactionItems).filter((item) => {
      const { transactionId } = item

      const matchTransactionItem = matchFilterNumber(transactionId, filterTransactionId)

      return (
        matchTransactionItem
      )
    })

    return sortArrayBy(filtered, 'asc', 'name')
  }, [transactionItemsUpdatedAt, JSON.stringify(filters)])

  const { loading: loadingTransactionItems } = useReduxAction(
    'transactionItems',
    'loadTransactionItems',
    {
      ...filters,
      ...requestOptions,
    },
    [performHttpRequests],
    {
    	shouldPerformFn: ({ loading }) => performHttpRequests && !loading,
    },
  )

  return {
    filteredTransactionItems,
    hasFilteredTransactionItems: !!filteredTransactionItems.length,
    loading: loadingTransactionItems,
  }
}
export default useTransactionItems

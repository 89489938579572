import { DashboardModule, Form } from '@campaignhub/suit-theme'

import useCountry from '@hooks/useCountry'

import type { ContactFormPayload } from '@hooks/useContact'

const viewOnly = JSON.parse(process.env.REACT_APP_VIEW_ONLY || "false")

const BankDetails = (props: { contactFormPayload: ContactFormPayload }) => {
  const { contactFormPayload } = props || {}
  const {
    entityState: {
      bankAccountNumber,
      bsb,
      businessName,
      countryId,
    },
    handlers,
  } = contactFormPayload

  const { country, loading } = useCountry({ id: countryId })
  const { currency } = country || {}

  return (
    <DashboardModule loading={loading} title="Bank Details">
      <Form padding="large">
        <Form.Row>
          <Form.Field label="Bank Account Number">
            <input
              disabled={viewOnly}
              name="bankAccountNumber"
              value={bankAccountNumber}
              type="text"
              {...handlers}
            />
          </Form.Field>

          <Form.Field label="Bank Account BSB" marginTop={['large', 0]}>
            <input
              disabled={viewOnly}
              name="bsb"
              value={bsb}
              type="text"
              {...handlers}
            />
          </Form.Field>
        </Form.Row>

        <Form.Row>
          <Form.Field label="Bank Account Name" marginTop="large">
            <input
              disabled={viewOnly}
              name="businessName"
              value={businessName}
              type="text"
              {...handlers}
            />
          </Form.Field>

          <Form.Field label="Currency" marginTop="large">
            <input
              disabled
              name="currency"
              value={currency}
              type="text"
              {...handlers}
            />
          </Form.Field>
        </Form.Row>
      </Form>
    </DashboardModule>
  )
}

export default BankDetails

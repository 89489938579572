type HandleErrorParams = {
  errors?: {
    [key: string]: string[],
  },
  type: string,
  title: string,
  status: number,
}

export const handleError = (data: HandleErrorParams) => {
  console.log('Raw Error', data)

  const errors = data?.errors
  const formattedErrors = !!errors && Object.values(errors)?.flat()

  return formattedErrors || ['Something went wrong']
}

export default handleError

import { useContext } from 'react'

import {
  Button, Form, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject, formatDate } from '@campaignhub/javascript-utils'

import useContact, { useStatementForm } from '@hooks/useContact'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

const MODAL_KEY = 'GenerateStatementModal'

type ModalCallbacks = {
  closeModal: () => void,
  generateStatement: (payload: HandleCallbackActionParams) => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const GenerateStatementModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, generateStatement } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const { contact: initContactUser } = modalPayload 

  const {
    callbacks: { downloadStatementPDF: downloadFn },
    contact: { id: contactId }
  } = useContact(initContactUser)

  const {
    entityState,
    entityState: {
      endDate,
      startDate,
    },
    handlers,
  } = useStatementForm({ id: contactId }, { validateOn: 'change' })

  const generateStatementPayload = {
    callbacks: {
      action: downloadFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    toastText: 'Statement Generated',
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Generate" titleSecondLine="Statement" />

      <SidebarModal.Content>
        <Form>
          <Form.Field label="Start Date">
            <input
              name="startDate"
              type="date"
              value={formatDate(startDate, 'ISO8601')}
              {...handlers}
            />
          </Form.Field>

          <Form.Field label="End Date" marginTop="large">
            <input
              name="endDate"
              type="date"
              value={formatDate(endDate, 'ISO8601')}
              {...handlers}
            />
          </Form.Field>
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          onClick={() => generateStatement(generateStatementPayload)}
          size="large"
        >
          Generate Statement
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <GenerateStatementModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal

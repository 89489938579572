import {
  Box, DashboardModule, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import { digObject, formatDate } from '@campaignhub/javascript-utils'

import { getInvoiceStatusDisplayKey } from '@functions/invoice'
import { statusColor } from '@functions/status'

import useSelector from '@hooks/useSelector'
import useTransaction from '@hooks/useTransaction'

import type { InvoicePayload } from '@hooks/useInvoice'

import CampaignMetaData from './CampaignMetaData'

const BillingDetails = (props: { invoicePayload: InvoicePayload }) => {
  const { invoicePayload } = props
  const { invoice, loading } = invoicePayload || {}

  const {
    businessUnitId,
    dateDue,
    number,
    transactionId,
  } = invoice || {}

  const hasCampaignMetaData = !!digObject(invoice?.invoiceMetaData, 'campaignId')

  const transactionPayload = useTransaction({ id: transactionId })
  const {
    transaction: {
      countryId,
      dateCreated,
      dateUpdated,
      terms,
    },
  } = transactionPayload

  const { businessUnits, countries, statuses } = useSelector(reduxState => reduxState.entities)

  const businessUnit = digObject(businessUnits, String(businessUnitId), {})
  const { name: businessUnitName } = businessUnit

  const country = digObject(countries, String(countryId), {})
  const { currency } = country

  const displayStatusKey = getInvoiceStatusDisplayKey(invoice, statuses)

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column' }}
      loading={loading}
      title="Billing Details"
    >
      {!loading && (
        <Box flexDirection="column">
          {hasCampaignMetaData && <CampaignMetaData invoice={invoice} />}

          <Box flexDirection="column" padding="large">
            <Box alignItems="center" justifyContent="space-between">
              <Text color="bodyFontLightColor" fontSize="small">Status</Text>

              <StatusBadge
                boxProps={{ justifySelf: 'end' }}
                color={statusColor(displayStatusKey)}
                ghost
                text={displayStatusKey}
              />
            </Box>

            <Box alignItems="center" justifyContent="space-between" marginTop="large">
              <Text color="bodyFontLightColor" fontSize="small">Due</Text>

              <Text fontSize="small" textAlign="right">
                {dateDue && formatDate(dateDue, 'ISO8601', 'dd LLLL yyyy')}
              </Text>
            </Box>

            <Box alignItems="center" justifyContent="space-between" marginTop="large">
              <Text color="bodyFontLightColor" fontSize="small">Created</Text>

              <Text fontSize="small" textAlign="right">
                {dateCreated && formatDate(dateCreated, 'ISO8601', 'dd LLLL yyyy')}
              </Text>
            </Box>

            <Box alignItems="center" justifyContent="space-between" marginTop="large">
              <Text color="bodyFontLightColor" fontSize="small">Last Updated</Text>

              <Text fontSize="small" textAlign="right">
                {dateUpdated && formatDate(dateUpdated, 'ISO8601', 'dd LLLL yyyy')}
              </Text>
            </Box>

            <Box alignItems="center" justifyContent="space-between" marginTop="large">
              <Text color="bodyFontLightColor" fontSize="small">Reference or Title</Text>

              <Text fontSize="small" textAlign="right">#{number}</Text>
            </Box>

            <Box alignItems="center" justifyContent="space-between" marginTop="large">
              <Text color="bodyFontLightColor" fontSize="small">Currency</Text>

              <Text fontSize="small" textAlign="right">{currency}</Text>
            </Box>

            {terms && (
              <Box alignItems="center" justifyContent="space-between" marginTop="large">
                <Text color="bodyFontLightColor" fontSize="small">Terms</Text>

                <Text fontSize="small" textAlign="right">{terms}</Text>
              </Box>
            )}

            <Box alignItems="center" justifyContent="space-between" marginTop="large">
              <Text color="bodyFontLightColor" fontSize="small">Platform</Text>

              <Text fontSize="small" textAlign="right">{businessUnitName}</Text>
            </Box>
          </Box>
        </Box>
      )}
    </DashboardModule>
  )
}

export default BillingDetails

import { useContext } from 'react'
import Swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faPauseCircle } from '@fortawesome/pro-light-svg-icons'

import { Button, Tools } from '@campaignhub/suit-theme'

import useSelector from '@hooks/useSelector'

import PageContext from '@contexts/pageContext'

import { getStatusIdByKey } from '@functions/status'

import { swalColors } from '@constants/swal'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'
import type { SubscriptionPayload } from '@hooks/useSubscription'

type ConfirmProps = {
  actionFn: () => void,
  confirmButtonText: string,
  icon: string,
  text: string,
  title: string,
}

const confirmAction = (params: ConfirmProps) => {
  const {
    actionFn,
    confirmButtonText,
    icon,
    text,
    title,
  } = params

  const { deletion } = swalColors

  Swal
    .fire({
      confirmButtonColor: deletion,
      confirmButtonText,
      icon,
      showCancelButton: true,
      text,
      title,
    })
    .then(({ value }) => {
      if (value){
        actionFn()
      }
    })
}

interface SubscriptionToolsProps {
  subscriptionPayload: SubscriptionPayload,
}

const SubscriptionTools = (props: SubscriptionToolsProps) => {
  const { subscriptionPayload } = props || {}
  const { callbacks, subscription } = subscriptionPayload || {}
  const { updateSubscription: updateSubscriptionFn } = callbacks || {}
  const { statusId } = subscription || {}

  const { statuses } = useSelector(reduxState => reduxState.entities)

  const cancelledStatusId = getStatusIdByKey('cancelled', statuses)

  // Early return/Don't show Tools if subscription has been cancelled
  if (statusId === cancelledStatusId) return null

  const activeStatusId = getStatusIdByKey('active', statuses)
  const pausedStatusId = getStatusIdByKey('paused_payment_collection', statuses)
  const onHoldStatusId = getStatusIdByKey('on_hold', statuses)

  const isPaused = (statusId === pausedStatusId) || (statusId === onHoldStatusId)

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      showPauseSubscriptionModal,
      updateSubscription,
    },
  } = pageContext

  const cancelSubscriptionPayload = {
    callbacks: {
      action: updateSubscriptionFn,
    },
    entityParams: { statusId: cancelledStatusId },
    toastText: 'Subscription Cancelled',
  }

  const unPauseSubscriptionPayload = {
    callbacks: {
      action: updateSubscriptionFn,
    },
    entityParams: { statusId: activeStatusId },
    toastText: 'Subscription Unpaused',
  }

  return (
    <Tools>
      <Button
        buttonStyle="secondaryUtility"
        icon={<FontAwesomeIcon icon={faTimesCircle} />}
        onClick={() => confirmAction({
          actionFn: () => updateSubscription(cancelSubscriptionPayload),
          confirmButtonText: 'Proceed',
          icon: 'error',
          text: 'Are you sure? This cannot be undone.',
          title: 'Cancel Subscription?',
        })}
        size="medium"
      >
        Cancel Subscription
      </Button>

      <Button
        buttonStyle="secondaryUtility"
        icon={<FontAwesomeIcon icon={faPauseCircle} />}
        marginTop="medium"
        onClick={isPaused
          ? () => confirmAction({
            actionFn: () => updateSubscription(unPauseSubscriptionPayload),
            confirmButtonText: 'Unpause',
            icon: 'warning',
            text: 'Are you sure? The contact will resume being charged.',
            title: 'Unpause Subscription?',
          })
          : () => showPauseSubscriptionModal({ subscription })}
        size="medium"
      >
        {isPaused ? 'Unpause Subscription' : 'Pause Subscription'}
      </Button>
    </Tools>
  )
}

export default SubscriptionTools

import { useContext, useEffect } from 'react'
import { DateTime } from 'luxon'
import { formatMoney } from 'accounting'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import {
  Button, Form, LoadingModule, SidebarModal, Text,
} from '@campaignhub/suit-theme'
import { useSetState } from '@campaignhub/react-hooks'

import {
  formatDate, generateRedirectUrl, toggleArray,
} from '@campaignhub/javascript-utils'

import { generateUrls } from '@hooks/useContact'

import { getMinSubscriptionEndDate } from '@functions/subscription'

import EntitySelector from '@components/EntitySelector'

import PageContext from '@contexts/pageContext'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'
import type { SubscriptionItemModel } from '@models/types'

import useCreateSubscription from './hooks/useCreateSubscription'

import ContactPaymentMethod from './components/ContactPaymentMethod'
import SubscriptionItemListItem from './components/SubscriptionItemListItem'

const MODAL_KEY = 'CreateSubscriptionModal'

const today = DateTime.now().toFormat('yyyy-MM-dd')

const defaultState = {
  minSubscriptionEndDate: today,
}

type CreateSubscriptionModalProps = {
  callbacks: {
    closeModal: () => void,
    createSubscription: (createSubscriptionPayload: HandleCallbackActionParams) => void,
  },
  showModal: boolean,
}

const CreateSubscriptionModal = (props: CreateSubscriptionModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, createSubscription } = callbacks || {}

  const [state, setState] = useSetState(defaultState)
  const { minSubscriptionEndDate } = state

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      showCreateSubscriptionItemModal,
    },
  } = pageContext

  const useCreateSubscriptionPayload = useCreateSubscription()
  const {
    callbacks: {
      createSubscriptionFn,
    },
    contactPaymentMethodsPayload,
    hasContactUsers,
    hasMultipleSubscriptionItems,
    hasSubscriptionItems,
    loading,
    prices,
    subscriptionFormPayload,
    total,
  } = useCreateSubscriptionPayload

  const {
    entityState,
    entityState: {
      billingStart,
      contactId,
      interval,
      items,
      subscriptionEnd,
      subscriptionStart,
      trialPeriodDays,
    },
    errors,
    handlers,
    saveEnabled,
    setEntityState,
  } = subscriptionFormPayload

  const { editContactDetailsUrl } = generateUrls({ id: contactId })

  const createSubscriptionPayload = {
    callbacks: {
      action: createSubscriptionFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    shouldRedirect: true,
    toastText: 'Subscription Created',
  }

  // Set Subscription Interval from Price of first added Subscription Item/Product
  useEffect(() => {
    if (items.length && !interval){
      const subscriptionItem = items[0]
      const { priceId } = subscriptionItem

      const price = prices[priceId]
      const { interval: priceInteval } = price

      setEntityState({ interval: priceInteval })
    }
  }, [items.length])

  // Auto select billing start date same as subscription start date when user has selected
  // Do we want to autoselect both of these with todays date?
  useEffect(() => {
    if (subscriptionStart && !billingStart){
      setEntityState({ billingStart: subscriptionStart })
      setState({ minSubscriptionEndDate: getMinSubscriptionEndDate(subscriptionStart, interval) })
    }
  }, [subscriptionStart])

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Create" titleSecondLine="Subscription" />

      <SidebarModal.Content>
        <Form>
          <EntitySelector
            callbacks={{
              selectItem: contact => setEntityState({
                contactId: contact ? contact.id : null,
              }),
            }}
            entityKey="contacts"
            label="* Contact"
            selectedItemId={contactId}
          />

          <LoadingModule boxProps={{ marginTop: 'large' }} loading={loading} />

          {!loading && !!contactId && (
            <>
              {!hasContactUsers && (
                <Form.Field label="Contact Users" marginTop="large">
                  <SidebarModal.NoticeBox boxProps={{ marginBottom: 'medium' }}>
                    <SidebarModal.NoticeBox.Title>
                      No Contact Users
                    </SidebarModal.NoticeBox.Title>

                    <SidebarModal.NoticeBox.Paragraph>
                      You first need to import a user to manage
                      this contact in core billing before we can create a subscription.
                    </SidebarModal.NoticeBox.Paragraph>
                  </SidebarModal.NoticeBox>

                  <Button
                    as="a"
                    buttonStyle="secondaryUtility"
                    href={`${editContactDetailsUrl}?showModal=ImportUsersModal?redirect=${generateRedirectUrl()}`}
                    icon={<FontAwesomeIcon icon={faPlus} />}
                    size={['large', 'medium']}
                    width="100%"
                  >
                    Import Contact Users
                  </Button>
                </Form.Field>
              )}

              {hasContactUsers && (
                <>
                  <ContactPaymentMethod contactPaymentMethodsPayload={contactPaymentMethodsPayload} />

                  <SidebarModal.Separator boxProps={{ marginTop: 'medium' }} />

                  <Form.Field label="Products" marginTop="large">
                    {items.map((subscriptionItem: SubscriptionItemModel) => {
                      const { businessUnitProductId } = subscriptionItem

                      return (
                        <SubscriptionItemListItem
                          key={businessUnitProductId}
                          subscriptionItem={subscriptionItem}
                        />
                      )
                    })}

                    <Button
                      buttonStyle="secondaryUtility"
                      icon={<FontAwesomeIcon icon={faPlus} />}
                      onClick={() => showCreateSubscriptionItemModal({
                        callbacks: {
                          addSubscriptionItem: (subscriptionItem: SubscriptionItemModel) => {
                            setEntityState({ items: toggleArray(items, subscriptionItem, { deepCompare: true }) })
                          },
                        },
                        contactId,
                        interval,
                      })}
                      size={['large', 'medium']}
                      width="100%"
                    >
                      Add Product
                    </Button>
                  </Form.Field>

                  {hasMultipleSubscriptionItems && total && (
                    <Form.Field boxProps={{ lineHeight: 1.3, textAlign: 'end' }} marginTop="large">
                      <Text fontSize="large">{formatMoney(total)}</Text>
                      <Text color="bodyFontLightColor" fontSize="small">TOTAL</Text>
                    </Form.Field>
                  )}
                </>
              )}

              {hasSubscriptionItems && (
                <>
                  <Form.Field errorMessage={errors.subscriptionStart} label="* Start Date" marginTop="large">
                    <input
                      min={today}
                      name="subscriptionStart"
                      type="date"
                      value={formatDate(subscriptionStart, 'ISO8601')}
                      {...handlers}
                    />
                  </Form.Field>

                  <Form.Field errorMessage={errors.billingStart} label="* Billing Start Date" marginTop="large">
                    <input
                      min={today}
                      name="billingStart"
                      type="date"
                      value={formatDate(billingStart, 'ISO8601')}
                      {...handlers}
                    />
                  </Form.Field>

                  <Form.Field label="End Date" marginTop="large">
                    <input
                      min={minSubscriptionEndDate}
                      name="subscriptionEnd"
                      type="date"
                      value={formatDate(subscriptionEnd, 'ISO8601')}
                      {...handlers}
                    />
                  </Form.Field>

                  <Form.Field label="Free Trial Days" marginTop="large">
                    <input
                      name="trialPeriodDays"
                      type="number"
                      value={trialPeriodDays}
                      {...handlers}
                    />
                  </Form.Field>
                </>
              )}
            </>
          )}
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          onClick={() => createSubscription(createSubscriptionPayload)}
          size="large"
        >
          Create Subscription
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: CreateSubscriptionModalProps) => (
  <SidebarModal.RenderController {...props}>
    <CreateSubscriptionModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal

import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBan, faCheckCircle, faExclamationTriangle, faFileExport, faList, faPencil,
} from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, Tools,
} from '@campaignhub/suit-theme'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'

import AdminPageHeader from '@components/AdminPageHeader'
import PageFilterModule from '@components/PageFilterModule'

import Subscriptions from '../Subscriptions'

const viewOnly = JSON.parse(process.env.REACT_APP_VIEW_ONLY || "false")

interface PageContentProps {
  pageFilters: {},
}

const buildPageFilterFields = () => {
  const boxProps = { marginBottom: 'large' }
  const style = { marginBottom: 16 }

  // Add Subscription Cost Filter (low & high values)

  return [
    {
      component: 'EntitySelector',
      componentProps: {
        boxProps,
        endpoint: '/contacts',
        entityKey: 'contacts',
      },
      key: 'contactId',
    },
    {
      component: 'EntitySelector',
      componentProps: {
        boxProps,
        endpoint: '/business-units',
        entityKey: 'businessUnits',
      },
      key: 'businessUnitId',
    },
    {
      components: [
        {
          component: 'DynamicInput',
          componentProps: {
            dateFormat: 'DD/MM/YYYY',
            dateParseFormat: 'YYYY-MM-DD',
            fieldName: 'startDate',
            inputType: 'date',
            style,
          },
          key: 'startDate',
        },
        {
          component: 'DynamicInput',
          componentProps: {
            dateFormat: 'DD/MM/YYYY',
            dateParseFormat: 'YYYY-MM-DD',
            fieldName: 'endDate',
            inputType: 'date',
            style,
          },
          key: 'endDate',
        },
      ],
      label: 'Billed Within',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'select',
        style,
      },
      key: 'sort',
      label: 'Sort By',
      values: [
        { key: 'asc_subscriptionStart', label: 'Oldest First' },
        { key: 'desc_subscriptionStart', label: 'Newest First' },
        { key: 'desc_incTaxAmount', label: 'Highest Amount First' },
        { key: 'asc_incTaxAmount', label: 'Lowest Amount First' },
        { key: 'asc_contact.name', label: 'Contact (A-Z)' },
        { key: 'desc_contact.name', label: 'Contact (Z-A)' },
      ],
    },
  ]
}

const PageContent = (props: PageContentProps) => {
  const { pageFilters } = props

  useLocation()

  const { status } = parsePermittedQueryParams(getQueryParamsFromHash(), ['status'])
  const currentStatus = status || 'all'

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      resetFilters,
      showCreateSubscriptionModal,
      updateFilters,
    },
    defaultFilters,
  } = pageContext

  const actionContentProps = {
    actionContent: (
      <Button
        buttonStyle="primaryCreate"
        onClick={() => showCreateSubscriptionModal()}
        size="medium"
      >
        Create
      </Button>
    )
  };
  
  return (
    <>
      <Helmet>
        <title>Subscriptions | Billing</title>
      </Helmet>

      <AdminPageHeader
        {...(!viewOnly && actionContentProps)}
        activeTabBarItemKey={currentStatus}
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: '#/accounting/subscriptions',
            icon: faList,
            key: 'all',
            title: 'All',
          },
          {
            href: '#/accounting/subscriptions?status=pending',
            icon: faPencil,
            key: 'pending',
            title: 'Pending',
          },
          {
            href: '#/accounting/subscriptions?status=active',
            icon: faCheckCircle,
            key: 'active',
            title: 'Active',
          },
          {
            href: '#/accounting/subscriptions?status=expiring',
            icon: faExclamationTriangle,
            key: 'expiring',
            title: 'Expiring',
          },
          {
            href: '#/accounting/subscriptions?status=inactive',
            icon: faBan,
            key: 'inactive',
            title: 'Inactive',
          },
        ]}
        title="Subscriptions"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <Subscriptions
              currentStatus={currentStatus}
              pageFilters={pageFilters}
            />
          </Columns.Main>

          <Columns.Sidebar>
            <Tools>
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faFileExport} />}
                iconPosition="left"
                onClick={() => console.log('Generate CSV')}
                size="medium"
              >
                Generate .CSV
              </Button>
            </Tools>

            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              defaultFilters={defaultFilters}
              filterFields={buildPageFilterFields()}
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent

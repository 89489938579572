import { Helmet } from 'react-helmet-async'

import { faPencil } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, LoadingModule,
} from '@campaignhub/suit-theme'
import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import useInvoice, { generateUrls } from '@hooks/useInvoice'

import AdminPageHeader from '@components/AdminPageHeader'
import EntityActivity from '@components/EntityActivity'

import { defaultRequestOptions } from '@sections/Client/packs/Accounting'

import ContactDetails from '@components/ContactDetails'
import BillingDetails from '../BillingDetails'
import InvoiceActions from '../InvoiceActions'
import Tools from '../Tools'
import Transaction from '../Transaction'

const PageContent = (props: { invoiceId: number }) => {
  const { invoiceId } = props || {}

  const invoicePayload = useInvoice(
    {
      id: invoiceId,
    },
    {
      performHttpRequests: !!invoiceId,
      requestOptions: {
        ...defaultRequestOptions.invoice,
      },
    },
  )
  const {
    callbacks,
    invoice,
    invoice: {
      number,
    },
    isDraftInvoice,
    loading,
  } = invoicePayload

  const { invoicesIndexUrl } = generateUrls()

  const { redirect } = parsePermittedQueryParams(getQueryParamsFromHash(), ['redirect'])

  return (
    <>
      <Helmet>
        <title>Invoice | Billing</title>
      </Helmet>

      <AdminPageHeader
        activeTabBarItemKey="edit"
        actionContent={(
          <Button
            as="a"
            buttonStyle="secondaryUtility"
            href={redirect || invoicesIndexUrl}
            size="medium"
          >
            Back
          </Button>
        )}
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: '#',
            icon: faPencil,
            key: 'edit',
            title: 'Edit Invoice',
          },
        ]}
        title={number ? `Invoice #${number}` : 'Invoice'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && (
              <>
                {isDraftInvoice && (
                  <InvoiceActions invoicePayload={invoicePayload} />
                )}

                <ContactDetails entity={invoice} />

                <Transaction invoicePayload={invoicePayload} />

                <EntityActivity
                  boxProps={{ marginTop: 'large' }}
                  componentSettings={{
                    conversation: {
                      title: number ? `Invoice #${number}` : 'Invoice',
                    },
                  }}
                  subjectType="Invoice"
                  subjectId={invoiceId}
                />
              </>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <Tools callbacks={callbacks} />

            <BillingDetails invoicePayload={invoicePayload} />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent

import { Columns, LoadingModule } from '@campaignhub/suit-theme'

import useContact from '@hooks/useContact'

import Statements from '../Statements'
import PageLayout from '../../../../components/PageLayout'
import Tools from '../Tools'

const PageContent = (props: { contactId: number }) => {
  const { contactId } = props

  const contactPayload = useContact({ id: contactId })

  const { callbacks, contact, loading } = contactPayload
  const { name } = contact || {}

  return (
    <PageLayout entityPayload={contactPayload} activeTabBarItemKey="statements">
      <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
        <Columns.Main>
          <LoadingModule loading={loading} />

          {!loading && (
            <Statements contactId={contactId} />
          )}
        </Columns.Main>

        <Columns.Sidebar>
          <Tools callbacks={callbacks} />
        </Columns.Sidebar>
      </Columns>
    </PageLayout>
  )
}

export default PageContent

export type ContactPaymentMethodRequestOptions = {
  includeContactPaymentMethodContact?: boolean,
  includeContactPaymentMethodPaymentMethod?: boolean,
}

export type PaymentMethodData = {
  bankAccount?: {
    account_name: string,
    account_number: number,
    bsb: number,
  },
  card?: {
    billing_details: {
      name: string,
      email: string,
      phone: string,
      address: {
        city: string,
        line1: string,
        line2: string,
        state: string,
        country: string,
        postal_code: string,
      },
    },
    brand: string,
    exp_month: number,
    exp_year: number,
    last4: number,
    name: string,
  },
}

export type ContactPaymentMethodModel = {
  cacheKey?: string,
  contactId?: number,
  data: PaymentMethodData,
  paymentMethodCode?: string, // Added in contactPaymentMethodDTO from paymentMethod - code
  paymentMethodDescription?: string, // Added in contactPaymentMethodDTO from paymentMethod - description
  id: number | null,
  isDefault: boolean,
  paymentMethodId?: number,
}

const state = {
  contactId: null,
  data: {},
  id: null,
  isDefault: false,
  paymentMethodId: null,
}

export const requiredFields = [
  { key: 'contactId' },
  { key: 'paymentMethodId' },
]

export default state

import { useMemo } from 'react'

import { matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { CountryModel, CountryRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['countries']

type CountryFilters = {
  string?: string,
}

type UseCountriesOptions = {
  filters?: CountryFilters,
  performHttpRequests?: boolean,
  requestOptions?: CountryRequestOptions,
}

function useCountries(options: UseCountriesOptions = {}) {
  const { filters = {}, requestOptions } = options
  const {
    string: filterString,
  } = filters

  const {
    updatedEntities: { countries: countriesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { countries } = useSelector(reduxState => reduxState.entities)

  const filteredCountries = useMemo(() => {
    const filtered = Object.values(countries).filter((country: CountryModel) => {
      const { id, name } = country

      const matchId = id.toString() === filterString
      const matchName = matchFilterString(name, filterString)
      const matchString = matchId || matchName

      return matchString
    })

    return sortArrayBy(filtered, 'asc', 'name')
  }, [countriesUpdatedAt, JSON.stringify(filters)])

  const filteredCountriesCount = filteredCountries.length
  const hasFilteredCountries = !!filteredCountriesCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredCountriesCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingCountries } = useReduxAction(
    'countries',
    'loadCountries',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredCountries,
    filteredCountriesCount,
    hasFilteredCountries,
    loading: loadingCountries,
  }
}

export default useCountries

import React from 'react'

import { DashboardModuleListItem } from '@campaignhub/suit-theme'
// TODO: update suit theme to export ListItemProps
// Define below interface as intersection of ListItemProps from suit theme and additional
// UserListItemProps i.e. isModal

interface UserListItemProps {
  boxProps?: object,
  children?: React.ReactNode,
  deleteFn?: () => void,
  editFn?: () => void,
  href?: string,
  isModal?: boolean,
  isSidebar?: boolean,
  onClick?: () => void,
  secondaryText?: string | React.ReactNode,
  tertiaryText?: string | React.ReactNode,
  title: string | React.ReactNode,
}

const UserListItem = (props: UserListItemProps) => {
  const {
    boxProps,
    deleteFn,
    editFn,
    href,
    isModal,
    isSidebar = true,
    onClick,
    secondaryText,
    tertiaryText,
    title,
    ...restProps
  } = props

  const hasIconFn = !!editFn || !!deleteFn

  const modalBoxProps = isModal ? {
    borderBottom: 'none',
    paddingX: 0,
    paddingY: 'medium',
  } : {}

  return (
    <DashboardModuleListItem
      boxProps={{
        ...modalBoxProps,
        ...boxProps,
      }}
      href={href}
      isSidebar={isSidebar}
      onClick={onClick}
      secondaryText={secondaryText}
      title={title}
      {...restProps}
    >
      {tertiaryText && (
        <DashboardModuleListItem.TertiaryText>
          {tertiaryText}
        </DashboardModuleListItem.TertiaryText>
      )}

      {hasIconFn && (
        <DashboardModuleListItem.Actions>
          {deleteFn && (
            <DashboardModuleListItem.Icon
              onClick={() => deleteFn()}
              marginRight="medium"
              type="delete"
            />
          )}

          {editFn && (
            <DashboardModuleListItem.Icon
              onClick={() => editFn()}
              type="edit"
            />
          )}
        </DashboardModuleListItem.Actions>
      )}

    </DashboardModuleListItem>
  )
}

export default UserListItem

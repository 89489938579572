import { useContext, useEffect } from 'react'
import { formatMoney } from 'accounting'

import {
  Button, Form, ModalContext, SidebarModal, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useTransactionItem, { useTransactionItemForm } from '@hooks/useTransactionItem'

import EntitySelector from '@components/EntitySelector'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'
import type { BusinessUnitProductModel } from '@models/types'

const MODAL_KEY = 'CreateTransactionItemModal'

type ModalCallbacks = {
  closeModal: () => void,
  createTransactionItem: (payload: HandleCallbackActionParams) => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const CreateTransactionItemModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, createTransactionItem } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const { businessUnitId, transactionItem: initTransactionItem } = modalPayload

  const {
    callbacks: {
      createTransactionItem: createFn,
    },
    creating,
    transactionItem,
  } = useTransactionItem(initTransactionItem)

  const {
    blurFields,
    entityState,
    entityState: {
      businessUnitProductId,
      incTaxTotal,
      title,
      qty,
      unitPriceIncTax,
    },
    errors,
    handlers,
    saveEnabled,
    setEntityState,
  } = useTransactionItemForm(transactionItem, { validateOn: 'change' })

  const createTransactionItemPayload = {
    callbacks: {
      action: createFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    toastText: 'Transaction Item Created',
  }

  useEffect(() => {
    if (blurFields.length){
      // Calculate the Transaction Item incTaxTotal when QTY or Price changed.
      const newIncTaxTotal = qty * unitPriceIncTax
      return setEntityState({ incTaxTotal: newIncTaxTotal })
    }
  }, [qty, unitPriceIncTax])

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Add" titleSecondLine="Item" />

      <SidebarModal.Content>
        <Form>
          {!!businessUnitId && (
            <EntitySelector
              callbacks={{
                selectItem: (businessUnitProduct: BusinessUnitProductModel) => setEntityState({
                  businessUnitProductId: businessUnitProduct ? businessUnitProduct.id : null,
                }),
              }}
              entityKey="businessUnitProducts"
              searchRequestOptions={{
                businessUnitId,
                isSubscribable: false,
              }}
              selectedItemId={businessUnitProductId}
            />
          )}

          {!!businessUnitProductId && (
            <Form.Field label="* Price" errorMessage={errors.unitPriceIncTax} marginTop="large">
              <input
                name="unitPriceIncTax"
                type="number"
                value={unitPriceIncTax}
                {...handlers}
              />
            </Form.Field>
          )}

          {!!businessUnitProductId && !!unitPriceIncTax && (
            <>
              <Form.Field label="* QTY" errorMessage={errors.qty} marginTop="large">
                <input name="qty" type="number" value={qty} {...handlers} />
              </Form.Field>

              <Form.Field label="* Description" errorMessage={errors.title} marginTop="large">
                <input name="title" type="text" value={title} {...handlers} />
              </Form.Field>
            </>
          )}

          {!!unitPriceIncTax && qty && (
            <Form.Field boxProps={{ lineHeight: 1.3, textAlign: 'end' }} marginTop="large">
              <Text fontSize="large">{formatMoney(incTaxTotal)}</Text>
              <Text color="bodyFontLightColor" fontSize="small">TOTAL</Text>
            </Form.Field>
          )}
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating}
          onClick={() => createTransactionItem(createTransactionItemPayload)}
          size="large"
        >
          {saveEnabled ? 'Create Transaction Item' : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <CreateTransactionItemModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal

import { DashboardModule } from '@campaignhub/suit-theme'

import { snakeToTitleCase } from '@campaignhub/javascript-utils'

import type { InvoicesPayload } from '@hooks/useInvoices'

import ListBlankState from '@components/ListBlankState'

import InvoicesTable from './components/Table'

interface InvoicesProps {
  invoicesPayload: InvoicesPayload,
  status: string,
}

const Invoices = (props: InvoicesProps) => {
  const { invoicesPayload, status } = props
  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredInvoicesCount,
    hasFilteredInvoices,
    loading,
  } = invoicesPayload

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column' }}
      loading={loading}
      title={`${snakeToTitleCase(status)} Invoices`}
    >
      {!loading && !hasFilteredInvoices && <ListBlankState />}

      {!loading && hasFilteredInvoices && (
        <InvoicesTable invoicesPayload={invoicesPayload} statusKey={status} />
      )}

      <DashboardModule.LoadMoreFooter
        callbacks={{ loadMore }}
        canLoadMore={canLoadMore}
        entityCount={filteredInvoicesCount}
        loading={loading}
      />
    </DashboardModule>
  )
}

export default Invoices

export type BillingNoteRequestOptions = {}

export type BillingNoteModel = {
  contactId?: number,
  dateCreated?: string,
  id?: number,
  note?: string,
  title?: string,
  userfullName?: string, // Added in billingNoteDTO
  userId?: number,
}

const state = {
  contactId: null,
  id: null,
  title: '',
  note: '',
  userId: '',
}

export const requiredFields = [
  { key: 'contactId' },
  { key: 'note' },
  { key: 'userId' },
]

export default state

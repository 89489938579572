import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { BillingEntityModel } from '@models/billingEntity'
import type { AppDispatch } from '@redux/store'
import type {
  Action, ActionResult, EntityOptions, Errors,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/billingEntity/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/billingEntity/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/billingEntity/FETCH_FAILURE'

export type BillingEntityModuleState = {
  errors: string[],
  loaded: boolean,
  loading: boolean,
}

const initialState: BillingEntityModuleState = {
  errors: [],
  loaded: false,
  loading: false,
}

// Actions
export function fetchRequest(): Action {
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(): Action {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []): Action {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

// Action Creators
export function loadBillingEntities(options: EntityOptions = {}) {
  return (dispatch: AppDispatch): Promise<ActionResult<BillingEntityModel[]>> => {
    dispatch(fetchRequest())

    const promise = api('/billing-entity', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.BILLING_ENTITY_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: BillingEntityModuleState = initialState,
  action: Action = { type: '' },
): BillingEntityModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}

import { formatMoney } from 'accounting'

import {
  Box, ListItem, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import { statusColor } from '@functions/status'

type InvoiceSummaryItem = {
  invoiceCount: number,
  invoicesTotalAmount: number,
  key: string,
}

interface InvoiceSummaryGroupProps {
  invoiceSummaryItem: InvoiceSummaryItem,
}

const InvoiceSummaryGroup = (props: InvoiceSummaryGroupProps) => {
  const { invoiceSummaryItem } = props || {}
  const { invoiceCount, invoicesTotalAmount, key } = invoiceSummaryItem

  // For Approved Invoices we are displaying 'Invoiced'
  const statusKey = key === 'approved' ? 'invoiced' : key

  return (
    <ListItem
      boxProps={{
        alignItems: 'center', borderBottom: '1px dashed', lineHeight: 1.3, padding: 'large',
      }}
      disableHover
      forceBottomBorder
    >
      <Box flexDirection="column">
        <Text color="bodyFontLightColor" fontSize="small">
          {`${invoiceCount || '0'} Invoices`}
        </Text>

        <Text color={statusColor(statusKey) || 'bodyFontColor'} fontSize="large" marginTop="small">
          {formatMoney(invoicesTotalAmount)}
        </Text>
      </Box>

      <StatusBadge
        boxProps={{ justifySelf: 'end', height: 25, width: 80 }}
        color={statusColor(statusKey)}
        ghost
        text={statusKey}
      />
    </ListItem>
  )
}

export default InvoiceSummaryGroup

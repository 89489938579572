import { useMemo } from 'react'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import PageContent from './components/PageContent'

const Invoices = (props: { businessUnitId: number }) => {
  const { businessUnitId } = props || {}

  const pageContext = useMemo(() => ({
    callbacks: {},
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <PageContent businessUnitId={businessUnitId} />
    </PageContext.Provider>
  )
}

export default Invoices

import { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltToBottom, faFilePdf } from '@fortawesome/pro-light-svg-icons'

import { Button, Tools } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

interface CreditToolsProps {
  callbacks: {
    downloadCreditPDF: () => void,
  },
}

const CreditTools = (props: CreditToolsProps) => {
  const { callbacks } = props
  const { downloadCreditPDF: downloadFn } = callbacks || {}

  const pageContext = useContext(PageContext)
  const { callbacks: { downloadCreditPDF } } = pageContext
  const downloadPDFPayload = {
    callbacks: {
      action: downloadFn,
    },
    toastText: 'Credit PDF Generated',
  }

  return (
    <Tools boxProps={{ marginTop: ['large', 0] }}>
      <Button
        buttonStyle="secondaryUtility"
        icon={<FontAwesomeIcon icon={faArrowAltToBottom} />}
        onClick={() => downloadCreditPDF(downloadPDFPayload)}
        size="medium"
      >
        Download PDF
      </Button>
    </Tools>
  )
}

export default CreditTools

import { useMemo } from 'react'
import { formatMoney } from 'accounting'

import { StatusBadge, Table, Text } from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import statusColor from '@functions/status'

import useSelector from '@hooks/useSelector'

import { CreditModel } from '@models/types'
import { EntitiesState } from '@redux/entities'

interface RequestTableProps {
  credit: CreditModel,
}

type BuildColumnsParams = {
  entities: EntitiesState,
}

const buildColumns = (props: BuildColumnsParams) => {
  const { entities } = props || {}

  const {
    businessUnits,
    statuses,
    transactions,
  } = entities || {}

  const tableColumns = [
    {
      cellProps: {
        key: 'statusId',
        style: {
          verticalAlign: 'middle',
        },
      },
      title: 'Status',
      dataKey: 'statusId',
      render: (statusId: number) => {
        const status = digObject(statuses, String(statusId), {})
        const { key, name } = status

        return (
          <StatusBadge color={statusColor(key)} ghost text={name} />
        )
      },
    },
    {
      cellProps: {
        key: 'id',
      },
      title: 'Business Unit',
      dataKey: 'id',
      render: (id: number, credit: CreditModel) => {
        const { transactionId } = credit || {}

        const transaction = digObject(transactions, String(transactionId), {})
        const { businessUnitId } = transaction || {}

        const businessUnit = digObject(businessUnits, String(businessUnitId), {})
        const { name } = businessUnit || {}

        return (
          <Text variant="ellipsis">{name}</Text>
        )
      },
    },
    {
      cellProps: {
        key: 'title',
      },
      title: 'Description',
      dataKey: 'title',
      render: (title: string) => (
        <Text variant="ellipsis">{title}</Text>
      ),
    },
    {
      cellProps: {
        key: 'transactionId',
        style: {
          verticalAlign: 'middle',
        },
      },
      title: 'Amount ($)',
      dataKey: 'transactionId',
      render: (transactionId: number) => {
        const transaction = digObject(transactions, String(transactionId), {})
        const { incTaxTotal } = transaction || {}

        return `${formatMoney(incTaxTotal)}`
      },
    },
    {
      cellProps: {
        key: 'remainingCredit',
        style: {
          verticalAlign: 'middle',
        },
      },
      title: 'Remaining ($)',
      dataKey: 'remainingCredit',
      render: (remainingCredit: number) => `${formatMoney(remainingCredit)}`,
    },
  ]

  return tableColumns
}

const CreditRequestTable = (props: RequestTableProps) => {
  const { credit } = props || {}

  const { entities } = useSelector(reduxState => reduxState)

  const memoColumns = useMemo(() => buildColumns({ entities }), [credit])

  return (
    <Table
      boxProps={{
        border: 'none', maxHeight: 500, overflowY: 'auto',
      }}
      columns={memoColumns}
      data={[credit]}
      stickyHeader
    />
  )
}

export default CreditRequestTable

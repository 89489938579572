import { DashboardModule, Form } from '@campaignhub/suit-theme'

import type { ContactFormPayload } from '@hooks/useContact'

const ExternalCodes = (props: { contactFormPayload: ContactFormPayload }) => {
  const { contactFormPayload } = props || {}
  const {
    entityState: {
      stripeReferenceKey,
      realbaseId,
    },
    handlers,
  } = contactFormPayload

  return (
    <DashboardModule title="External Codes">
      <Form padding="large">
        <Form.Row>
          <Form.Field label="Stripe Reference Key">
            <input
              disabled
              name="stripeReferenceKey"
              value={stripeReferenceKey}
              type="text"
              {...handlers}
            />
          </Form.Field>

          <Form.Field label="Realbase ID #">
            <input
              disabled
              name="stripeReferenceKey"
              value={realbaseId}
              type="text"
              {...handlers}
            />
          </Form.Field>
        </Form.Row>
      </Form>
    </DashboardModule>
  )
}

export default ExternalCodes

import { useEffect } from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@emotion/react'
import { useAuth0 } from '@auth0/auth0-react'
import { HelmetProvider } from 'react-helmet-async'

import { theme } from '@campaignhub/suit-theme'

import '@campaignhub/suit-theme/dist/styles.css'
import '@styles/reset.css'

import AuthController from '@sections/Auth/AuthController'

import { setAccessToken } from '@functions/accessToken'
import stripLoginDetailsFromUrl from '@functions/stripLoginDetailsFromUrl'

import store from '@redux/store'

const App = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()

  useEffect(() => {
    if (isAuthenticated){
      setAccessToken(getAccessTokenSilently)
      stripLoginDetailsFromUrl()
    }
  }, [getAccessTokenSilently, isAuthenticated])

  return (
    <HelmetProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AuthController />
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  )
}

export default App

import { digObject } from '@campaignhub/javascript-utils'
import { useLatestEntity } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ContactTypeModel } from '@models/types'

type Options = { performHttpRequests?: boolean }

export interface ContactTypePayload {
  contactType: ContactTypeModel,
  loading: boolean,
  hasParentContactType: boolean,
  parentContactType?: ContactTypeModel,
}

export const useRelations = (contactType: ContactTypeModel) => {
  const { parentContactTypeId } = contactType || {}

  const { contactTypes } = useSelector(reduxState => reduxState.entities)

  const parentContactType = digObject(contactTypes, String(parentContactTypeId), {})

  return {
    parentContactType,
  }
}

function useContactType(initEntity: ContactTypeModel, options?: Options): ContactTypePayload {
  const { entity: contactType } = useLatestEntity(initEntity, 'contactTypes')
  const { id, key } = contactType

  const { performHttpRequests } = options || {}

  const { parentContactType } = useRelations(contactType)

  // Only ContactType 'brand' does not have a parent ContactType
  const hasParentContactType = key !== 'brand'

  useReduxAction('contactTypes', 'loadContactTypes', {}, [id, performHttpRequests], {
    dispatchAction: (action, requestOptions) => action(id, requestOptions),
    shouldPerformFn: (entityReducer) => {
      const { loadedIds } = entityReducer
      return performHttpRequests && !loadedIds.includes(id)
    },
  })

  const { loading } = useSelector(reduxState => reduxState.contactTypes)

  return {
    contactType,
    hasParentContactType,
    loading,
    parentContactType,
  }
}

export default useContactType

export type BusinessNumberModel = {
  id: number | null,
  number?: string,
  prefix?: string,
}

const state = {
  id: null,
  number: '',
  prefix: '',
}

export const requiredFields = [
  { key: 'number' },
  { key: 'prefix' },
]

export default state
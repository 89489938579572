import { Box, Text } from '@campaignhub/suit-theme'

const TableHeader = (props: { editingInvoice: boolean }) => {
  const { editingInvoice } = props

  return (
    <Box
      color="bodyFontLightColor"
      borderBottom="1px solid"
      borderColor="lineColor"
      padding="large"
    >
      <Text color="bodyFontLightColor" fontSize="small" width="100%">
        Product
      </Text>

      <Text color="bodyFontLightColor" fontSize="small" width="100%">
        Description
      </Text>

      <Box alignItems="center" justifyContent="flex-start" width="290px">
        <Text color="bodyFontLightColor" fontSize="small" width="80px">
          QTY
        </Text>

        <Text color="bodyFontLightColor" fontSize="small" width="80px">
          Price
        </Text>

        <Text
          color="bodyFontLightColor"
          fontSize="small"
          marginRight={editingInvoice ? '50px' : 0}
          width="80px"
        >
          Amount
        </Text>
      </Box>
    </Box>
  )
}

export default TableHeader

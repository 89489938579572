import { useContext } from 'react'

import {
  Button,
  Form,
  ModalContext,
  SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject, formatDate } from '@campaignhub/javascript-utils'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'
import useInvoices, { useInvoiceForm } from '@hooks/useInvoices'

const MODAL_KEY = 'GenerateInvoicesCsvModal'

type ModalCallbacks = {
  closeModal: () => void,
  generateInvoicesCSV: (payload: HandleCallbackActionParams) => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const GenerateInvoicesCsvModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, generateInvoicesCSV } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const { invoice: initInvoice } = modalPayload

  const {
    callbacks: { downloadInvoicesCSV: downloadFn },
  } = useInvoices(initInvoice)

  const {
    entityState,
    entityState: {
      endDate,
      startDate,
    },
    errors,
    handlers,
    saveEnabled,
  } = useInvoiceForm({ contactId: modalPayload.filterContactId, status: modalPayload.filterStatus }, { validateOn: 'change' })

  // To be used with handleCallbackAction
  const generateInvoicesCsvPayload = {
    callbacks: {
      action: downloadFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    toastText: 'Invoice Csv Created',
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Generate" titleSecondLine="Invoice CSV" />

      <SidebarModal.Content>
        <Form>
          <Form.Field errorMessage={errors.startDate} label="* Start Date">
            <input
              name="startDate"
              type="date"
              value={formatDate(startDate, 'ISO8601')}
              {...handlers}
            />
          </Form.Field>

          <Form.Field label="End Date" marginTop="large">
            <input
              name="endDate"
              type="date"
              value={formatDate(endDate, 'ISO8601')}
              {...handlers}
            />
          </Form.Field>
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          disabled={!saveEnabled}
          buttonStyle="primaryCreate"
          onClick={() => generateInvoicesCSV(generateInvoicesCsvPayload)}
          size="large"
        >
          Generate Invoice
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <GenerateInvoicesCsvModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal

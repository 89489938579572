import {
  Box, Form, LoadingModule, SidebarModal,
} from '@campaignhub/suit-theme'

import useContactPaymentMethods from '@hooks/useContactPaymentMethods'
import usePaymentMethods from '@hooks/usePaymentMethods'

import PaymentMethodListItem from '@components/PaymentMethodListItem'

import { ContactPaymentMethodModel } from '@models/contactPaymentMethod'

interface ContactPaymentMethodProps {
  contactPaymentMethodsPayload: ReturnType<typeof useContactPaymentMethods>,
}

const ContactPaymentMethod = (props: ContactPaymentMethodProps) => {
  const { contactPaymentMethodsPayload } = props || {}

  const { filteredContactPaymentMethods, hasContactPaymentMethod } = contactPaymentMethodsPayload

  // load Payment Methods
  const { loading } = usePaymentMethods({ performHttpRequests: !!filteredContactPaymentMethods.length })

  // loading Module

  return (
    <>
      <LoadingModule boxProps={{ marginTop: 'large' }} loading={loading} />

      {!loading && (
        <Form.Field label="Payment Method" marginTop="large">
          {!hasContactPaymentMethod && (
            <SidebarModal.NoticeBox boxProps={{ marginBottom: 'medium' }}>
              <SidebarModal.NoticeBox.Title>
                No Contact Payment Method
              </SidebarModal.NoticeBox.Title>

              <SidebarModal.NoticeBox.Paragraph>
                If a contact has no payment method, after creating a draft subscription the contact will be
                invited to core billing to add a payment method.
              </SidebarModal.NoticeBox.Paragraph>

              <SidebarModal.NoticeBox.Paragraph>
                Once a payment method is added the subscription is created and confirmation is sent.
              </SidebarModal.NoticeBox.Paragraph>
            </SidebarModal.NoticeBox>
          )}

          {hasContactPaymentMethod && (
            <Box flexDirection="column">
              {filteredContactPaymentMethods.map((contactPaymentMethod: ContactPaymentMethodModel) => (
                <PaymentMethodListItem
                  key={contactPaymentMethod?.id}
                  contactPaymentMethod={contactPaymentMethod}
                  isModal
                />
              ))}
            </Box>
          )}
        </Form.Field>
      )}

    </>
  )
}

export default ContactPaymentMethod

export type PriceRequestOptions = {
  includePriceBillingType?: boolean,
  includePriceBusinessUnitProduct?: boolean,
}

export type PriceModel = {
  amount?: number,
  billingTypeId?: number,
  businessUnitProductId?: number,
  countryId?: number,
  id: number | null,
  incTaxAmount?: number,
  interval?: string,
  isDefault?: boolean,
  name?: string,
  stripeReferenceKey?: string,
  taxAmount?: number,
}

const state = {
  amount: null,
  billingTypeId: null,
  businessUnitProductId: null,
  countryId: null,
  id: null,
  incTaxAmount: null,
  interval: '',
  isDefault: false,
  name: '',
  stripeReferenceKey: '',
  taxAmount: null,
}

export const requiredFields = [
  { key: 'billingTypeId' },
  { key: 'businessUnitProductId' },
  { key: 'countryId' },
  { key: 'incTaxAmount' },
  { key: 'interval' },
]

export default state

import { useMemo } from 'react'

import { sortArrayBy } from '@campaignhub/javascript-utils'
import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

const watchEntityKeys = ['billingTypes']

interface Options {
  performHttpRequests?: boolean,
}

function useBillingTypes(options: Options = {}) {
  const { performHttpRequests } = options || {}

  const {
    updatedEntities: { billingTypes: billingTypesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const entities = useSelector(reduxState => reduxState.entities)
  const { billingTypes } = entities || {}

  const filteredBillingTypes = useMemo(() => {
    const array = Object.values(billingTypes)
    return sortArrayBy(array, 'asc', 'name')
  }, [billingTypesUpdatedAt])

  useReduxAction(
    'billingTypes',
    'loadBillingTypes',
    {},
    [performHttpRequests],
    { shouldPerformFn: ({ loaded, loading }) => performHttpRequests && !loaded && !loading },
  )

  const { loading } = useSelector(reduxState => reduxState.billingTypes)

  return {
    filteredBillingTypes,
    loading,
  }
}
export default useBillingTypes

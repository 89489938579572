import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'

import {
  faClock, faCreditCardBlank, faFile, faPencil, faStore,
} from '@fortawesome/pro-light-svg-icons'

import { Box, Columns } from '@campaignhub/suit-theme'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'

import useInvoices from '@hooks/useInvoices'
import useCurrentUser from '@hooks/useCurrentUser'

import { defaultRequestOptions } from '@sections/Client/packs/Accounting'

import AdminPageHeader from '@components/AdminPageHeader'
import PageFilterModule from '@components/PageFilterModule'

import Invoices from '../Invoices'
import Tools from '../InvoiceTools'

interface PageContentProps {
  pageFilters: {},
}

const buildPageFilterFields = () => {
  const boxProps = { marginBottom: 'large' }
  const style = { marginBottom: 16 }

  return [
    {
      component: 'EntitySelector',
      componentProps: {
        boxProps,
        endpoint: '/contacts',
        entityKey: 'contacts',
      },
      key: 'contactId',
    },
    {
      component: 'EntitySelector',
      componentProps: {
        boxProps,
        endpoint: '/business-units',
        entityKey: 'businessUnits',
      },
      key: 'businessUnitId',
    },
    {
      components: [
        {
          component: 'DynamicInput',
          componentProps: {
            dateFormat: 'DD/MM/YYYY',
            dateParseFormat: 'YYYY-MM-DD',
            fieldName: 'startDate',
            inputType: 'date',
            style,
          },
          key: 'startDate',
          label: 'Start Date',
        },
        {
          component: 'DynamicInput',
          componentProps: {
            dateFormat: 'DD/MM/YYYY',
            dateParseFormat: 'YYYY-MM-DD',
            fieldName: 'endDate',
            inputType: 'date',
            style,
          },
          key: 'endDate',
          label: 'End Date',
        },
      ],
    },
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'select',
        style,
      },
      key: 'sort',
      label: 'Sort By',
      values: [
        { key: 'asc_dateCreated', label: 'Oldest First' },
        { key: 'desc_dateCreated', label: 'Newest First' },
        { key: 'desc_balance', label: 'Highest Amount First' },
        { key: 'asc_balance', label: 'Lowest Amount First' },
        { key: 'asc_contact.name', label: 'Contact (A-Z)' },
        { key: 'desc_contact.name', label: 'Contact (Z-A)' },
      ],
    },
  ]
}

const PageContent = (props: PageContentProps) => {
  const { pageFilters } = props
  const { isAdmin } = useCurrentUser()

  useLocation()

  const { status } = parsePermittedQueryParams(getQueryParamsFromHash(), ['status'])
  const currentStatus = isAdmin ? (status || 'draft') : 'approved' 

  const invoicesPayload = useInvoices({
    filters: {
      status: currentStatus,
      ...pageFilters,
    },
    performHttpRequests: !!currentStatus,
    requestOptions: {
      ...defaultRequestOptions.invoice,
    },
  })

  const {
    callbacks: {
      clearSelectedInvoiceIds,
    },
  } = invoicesPayload

  const pageContext = useContext(PageContext)
  const { callbacks: { resetFilters, updateFilters }, defaultFilters } = pageContext  

  return (
    <>
      <Helmet>
        <title>Invoices | Billing</title>
      </Helmet>

      <AdminPageHeader
        activeTabBarItemKey={currentStatus}
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            hidden: !isAdmin,
            href: '#/accounting/invoices?status=draft',
            icon: faPencil,
            key: 'draft',
            onClick: () => clearSelectedInvoiceIds(),
            title: 'Draft',
          },
          {
            href: '#/accounting/invoices?status=approved',
            icon: faClock,
            key: 'approved',
            onClick: () => clearSelectedInvoiceIds(),
            title: 'Invoiced',
          },
          {
            href: '#/accounting/invoices?status=overdue',
            icon: faStore,
            key: 'overdue',
            onClick: () => clearSelectedInvoiceIds(),
            title: 'Overdue',
          },
          {
            href: '#/accounting/invoices?status=paid',
            icon: faCreditCardBlank,
            key: 'paid',
            onClick: () => clearSelectedInvoiceIds(),
            title: 'Paid',
          },
          {
            hidden: !isAdmin,
            href: '#/accounting/invoices?status=unassigned',
            icon: faFile,
            key: 'unassigned',
            onClick: () => clearSelectedInvoiceIds(),
            title: 'Unassigned',
          },
        ]}
        title="Invoices"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <Invoices
              status={currentStatus}
              invoicesPayload={invoicesPayload}
            />
          </Columns.Main>

          <Columns.Sidebar>
            <Tools invoicesPayload={invoicesPayload} status={currentStatus} />

            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              defaultFilters={defaultFilters}
              filterFields={buildPageFilterFields()}
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent

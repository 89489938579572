import { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp, faPlus } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, DashboardModule, Link,
} from '@campaignhub/suit-theme'
import { useSetState } from '@campaignhub/react-hooks'

import useBillingNotes from '@hooks/useBillingNotes'
import useCurrentUser from '@hooks/useCurrentUser'

import PageContext from '@contexts/pageContext'

import type { BillingNoteModel } from '@models/types'

import BillingNote from './BillingNote/index'

interface BillingNotesProps {
  contactId: number,
}

const defaultState = {
  showArchive: false,
}

const BillingNotes = (props: BillingNotesProps) => {
  const { contactId } = props || {}

  const [state, setState] = useSetState(defaultState)
  const { showArchive } = state

  const billingNotesPayload = useBillingNotes({ filters: { contactId } })
  const {
    filteredBillingNotes,
    hasFilteredBillingNotes,
    loading,
  } = billingNotesPayload

  const hasArchive = filteredBillingNotes.length > 1

  const { currentUser } = useCurrentUser()
  const { id: userId } = currentUser

  const pageContext = useContext(PageContext)
  const { callbacks: { showCreateBillingNoteModal } } = pageContext

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column' }}
      headerRight={hasArchive && (
        <Box marginLeft="medium">
          <Box color="faintGrey" fontSize="small" marginRight="medium" width="fit-content">
            <FontAwesomeIcon icon={showArchive ? faAngleUp : faAngleDown} />
          </Box>

          <Link
            disabledUnderline
            greyLink
            onClick={() => setState({ showArchive: !showArchive })}
            textProps={{ fontSize: 'small' }}
          >
            {showArchive ? 'Hide Archive' : 'Show Archive'}
          </Link>
        </Box>
      )}
      loading={loading}
      title="Billing Notes"
    >
      {!loading && !hasFilteredBillingNotes && (
        <DashboardModule.BlankState>
          <DashboardModule.BlankState.Title>
            No Biling Notes
          </DashboardModule.BlankState.Title>

          <DashboardModule.BlankState.Paragraph>
            Add a Billing Note for this contact.
          </DashboardModule.BlankState.Paragraph>
        </DashboardModule.BlankState>
      )}

      {!loading && hasFilteredBillingNotes && (
        <DashboardModule.ScrollableSection>
          {filteredBillingNotes.map((billingNote: BillingNoteModel, index) => (
            <BillingNote
              key={billingNote?.id}
              billingNote={billingNote}
              index={index}
              showArchive={showArchive}
            />
          ))}
        </DashboardModule.ScrollableSection>
      )}

      <DashboardModule.Footer>
        <Button
          buttonStyle="secondaryUtility"
          icon={<FontAwesomeIcon icon={faPlus} />}
          iconPosition="left"
          onClick={() => showCreateBillingNoteModal({ billingNote: { contactId, userId } })}
          size="medium"
        >
          {hasFilteredBillingNotes ? 'Update' : 'Add'} Billing Note
        </Button>
      </DashboardModule.Footer>
    </DashboardModule>
  )
}

export default BillingNotes

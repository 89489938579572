import { useMemo } from 'react'

import { matchFilterNumber, sortArrayBy } from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { CreditAllocationModel, CreditAllocationRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['creditAllocations']

type CreditAllocationFilters = {
  creditId?: number,
  invoiceId?: number,
}

type UseCreditAllocationsOptions = {
  filters?: CreditAllocationFilters,
  performHttpRequests?: boolean,
  requestOptions?: CreditAllocationRequestOptions,
}

function useCreditAllocations(options: UseCreditAllocationsOptions) {
  const { filters = {}, performHttpRequests, requestOptions } = options
  const {
    creditId: filterCreditId,
    invoiceId: filterInvoiceId,
  } = filters

  const {
    updatedEntities: { creditAllocations: creditAllocationsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { creditAllocations } = useSelector(reduxState => reduxState.entities)

  const filteredCreditAllocations = useMemo(() => {
    const filtered = Object.values(creditAllocations).filter((creditAllocation: CreditAllocationModel) => {
      const { creditId, invoiceId } = creditAllocation

      const matchCredit = matchFilterNumber(creditId, filterCreditId)
      const matchInvoice = matchFilterNumber(invoiceId, filterInvoiceId)

      return matchCredit && matchInvoice
    })

    return sortArrayBy(filtered, 'asc', 'name')
  }, [creditAllocationsUpdatedAt, JSON.stringify(filters)])

  const filteredCreditAllocationsCount = filteredCreditAllocations.length
  const hasFilteredCreditAllocations = !!filteredCreditAllocationsCount

  const { loading: loadingCreditAllocations } = useReduxAction(
    'creditAllocations',
    'loadCreditAllocations',
    {
      ...requestOptions,
    },
    [performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    filteredCreditAllocations,
    filteredCreditAllocationsCount,
    hasFilteredCreditAllocations,
    loading: loadingCreditAllocations,
  }
}

export default useCreditAllocations

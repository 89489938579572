import { Box, DashboardModule } from '@campaignhub/suit-theme'

import useInvoices from '@hooks/useInvoices'

import ListBlankState from '@components/ListBlankState'

import InvoiceListItem from './components/InvoiceListItem'

const OverdueInvoices = () => {
  const currentStatus = 'overdue'

  const invoicesPayload = useInvoices({
    filters: {
      status: currentStatus,
      limit: 20,
    },
    performHttpRequests: !!currentStatus,
    requestOptions: {
      includeInvoiceContact: true,
    },
  })
  const {
    filteredInvoices,
    hasFilteredInvoices,
    loading,
  } = invoicesPayload

  return (
    <DashboardModule
      loading={loading}
      title="Invoices Overdue"
    >
      <Box flexDirection="column">
        {!loading && !hasFilteredInvoices && <ListBlankState />}

        {!loading && filteredInvoices.map(invoice => (
          <InvoiceListItem key={invoice.id} invoice={invoice} />
        ))}
      </Box>
    </DashboardModule>
  )
}

export default OverdueInvoices

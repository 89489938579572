import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { InvoiceCsvFormParams, InvoiceModel } from '@models/invoice'
import type { AppDispatch } from '@redux/store'
import type {
  Action, ActionResult, EntityOptions, Errors, FetchRequestPayload, RootReducerState,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/invoices/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/invoices/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/invoices/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/invoices/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/invoices/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/invoices/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/invoices/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/invoices/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/invoices/UPDATE_FAILURE'

export type InvoiceModuleState = {
  creating: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loading: boolean,
  updating: boolean,
}

const initialState: InvoiceModuleState = {
  creating: false,
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loadedIds: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}): Action {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(): Action {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []): Action {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(): Action {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(): Action {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []): Action {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(): Action {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(): Action {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []): Action {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadInvoices(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState): Promise<ActionResult<InvoiceModel[]>> => {
    const loadedForKeys = [...getState().invoices.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)) {
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/invoices', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.INVOICE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())
        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))
        return { success: false, errors }
      })

    return promise
  }
}

export function loadInvoice(invoiceId: number, options: EntityOptions = {}) {
  return (dispatch: AppDispatch, getState: () => RootReducerState): Promise<ActionResult<InvoiceModel>> => {
    const state = getState()
    const loadedIds = [...state.invoices.loadedIds]

    if (invoiceId && !loadedIds.includes(invoiceId)) {
      loadedIds.push(invoiceId)
    }

    dispatch(fetchRequest({ loadedIds }))

    const promise = api(`/invoices/${invoiceId}`, options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.INVOICE)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))
        return { success: false, errors }
      })

    return promise
  }
}

export function createInvoice(invoice: InvoiceModel, options: EntityOptions = {}) {
  const config = {
    method: 'POST',
    data: JSON.stringify(invoice),
  }

  return (dispatch: AppDispatch): Promise<ActionResult<InvoiceModel>> => {
    dispatch(createRequest())

    const promise = api('/invoices', options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.INVOICE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function updateInvoice(invoice: InvoiceModel, options: EntityOptions = {}) {
  const config = {
    method: 'PUT',
    data: JSON.stringify(invoice),
  }

  return (dispatch: AppDispatch): Promise<ActionResult<InvoiceModel>> => {
    dispatch(updateRequest())

    const promise = api(`/invoices/${invoice.id}`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.INVOICE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Bulk Actions
export function mergeInvoices(mergeParams, options: EntityOptions = {}) {
  // TODO: Update this to meet route param updates
  const { selectedInvoiceIds } = mergeParams

  const config = {
    method: 'PUT',
    data: JSON.stringify({
      ...mergeParams,
    }),
  }

  return (dispatch: AppDispatch): Promise<ActionResult<InvoiceModel>> => {
    dispatch(updateRequest())

    const promise = api('/invoices/bulk-merge', options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.INVOICE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function approveInvoices(invoiceIds: number[], options: EntityOptions = {}) {
  const config = {
    method: 'PUT',
    data: JSON.stringify({
      invoiceIds,
    }),
  }

  return (dispatch: AppDispatch): Promise<ActionResult<object>> => {
    dispatch(updateRequest())

    const promise = api('/invoices/bulk-approve', options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.INVOICE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function sendReminders(invoiceIds: number[], options: EntityOptions = {}) {
  const config = {
    method: 'PUT',
    data: JSON.stringify({
      invoiceIds,
    }),
  }

  return (dispatch: AppDispatch): Promise<ActionResult<object>> => {
    dispatch(updateRequest())

    const promise = api('/invoices/bulk-send-reminders', options, config)
      .then(({ data }) =>
        // TODO: returns activity/logs - update store
        ({ success: true, data }))
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function voidInvoices(invoiceIds: number[], options: EntityOptions = {}) {
  const config = {
    method: 'PUT',
    data: JSON.stringify({
      invoiceIds,
    }),
  }

  return (dispatch: AppDispatch): Promise<ActionResult<object>> => {
    dispatch(updateRequest())

    const promise = api('/invoices/bulk-void', options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.INVOICE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function downloadInvoicePDF(id: number) {
  const config = {
    method: 'POST',
    data: JSON.stringify({
      id,
    }),
    responseType: 'blob', // API Returns PDF directly
  }

  return (dispatch: AppDispatch): Promise<ActionResult<object>> => {
    dispatch(updateRequest())

    const promise = api(`/invoices/${id}/pdf`, {}, config)
      .then(({ data }) => {
        // API returns PDF directly. Open PDF in new tab.
        window.open(URL.createObjectURL(data))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function downloadInvoicesCSV(entityParams: InvoiceCsvFormParams) {

  const config = {
    responseType: 'blob', // API Returns PDF directly
  }

  return (dispatch: AppDispatch): Promise<ActionResult<object>> => {
    dispatch(updateRequest())

    const promise = api(`/invoices/csv`, entityParams, config)
      .then(({ data }) => {
        // API returns PDF directly. Open PDF in new tab.
        window.open(URL.createObjectURL(data))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: InvoiceModuleState = initialState,
  action: Action = { type: '' },
): InvoiceModuleState {
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}

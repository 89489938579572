import { useContext } from 'react'

import {
  Button, Form, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useBillingNote, { useBillingNoteForm } from '@hooks/useBillingNote'
import useBillingNotes from '@hooks/useBillingNotes'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

const MODAL_KEY = 'CreateBillingNoteModal'

type ModalCallbacks = {
  closeModal: () => void,
  createBillingNote: (payload: HandleCallbackActionParams) => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const CreateBillingNoteModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, createBillingNote } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const billingNote = digObject(modalPayload, 'billingNote', {})

  const {
    callbacks: {
      createBillingNote: createFn,
    },
    creating,
  } = useBillingNote(billingNote)

  const {
    entityState,
    entityState: {
      contactId,
      note,
      title,
    },
    errors,
    handlers,
    saveEnabled,
  } = useBillingNoteForm(billingNote, { validateOn: 'change' })

  const { hasFilteredBillingNotes } = useBillingNotes({ filters: { contactId } })

  const createBillingNotePayload = {
    callbacks: {
      action: createFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    toastText: 'Billing Note Created',
  }

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header
        callbacks={callbacks}
        title={hasFilteredBillingNotes ? 'Update' : 'Add'}
        titleSecondLine="Billing Note"
      />

      <SidebarModal.Content>
        <Form>
          <Form.Field label="Title">
            <input name="title" type="text" value={title} {...handlers} />
          </Form.Field>

          <Form.Field label="* Note" errorMessage={errors.note} marginTop="large">
            <textarea
              name="note"
              style={{ height: 120 }}
              type="text"
              value={note}
              {...handlers}
            />
          </Form.Field>
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating}
          loadingBubbleColor="white"
          onClick={() => createBillingNote(createBillingNotePayload)}
          size="large"
        >
          {saveEnabled ? `${hasFilteredBillingNotes ? 'Update' : 'Add'} Billing Note` : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <CreateBillingNoteModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal

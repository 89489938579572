import { useContext } from 'react'
import Swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Form, Link, ModalContext, SidebarModal, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import { useSetState } from '@campaignhub/react-hooks'

import useBusinessUnitProducts from '@hooks/useBusinessUnitProducts'
import useSubscriptionItem, { useRelations, useSubscriptionItemForm } from '@hooks/useSubscriptionItem'
import usePrice from '@hooks/usePrice'
import usePrices from '@hooks/usePrices'

import { displaySubscriptionInterval } from '@functions/subscription'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import BusinessUnitLogo from '@components/BusinessUnitLogo'
import EntitySelector from '@components/EntitySelector'

const MODAL_KEY = 'EditSubscriptionItemModal'

interface EditSubscriptionItemModalProps {
  callbacks: {
    closeModal: () => void,
    deleteSubscriptionItem: (deleteSubscriptionPayload: HandleCallbackActionParams) => void,
    updateSubscriptionItem: (updateSubscriptionPayload: HandleCallbackActionParams) => void,
  },
  showModal: boolean,
}

type DeleteSubscriptionItemParams = {
  deleteSubscriptionItemPayload: HandleCallbackActionParams,
  deleteSubscriptionItem: (deleteSubscriptionPayload: HandleCallbackActionParams) => void,
}

const confirmDelete = (params: DeleteSubscriptionItemParams) => {
  const { deleteSubscriptionItem, deleteSubscriptionItemPayload } = params

  Swal
    .fire({
      title: 'Delete Subscription Item?',
      text: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteSubscriptionItem(deleteSubscriptionItemPayload)
      }
    })
}

const defaultState = {
  businessUnitId: null,
}

const EditSubscriptionItemModal = (props: EditSubscriptionItemModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, deleteSubscriptionItem, updateSubscriptionItem } = callbacks

  const [state, setState] = useSetState(defaultState)
  const { businessUnitId } = state

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const { subscriptionItem: initSubscriptionItem } = modalPayload

  const subscriptionItemPayload = useSubscriptionItem(initSubscriptionItem || {})
  const {
    callbacks: {
      deleteSubscriptionItem: deleteFn,
      updateSubscriptionItem: updateFn,
    },
    subscriptionBillingType,
    subscriptionItem,
    loading: loadingSubscriptionItems,
  } = subscriptionItemPayload

  const subscriptionItemFormPayload = useSubscriptionItemForm(subscriptionItem)
  const {
    entityState,
    entityState: {
      businessUnitProductId,
      id,
      priceId,
      qty,
    },
    errors,
    handlers,
    saveEnabled,
  } = subscriptionItemFormPayload

  const subscriptionBillingTypeId = digObject(subscriptionBillingType, 'id')

  // Subscription Item Relations
  const { businessUnit, businessUnitProduct, subscription } = useRelations(subscriptionItem)
  const { key: businessUnitKey, name: businessUnitName } = businessUnit
  const { name: businessUnitProductName } = businessUnitProduct
  const { interval } = subscription

  const businessUnitProductsPayload = useBusinessUnitProducts({
    filters: {
      businessUnitId: businessUnit?.id || businessUnitId,
    },
    performHttpRequests: true,
  })
  const {
    filteredBusinessUnitProducts,
    loading: loadingBusinessUnitProducts,
  } = businessUnitProductsPayload

  const pricesPayload = usePrices({
    filters: {
      businessUnitProductId,
    },
    performHttpRequests: !!businessUnitProductId,
  })
  const { filteredPrices, loading: loadingPrices } = pricesPayload

  const { callbacks: { createOrEditPrice } } = usePrice({
    billingTypeId: subscriptionBillingTypeId,
    businessUnitProductId,
  })

  const loading = loadingSubscriptionItems && loadingBusinessUnitProducts && loadingPrices

  const deleteSubscriptionItemPayload = {
    callbacks: {
      action: deleteFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    toastText: 'Subscription Item Deleted',
  }

  const updateSubscriptionItemPayload = {
    callbacks: {
      action: updateFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    toastText: 'Subscription Item Updated',
  }

  // TODO: Do we want to show a total for Price x Qty in this modal?

  // Should not show/be able to delete subscription item if it is the only item?
  // Instead should delete subscription

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title={id ? 'Edit' : 'Add'} titleSecondLine="Subscription Item" />

      {id && (
        <SidebarModal.SubHeader>
          <BusinessUnitLogo businessUnitKey={businessUnitKey} />

          <Box flexDirection="column" lineHeight="1.3">
            <Text fontSize="small">{businessUnitProductName}</Text>

            <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
              {businessUnitName || ''} {interval ? `- ${displaySubscriptionInterval(interval)} Subscription` : ''}
            </Text>
          </Box>
        </SidebarModal.SubHeader>
      )}

      <SidebarModal.Content>
        <Form>
          {!id && (
            <EntitySelector
              callbacks={{
                selectItem: businessUnit => setState({
                  businessUnitId: businessUnit ? businessUnit.id : null,
                }),
              }}
              entityKey="businessUnits"
              label="* Business Unit"
              selectedItemId={businessUnitId}
            />
          )}

          <Form.Field
            errorMessage={errors.businessUnitProductId}
            label="* Product"
            marginTop={id ? 0 : 'large'}
          >
            <select
              data-value-type="number"
              disabled={!id && !businessUnitId}
              name="businessUnitProductId"
              value={businessUnitProductId}
              {...handlers}
            >
              <option value="">Please Select...</option>
              {filteredBusinessUnitProducts.map(businessUnitProduct => (
                <option key={businessUnitProduct.id} value={businessUnitProduct.id}>
                  {businessUnitProduct.name}
                </option>
              ))}
            </select>
          </Form.Field>

          <Form.Field
            errorMessage={errors.priceId}
            label="* Price"
            labelRight={businessUnitProductId
              && (
                <Link onClick={() => createOrEditPrice()} textProps={{ fontSize: 'xsmall' }}>
                  + Add Price
                </Link>
              )}
            marginTop="large"
          >
            <select
              data-value-type="number"
              disabled={!businessUnitProductId}
              name="priceId"
              value={priceId}
              {...handlers}
            >
              {filteredPrices.map((price) => {
                const { id: filteredPriceId, incTaxAmount, name } = price || {}

                return (
                  <option key={filteredPriceId} value={filteredPriceId}>
                    {name ? `$${incTaxAmount} - ${name}` : `$${incTaxAmount}`}
                  </option>
                )
              })}
            </select>
          </Form.Field>

          <Form.Field errorMessage={errors.qty} label="* Quantity" marginTop="large">
            <input
              name="qty"
              value={qty}
              type="number"
              {...handlers}
            />
          </Form.Field>

          {id && (
            <Form.Field direction="column" label="More Options" marginTop="large">
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faTrashAlt} />}
                onClick={() => confirmDelete({ deleteSubscriptionItem, deleteSubscriptionItemPayload })}
                size={['large', 'medium']}
                width="100%"
              >
                Delete Subscription Item
              </Button>
            </Form.Field>
          )}
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={loading}
          onClick={() => updateSubscriptionItem(updateSubscriptionItemPayload)}
          size="large"
        >
          {id ? 'Update Item' : 'Add Item'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: EditSubscriptionItemModalProps) => (
  <SidebarModal.RenderController {...props}>
    <EditSubscriptionItemModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal

import { Box, businessUnitColors, LogoMark } from '@campaignhub/suit-theme'
import { digObject } from '@campaignhub/javascript-utils'

interface BusinessUnitLogoProps {
  boxProps?: object,
  businessUnitKey: string,
}

const BusinessUnitLogo = (props: BusinessUnitLogoProps) => {
  const { boxProps, businessUnitKey } = props

  if (!businessUnitKey) return null

  const businessUnitColor = digObject(businessUnitColors, `${businessUnitKey}.primary`, 'grey')

  return (
    <Box
      alignItems="center"
      backgroundColor={businessUnitColor}
      borderRadius={5}
      flexShrink="0"
      height={40}
      justifyContent="center"
      marginRight="large"
      width={40}
      {...boxProps}
    >
      <LogoMark fillColor="white" logoKey={businessUnitKey} height={24} width={24} />
    </Box>
  )
}

export default BusinessUnitLogo

import { useMemo } from 'react'

import { matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { UserTypeModel, UserTypeRequestOptions } from '@models/userType'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['userTypes']

type UserTypeFilters = {
  string?: string,
}

type UseUserTypesOptions = {
  filters?: UserTypeFilters,
  performHttpRequests?: boolean,
  requestOptions?: UserTypeRequestOptions,
}

function useUserTypes(options: UseUserTypesOptions = {}) {
  const { filters = {}, performHttpRequests, requestOptions } = options
  const {
    string: filterString,
  } = filters

  const {
    updatedEntities: { userTypes: userTypesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { userTypes } = useSelector(reduxState => reduxState.entities)

  const filteredUserTypes = useMemo(() => {
    const filtered = Object.values(userTypes).filter((userType: UserTypeModel) => {
      const { id, name } = userType

      const matchId = id.toString() === filterString
      const matchName = matchFilterString(name, filterString)
      const matchString = matchId || matchName

      return matchString
    })

    return sortArrayBy(filtered, 'asc', 'name')
  }, [userTypesUpdatedAt, JSON.stringify(filters)])

  const filteredUserTypesCount = filteredUserTypes.length
  const hasFilteredUserTypes = !!filteredUserTypesCount

  const { loading: loadingUserTypes } = useReduxAction(
    'userTypes',
    'loadUserTypes',
    {
      ...requestOptions,
      ...filters,
    },
    [JSON.stringify(filters), performHttpRequests],
    {
      shouldPerformFn: (entityReducer) => {
        const { loaded, loading } = entityReducer
        return performHttpRequests && !loading && !loaded
      },
    },
  )

  return {
    filteredUserTypes,
    filteredUserTypesCount,
    hasFilteredUserTypes,
    loading: loadingUserTypes,
  }
}

export default useUserTypes

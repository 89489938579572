import {
  faChartLine,
  faCog,
  faCreditCardBlank,
  faFileAlt,
  faLifeRing,
  faReceipt,
  faStopwatch,
  faAddressBook,
  faTachometer,
} from '@fortawesome/pro-light-svg-icons'

import { LogoMark } from '@campaignhub/suit-theme'

import type { BusinessUnitModel } from '@models/types'

type GenerateMainNavigationItemsParams = {
  businessUnits: BusinessUnitModel[],
  isAdmin: boolean,
  userTypeName: string
}

const generateMainNavigationItems = (params: GenerateMainNavigationItemsParams) => {
  const { businessUnits, isAdmin, userTypeName } = params

  const isCustomer = userTypeName === "Customer"

  // TODO: Update when Screens ready for Clients as well as Admin

  return {
    sections: [
      {
        key: 'dashboard',
        visible: isAdmin || isCustomer,
        items: [
          {
            href: '#/',
            icon: faTachometer,
            key: 'dashboard',
            title: 'Dashboard',
            visible: true,
          },
        ],
      },
      {
        key: 'business-units',
        title: 'Business Units',
        visible: isAdmin,
        items: businessUnits.map((businessUnit: BusinessUnitModel) => {
          const { id, key, name } = businessUnit

          return {
            href: `#/business-units/${id}/edit`,
            iconComponent: <LogoMark logoKey={key} height={16} width={16} />,
            key,
            title: name,
            visible: isAdmin,
          }
        }),
      },
      {
        key: 'accounting',
        title: 'Accounting',
        visible: isAdmin || isCustomer,
        items: [
          {
            href: '#/accounting/invoices',
            icon: faReceipt,
            key: 'invoices',
            title: 'Invoices',
            visible: isAdmin || isCustomer,
          },
          {
            href: '#/accounting/credits',
            icon: faCreditCardBlank,
            key: 'credits',
            title: 'Credits',
            visible: isAdmin || isCustomer,
          },
          {
            href: '#/accounting/subscriptions',
            icon: faStopwatch,
            key: 'subscriptions',
            title: 'Subscriptions',
            visible: isAdmin || isCustomer,
          },
          // {
          //   href: '#/accounting/statements',
          //   icon: faFileAlt,
          //   key: 'statements',
          //   title: 'Statements',
          //   visible: isAdmin,
          // },
        ],
      },
      {
        key: 'admin',
        title: 'Admin',
        visible: true,
        items: [
          {
            href: '#/admin/contacts',
            icon: faAddressBook,
            key: 'contacts',
            title: 'Contacts',
            visible: true,
          },
          // {
          //   href: '#/admin/settings',
          //   icon: faCog,
          //   key: 'settings',
          //   title: 'Settings',
          //   visible: isAdmin,
          // },
          // {
          //   href: '#/admin/reports',
          //   icon: faChartLine,
          //   key: 'reports',
          //   title: 'Reports',
          //   visible: isAdmin,
          // },
        ],
      },
      // {
      //   key: 'support',
      //   title: 'Support',
      //   visible: isAdmin && !isCustomer,
      //   items: [
      //     {
      //       href: 'training',
      //       icon: faLifeRing,
      //       key: 'training',
      //       title: 'Training',
      //       visible: true,
      //     },
      //   ],
      // },
    ],
  }
}

export default generateMainNavigationItems

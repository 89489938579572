import type { ContactModel, CreditAllocationModel, TransactionModel } from './types'

export type CreditRequestOptions = {
  includeCreditAllocations?: boolean,
  includeCreditContact?: boolean,
  includeCreditTransaction?: boolean,
}

export type CreditModel = {
  amount?: number,
  businessUnitId?: number,
  contact?: ContactModel,
  contactId?: number,
  creditAllocations?: CreditAllocationModel,
  dateCreated?: string,
  dateDue?: string,
  id: number | null,
  number?: string,
  remainingCredit?: number,
  statusId?: number,
  title?: string,
  transaction?: TransactionModel,
  transactionId?: number,
}

export type CreditCsvFormParams = {
  endDate?: string | null,
  startDate: string,
  status: string,
}

export const creditFormState = {
  endDate: '',
  startDate: '',
  status: null,
}

const state = {
  amount: null,
  businessUnitId: null,
  contactId: null,
  id: null,
  number: '',
  remainingCredit: '',
  title: '',
  transactionId: null,
}

export const requiredFields = [
  { key: 'amount' },
  { key: 'number' },
  { key: 'remainingCredit' },
]

export default state

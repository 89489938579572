import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport } from '@fortawesome/pro-light-svg-icons'

import { Button, Tools } from '@campaignhub/suit-theme'
import { CreditsPayload } from '@hooks/useCredits'

interface CreditToolsProps {
  creditsPayload: CreditsPayload,
}

const CreditTools = (props: CreditToolsProps) => {
  const { creditsPayload } = props
  const {
    callbacks: {
      generateCreditsCSV,
    }
  } = creditsPayload || {}

  return (
    <Tools>
      <Button
        buttonStyle="secondaryUtility"
        icon={<FontAwesomeIcon icon={faFileExport} />}
        iconPosition="left"
        onClick={() => generateCreditsCSV()}
        size="medium"
      >
        Generate .CSV
      </Button>
    </Tools>
  )
}

export default CreditTools

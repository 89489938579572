import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { SubscriptionTransactionModel } from '@models/subscriptionTransaction'
import type { AppDispatch } from '@redux/store'
import type {
  Action, ActionResult, EntityOptions, Errors, FetchRequestPayload, RootReducerState,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/subscriptionTransaction/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/subscriptionTransaction/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/subscriptionTransaction/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/subscriptionTransaction/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/subscriptionTransaction/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/subscriptionTransaction/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/subscriptionTransaction/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/subscriptionTransaction/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/subscriptionTransaction/UPDATE_FAILURE'

export type SubscriptionTransactionModuleState = {
  creating: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loading: boolean,
  updating: boolean,
}

const initialState: SubscriptionTransactionModuleState = {
  creating: false,
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loadedIds: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}): Action {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(): Action {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []): Action {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(): Action {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(): Action {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []): Action {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(): Action {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(): Action {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []): Action {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadSubscriptionTransactions(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState): Promise<ActionResult<SubscriptionTransactionModel[]>> => {
    const loadedForKeys = [...getState().subscriptionTransactions.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/subscription-transactions', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.SUBSCRIPTION_TRANSACTION_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function loadSubscriptionTransaction(subscriptionTransactionId: number, options: EntityOptions = {}) {
  return (dispatch: AppDispatch, getState: () => RootReducerState): Promise<ActionResult<SubscriptionTransactionModel>> => {
    const state = getState()
    const loadedIds = [...state.subscriptionTransactions.loadedIds]

    if (subscriptionTransactionId && !loadedIds.includes(subscriptionTransactionId)){
      loadedIds.push(subscriptionTransactionId)
    }

    dispatch(fetchRequest({ loadedIds }))

    const promise = api(`/subscription-transactions/${subscriptionTransactionId}`, options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.INVOICE)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))
        return { success: false, errors }
      })

    return promise
  }
}

export function createSubscriptionTransaction(
  subscriptionTransaction: SubscriptionTransactionModel,
  options: EntityOptions = {},
) {
  const config = {
    method: 'POST',
    data: JSON.stringify(subscriptionTransaction),
  }

  return (dispatch: AppDispatch): Promise<ActionResult<SubscriptionTransactionModel>> => {
    dispatch(createRequest())

    const promise = api('/subscription-transactions', options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.SUBSCRIPTION_TRANSACTION)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function updateSubscriptionTransaction(subscriptionTransaction: SubscriptionTransactionModel, options: EntityOptions = {}) {
  const config = {
    method: 'PUT',
    data: JSON.stringify(subscriptionTransaction),
  }

  return (dispatch: AppDispatch): Promise<ActionResult<SubscriptionTransactionModel>> => {
    dispatch(updateRequest())

    const promise = api(`/subscription-transactions/${subscriptionTransaction.id}`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.SUBSCRIPTION_TRANSACTION)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: SubscriptionTransactionModuleState = initialState,
  action: Action = { type: '' },
): SubscriptionTransactionModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}

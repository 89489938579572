import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'
import { useRelations } from '@hooks/useUser'

import type { ModuleState } from '@redux/modules/module'
import type { UserModel } from '@models/user'

interface UseCurrentUserOptions {
  performHttpRequests?: boolean,
}

function useCurrentUser(options?: UseCurrentUserOptions) {
  const { performHttpRequests } = options || {}

  useReduxAction(
    'currentUser',
    'loadCurrentUser',
    {},
    [performHttpRequests],
    {
      shouldPerformFn: (entityReducer: ModuleState) => {
        const { errors, loaded, loading } = entityReducer
        return performHttpRequests && !loaded && !loading && !errors?.length
      },
    },
  )

  const entityReducer: ModuleState & { result: UserModel } = useSelector(reduxState => reduxState.currentUser)
  const { loaded, loading, result: currentUser } = entityReducer

  const { isSystemAdmin } = currentUser || {}

  const currentUserRelations = useRelations(currentUser)
  const { userType } = currentUserRelations || {}

  return {
    currentUser,
    isAdmin: isSystemAdmin,
    loaded,
    loading,
    userType,
    userTypeName: userType.name,
  }
}

export default useCurrentUser

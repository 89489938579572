// Not sure if we want to export this from a library
// Check if swal has its own types export
export type SwalConfirmParams = {
  actionFn?: Function,
  cancelButtonColor?: 'approval' | 'deletion' | string,
  cancelButtonText?: string,
  confirmButtonColor?: 'approval' | 'deletion' | string,
  confirmButtonText?: string,
  icon?: 'approval' | 'deletion' | string,
  text?: string,
  title?: string,
}

export type SwalColors = {
  approval: string,
  deletion: string,
}

export const swalColors: SwalColors = {
  approval: '#43ac6a',
  deletion: '#DD6B55',
}

export default swalColors

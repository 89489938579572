import { ContactModel } from '@models/contact'
import { SubscriptionItemModel } from '@models/subscriptionItem'

export type SubscriptionRequestOptions = {
  includeSubscriptionContact?: boolean,
  includeSubscriptionSubscriptionItems?: boolean,
  includeSubscriptionTotals?: boolean,
}

export type SubscriptionModel = {
  active?: boolean,
  billingStart?: string,
  businessUnitIds?: number[],
  cacheKey?: string,
  contact?: ContactModel,
  contactId?: number,
  id: number | null,
  incTaxAmount?: number,
  interval?: string,
  items?: SubscriptionItemModel[],
  name?: string,
  nextBillingDate?: string,
  resumeDate?: string,
  status?: string,
  statusId?: number,
  stripeReferenceKey?: string,
  subscriptionEnd?: string,
  subscriptionStart?: string,
  taxAmount?: number,
  totalRevenue?: number,
  trialPeriodDays?: number,
}

const state = {
  active: true,
  billingStart: '',
  contactId: null,
  id: null,
  incTaxAmount: null,
  interval: '',
  items: [],
  name: '',
  resumeDate: '',
  statusId: null,
  stripeReferenceKey: '',
  subscriptionEnd: '',
  subscriptionStart: '',
  taxAmount: null,
  trialPeriodDays: null,
}

export const requiredFields = [
  { key: 'billingStart' },
  { key: 'contactId' },
  { key: 'subscriptionStart' },
]

export default state

import { useMemo } from 'react'

import { sortArrayBy } from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { AccountingSystemRequestOptions } from "@models/accountingSystem"
import type { ModuleState } from "@redux/modules"

const watchEntityKeys = ['accountingSystems']

type AccountingSystemFilters = {
  string?: string,
}

type UseAccountingSystemsOptions = {
  filters?: AccountingSystemFilters,
  performHttpRequests?: boolean,
  requestOptions?: AccountingSystemRequestOptions,
}

function useAccountingSystems(options: UseAccountingSystemsOptions = {}) {
  const { performHttpRequests } = options

  const {
    updatedEntities: { accountingSystems: accountingSystemsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { accountingSystems } = useSelector(reduxState => reduxState.entities)

  const filteredAccountingSystems = useMemo(() => {
    const filtered = Object.values(accountingSystems)
    return sortArrayBy(filtered, 'asc', 'name')
  }, [accountingSystemsUpdatedAt])

   useReduxAction(
    'accountingSystems',
    'loadAccountingSystems',
    {},
    [performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    filteredAccountingSystems,
  }
}

export default useAccountingSystems
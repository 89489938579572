import { useMemo } from 'react'

import { matchFilterNumber, sortArrayBy } from '@campaignhub/javascript-utils'
import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ContactUserModel } from '@models/contactUser'

const watchEntityKeys = ['contactUsers']

interface useContactUsersOptions {
  filters: {
    contactId?: number,
    isPrimary?: boolean,
    userId?: number,
  },
  performHttpRequests?: boolean,
}

function useContactUsers(options: useContactUsersOptions) {
  const { filters, performHttpRequests } = options || {}

  const {
    contactId: filterContactId,
    isPrimary: filterIsPrimary,
    userId: filterUserId,
  } = filters || {}

  const {
    updatedEntities: { contactUsers: contactUsersUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const entities = useSelector(reduxState => reduxState.entities)
  const { contactUsers } = entities || {}

  const filteredContactUsers = useMemo(() => {
    const array = Object.values(contactUsers).filter((contactUser: ContactUserModel) => {
      const { contactId, isPrimary, userId } = contactUser || {}

      const contactMatch = matchFilterNumber(contactId, filterContactId)
      const primaryContactMatch = filterIsPrimary ? isPrimary === filterIsPrimary : true
      const userMatch = matchFilterNumber(userId, filterUserId)

      return contactMatch
      && primaryContactMatch
      && userMatch
    })

    return sortArrayBy(array, 'asc', 'name')
  }, [contactUsersUpdatedAt])

  const primaryContactUser = filterIsPrimary ? filteredContactUsers[0] : null

  const { loading } = useReduxAction(
    'contactUsers',
    'loadContactUsers',
    {
      ...filters,
    },
    [performHttpRequests],
    { shouldPerformFn: ({ loading }) => performHttpRequests && !loading },
  )

  return {
    contactUsersCount: filteredContactUsers.length,
    filteredContactUsers,
    hasContactUsers: !!filteredContactUsers.length,
    loading,
    primaryContactUser,
  }
}
export default useContactUsers

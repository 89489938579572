import { Box } from '@campaignhub/suit-theme'

import useTransactionItems from '@hooks/useTransactionItems'

import type { TransactionModel, TransactionItemModel } from '@models/types'

import TransactionTableHeader from './components/TransactionTableHeader'
import TransactionItem from './components/TransactionItem'

interface TransactionItemsProps {
  editing: boolean,
  transaction: TransactionModel,
}

const TransactionItems = (props: TransactionItemsProps) => {
  const { editing: editingInvoice, transaction } = props
  const { id: transactionId } = transaction || {}

  const transactionItemsPayload = useTransactionItems({
    filters: {
      transactionId,
    },
    performHttpRequests: !!transactionId,
    requestOptions: {
      includeTransactionItemBusinessUnitProduct: true,
    },
  })
  const {
    filteredTransactionItems,
  } = transactionItemsPayload

  return (
    <Box
      display="block"
      flexDirection="column"
      overflowX="auto"
    >
      <Box flexDirection="column" minWidth="100%" width="650px">
        <TransactionTableHeader editingInvoice={editingInvoice} />

        {filteredTransactionItems.map((transactionItem: TransactionItemModel) => (
          <TransactionItem editingInvoice={editingInvoice} transactionItem={transactionItem} />
        ))}
      </Box>
    </Box>
  )
}

export default TransactionItems

import { Columns, LoadingModule } from '@campaignhub/suit-theme'

import useContact from '@hooks/useContact'

import Subscriptions from '@components/Subscriptions'

import PageLayout from '../../../../components/PageLayout'

import Tools from '../Tools'

const PageContent = (props: { contactId: number }) => {
  const { contactId } = props

  const contactPayload = useContact({ id: contactId })

  const { callbacks, contact, loading } = contactPayload
  const { name } = contact || {}

  return (
    <PageLayout entityPayload={contactPayload} activeTabBarItemKey="subscriptions">
      <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
        <Columns.Main>
          <LoadingModule loading={loading} />

          {!loading && (
            <Subscriptions
              options={{
                filters: {
                  contactId,
                },
                performHttpRequests: !!contactId,
                requestOptions: {
                  includeSubscriptionTotals: !!contactId,
                },
              }}
            />
          )}
        </Columns.Main>

      </Columns>
    </PageLayout>
  )
}

export default PageContent

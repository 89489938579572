import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import BusinessUnitLogo from '@components/BusinessUnitLogo'

import { generateUrls } from '@hooks/useBusinessUnit'

import { BusinessUnitModel } from '@models/businessUnit'

interface BusinessUnitCreditsListItemProps {
  businessUnit: BusinessUnitModel,
}

const BusinessUnitCreditsListItem = (props: BusinessUnitCreditsListItemProps) => {
  const {
    businessUnit: {
      id,
      key,
      name,
      totalPendingCredits,
      totalPendingCreditsAmount,
    },
  } = props

  const { businessUnitCreditsUrl } = generateUrls({ id })

  return (
    <ListItem boxProps={{ as: 'a', borderBottom: '1px dashed', padding: 'large' }} href={businessUnitCreditsUrl} showAngleIcon>
      <BusinessUnitLogo businessUnitKey={key} />

      <Box flexDirection="column" maxWidth={['45%', '70%']} marginRight="medium" justifyContent="center">
        <Text fontSize="small" variant="ellipsis">
          {name}
        </Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="small" variant="ellipsis">
          {totalPendingCredits} Pending Credits
        </Text>
      </Box>

      <Box alignItems="flex-end" flexDirection="column" justifyContent="center" marginLeft="auto">
        <Text fontSize="small">{`$${totalPendingCreditsAmount?.toFixed(2)}`}</Text>
      </Box>
    </ListItem>
  )
}

export default BusinessUnitCreditsListItem

import { Columns, LoadingModule } from '@campaignhub/suit-theme'

import useBusinessUnit from '@hooks/useBusinessUnit'

import Subscriptions from '@components/Subscriptions'
import SubscribersGraph from '@components/SubscribersGraph'
import SubscriptionStatistics from '@components/SubscriptionStatistics'

import PageLayout from '../../../../components/PageLayout'

import Tools from '../Tools'

const PageContent = (props: { businessUnitId: number }) => {
  const { businessUnitId } = props

  const businessUnitPayload = useBusinessUnit({ id: businessUnitId })

  const { businessUnit, callbacks, loading } = businessUnitPayload
  const { name } = businessUnit || {}

  return (
    <PageLayout entityPayload={businessUnitPayload} activeTabBarItemKey="subscriptions">
      <Columns flexDirection={['column', 'column', 'row']}>
        <Columns.Main>
          <LoadingModule loading={loading} />

          <Subscriptions
            options={{
              filters: {
                businessUnitId,
              },
              performHttpRequests: !!businessUnitId,
              requestOptions: {
                includeSubscriptionContact: !!businessUnitId,
                includeSubscriptionTotals: !!businessUnitId,
              },
            }}
          />
        </Columns.Main>

        <Columns.Sidebar>
          {/* <Tools callbacks={callbacks} /> */}

          <SubscriptionStatistics />

          <SubscribersGraph />
        </Columns.Sidebar>
      </Columns>
    </PageLayout>
  )
}

export default PageContent

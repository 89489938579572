import { useMemo } from 'react'

import { matchFilterKey, matchFilterNumber, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { EntityOptions } from '@redux/modules/module'
import type { ActivityModel } from '@models/types'

const watchEntityKeys = ['activities']

type ActivityFilters = {
  recipientId?: number,
  recipientType?: string,
}

type UseActivitiesOptions = {
  filters?: ActivityFilters,
  performHttpRequests?: boolean,
  requestOptions?: EntityOptions,
}

function useActivities(options: UseActivitiesOptions) {
  const { filters = {}, requestOptions } = options
  const {
    recipientId: filterRecipientId,
    recipientType: filterRecipientType,
  } = filters

  const {
    updatedEntities: { activities: activitiesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { activities } = useSelector(reduxState => reduxState.entities)

  // Filter redux entities with provided filters
  const filteredActivities = useMemo(() => {
    const filtered = Object.values(activities).filter((activity: ActivityModel) => {
      const {
        recipientId, recipientType, trackableId, trackableType,
      } = activity

      const matchRecipientId = matchFilterNumber(recipientId, filterRecipientId)
      const matchRecipientType = matchFilterKey(recipientType, filterRecipientType)
      const matchRecipient = matchRecipientId && matchRecipientType

      // Backend matches against recipient or trackable
      const matchTrackableId = matchFilterNumber(trackableId, filterRecipientId)
      const matchTrackableType = matchFilterKey(trackableType, filterRecipientType)
      const matchTrackable = matchTrackableId && matchTrackableType

      return (matchRecipient || matchTrackable)
    })

    return sortArrayBy(filtered, 'desc', 'id')
  }, [activitiesUpdatedAt, JSON.stringify(filters)])

  const filteredActivitiesCount = filteredActivities.length
  const hasFilteredActivities = !!filteredActivitiesCount

  // To be used in conjunction with DashboardModule.LoadMoreFooter
  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredActivitiesCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  // Load entities when performHttpRequests is true
  const { loading: loadingActivities } = useReduxAction(
    'activities',
    'loadActivities',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredActivities,
    filteredActivitiesCount,
    hasFilteredActivities,
    loading: loadingActivities,
  }
}

export default useActivities

import { useMemo } from 'react'

import { matchFilterNumber, sortArrayBy } from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { BillingNoteModel, BillingNoteRequestOptions } from '@models/billingNote'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['billingNotes']

type BillingNoteFilters = {
  contactId?: number,
  userId?: number,
}

type UseBillingNotesOptions = {
  filters?: BillingNoteFilters,
  performHttpRequests?: boolean,
  requestOptions?: BillingNoteRequestOptions,
}

function useBillingNotes(options: UseBillingNotesOptions) {
  const { filters = {}, performHttpRequests, requestOptions } = options
  const {
    contactId: filterContactId,
    userId: filterUserId,
  } = filters

  const {
    updatedEntities: { billingNotes: billingNotesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { billingNotes } = useSelector(reduxState => reduxState.entities)

  const filteredBillingNotes = useMemo(() => {
    const filtered = Object.values(billingNotes).filter((billingNote: BillingNoteModel) => {
      const { contactId, userId } = billingNote

      const matchContactId = matchFilterNumber(contactId, filterContactId)
      const matchUserId = matchFilterNumber(userId, filterUserId)

      return matchContactId && matchUserId
    })

    return sortArrayBy(filtered, 'desc', 'dateCreated')
  }, [billingNotesUpdatedAt, JSON.stringify(filters)])

  const filteredBillingNotesCount = filteredBillingNotes.length
  const hasFilteredBillingNotes = !!filteredBillingNotesCount

  const latestBillingNote = filteredBillingNotes[0]

  const { loading: loadingBillingNotes } = useReduxAction(
    'billingNotes',
    'loadBillingNotes',
    {
      ...filters,
      ...requestOptions,
    },
    [filters, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    filteredBillingNotes,
    filteredBillingNotesCount,
    hasFilteredBillingNotes,
    latestBillingNote,
    loading: loadingBillingNotes,
  }
}

export default useBillingNotes

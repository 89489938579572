import { DashboardModuleListItem } from '@campaignhub/suit-theme'

import { ContactModel } from '@models/contact'

import { generateUrls } from '@hooks/useContact'
import useCurrentUser from '@hooks/useCurrentUser'

interface ContactListItemProps {
  contact: ContactModel,
}

const ContactListItem = (props: ContactListItemProps) => {
  const { contact } = props
  const { id, name } = contact

  const { editContactUrl } = generateUrls(contact)

  const { isAdmin } = useCurrentUser()

  return (
    <DashboardModuleListItem
      title={name}
      href={editContactUrl}
    >
      {isAdmin && (
        <DashboardModuleListItem.TertiaryText>
          #{id}
        </DashboardModuleListItem.TertiaryText>
      )}
    </DashboardModuleListItem>
  )
}

export default ContactListItem

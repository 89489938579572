import { useMemo } from 'react'

import { matchFilterNumber, sortArrayBy } from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { SubscriptionItemModel } from '@models/subscriptionItem'

const watchEntityKeys = ['subscriptionItems']

export interface SubscriptionItemsPayload {
  callbacks: {
    generateCSV: () => void,
  },
  filteredSubscriptionItems: SubscriptionItemModel[],
  hasFilteredSubscriptionItems: boolean,
  loading: boolean,
}

export interface UseSubscriptionItemsProps {
  filters: {
    businessUnitProductId?: number,
    contactId?: number,
    subscriptionId?: number,
  },
  performHttpRequests: boolean,
  requestOptions?: {
    [key: string]: boolean,
  },
}

function useSubscriptionItems(options: UseSubscriptionItemsProps) {
  const { filters, performHttpRequests, requestOptions } = options || {}

  const {
    contactId: filterContactId,
    businessUnitProductId: filterBusinessUnitProductId,
    subscriptionId: filterSubscriptionId,
  } = filters || {}

  const {
    updatedEntities: { subscriptionItems: subscriptionItemsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { loading: loadingSubscriptionItems } = useSelector(reduxState => reduxState.subscriptionItems)

  const entities = useSelector(reduxState => reduxState.entities)
  const { subscriptionItems } = entities

  const filteredSubscriptionItems = useMemo(() => {
    const filtered = Object.values(subscriptionItems).filter((subscriptionItem) => {
      const { businessUnitProductId, subscriptionId } = subscriptionItem

      const matchBusinessUnitProduct = matchFilterNumber(businessUnitProductId, filterBusinessUnitProductId)
      const matchSubscription = matchFilterNumber(subscriptionId, filterSubscriptionId)

      return matchBusinessUnitProduct
        && matchSubscription
    })

    return sortArrayBy(filtered, 'asc', 'name')
  }, [subscriptionItemsUpdatedAt, JSON.stringify(filters)])

  useReduxAction(
    'subscriptionItems',
    'loadSubscriptionItems',
    {
      ...filters,
      ...requestOptions,
    },
    [filters, performHttpRequests],
    {
      shouldPerformFn: ({ loading }) => performHttpRequests && !loading,
    },
  )

  return {
    filteredSubscriptionItems,
    hasFilteredSubscriptionItems: !!filteredSubscriptionItems.length,
    loading: loadingSubscriptionItems,
  }
}

export default useSubscriptionItems

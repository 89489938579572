import { Route, Routes } from 'react-router-dom'

import Credits from './screens/Credits'
import Details from './screens/Details'
import Invoices from './screens/Invoices'
import Overview from './screens/Overview'
import Statements from './screens/Statements'
import Subscriptions from './screens/Subscriptions'

const ContactEditRoutes = (props: { contactId: number }) => {
  const { contactId } = props || {}

  return (
    <Routes>
      <Route index element={<Overview contactId={contactId} />} />
      <Route path="/credits" element={<Credits contactId={contactId} />} />
      <Route path="/details" element={<Details contactId={contactId} />} />
      <Route path="/invoices" element={<Invoices contactId={contactId} />} />
      <Route path="/statements" element={<Statements contactId={contactId} />} />
      <Route path="/subscriptions" element={<Subscriptions contactId={contactId} />} />
    </Routes>
  )
}

export default ContactEditRoutes

import { useMemo } from 'react'

import { ModalContext } from '@campaignhub/suit-theme'
import { useModals, useSetState, useShowModal } from '@campaignhub/react-hooks'
import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import CreateBillingNoteModal from '@modals/CreateBillingNoteModal'
import CreateOrEditContactPaymentMethodModal from '@modals/CreateOrEditContactPaymentMethodModal'
import GenerateStatementModal from '@modals/GenerateStatementModal'
import ImportUsersModal from '@modals/ImportUsersModal'
import ManageContactUserModal from '@modals/ManageContactUserModal'
import RequestPaymentMethodModal from '@modals/RequestPaymentMethodModal'

import PageContent from './components/PageContent'

export type PageContextPayload = {
  callbacks: {
    showCreateOrEditContactPaymentMethodModal: () => void,
    deleteContactPaymentMethod: (payload: HandleCallbackActionParams) => void,
    updateContactPaymentMethod: (payload: HandleCallbackActionParams) => void,
  },
}

const defaultState = {
  showCreateBillingNoteModal: false,
  showCreateOrEditContactPaymentMethodModal: false,
  showGenerateStatementModal: false,
  showImportUsersModal: false,
  showManageContactUserModal: false,
  showRequestPaymentMethodModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    CreateBillingNoteModal: {
      closeModal: () => setState({ showCreateBillingNoteModal: false }),
      createBillingNote: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
    CreateOrEditContactPaymentMethodModal: {
      closeModal: () => setState({ showCreateOrEditContactPaymentMethodModal: false }),
      createContactPaymentMethod: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      deleteContactPaymentMethod: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      updateContactPaymentMethod: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
    GenerateStatementModal: {
      closeModal: () => setState({ showGenerateStatementModal: false }),
      generateStatement: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
    ImportUsersModal: {
      closeModal: () => setState({ showImportUsersModal: false }),
      importUsers: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
    ManageContactUserModal: {
      closeModal: () => setState({ showManageContactUserModal: false }),
      deleteContactUser: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      updateContactUser: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
    RequestPaymentMethodModal: {
      closeModal: () => setState({ showRequestPaymentMethodModal: false }),
      requestContactPaymentMethod: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const Details = (props: { contactId: number }) => {
  const { contactId } = props || {}

  const [state, setState] = useSetState(defaultState)
  const {
    showCreateBillingNoteModal,
    showCreateOrEditContactPaymentMethodModal,
    showGenerateStatementModal,
    showImportUsersModal,
    showManageContactUserModal,
    showRequestPaymentMethodModal,
  } = state

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      deleteContactPaymentMethod: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      deleteContactUser: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      showCreateBillingNoteModal: (payload) => {
        setModalData('CreateBillingNoteModal', payload)
        setState({ showCreateBillingNoteModal: true })
      },
      showCreateOrEditContactPaymentMethodModal: (payload) => {
        setModalData('CreateOrEditContactPaymentMethodModal', payload)
        setState({ showCreateOrEditContactPaymentMethodModal: true })
      },
      showGenerateStatementModal: (payload) => {
        setModalData('GenerateStatementModal', payload)
        setState({ showGenerateStatementModal: true })
      },
      showImportUsersModal: () => { setState({ showImportUsersModal: true }) },
      showManageContactUserModal: (payload) => {
        setModalData('ManageContactUserModal', payload)
        setState({ showManageContactUserModal: true })
      },
      showRequestPaymentMethodModal: () => {
        setState({ showRequestPaymentMethodModal: true })
      },
      toggleActive: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      updateContact: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }), [])

  const { showModal } = parsePermittedQueryParams(getQueryParamsFromHash('CreateOrEditContactPaymentMethodModal'), ['showModal'])

  // Open the CreateOrEditContactPaymentMethodModal from external email link
  // Open the ImportUsersModal from createSubscriptionModal if contact has no users
  useShowModal({
    modalKey: 'CreateOrEditContactPaymentMethodModal',
    options: {
      callbacks: {
        showCreateOrEditContactPaymentMethodModal: showModal,
      },
    },
  })

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent contactId={contactId} />

        <CreateBillingNoteModal
          callbacks={callbacks('CreateBillingNoteModal', setState)}
          showModal={showCreateBillingNoteModal}
        />

        <CreateOrEditContactPaymentMethodModal
          callbacks={callbacks('CreateOrEditContactPaymentMethodModal', setState)}
          showModal={showCreateOrEditContactPaymentMethodModal}
          contactPaymentMethod={{ contactId }}
        />

        <GenerateStatementModal
          callbacks={callbacks('GenerateStatementModal', setState)}
          showModal={showGenerateStatementModal}
        />

        <ImportUsersModal
          callbacks={callbacks('ImportUsersModal', setState)}
          contactId={contactId}
          showModal={showImportUsersModal}
        />

        <ManageContactUserModal
          callbacks={callbacks('ManageContactUserModal', setState)}
          showModal={showManageContactUserModal}
        />

        <RequestPaymentMethodModal
          callbacks={callbacks('RequestPaymentMethodModal', setState)}
          contactId={contactId}
          showModal={showRequestPaymentMethodModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Details

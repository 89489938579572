import { Form, SidebarModal } from '@campaignhub/suit-theme'

import Recipient from './Recipient'

interface RecipientsProps {
  callbacks: {
    toggleRecipient: (userId: number) => void,
  },
  userIds: number[],
}

const Recipients = (props: RecipientsProps) => {
  const { callbacks, userIds } = props || {}
  const { toggleRecipient } = callbacks || {}

  const hasRecipients = !!userIds?.length

  return (
    <Form.Field label="Recipients" marginTop="large">
      {!hasRecipients && (
        <SidebarModal.NoticeBox boxProps={{ marginBottom: 'medium' }}>
          <SidebarModal.NoticeBox.Title>
            No Recipients
          </SidebarModal.NoticeBox.Title>

          <SidebarModal.NoticeBox.Paragraph>
            Add a recipient to recieve this request payment method email.
          </SidebarModal.NoticeBox.Paragraph>
        </SidebarModal.NoticeBox>
      )}

      {hasRecipients && userIds.map((userId: number) => (
        <Recipient
          callbacks={{
            toggleRecipient: () => toggleRecipient(userId),
          }}
          key={userId}
          userId={userId}
        />
      ))}
    </Form.Field>
  )
}

export default Recipients

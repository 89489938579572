import type {
  ContactModel, TransactionItemModel, TransactionTypeModel, StatusModel,
} from './types'

export type TransactionRequestOptions = {
  includeTransactionContact?: boolean,
  includeTransactionStatus?: boolean,
  includeTransactionTransactionItems?: boolean,
  includeTransactionTransactionType?: boolean,
}

export type TransactionModel = {
  businessUnitId?: number,
  cacheKey?: string,
  contact?: ContactModel,
  contactId?: number,
  countryId?: number,
  currency?: string,
  dateCreated?: string,
  dateExported?: string,
  dateUpdated?: string,
  id?: number,
  incTaxTotal?: number,
  overrideTotal?: number,
  referenceCode?: string,
  status?: StatusModel,
  statusId?: number,
  stripeReferenceKey?: string,
  tax?: number,
  title?: string,
  transactionItems?: TransactionItemModel[],
  transactionType?: TransactionTypeModel,
  transactionTypeId?: number,
}

const state = {
  businessUnitId: null,
  contactId: null,
  countryId: null,
  currency: '',
  dateCreated: '',
  dateExported: '',
  dateUpdated: '',
  id: null,
  incTaxTotal: null,
  overrideTotal: null,
  referenceCode: '',
  statusId: null,
  stripeReferenceKey: '',
  tax: null,
  title: '',
  transactionItems: [],
  transactionTypeId: null,
}

export const requiredFields = [
  { key: 'firstName' },
  { key: 'lastName' },
]

export default state

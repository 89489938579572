import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useDebounce, useSetState, useThunkDispatch } from '@campaignhub/react-hooks'

import { digObject, sortArrayBy, toggleArray } from '@campaignhub/javascript-utils'

import useContact from '@hooks/useContact'

import * as userActions from '@redux/modules/user'

import type { AppDispatch } from '@redux/store'
import type { ContactModel, UserModel } from '@models/types'
import type { EntitiesState } from '@redux/entities'

export type RealbaseUser = {
  id: number,
  fullName: string,
  [key: string]: any,
}

type State = {
  loading: boolean,
  realbaseUsers: RealbaseUser[],
  selectedUserIds: number[],
  string: string,
}

const defaultState: State = {
  loading: false,
  realbaseUsers: [],
  selectedUserIds: [],
  string: '',
}

type SetState = (state: Partial<typeof defaultState>) => void

export type LoadFromRealbaseParams = {
  dispatch: AppDispatch,
  fetchRequestParams: {
    contactTypeId: number,
    realbaseId: number,
    string?: string,
  },
  setState: SetState,
}

const loadFromRealbase = (params: LoadFromRealbaseParams) => {
  const { dispatch, fetchRequestParams, setState } = params
  const { loadFromRealbase: loadFromRealbaseFn } = userActions

  dispatch(loadFromRealbaseFn(fetchRequestParams))
    .then(({ success, data }) => {
      if (success){
        setState({ realbaseUsers: data, loading: false })
      }
      setState({ loading: false })
    })
}

type ImportUsersParams = {
  dispatch: AppDispatch,
  payload: {
    contactId: number,
    selectedUserIds: number[],
  },
}

const importUsers = (params: ImportUsersParams) => {
  const { payload, dispatch } = params
  const { importUsers: importFn } = userActions

  return dispatch(importFn(payload))
}

type GetAlreadyImportedUserIdsParams = {
  contact: ContactModel,
  reduxUsers: Pick<EntitiesState, 'users'>,
}

const getAlreadyImportedUserIds = (params: GetAlreadyImportedUserIdsParams) => {
  const { contact, reduxUsers } = params || {}
  const contactUserIds = digObject(contact, 'user_ids', [])

  const alreadyImportedRealbaseIds = Object.values(reduxUsers).reduce((acc, user: UserModel) => {
    const { id: userId, realbaseId } = user

    if (contactUserIds.includes(userId) && realbaseId){
      acc.push(realbaseId)
    }

    return acc
  }, [])

  return alreadyImportedRealbaseIds
}

type UseImportUsersProps = {
  contactId: number,
}

const useImportUsers = (props: UseImportUsersProps) => {
  const { contactId } = props || {}

  const contactPayload = useContact({ id: contactId }, { performHttpRequests: !!contactId })
  const { contact } = contactPayload || {}
  const { contactTypeId, realbaseId: contactRealbaseId } = contact || {}

  const [state, setState] = useSetState(defaultState)
  const {
    loading,
    realbaseUsers,
    selectedUserIds,
    string,
  } = state

  const dispatch = useThunkDispatch()

  const debouncedString = useDebounce(string, 300)

  const { entities } = useSelector(reduxState => reduxState)
  const { contactTypes, users: reduxUsers } = entities

  const contactType = digObject(contactTypes, String(contactTypeId), {})
  const isIndividualContact = contactType.key === 'user'

  // For Contact of type 'Individual' - only performing search after search string entered
  // Only show available users after a fetch request has been performed
  const showAvailableUsers = isIndividualContact ? !!debouncedString : !!contactId

  // Create an array of realbase_ids for users that have already been imported
  // Push the realbaseId if the user has already been imported
  const alreadyImportedRealbaseIds = getAlreadyImportedUserIds({ reduxUsers, contact })

  // Re-fetch users whenever search string change
  useEffect(() => {
    if (contactId && contactTypeId && contactRealbaseId){
      const fetchRequestParams = {
        contactTypeId,
        realbaseId: contactRealbaseId,
        string: debouncedString,
      }

      // For Contact of type 'Individual'
      // Only perform search after user enters search string
      if (isIndividualContact){
        if (debouncedString){
          loadFromRealbase({ fetchRequestParams, dispatch, setState })
          setState({ realbaseUsers: [], loading: true })
        }

        return
      }

      loadFromRealbase({ fetchRequestParams, dispatch, setState })
      setState({ realbaseUsers: [], loading: true })
    }
  }, [contactId, debouncedString])

  return {
    alreadyImportedRealbaseIds,
    callbacks: {
      importUsers: payload => importUsers({ payload, dispatch }),
      setState,
      toggleUser: (id: number) => setState({ selectedUserIds: toggleArray(selectedUserIds, id) }),
    },
    hasRealbaseUsers: !!realbaseUsers.length,
    hasSelectedUsers: !!selectedUserIds.length,
    loading,
    realbaseUsers: sortArrayBy(realbaseUsers, 'asc', 'fullName'),
    selectedUserIds,
    showAvailableUsers,
    string,
  }
}

export default useImportUsers

import useContactUser from '@hooks/useContactUser'

import UserListItem from '@components/UserListItem'

import type { ContactUserModel } from '@models/types'

interface ContactUserProps {
  contactUser: ContactUserModel,
  editable: boolean,
}

const ContactUser = (props: ContactUserProps) => {
  const { contactUser, editable } = props

  const contactUserPayload = useContactUser(contactUser)
  const {
    callbacks: {
      manageContactUser,
    },
    contactUser: {
      isPrimary,
      userEmail,
      userFullName,
    },
  } = contactUserPayload

  return (
    <UserListItem
      onClick={editable ? () => manageContactUser() : undefined}
      secondaryText={`${userEmail}${isPrimary ? ' (Primary)' : ''}`}
      title={userFullName}
    />
  )
}

export default ContactUser

import { DashboardModule } from '@campaignhub/suit-theme'

import useInvoiceSummaryByStatus from '@hooks/useInvoiceSummaryByStatus'

import InvoiceSummaryItem from './InvoiceSummaryItem'

interface InvoicesSummaryProps {
  filters?: {
    businessUnitId?: number,
    contactId?: number,
  },
  href?: string,
}

// Recent Invoices - Invoice Summary by Status
const InvoicesSummary = (props: InvoicesSummaryProps) => {
  const { filters, href } = props

  const invoicesSummaryPayload = useInvoiceSummaryByStatus({ filters })
  const { invoiceSummaryItems, loading } = invoicesSummaryPayload

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column' }}
      headerRight={href ? (
        <DashboardModule.Link href={href}>
          View All
        </DashboardModule.Link>
      ) : ''}
      loading={loading}
      title="Recent Invoices"
    >
      {invoiceSummaryItems.map(invoiceSummaryItem => (
        <InvoiceSummaryItem
          key={invoiceSummaryItem.key}
          invoiceSummaryItem={invoiceSummaryItem}
        />
      ))}
    </DashboardModule>
  )
}

export default InvoicesSummary

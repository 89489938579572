import { useMemo } from 'react'
import { formatMoney } from 'accounting'

import { Link, Table, Text } from '@campaignhub/suit-theme'
import { formatDate, goToEntity } from '@campaignhub/javascript-utils'

import { generateUrls as generateInvoiceUrls } from '@hooks/useInvoice'
import useCreditAllocations from '@hooks/useCreditAllocations'

import { CreditAllocationModel, CreditModel } from '@models/types'

interface HistoryTableProps {
    credit: CreditModel,
  }

const buildColumns = () => [
  {
    cellProps: {
      key: 'date',
      style: {
        verticalAlign: 'middle',
      },
    },
    title: 'Date',
    dataKey: 'date',
    render: (date: string) => (
      <Text variant="ellipsis" width={220}>
        {date && formatDate(date, 'ISO8601', "ccc, dd LLL yyyy 'at' h:mm a")}
      </Text>
    ),
  },
  {
    cellProps: {
      key: 'Amount',
      style: {
        verticalAlign: 'middle',
      },
    },
    title: 'Amount',
    dataKey: 'amount',
    render: (amount: string) => (
      <Text variant="ellipsis" width={150}>{formatMoney(amount)}</Text>
    ),
  },
  {
    cellProps: {
      key: 'invoiceNumber',
      style: {
        verticalAlign: 'middle',
      },
    },
    title: 'Inv Number #',
    dataKey: 'invoiceNumber',
    render: (invoiceNumber: string, creditAllocation: CreditAllocationModel) => {
      const { invoiceId } = creditAllocation || {}

      return (
        <Link
          disabledUnderline
          onClick={() => goToEntity({
            generateUrls: () => generateInvoiceUrls({ id: invoiceId }),
            entityUrlKey: 'editInvoiceUrl',
          })}
        >
          {invoiceNumber}
        </Link>
      )
    },
  },
]

const CreditHistoryTable = (props: HistoryTableProps) => {
  const { credit } = props
  const { id } = credit || {}

  // Credit Allocations are loaded via Credit IncludeCreditCreditAllocations
  const creditAllocationsPayload = useCreditAllocations({ filters: { creditId: id } })
  const { filteredCreditAllocations } = creditAllocationsPayload

  const memoColumns = useMemo(() => buildColumns(), [filteredCreditAllocations])

  return (
    <Table
      boxProps={{
        border: 'none', maxHeight: 500, overflowY: 'auto',
      }}
      columns={memoColumns}
      data={filteredCreditAllocations}
      stickyHeader
    />
  )
}

export default CreditHistoryTable

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/pro-light-svg-icons'

import { DashboardModule } from '@campaignhub/suit-theme'

import useContactPaymentMethods from '@hooks/useContactPaymentMethods'
import usePaymentMethods from '@hooks/usePaymentMethods'

import PaymentMethodListItem from '@components/PaymentMethodListItem'

import type { ContactPaymentMethodModel } from '@models/types'
import type { SubscriptionPayload } from '@hooks/useSubscription'

const BillingDetails = (props: { subscriptionPayload: SubscriptionPayload }) => {
  const { subscriptionPayload } = props
  const { loading: loadingSubscription, subscription: { contactId } } = subscriptionPayload

  // load Payment Methods
  const { loading: loadingPaymentMethods } = usePaymentMethods({ performHttpRequests: !!contactId })

  const contactPaymentMethodsPayload = useContactPaymentMethods({
    filters: {
      contactId,
      isDefault: !!contactId,
    },
    performHttpRequests: true,
    requestOptions: {
      includeContactPaymentMethodPaymentMethod: true,
    },
  })

  const {
    filteredContactPaymentMethods,
    hasContactPaymentMethod,
    loading: loadingContactPaymentMethods,
  } = contactPaymentMethodsPayload

  const loading = loadingContactPaymentMethods && loadingPaymentMethods && loadingSubscription

  // TODO Confirm with Darrell do we want to display the billing contact (name & email) here as well

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column', height: '100%' }}
      loading={loading}
      title="Billing Details"
    >

      {!loading && hasContactPaymentMethod && filteredContactPaymentMethods.map((contactPaymentMethod: ContactPaymentMethodModel) => (
        <PaymentMethodListItem
          key={contactPaymentMethod?.id}
          contactPaymentMethod={contactPaymentMethod}
        />
      ))}

      {/* <Button
        buttonStyle="secondaryUtility"
        icon={<FontAwesomeIcon icon={faExternalLink} />}
        onClick={() => console.log('Open editPaymentMethodModal')}
        size="medium"
      >
        Change Payment Method
      </Button> */}
    </DashboardModule>
  )
}

export default BillingDetails

import React, { useMemo } from 'react'
import { toast } from 'react-toastify'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import { ContactModel } from '@models/contact'

import PageContent from './components/PageContent'

const defaultState = {}

const Statements = (props: { contactId: number }) => {
  const { contactId } = props || {}

  const [state, setState] = useSetState(defaultState)

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {},
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent contactId={contactId} />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Statements

import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { StatusModel } from '@models/status'
import type { AppDispatch } from '@redux/store'
import type {
  Action, ActionResult, EntityOptions, Errors, FetchRequestPayload,
  RootReducerState,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/status/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/status/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/status/FETCH_FAILURE'

export type StatusModuleState = {
  errors: string[],
  loaded: boolean,
  loadedIds: number[],
  loading: boolean,
}

const initialState: StatusModuleState = {
  errors: [],
  loaded: false,
  loadedIds: [],
  loading: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}): Action {
  return {
    type: FETCH_REQUEST,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(): Action {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []): Action {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

// Action Creators
export function loadStatuses(options: EntityOptions) {
  return (dispatch: AppDispatch): Promise<ActionResult<StatusModel[]>> => {
    dispatch(fetchRequest())

    const promise = api('/statuses', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.STATUS_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, result: data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function loadStatus(statusId: number, options: EntityOptions = {}) {
  return (dispatch: AppDispatch, getState: () => RootReducerState): Promise<ActionResult<StatusModel>> => {
    const state = getState()
    const loadedIds = [...state.statuses.loadedIds]

    if (statusId && !loadedIds.includes(statusId)){
      loadedIds.push(statusId)
    }

    dispatch(fetchRequest({ loadedIds }))

    const promise = api(`/statuses/${statusId}`, options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.STATUS)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: StatusModuleState = initialState,
  action: Action = { type: '' },
): StatusModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        errors: [],
        loaded: true,
        loading: false,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        errors: action.errors || [],
        loaded: false,
        loading: false,
      }
    default:
      return state
  }
}

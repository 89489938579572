import { Errors } from "@campaignhub/react-hooks/build/hooks/useForm"
import { Form, Grid, Link } from "@campaignhub/suit-theme"
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { BusinessNumberModel } from "@models/businessNumber"

interface BusinessNumberItemProps {
  index: number,
  businessNumber: BusinessNumberModel,
  errors: {} | Errors,
  handleChange: (index: number, key: string) => void,
  handleDelete: (index: number) => void,
}

const BusinessNumberItem = (props: BusinessNumberItemProps) => {
  const { index, businessNumber, errors, handleChange, handleDelete } = props || {}

  return (
    <div key={index} style={{ paddingBottom: '5px'}}>
      <Grid alignItems="center"
            gridGap="large"
            gridTemplateColumns="auto auto 35px"
            marginTop="large">

          <Form.Field label="* Business Number" errorMessage={errors[index]?.number}> 
              <input
              type="text"
              value={businessNumber.number}
              onChange={handleChange(index, "number")}
              />
          </Form.Field>
          <Form.Field label="* Business Number Prefix" errorMessage={errors[index]?.prefix}>
              <input
              type="text"
              value={businessNumber.prefix}
              onChange={handleChange(index, "prefix")}
              />
          </Form.Field>
          
          {index != 0 ? 
            <Form.Field >
              <Link
                greyLink
                onClick={handleDelete}
                textProps={{ marginLeft: 'small' }}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </Link>
            </Form.Field> : null}
      </Grid>
    </div>
  )
}

export default BusinessNumberItem

import { DashboardModule, DashboardModuleListItem } from '@campaignhub/suit-theme'

import useBusinessUnit from '@hooks/useBusinessUnit'

import BusinessUnitLogo from '@components/BusinessUnitLogo'

const Details = (props: { businessUnitId: number }) => {
  const { businessUnitId } = props

  const businessUnitPayload = useBusinessUnit({ id: businessUnitId }, { performHttpRequests: !!businessUnitId })
  const {
    businessUnit,
    loading,
    urls: {
      businessUnitDetailsUrl,
    },
  } = businessUnitPayload

  const {
    businessNumber, key, name,
  } = businessUnit || {}

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column', lineHeight: 1.3 }}
      headerRight={<DashboardModule.Link href={businessUnitDetailsUrl}>View All</DashboardModule.Link>}
      loading={loading}
      title="Details"
    >
      <DashboardModuleListItem
        imageComponent={<BusinessUnitLogo businessUnitKey={key} />}
        imageVariant="square"
        title={name}
        secondaryText={`ABN: ${businessNumber}`}
      />
    </DashboardModule>
  )
}

export default Details

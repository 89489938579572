import {
  faCoins,
  faCopy,
  faCreditCardBlank,
  faFileInvoice,
  faStore,
  faTachometer,
} from '@fortawesome/pro-light-svg-icons'

import { generateUrls } from '@hooks/useBusinessUnit'

import validateSubscriptionProducts from '@functions/businessUnit'

import type { BusinessUnitModel } from '@models/businessUnit'

const generateTabBarItems = (businessUnit: BusinessUnitModel) => {
  const { key } = businessUnit || {}
  const urls = generateUrls(businessUnit)
  const {
    businessUnitCreditsUrl,
    businessUnitDetailsUrl,
    businessUnitInvoicesUrl,
    businessUnitOverviewUrl,
    businessUnitStatementsUrl,
    businessUnitSubscriptionsUrl,
  } = urls

  const hasSubcriptionProducts = validateSubscriptionProducts(key)

  const tabBarItems = [
    {
      href: businessUnitOverviewUrl,
      icon: faTachometer,
      key: 'overview',
      title: 'Overview',
    },
    {
      href: businessUnitDetailsUrl,
      icon: faStore,
      key: 'details',
      title: 'Details',
    },
    {
      href: businessUnitInvoicesUrl,
      icon: faFileInvoice,
      key: 'invoices',
      title: 'Invoices',
    },
    {
      href: businessUnitCreditsUrl,
      icon: faCreditCardBlank,
      key: 'credits',
      title: 'Credits',
    },
    // {
    //   href: businessUnitStatementsUrl,
    //   icon: faCopy,
    //   key: 'statements',
    //   title: 'Statements',
    // },
  ]

  if (hasSubcriptionProducts){
    tabBarItems.push({
      href: businessUnitSubscriptionsUrl,
      icon: faCoins,
      key: 'subscriptions',
      title: 'Subscriptions',
    })
  }

  return tabBarItems
}

export default generateTabBarItems

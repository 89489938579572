import { faComments, faHistory } from '@fortawesome/pro-light-svg-icons'

import { EntityActivityContainer } from '@campaignhub/suit-theme'

import Conversation from '@components/Conversation'

import Activity from './Activity'

import defaultRequestOptions from './defaultRequestOptions'

export const requestOptions = {
  activity: {
    ...defaultRequestOptions.activity,
  },
  conversation: {
    ...defaultRequestOptions.comment,
    ...defaultRequestOptions.conversation,
    ...defaultRequestOptions.subscriber,
  },
}

interface EntityActivityProps {
  boxProps: object,
  componentSettings?: {
    [key: string]: object,
  },
  subjectId: number,
  subjectType: string,
}

const viewOnly = JSON.parse(process.env.REACT_APP_VIEW_ONLY || "false")

const EntityActivity = (props: EntityActivityProps) => {
  const {
    boxProps, componentSettings = {}, subjectId, subjectType,
  } = props

  return (
    <EntityActivityContainer
      boxProps={{
        height: !viewOnly ? 500 : 380,
        ...boxProps,
      }}
      components={{
        activity: {
          icon: faHistory,
          label: 'Activity',
          render: (params: any) => (
            <Activity
              requestOptions={requestOptions.activity}
              {...params}
            />
          ),
        },
        conversation: {
          icon: faComments,
          label: 'Conversation',
          render: (params: any) => {
            const settings = componentSettings.conversation || {}
            const initConversation = {
              subject_id: subjectId,
              subject_type: subjectType,
              title: settings.title,
            }

            return (
              <Conversation
                callbacks={{}}
                conversation={initConversation}
                requestOptions={requestOptions.conversation}
                {...params}
              />
            )
          },
        },
      }}
      defaultSelectedComponentKey="conversation"
      subjectId={subjectId}
      subjectType={subjectType}
    />
  )
}

export default EntityActivity

import type { ContactModel, UserModel } from '@models/types'

export type ContactUserModel = {
  cacheKey?: string,
  contact?: ContactModel,
  contactId?: number,
  id: number | null,
  isAdmin?: boolean,
  isOwner?: boolean,
  isPrimary?: boolean,
  user?: UserModel,
  userEmail?: string, // Added in contactUserDTO,
  userFullName?: string, // Added in contactUserDTO,
  userId?: number,
}

const state = {
  contactId: null,
  id: null,
  isAdmin: false,
  isOwner: false,
  isPrimary: false,
  userId: null,
}

export const requiredFields = [
  { key: 'contactId' },
  { key: 'userId' },
]

export default state

import { Helmet } from 'react-helmet-async'

import { faPencil } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, LoadingModule,
} from '@campaignhub/suit-theme'
import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import useSubscription, { generateUrls } from '@hooks/useSubscription'

import AdminPageHeader from '@components/AdminPageHeader'
import EntityActivity from '@components/EntityActivity'

import { defaultRequestOptions } from '@sections/Client/packs/Accounting'

import BillingDetails from '../BillingDetails'
import Contact from '../Contact'
import Details from '../Details'
import Items from '../Items'
import Tools from '../Tools'
import Transactions from '../Transactions'

const viewOnly = JSON.parse(process.env.REACT_APP_VIEW_ONLY || "false")

const PageContent = (props: { subscriptionId: number }) => {
  const { subscriptionId } = props

  const subscriptionPayload = useSubscription(
    {
      id: subscriptionId,
    },
    {
      performHttpRequests: !!subscriptionId,
      requestOptions: {
        ...defaultRequestOptions.subscription,
      },
    },
  )
  const { callbacks, subscription, loading } = subscriptionPayload
  const { contactId, name } = subscription

  const { subscriptionsIndexUrl } = generateUrls()

  const { redirect } = parsePermittedQueryParams(getQueryParamsFromHash(), ['redirect'])

  return (
    <>
      <Helmet>
        <title>Subscription | Billing</title>
      </Helmet>

      <AdminPageHeader
        activeTabBarItemKey="edit"
        actionContent={(
          <Button
            as="a"
            buttonStyle="secondaryUtility"
            href={redirect || subscriptionsIndexUrl}
            size="medium"
          >
            Back
          </Button>
        )}
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: '#',
            icon: faPencil,
            key: 'edit',
            title: 'Edit Subscription',
          },
        ]}
        title={name || 'Subscription'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && (
              <>
                <Items subscriptionId={subscriptionId} />

                <Transactions subscription={subscription} />

                <EntityActivity
                  boxProps={{ marginTop: 'large' }}
                  componentSettings={{
                    conversation: {
                      title: name ? `Subscription #${name}` : 'Subscription',
                    },
                  }}
                  subjectType="Subscription"
                  subjectId={subscriptionId}
                />
              </>
            )}
          </Columns.Main>

          <Columns.Sidebar>
          
            {!viewOnly && <Tools subscriptionPayload={subscriptionPayload} />}

            <Details subscriptionPayload={subscriptionPayload} />

            <BillingDetails subscriptionPayload={subscriptionPayload} />

            <Contact contactId={contactId} />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent

import { useEffect } from 'react'
import { DashboardModule, Form } from '@campaignhub/suit-theme'
import { default as defaultAccountingSystemBillingState } from "@models/accountingSystemBillingEntity"
import useAccountingSystems from '@hooks/useAccountingSystems'
import { useAccountingSystemBillingEntities } from '@hooks/useAccountingSystemBillingEntities'
import { BusinessUnitFormPayload } from '@hooks/useBusinessUnit'
import useBillingEntities from '@hooks/useBillingEntities'

interface AccountingSystemProps {
  businessUnitFormPayload: BusinessUnitFormPayload,
  setValidationStatus: React.Dispatch<React.SetStateAction<boolean>>,
}

const AccountingSystem = (props: AccountingSystemProps) => {
  const { businessUnitFormPayload, setValidationStatus } = props || {}
  const {
    entityState: {
      accountingSystemBillingEntities,
      accountingSystemId,
      billingEntityId,
    },
    setEntityState
   } = businessUnitFormPayload

  const { filteredAccountingSystems } = useAccountingSystems()
  const { filteredBillingEntities } = useBillingEntities()
  const { formErrors } = useAccountingSystemBillingEntities(accountingSystemBillingEntities, setValidationStatus, setEntityState)

  const handleChange = (index: number, key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const numValue = parseInt(e.target.value)
    const newItems = [...accountingSystemBillingEntities]
    newItems[index] = { ...newItems[index], id: index, [key]: numValue }
    setEntityState({ accountingSystemBillingEntities: newItems })
    setEntityState({ [key]: numValue})
  }

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column', padding: 'large' }}
      title="Destination System"
    >
      <Form>
        <Form.Row>
          <Form.Field
            label="* Accounting System" errorMessage={formErrors[0]?.accountingSystemId}
          >
            <select
              disabled={false}
              name="accountingSystemId"
              value={accountingSystemId}
              onChange={handleChange(0, "accountingSystemId")}
            >
              <option value="">Please Select...</option>
              {filteredAccountingSystems.map(accounting => (
                  <option key={accounting.id} value={accounting.id}>
                    {accounting.name}
                  </option>
                ))}
            </select>
          </Form.Field>
        </Form.Row>
        <Form.Row marginTop="large">
          <Form.Field       
              label="* Company" errorMessage={formErrors[0]?.billingEntityId}
            >
              <select
                disabled={false}
                name="billingEntityId"
                value={billingEntityId}
                onChange={handleChange(0, "billingEntityId")}
              >
              <option value="">Please Select...</option>
              {filteredBillingEntities.map(billing => (
                  <option key={billing.id} value={billing.id}>
                    {billing.companyName}
                  </option>
                ))}
            </select>
            </Form.Field>
        </Form.Row>
      </Form>
    </DashboardModule>
  )
}

export default AccountingSystem
